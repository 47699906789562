import { IApplicationState } from 'reduxStore/types';
import { getSortedCities } from 'Components/Header/CitiesButton/utils';

export const getCurrentCity = (state: IApplicationState) => state.currentCity;
export const getCurrentCityId = (state: IApplicationState) => getCurrentCity(state).id;
export const getCurrentAddress = (state: IApplicationState) => state.currentAddress;
export const getCurrentAddressName = (state: IApplicationState) => getCurrentAddress(state).name;
export const getCitiesList = (state: IApplicationState) => getSortedCities(state.cities);
export const getCityNamesList = (state: IApplicationState) => getCitiesList(state).map(city => city.name);
export const getFullCityInfo = (state: IApplicationState) => {
  const city = getCurrentCity(state);
  const isCityContractFull = city.hasOwnProperty('phone') && city.hasOwnProperty('social_networks');
  return isCityContractFull ? city : getCitiesList(state).find(({ id }) => id === city.id);
};

export const checkIsFullAddress = (state: IApplicationState) => !!getCurrentAddress(state).apartment;

export const checkIsCoordinate = (state: IApplicationState) => {
  const coordinate = getCurrentAddress(state).coordinates;
  return coordinate?.latitude && coordinate?.longitude;
};
