import { IProduct } from 'Modules/Products/types';
import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import { formattedProductForGTag } from 'utils/tagManager/helpers';

export default function goToCheckoutPage(cartProducts: IProduct[]): void {
  analyticsManager.gtag(EnumGtagEvents.BEGIN_CHECKOUT, {
    items: cartProducts
      .filter(product => product.amount > 0 && product.available)
      .map(product => ({
        ...formattedProductForGTag(product),
      })),
  });
}
