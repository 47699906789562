/* eslint-disable */
// @ts-nocheck
import { createGlobalStyle } from 'styled-components';
import { resetStyles } from 'assets/styles/_reset';
import SupportImg from './assets/images/usedesk/support.png';
import ClientImg from './assets/images/usedesk/user.png';
import CloseImg from './assets/images/usedesk/x.svg';
import CheckImg from './assets/images/usedesk/check.svg';
import ClipImg from './assets/images/usedesk/clip.svg';
import FingerUpImg from './assets/images/usedesk/finger_up.png';
import SadFaceImg from './assets/images/usedesk/face.png';
import ChatIcon from './assets/images/usedesk/chatIcon.svg';
import SubmitImg from './assets/images/usedesk/submit.svg';
import { colors } from './constants/colors';

export const GlobalStyle = createGlobalStyle`
  ${resetStyles}

  body {
    font-family: ${({ theme }) => theme.fontFamily}, Helvetica, Arial, sans-serif;
  }
  

  #usedesk-messenger * {
    font-family: ${({ theme }) => theme.fontFamily}, Helvetica,Arial,sans-serif !important;
    display: none!important;

    .uw__chat-messages {
      overflow-x: hidden;
    }
    .uw__chat-content {
      background-color: ${colors.titanWhite};
    }

    .uw__header {
      min-height: 62px;

      @media (max-width: 600px) {
        height: 76px;
      }

      &-title {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .uw__header__control {
      margin-right: 2px !important;
    }

    .uw__chat-message {
      padding-bottom: 20px;
    }

    .uw__chat-message-content {
      background-color: ${colors.white} !important;
      border-bottom: 0;
      border-radius: 12px;
      width: 100%;
      padding: 10px !important;

      &::after {
        content: '';
        background-image: url(/static/user.611417cd.png);
        background-repeat: no-repeat;
        position: absolute;
        background-size: contain;
        width: 48px;
        height: 48px;
        right: -60px;
        top: 0;
      }
    }

    .uw__chat-message__date {
      position: absolute;
      right: 0;
      top: 0;
      margin: 10px;
      font-size: 12px;
      color: ${colors.blackRussian};
      opacity: 0.4;
    }

    .uw__rating-service-message {
      .uw__service-chat-message {
          background-color: transparent;
          border: 0;
      }
    }

    .uw__chat-message__text {
      font-size: 13px; 
    }

    .uw__widget-layout {
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
      &__content {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }

    .uw__icon-button-box {
      width: 14px;
      height: 14px;
    }

    .uw__rating-round-button {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
      background-color: white;

      div[name="like"] {
        background-image: url(${FingerUpImg});
      }

      div[name="dislike"] {
        background-image: url(${SadFaceImg});
      }
    }

    .uw__message-form__input { height: 40px; }

    .uw__field-box > textarea {
      background-color: ${colors.ghostWhite};
      border: 0;
      border-radius: 12px;
      font-size: 12px !important;
      padding: 12px;
      height: 40px !important;
      outline: none;
      line-height: 16px;
      max-width: 325px;

      &::placeholder {
        font-size: 12px;
        color: ${colors.paleBlackRussianWeak};
      }

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        font-size: 16px;
      }
    }

    #uw-smile-button {
        display: none;
    }

    .uw__messages-counter {
      font-size: 12px;
      line-height: 7px;
    }

    #uw-attachment-button {
      margin: 5px -5px;
      padding: 0;

      .uw__file-loader-input-box {
        width: 16px;
        height: 16px;
      }

      div[name="paperclip"] {
        background-image: url(${ClipImg});
        background-size: contain;
        background-position: center;
      }
    }

    #uw-task-solved-button {
      .uw__icon {
        background-image: url(${CheckImg});
      }
    }

    #uw-close-chat-button {
      padding-right: 0px;
      .uw__icon[name="close"] {
        background-image: url(${CloseImg});
      }
    }

    #uw-main-button {
      background: url(${ChatIcon}) no-repeat center;

      .uw__round-button__image {
        display: none;
      }

      &-close {
        border-radius: 12px;
        div[icon="close"] {
          width: 12px;
          height: 12px;
        }
      }
    }

    #uw-message-submit-button {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      padding: 13px;
      border-radius: 12px;
      background-color: ${colors.mintTwo};
      margin: -5px -10px 0 -15px;

      .uw__icon-button-box {
        width: 16px;
        height: 16px;

        .uw__icon {
          background-size: contain;
        }
      }

      .uw__icon[name="submit"] {
        background-image: url(${SubmitImg});
      }
    }

    .uw__chat-message-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .uw__chat-message__avatar > .uw__avatar {
        background-image: url(${SupportImg});
        background-color: transparent;
        width: 48px;
        height: 48px;
      }
    } 

    .uw__chat-message__text {
      color: ${colors.black};
    }
    
    .uw__chat-message-content {
      &::after {
        background-image: url(${ClientImg});
        width: 48px;
        height: 48px;
      }
    }
  }
`;
