export const MOSCOW_ID = 4;
export const SPB_ID = 1;

export const HEIGHT_ROW = 48;

export const CITIES_BUTTON_ID = 'CITIES_BUTTON_ID';
export const CITIES_MODAL_ID = 'CITIES_MODAL_ID';
export const CITIES_MODAL_LIST_TEST_ID = 'CITIES_MODAL_LIST_TEST_ID';

export const MOBILE_WIDTH = 580;
