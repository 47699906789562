import { AnyAction } from 'redux';
import {
  ADD_USER_SET_LIST_ITEM,
  SET_USER_SETS_LIST,
  EDIT_USER_SET_LIST_ITEM,
  REMOVE_USER_SET_LIST_ITEM,
  TOGGLE_EDITOR,
} from './actions';
import { IUserSetEditor, IUserSetListItem } from './types';

export interface IUserSetsState {
  list: IUserSetListItem[];
  isIdle: boolean;
  editor: IUserSetEditor;
}

const userSetsInitialState: IUserSetsState = {
  list: [],
  isIdle: false,
  editor: {
    show: false,
    currentSet: null,
    modelsToAdd: [],
    hasPrevPath: false,
  },
};

const setUserSetsList = (state: IUserSetsState, payload: IUserSetListItem[]): IUserSetsState => ({
  ...state,
  list: payload,
  isIdle: true,
});
const addUserSetListItem = (state: IUserSetsState, payload: IUserSetListItem): IUserSetsState => ({
  ...state,
  list: [payload, ...state.list],
});
const editUserSetListItem = (state: IUserSetsState, payload: IUserSetListItem): IUserSetsState => {
  const updatedList = state.list.map(item => {
    if (item.id !== payload.id) return item;
    return payload;
  });

  return { ...state, list: updatedList };
};

const removeUserSetListItem = (state: IUserSetsState, payload: number): IUserSetsState => ({
  ...state,
  list: state.list.filter(userSetListItem => userSetListItem.id !== payload),
});

const toggleEditor = (state: IUserSetsState, payload: IUserSetEditor): IUserSetsState => ({
  ...state,
  editor: payload,
});

export default function userSetsReducer(
  state: IUserSetsState = userSetsInitialState,
  action: AnyAction
): IUserSetsState {
  switch (action.type) {
    case SET_USER_SETS_LIST:
      return setUserSetsList(state, action.payload);
    case ADD_USER_SET_LIST_ITEM:
      return addUserSetListItem(state, action.payload);
    case EDIT_USER_SET_LIST_ITEM:
      return editUserSetListItem(state, action.payload);
    case REMOVE_USER_SET_LIST_ITEM:
      return removeUserSetListItem(state, action.payload);
    case TOGGLE_EDITOR:
      return toggleEditor(state, action.payload);
    default:
      return state;
  }
}
