import { NODE_ENV_IS_DEVELOPMENT } from '../../../config/config';
import { EnumAFEvents } from 'constants/enums';
import { gtagEvent } from 'utils/gtag';
import { ICriteoEvent } from '../criteo';
import CriteoClient from 'utils/criteo';

interface IAFPayload {
  af_price?: number;
  af_order_id?: string;
  af_revenue?: number;
}

export function shieldingAnalyticsApi(debugInfo: string, debugData: unknown, callback: () => void): void {
  const needTestEventsInConsole = NODE_ENV_IS_DEVELOPMENT; // in cases when developing is in progress  and we don`t need really to send some events
  if (needTestEventsInConsole) {
    console.log(`--- ${debugInfo} ===>>>`, debugData);
  } else {
    callback();
  }
}

const analyticsManager = {
  af(eventName: EnumAFEvents, payload: IAFPayload) {
    if (window?.AF) {
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        af_currency: 'RUB',
        eventName,
        ...payload,
      });
    }
  },
  gtag(event: string, data?: { [key: string]: unknown }) {
    shieldingAnalyticsApi(`Gtag ---> ${event}`, data, () => {
      gtagEvent(event, data);
    });
  },
  criteo(event: ICriteoEvent) {
    CriteoClient.push(event);
  },
};

export default analyticsManager;
