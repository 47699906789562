import VkImage from 'assets/images/auth/vk.svg';
import GImage from 'assets/images/auth/g.svg';
import FbImage from 'assets/images/auth/fb.svg';

export const socialLinkTagselector = 'a.social-item';
export const userCredentialsMock = { id: 0, token: 'token' };
export const vkAuthUrlTail = `users/auth/vkontakte?desktop=true&front=localhost&user_id=${userCredentialsMock.id}&user_token=${userCredentialsMock.token}`;
export const AUTH_MODAL_SMS_TAB_TEST_ID = 'AUTH_MODAL_SMS_TAB_TEST_ID';
export const AUTH_MODAL_SMS_INPUT_TEST_ID = 'AUTH_MODAL_SMS_INPUT_TEST_ID';
export const AUTH_MODAL_RESEND_BUTTON_TEST_ID = 'AUTH_MODAL_RESEND_BUTTON_TEST_ID';
export const AUTH_MODAL_LIMIT_SMS_TEST_ID = 'AUTH_MODAL_LIMIT_SMS_TEST_ID';
export const AUTH_MODAL_SMS_TAB_INPUT_VALUE_TEST_ID = 'AUTH_MODAL_SMS_TAB_INPUT_VALUE_TEST_ID';
export const AUTH_MODAL_SMS_TAB_ERROR_TEST_ID = 'AUTH_MODAL_SMS_TAB_ERROR_TEST_ID';
export const AUTH_MODAL_SMS_TAB_RETURN_BTN_TEST_ID = 'AUTH_MODAL_SMS_TAB_RETURN_BTN_TEST_ID';

export const ENTER_PHONE_TAB_WRAPPER_TEST_ID = 'enter-phone-tab';
export const ENTER_PHONE_TAB_INPUT_TEST_ID = 'enter-phone-input';
export const ENTER_PHONE_SOCIAL_BUTTON_TEST_ID = 'enter-phone-social-button';

export const ENTER_TAX_ID_TAB_WRAPPER_TEST_ID = 'ENTER_TAX_ID_TAB_WRAPPER_TEST_ID';
export const ENTER_TAX_ID_TAB_INPUT_TEST_ID = 'ENTER_TAX_ID_TAB_INPUT_TEST_ID';
export const ENTER_TAX_ID_TAB_SUBMIT_BTN_TEST_ID = 'ENTER_TAX_ID_TAB_SUBMIT_BTN_TEST_ID';
export const ENTER_TAX_ID_TAB_RESET_BTN_TEST_ID = 'ENTER_TAX_ID_TAB_RESET_BTN_TEST_ID';

export const FORM_TAB_WRAPPER_TEST_ID = 'form-tab';
export const FORM_TAB_FORM_TEST_ID = 'form-tab-form';
export const FORM_TAB_NAME_TEST_ID = 'form-tab-name';
export const FORM_TAB_EMAIL_TEST_ID = 'form-tab-email';
export const FORM_TAB_SUBMIT_TEST_ID = 'form-tab-submit';

export const AUTH_MODAL_CONTAINER_TEST_ID = 'auth-modal-container';

export enum EnumSocials {
  Vkontakte = 'vkontakte',
  Facebook = 'facebook',
  Google = 'google',
}

export const Socials = [
  {
    name: EnumSocials.Vkontakte,
    img: VkImage,
  },
  {
    name: EnumSocials.Google,
    img: GImage,
  },
  {
    name: EnumSocials.Facebook,
    img: FbImage,
  },
];

export const authEvents = {
  login: 'login',
  phone: { name: 'login_enter_phone', data: { status: 'success' } },
  begin: 'login_begin',
};
