import React, { forwardRef, ComponentType } from 'react';
import { omit } from 'ramda';

interface IAnyProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const omitProps = (omitProps: string[]) => (SomeComponent: ComponentType<IAnyProp>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrapperComponent = forwardRef<any, IAnyProp>((props, ref) => (
    <SomeComponent {...omit(omitProps, props)} ref={ref} />
  ));

  WrapperComponent.displayName = `Wrapper-${SomeComponent.displayName || SomeComponent.name || 'unknown'}`;

  return WrapperComponent;
};
