import { useSelector } from 'react-redux';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { ICity } from 'Modules/SearchAddress/types';
import Cookie from 'js-cookie';
import { REJECTED_CITY_CHECK, getCookieDomain } from 'constants/cookie';
import { updCitySubdomain } from 'Components/Header/CitiesButton/utils';

export const useUpdCity = () => {
  const { subdomain } = useSelector(getCurrentCity);

  const changeCity = (city: ICity) => {
    Cookie.set(REJECTED_CITY_CHECK, 'true', getCookieDomain());
    updCitySubdomain(subdomain, city.subdomain);
  };

  return changeCity;
};
