import Http from 'lib/http';
import { IListProductsResponse } from 'Modules/Products/types';
import { ICategoryChild, IGetCategoriesResponse, IGetFavoritesCategoriesResponse, IProductLineResponse } from './types';

export const getCategories = (shop_id: number, fetcher = Http): Promise<IGetCategoriesResponse> => {
  return fetcher.get(`shops/${shop_id}/categories`);
};

export const getFavoritesCategories = (shop_id: number, fetcher = Http): Promise<IGetFavoritesCategoriesResponse> => {
  return fetcher.get(`shops/${shop_id}/favorites/categories`);
};

export const defaultProductListParams = {
  sort: 'popularity',
  sort_order: 'desc',
};

export const fetchCategoryProducts = (
  shop_id: number,
  categoryId: string,
  params?: object,
  fetcher = Http
): Promise<IListProductsResponse> =>
  fetcher.get(`shops/${shop_id}/products/search`, {
    limit: 30,
    ...defaultProductListParams,
    ...params,
    category_id: categoryId,
  });

export const fetchCategoryProductLine = (
  shop_id: number,
  categoryId: string,
  fetcher = Http
): Promise<IProductLineResponse> =>
  fetcher.get(`shops/${shop_id}/categories/${categoryId}/product_line`, { random: true });

export const fetchCategory = (
  shop_id: number,
  categoryId: string,
  params?: object,
  fetcher = Http
): Promise<ICategoryChild> =>
  fetcher.get(`shops/${shop_id}/categories/${categoryId}`, {
    ...defaultProductListParams,
    ...params,
  });

export const getProductsCount = (shop_id: number, params?: object): Promise<IListProductsResponse> =>
  Http.get(`shops/${shop_id}/products_count`, {
    ...defaultProductListParams,
    ...params,
  });
