import styled from 'styled-components';
import { mediaMdDown, mediaSmDown } from '../../theme/helpers/css';
import ExternalLink from '../ExternalLink';
import { Transitions } from '../../theme';
import { colors } from '../../constants/colors';
import Flex from 'Components/Grid/Flex';
import { ILinkProps } from './types';

export const SocialsWrapper = styled(Flex).attrs({ middle: true })`
  ${mediaMdDown`
    width: 100%;
    padding-bottom: 24px;
  `}
  ${mediaSmDown`
    padding-bottom: 20px;
  `}
`;

export const SocialLink = styled(ExternalLink)<ILinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: ${Transitions.bg};
  background-color: ${({ bgColor }) => bgColor || colors.white};
  &:hover {
    background-color: ${colors.light300};
  }
  & + & {
    margin-left: 12px;
  }
`;
