import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { colors } from 'constants/colors';
import { EnumNotification } from 'constants/enums';
import { INotification } from './reducer';
import { getNotificationsList } from './selectors';
import { removeNotificationAction } from './actions';
import styled from 'styled-components';
import { mediaSmDown, mediaSmUp } from '../../theme/helpers/css';
import { FontSize } from '../../theme';
import Text from 'Components/Typography/Text';
import Flex from 'Components/Grid/Flex';
import { TransformTransitionGroup, EnumTransitionNames } from '../../Components/Animation';
import { useHistory } from 'react-router';
import ErrorNotificationIcon from './NotificationsIcons/ErrorNotifications';
import SuccessNotificationIcon from './NotificationsIcons/SuccessNotification';

interface INotificationItem {
  type: EnumNotification;
}

const ThemeBackgroundColors = {
  [EnumNotification.success]: colors.success100,
  [EnumNotification.danger]: colors.fauxCoral,
  [EnumNotification.warning]: colors.maize,
  [EnumNotification.info]: colors.danger100,
};

const ThemeBorderColors = {
  [EnumNotification.success]: colors.success200,
  [EnumNotification.danger]: colors.fauxCoral,
  [EnumNotification.warning]: colors.maize,
  [EnumNotification.info]: colors.danger300,
};

const NotificationItem = styled(Flex).attrs({
  middle: true,
  center: true,
})<INotificationItem>`
  padding: 14px 22px;
  gap: 10px;
  background-color: ${({ type }) => ThemeBackgroundColors[type]};
  border: 1px solid ${({ type }) => ThemeBorderColors[type]};
  color: ${colors.white};
  cursor: ${({ onClick }) => onClick && 'pointer'};

  ${mediaSmUp`
    border-radius: 100px;
  `}
`;

const NotificationsContainer = styled(TransformTransitionGroup)`
  z-index: 10000;
  position: fixed;
  max-height: 100%;

  ${mediaSmUp`
    width: max-content;
    left: 60px;
    right: 60px;
    bottom: 60px;
    margin: auto;
  `}
  ${mediaSmDown`
    top: 0;
    left: 0;
    right: 0;
  `}
`;

const NotificationText = styled(Text).attrs({
  size: FontSize.s,
  color: colors.dark300,
})<INotificationItem>`
  line-height: 16px;
  ${mediaSmUp`
      &:before {
        margin-right: 8px;
      }
    `}

  ${mediaSmDown`
      &:after {
        margin-left: 8px;
      }
    `}
`;

export const NotificationModuleContainer = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsList);
  const history = useHistory();

  const removeNotification = useCallback(() => dispatch(removeNotificationAction()), [dispatch]);

  useEffect(() => {
    notifications.forEach(({ timeout }) => {
      setTimeout(() => {
        removeNotification();
      }, timeout || 2000);
    });
  }, [notifications, removeNotification]);

  return (
    <NotificationsContainer $transitionName={EnumTransitionNames.FADE_BOTTOM}>
      {notifications.map(({ id, theme, text, link }: INotification) => (
        <CSSTransition key={id} classNames={EnumTransitionNames.FADE_BOTTOM} timeout={200}>
          <NotificationItem type={theme} onClick={link ? () => history.push(link) : undefined}>
            {theme === 'info' && <ErrorNotificationIcon />}
            {theme === 'success' && <SuccessNotificationIcon />}
            <NotificationText type={theme} size={FontSize.s} dangerouslySetInnerHTML={{ __html: text }} />
          </NotificationItem>
        </CSSTransition>
      ))}
    </NotificationsContainer>
  );
};

export default NotificationModuleContainer;
