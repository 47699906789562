import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const MapPinIcon: FC<IIcon> = () => (
  <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path
        d="M8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66779 2.11529 8.5039C1.88378 7.34002 2.0026 6.13362 2.45673 5.03727C2.91086 3.94092 3.6799 3.00385 4.66659 2.34457C5.65328 1.68528 6.81332 1.33339 8 1.33339C9.18669 1.33339 10.3467 1.68528 11.3334 2.34457C12.3201 3.00385 13.0891 3.94092 13.5433 5.03727C13.9974 6.13362 14.1162 7.34002 13.8847 8.5039C13.6532 9.66779 13.0818 10.7369 12.2427 11.576L8 15.8187ZM11.3 10.6333C11.9526 9.98067 12.397 9.14915 12.577 8.24392C12.7571 7.33869 12.6646 6.4004 12.3114 5.54771C11.9582 4.69501 11.36 3.96621 10.5926 3.45345C9.82519 2.94069 8.92296 2.66701 8 2.66701C7.07704 2.66701 6.17481 2.94069 5.40739 3.45345C4.63997 3.96621 4.04183 4.69501 3.68861 5.54771C3.33539 6.4004 3.24294 7.33869 3.42297 8.24392C3.603 9.14915 4.04741 9.98067 4.7 10.6333L8 13.9333L11.3 10.6333ZM8 8.66667C7.64638 8.66667 7.30724 8.52619 7.05719 8.27615C6.80715 8.0261 6.66667 7.68696 6.66667 7.33334C6.66667 6.97972 6.80715 6.64058 7.05719 6.39053C7.30724 6.14048 7.64638 6 8 6C8.35362 6 8.69276 6.14048 8.94281 6.39053C9.19286 6.64058 9.33334 6.97972 9.33334 7.33334C9.33334 7.68696 9.19286 8.0261 8.94281 8.27615C8.69276 8.52619 8.35362 8.66667 8 8.66667Z"
        fill="black"
      />
    </g>
  </Icon>
);

export default MapPinIcon;
