import { ICategoryChild, ICategoryFilter, ICategoryFilterPrice } from '../Categories/types';
import { EnumCurrency } from 'Modules/Cart/types';
import { IShopFullLogo } from 'Modules/Shops/types';

export enum EnumProductType {
  piece = 'piece',
  pack = 'pack',
  weight = 'weight',
}

export enum EnumProductSpecialType {
  new = 'new',
}

export interface IProductInfo {
  name: string;
  value: string;
}

export enum EnumProductsSets {
  model_ids = 'model_ids',
  popularity_products = 'popularity_products',
  sale_products = 'sale_products',
  low_cost_scope = 'low_cost_scope',
  advertising = 'advertising',
  checkout = 'checkout',
  tag = 'tag',
}

export enum EnumPictureShapes {
  wide = 'wide',
  normal = 'normal',
}

export interface IProductImage {
  small: string; // 100x100
  medium: string; // 100x100
  large: string; // 500x500
  biggest: string; // оригинальный размер
}

export interface IAdvertisingLabel {
  id?: number;
  name?: string;
  text?: string;
  background_color?: string;
  font_color?: string;
  description?: string;
}

export interface IProductShop {
  group: string;
  logo: IShopFullLogo;
}

export interface IProductCategoryCase {
  accusative: string | null;
}

export enum EnumLabels {
  HIT = 'hit',
  NOVELTY = 'novelty',
  PROMO = 'promo',
}

export interface IProductTag {
  id: number;
  name: string;
}
export interface IProduct {
  picture_shape?: EnumPictureShapes;
  id: number;
  model_id: number;
  name: string;
  favorite: boolean;
  type: EnumProductType;
  currency: EnumCurrency;
  old_price?: number;
  sale: boolean;
  sale_end_date?: string; // ISO_8601
  price: number;
  price_per_kg: number;
  hide_price_per_kg: boolean;
  package?: number;
  weight: number;
  display_weight: string;
  unit_in_price_per_kg: string;
  item_weight: number;
  group_quantity?: number;
  special_type: EnumProductSpecialType;
  contents?: string[];
  info?: IProductInfo[] | string;
  image: IProductImage;
  similar_products?: IProduct[];
  additional_products?: IProduct[];
  can_added_in_order?: boolean;
  available: boolean;
  root_category_id: number | null;
  category_id: number;
  category_cases: IProductCategoryCase;
  parameterize?: string;
  amount: number;
  shop_id: number;
  comment: string;
  replaced_by?: number;
  advertising_label?: IAdvertisingLabel;
  product_line_campaign_id?: number;
  category?: string;
  root_category?: string;
  composition?: string;
  composition_list?: string[] | null;
  group?: string;
  shop_logo?: string;
  shop?: IProductShop;
  labels?: EnumLabels[];
  tags?: IProductTag[];
  pharmacy?: boolean;
  stock: number;
}

export interface IItems {
  list: IProduct[];
  missing: IProduct[];
  replaced: IProduct[];
  returned: IProduct[];
}

export interface IProductRequest {
  product_id: number;
  amount?: number;
  weight?: number;
  zone_id?: number;
  category_id?: number;
}
export interface IProductModel {
  id: number;
  name: string;
  image: IProductImage;
  type: EnumProductType;
  weight: number;
  volume: number;
  calories: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  composition: string | null;
  manufacturer: string;
  info: string;
  logo_shops: string[];
  images?: IProductImage[];
}

export interface IFullProductResponse {
  model: IProductModel;
  product: IProduct;
  onReportError?: () => void;
}

export interface IProductsResponse {
  products: IProduct[];
}

export interface IListProductsResponse {
  list: IProduct[];
  total: number;
}

export interface IProductFavoriteBase {
  model_id: number;
  favorite: boolean;
}

export interface IProductFavoriteRequest extends IProductFavoriteBase {
  shop_id: number;
}

export interface IProductFavoriteResponse {
  product: IProduct;
}

export interface IBannerImage {
  small: string;
  medium: string;
  large: string;
}

export interface IProductsSet {
  id: number;
  name: string;
  kind?: EnumProductsSets;
  main_banner_description?: string;
  old_banner?: IProductImage;
  top_position?: boolean;
  position: number;
  main_banner?: IProductImage;
  set_banner?: IProductImage;
  set_description?: string;
  text_color?: string;
  head_color?: string;
  total?: number;
  products: IProduct[];
  onClick?: () => void;
  url?: string;
  category_filter_enabled?: boolean;
  mobile_icon?: {
    large?: string;
  };
}

export interface IProductsSetRequest {
  category_id?: number;
  price?: {
    from?: number;
    to?: number;
  };
  filters?: number[];
  limit?: number;
  offset?: number;
  sort?: string;
  sort_order?: string;
  shop_id?: number;
}

type ISetCategory = ICategoryChild & {
  filters: ICategoryFilter;
};

export interface IProductsSetState {
  id: number;
  shop_id: number;
  isLoading: boolean;
  isLoadingCategories: boolean;
  price: ICategoryFilterPrice;
  name: string;
  kind: EnumProductsSets;
  products: {
    list: IProduct[];
    total: number;
  };
  filters?: ICategoryFilter[];
  categories: ISetCategory[];
  setBanner: IBannerImage;
  textColor?: string;
  headColor?: string;
  setDescription?: string;
  category_filter_enabled?: boolean;
  productPicture?: IBannerImage;
  backgroundColor?: string;
  category_id?: number | undefined;
}

export interface IProductsSets {
  list: IProductsSet[];
  tags: IProductsSet[];
  isLoaded: boolean;
}

export interface IProductsSetsState {
  sets: IProductsSets;
  current: IProductsSetState;
}

export enum EnumProductTypes {
  SET_PRODUCTS_SETS = 'SET_PRODUCTS_SETS',
  SET_PRODUCTS_SET = 'SET_PRODUCTS_SET',
  UPDATE_PRODUCTS_SET = 'UPDATE_PRODUCTS_SET',
  SET_PRODUCTS_SET_CATEGORIES = 'SET_PRODUCTS_SET_CATEGORIES',
  START_GET_PRODUCTS_SET = 'START_GET_PRODUCTS_SET',
  START_GET_PRODUCTS_SET_CATEGORIES = 'START_GET_PRODUCTS_SET_CATEGORIES',
  CLEAR_CURRENT_SET = 'CLEAR_CURRENT_SET',
  SET_SALE_PRODUCTS_SET = 'SET_SALE_PRODUCTS_SET',
  SET_TAGS_SETS = 'SET_TAGS_SETS',
}

export interface IProductLineResponse {
  list: IProduct[];
}
export interface IProductSetProductLineQuery {
  random?: boolean;
  shop_id: number;
}
