/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEPLOY_ENV_NOT_PROD } from '../../config/config';
import { sendCustomBugsnagReport } from './errors';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../Modules/AAA/selectors';
import { getCartId } from '../Modules/Cart/selectors';
import { getCurrentShopGroup, getCurrentShopGroupEn, getCurrentShopId } from '../Modules/Shops/selectors';
import { getCurrentCityId } from '../Modules/SearchAddress/selectors';
import { useCallback } from 'react';
import { truncateString } from 'utils/helpers';

// first measurement id by default
const GA_MEASUREMENT_ID_FOR_ROUTES = 'UA-65721260-1';

const DEV_GA_MEASUREMENT_ID_FOR_DATA_COLLECTION = 'G-W46MLQ2MJS';
const PROD_GA_MEASUREMENT_ID_FOR_DATA_COLLECTION = 'G-N1Z9J12MY3';

// second measurement id for data collection
export const GA_MEASUREMENT_ID_FOR_DATA_COLLECTION = DEPLOY_ENV_NOT_PROD
  ? DEV_GA_MEASUREMENT_ID_FOR_DATA_COLLECTION
  : PROD_GA_MEASUREMENT_ID_FOR_DATA_COLLECTION;

export const gtag = (...args: any[]) => {
  if (window.gtag) {
    if (DEPLOY_ENV_NOT_PROD) {
      // temporary log for test
      console.log('Gtag ----> ', ...args);
    }
    window.gtag(...args);
  }
};

export const gtagUniversalCounterConfig = (...args: any[]) => gtag('config', GA_MEASUREMENT_ID_FOR_ROUTES, ...args);

export const gtagConfig = (...args: any[]) => {
  try {
    gtag('config', GA_MEASUREMENT_ID_FOR_DATA_COLLECTION, ...args);
  } catch (e) {
    sendCustomBugsnagReport('gtag: measurement config', {
      e,
      args,
    });
  }
};
export const gtagEvent = (event: string, args: any) => {
  try {
    gtag('event', event, { ...args, send_to: GA_MEASUREMENT_ID_FOR_DATA_COLLECTION });
  } catch (e) {
    sendCustomBugsnagReport('gtag: measurement event', {
      e,
      event,
      args,
    });
  }
};

export const setGtagUserProperties = (properties: Record<string, string>) => {
  gtag('set', 'user_properties', properties);
};

export const useGtagUserProperties = () => {
  const { total_orders_count } = useSelector(getCurrentUser);
  const cartId = useSelector(getCartId);
  const shopId = useSelector(getCurrentShopId);
  const cityId = useSelector(getCurrentCityId);
  const shopGroupEn = useSelector(getCurrentShopGroupEn);
  const shopGroup = useSelector(getCurrentShopGroup);

  const setCommonUserProperties = useCallback(
    () =>
      setGtagUserProperties({
        order_number: truncateString(total_orders_count, 36),
        order_id: truncateString(cartId, 36),
        shop_id: truncateString(shopId, 36),
        shop_group: truncateString(shopGroupEn || shopGroup, 36),
        city_id: truncateString(cityId, 36),
      }),
    [cartId, cityId, shopGroup, shopGroupEn, shopId, total_orders_count]
  );

  return setCommonUserProperties;
};
