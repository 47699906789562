import { EnumCategoriesType, CategoriesActionsType } from './actions';
import { ICategory, ICategoryChild, IFavoriteCategory } from './types';
import { IProduct } from '../Products/types';
import { AnyAction } from 'redux';

export interface ICategoriesState {
  list: ICategory[];
  isLoading: boolean;
  isLoadedCategories: boolean;
  isLoadingProducts: boolean;
  isLoadingProductLine: boolean;
  products: {
    list: IProduct[];
    total: number;
  };
  currentCategory: ICategoryChild | null;
  productsLine: IProduct[];
}

export const categoriesInitialState = {
  list: [],
  isLoading: true,
  isLoadedCategories: false,
  isLoadingProducts: true,
  isLoadingProductLine: true,
  currentCategory: null,
  products: {
    list: [],
    total: 0,
  },
  productsLine: [],
};

export function categoriesReducer(
  state: ICategoriesState = categoriesInitialState,
  action: CategoriesActionsType
): ICategoriesState {
  switch (action.type) {
    case EnumCategoriesType.SET_CATEGORIES:
      return { ...state, ...action.payload, isLoadedCategories: true };
    case EnumCategoriesType.START_GET_CATEGORY:
      return { ...state, isLoading: true };
    case EnumCategoriesType.START_GET_CATEGORY_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: true,
      };
    case EnumCategoriesType.START_GET_CATEGORY_PRODUCT_LINE:
      return {
        ...state,
        isLoadingProductLine: true,
      };
    case EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: false,
        products: { ...action.payload, list: [...action.payload.list] },
      };
    case EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCT_LINE:
      return {
        ...state,
        isLoadingProductLine: false,
        productsLine: [...action.payload.list],
      };
    case EnumCategoriesType.FINISH_GET_CATEGORY:
      return {
        ...state,
        isLoading: false,
        currentCategory: { ...action.payload },
      };
    case EnumCategoriesType.ADD_CATEGORY_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          list: [...state.products.list, ...action.payload],
        },
      };
    case EnumCategoriesType.RESET_CURRENT_CATEGORY_AND_PRODUCTS:
      return {
        ...categoriesInitialState,
        list: state.list,
        isLoadedCategories: state.isLoadedCategories,
      };
    default:
      return state;
  }
}

export interface IFavoritesCategoriesState {
  list: IFavoriteCategory[];
  isLoading?: boolean;
}

export const favoritesCategoriesInitialState = {
  list: [],
  isLoading: false,
};

export function favoritesCategoriesReducer(
  state: IFavoritesCategoriesState = favoritesCategoriesInitialState,
  action: AnyAction
): IFavoritesCategoriesState {
  switch (action.type) {
    case EnumCategoriesType.SET_FAVORITES_CATEGORIES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case EnumCategoriesType.SET_FAVORITES_CATEGORIES:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
