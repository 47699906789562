import React, { useCallback, useEffect, useState } from 'react';
import { AuthModalState } from '../../../constants/enums';
import Loader from 'Components/Loader';
import AAAService from '../../../Modules/AAA/service';
import { IAuthResponse } from '../../../Modules/AAA/types';
import { FbqEventsEnum, fbqTrack } from '../../../utils/fbq';
import { AuthModalContainer } from './styled';
import { EnumYaCounters, ymReachGoal } from 'lib/yandexMetrics';
import { AUTH_MODAL_CONTAINER_TEST_ID } from './constants';
import { sendErrorNotification } from '../../../utils/errors';
import { useDispatch, useSelector } from 'react-redux';
import useAAA from './hooks';
import loadable from '@loadable/component';
import EntityAuthFirstStep from './EntityAuthFirstStep';
import { EnumTopMailGoals, topMailReachGoal } from 'utils/topMail';
import { checkIsTempUser, getCurrentUser } from '../../../Modules/AAA/selectors';

const EnterPhoneTab = loadable(() => import(/* webpackChunkName: "EnterPhoneTab" */ './EnterPhone'), {
  fallback: <Loader />,
});
const EnterSmsTab = loadable(() => import(/* webpackChunkName: "EnterSmsTab" */ './EnterSms'), {
  fallback: <Loader />,
});
const FormTab = loadable(() => import(/* webpackChunkName: "FormTab" */ './Form'), {
  fallback: <Loader />,
});
const AttemptsLimit = loadable(() => import(/* webpackChunkName: "AttemptsLimit" */ './AttemptsLimit'), {
  fallback: <Loader />,
});

export interface IGenericAuthModalProps {
  fullPage?: boolean;
  initialStep?: AuthModalState;
  lastStep?: AuthModalState;
  onSuccess?: () => Promise<void>;
  isEntityReg?: boolean;
  responseTimeout?: number;
}

// TODO Jira: https://igooods.atlassian.net/browse/FS-172, https://igooods.atlassian.net/browse/FS-93
const GenericAuthModal: React.FC<IGenericAuthModalProps> = ({
  fullPage,
  isEntityReg = false,
  initialStep = isEntityReg ? AuthModalState.RegisterStep : AuthModalState.Phone,
  lastStep = AuthModalState.FORM,
  onSuccess,
  responseTimeout = 120,
}: IGenericAuthModalProps) => {
  const { validateCode, updateProfile, currentCity, login } = useAAA();
  const dispatch = useDispatch();
  const isUserTemp = useSelector(checkIsTempUser);
  const { name, email } = useSelector(getCurrentUser);
  const [currentState, setCurrentState] = useState(initialStep);
  const [smsError, setSmsError] = useState<string | null>(null);
  const [smsTries, setSmstries] = useState<number>(0);
  const [formError, setFormError] = useState<string | null>(null);
  const [smsTimeout, setSmsTimeout] = useState<number>(responseTimeout);

  const handleTabSelect = useCallback((tabName: AuthModalState): void => {
    setCurrentState(tabName);
  }, []);

  const goToAttemptsTab = useCallback(() => {
    handleTabSelect(AuthModalState.Attempts);
  }, [handleTabSelect]);

  const goToFormTab = useCallback(() => {
    handleTabSelect(AuthModalState.FORM);
  }, [handleTabSelect]);

  useEffect(() => {
    if (!isUserTemp && !name && !email) {
      goToFormTab();
    }
  }, [isUserTemp, name, email, goToFormTab]);

  const goToSmsTab = useCallback(() => {
    handleTabSelect(AuthModalState.SMS);
  }, [handleTabSelect]);

  const goToEntityFirstStep = useCallback(() => {
    handleTabSelect(AuthModalState.RegisterStep);
    setSmstries(0);
    setSmsError(null);
  }, [handleTabSelect]);

  const goToPhoneTab = useCallback(() => {
    handleTabSelect(AuthModalState.Phone);
    setSmstries(0);
    setSmsError(null);
  }, [handleTabSelect]);

  const startLoading = useCallback(() => {
    handleTabSelect(AuthModalState.Loading);
  }, [handleTabSelect]);

  const clearSmsError = useCallback(() => {
    setSmsError(null);
  }, []);

  const clearFormError = useCallback(() => {
    setFormError(null);
  }, []);

  const goToRegisterTab = () => {
    handleTabSelect(AuthModalState.RegisterStep);
  };

  const requestProfileAuthCode = async () => {
    try {
      const { delay } = await AAAService.sendCode();
      typeof delay === 'number' && setSmsTimeout(delay + 1);
    } catch (e) {
      const delay = e.response?.data?.delay;

      if (typeof delay === 'number') setSmsTimeout(delay + 1);
      else {
        sendErrorNotification(e, dispatch);
        console.log(e);
      }
    } finally {
      goToSmsTab();
    }
  };

  const submitPhone = async (phone: string) => {
    startLoading();
    try {
      await login({ phone });
      await requestProfileAuthCode();
    } catch (e) {
      sendErrorNotification(e, dispatch);
      goToPhoneTab();
    }
  };

  const submitEntityFirstStep = async () => {
    startLoading();
    try {
      await requestProfileAuthCode();
    } catch (e) {
      sendErrorNotification(e, dispatch);
      goToEntityFirstStep();
    }
  };

  const sendForm = async (name: string, email: string) => {
    clearFormError();
    try {
      await updateProfile({ name, email });
      if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      setFormError(e.response.data.error.error);
    }
  };

  const resendSmsCode = async () => {
    startLoading();
    if (smsTries === 3) {
      goToAttemptsTab();
    } else {
      setSmstries(n => n + 1);
      try {
        const { delay } = await AAAService.sendCode();
        typeof delay === 'number' && setSmsTimeout(delay + 1);
        setSmsError(null);
      } catch (e) {
        const delay = e.response?.data?.delay;
        typeof delay === 'number' && setSmsTimeout(delay + 1);
        console.log(e);
      } finally {
        goToSmsTab();
      }
    }
  };

  const submitSms = async (code: string) => {
    try {
      // TODO Jira: https://igooods.atlassian.net/browse/FS-173
      const data = (await validateCode(code)) as never;
      if ((data as IAuthResponse).user.is_new_user && lastStep === AuthModalState.FORM) {
        fbqTrack(FbqEventsEnum.CompleteRegistration);
        ymReachGoal(EnumYaCounters.sign_up);
        topMailReachGoal(EnumTopMailGoals.sign_up);
        goToFormTab();
      } else if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      const errorText = e.response?.data?.error || 'Неверный код';
      setSmsError(errorText);
    }
  };

  return (
    <AuthModalContainer data-testid={AUTH_MODAL_CONTAINER_TEST_ID} state={currentState}>
      {
        {
          [AuthModalState.RegisterStep]: <EntityAuthFirstStep onSuccess={submitEntityFirstStep} />,
          [AuthModalState.Phone]: <EnterPhoneTab dispatchPhone={submitPhone} onRegisterClick={goToRegisterTab} />,
          [AuthModalState.SMS]: (
            <EnterSmsTab
              goToPhone={isEntityReg ? goToEntityFirstStep : goToPhoneTab}
              dispatchSms={submitSms}
              resendCode={resendSmsCode}
              error={smsError}
              clearError={clearSmsError}
              responseTimeout={smsTimeout}
            />
          ),
          [AuthModalState.FORM]: (
            <FormTab
              fullPage={fullPage}
              dispatchForm={sendForm}
              error={formError}
              clearError={clearFormError}
              isEntityReg={isEntityReg}
            />
          ),
          [AuthModalState.Loading]: <Loader />,
          [AuthModalState.Attempts]: <AttemptsLimit currentCity={currentCity} goToPhone={goToPhoneTab} />,
        }[currentState]
      }
    </AuthModalContainer>
  );
};

export default React.memo(GenericAuthModal);
