import { ICity } from 'Modules/SearchAddress/types';
import { CITY_COOKIE } from 'constants/cookie';
import { Response } from 'express';
import { getCookieDomain } from 'constants/cookie';

export const CITY_COOKIE_STORAGE_TIME = new Date(Number.MAX_SAFE_INTEGER / 512);

export const getCityForCookies = (city: ICity) => {
  return { ...city, name: city.name.replace(new RegExp('\\+', 'g'), ' ') };
};

export const setServerCityCookie = (res: Response, city: ICity, hostname: string) => {
  res.cookie(CITY_COOKIE, JSON.stringify(getCityForCookies(city)), {
    httpOnly: false,
    domain: getCookieDomain(hostname).domain,
    expires: CITY_COOKIE_STORAGE_TIME,
  });
};
