import { DefaultTheme } from 'styled-components';
import { colors } from 'constants/colors';

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum BreakpointsOffset {
  xsOffset = 'xsOffset',
  smOffset = 'smOffset',
  mdOffset = 'mdOffset',
  lgOffset = 'lgOffset',
}

export enum FontSize {
  xxs = '100',
  xs = '200',
  s = '300',
  m = '400',
  l = '500',
  xl = '600',
  xxl = '700',
  xxxl = '800',
}

export enum FontWeight {
  normal = '400',
  medium = '500',
  semiBold = '600',
  bold = '700',
  extraBold = '800',
}

export enum Colors {
  pineGreen = '#00736B',
  paleOliveGreen = '#9dd681',
  lightGreyGreen = '#b0de9a',
  lighterGreyGreen = '#D8EFCC',
  bubbleGumPink = '#ff6699',
  lightPink = '#fff2f7',
  darkHover = 'hsla(0,0%,100%,.1)',
  lightHover = 'rgba(0,0,0,.056)',
  dark = '#000',
  darkGrey = '#333333',
  greyishBrown = '#555555',
  warmGrey = '#999999',
  silverFour = '#e5e5e5',
  pinkishGrey = '#cccccc',
  greyish = '#b3b3b3',
  paleGreyThree = '#f5f6f7',
  paleGrey = '#f0f1f2',
  silver = '#ebeced',
  white = '#ffffff',
}

export enum Icons {
  plus = '\\e919',
  close = '\\e90f',
  heart = '\\e927',
  copy = '\\e90e',
  check = '\\e924',
  question = '\\e913',
  attention = '\\e90d',
}

export enum FontFamily {
  mainFont = 'FiraSans',
  secondFont = 'Montserrat',
}

export enum Transitions {
  bg = 'background-color 300ms ease-out',
  opacity = 'opacity 200ms ease-out',
  filter = 'filter 300ms ease-out',
  color = 'color 300ms ease-out',
  transform = 'transform 300ms ease-out',
  boxShadow = 'box-shadow 300ms ease-out',
  borderRadius = 'border-radius 150ms ease-out',
  top = 'top 0.2s ease-out',
}

export enum Animations {
  fadeInForwards = 'fade-in 0.3s cubic-bezier(0.4, 0.1, 0.1, 0.9) forwards',
}

const typography = {
  [FontSize.xxs]: { fontSize: '11px', lineHeight: '15px' },
  [FontSize.xs]: { fontSize: '12px', lineHeight: '16px' },
  [FontSize.s]: { fontSize: '14px', lineHeight: '22px' },
  [FontSize.m]: { fontSize: '16px', lineHeight: '24px' },
  [FontSize.l]: { fontSize: '18px', lineHeight: '26px' },
  [FontSize.xl]: { fontSize: '24px', lineHeight: '28px' },
  [FontSize.xxl]: { fontSize: '26px', lineHeight: '34px' },
  [FontSize.xxxl]: { fontSize: '32px', lineHeight: '40px' },
};

const typographyMobile = {
  [FontSize.xxs]: { fontSize: '10px', lineHeight: '15px' },
  [FontSize.xs]: { fontSize: '11px', lineHeight: '16px' },
  [FontSize.s]: { fontSize: '11px', lineHeight: '16px' },
  [FontSize.m]: { fontSize: '12px', lineHeight: '18px' },
  [FontSize.l]: { fontSize: '13px', lineHeight: '20px' },
  [FontSize.xl]: { fontSize: '17px', lineHeight: '22px' },
  [FontSize.xxl]: { fontSize: '22px', lineHeight: '26px' },
  [FontSize.xxxl]: { fontSize: '26px', lineHeight: '34px' },
};

const radioButton = {
  checkedBorderColor: colors.darkBlueGreen,
  checkedDotColor: colors.darkBlueGreen,
};

const checkBox = {
  icon: colors.primaryMain,
};

export const defaultTheme: DefaultTheme = {
  baseIndent: 4,
  breakpoints: { xs: 320, sm: 768, md: 1024, lg: 1280 },
  gridSize: 12,
  gutterWidth: 2,
  container: {
    indent: {
      xs: 4,
      sm: 4,
      md: 4,
      lg: 9,
    },
    maxWidth: '1920px',
  },
  typography: typography,
  typographyMobile: typographyMobile,
  button: {
    active: {
      bgColor: '#c3e0c8',
    },
    bgColor: 'red',
    borderRadius: {
      large: 4,
      normal: 4,
      small: 3,
    },
    color: 'white',
    disabled: {
      bgColor: '#f5f6f7',
      color: '#cccccc',
    },
    focus: {
      bgColor: '#cdebd2',
    },
    height: {
      large: 50,
      normal: 40,
      small: 30,
    },
    hover: {
      bgColor: '#d7f7dd',
    },
    loading: {
      bgColor: '#c3e0c8',
      color: 'green',
    },
    padding: {
      large: '0 24px',
      normal: '0 16px',
      small: '0 12px',
    },
  },
  radioButton: {
    buttonSize: {
      medium: 22,
      small: 16,
    },
    fontSize: {
      medium: 14,
      small: 14,
    },
    bgColor: colors.ghostWhiteFour,
    ...radioButton,
    dotColor: colors.pinkishGrey,
    checkedBgColor: colors.primaryMain,
    disabledColor: colors.ghostWhiteFour,
    disabledTextColor: colors.pinkishGrey,
    textColor: colors.dark300,
  },
  checkBox: {
    bg: colors.white,
    border: colors.whiteTwo,
    checkedBg: colors.paleGrey,
    checkedBorder: colors.paleGrey,
    labelColor: colors.dark300,
    ...checkBox,
  },
  toggle: {
    bg: colors.lightGreyTwo,
    checkedBg: colors.primaryAction,
    hoverBg: colors.primaryAction,
    disabledBg: colors.lightGrey,
    tapArea: 4,
  },
  input: {
    borderRadius: {
      large: 4,
      normal: 4,
      small: 3,
    },
    height: {
      default: {
        large: 50,
        normal: 40,
        small: 30,
      },
      mobile: {
        large: 35,
        normal: 35,
        small: 30,
      },
    },
    padding: {
      large: 16,
      normal: 12,
      small: 8,
    },
    fontSize: {
      large: FontSize.m,
      normal: FontSize.s,
      small: FontSize.xs,
    },
    bgColor: colors.paleGrey,
    hover: {
      bgColor: colors.silverFive,
    },
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
      placeholderColor: colors.pinkishGrey,
    },
    error: {
      bgColor: colors.lavenderBlushTwo,
    },
    focus: {
      bgColor: colors.white,
      shadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05)',
    },
    focusError: {
      bgColor: colors.white,
    },
    transition: Transitions.bg,
    label: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    errorText: {
      color: colors.reddishPink,
      fontSize: {
        large: FontSize.s,
        normal: FontSize.xs,
        small: FontSize.xs,
      },
      withPadding: true,
    },
    helperText: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    placeholder: {
      color: colors.warmGrey,
      asteriskColor: colors.reddishPink,
    },
  },
  projectTheme: 'igdesktop',
  fontFamily: FontFamily.mainFont,
};

export const themeConfig = {
  breakpointNames: ['xs', 'sm', 'md', 'lg'],
  breakpointOffsetNames: ['xsOffset', 'smOffset', 'mdOffset', 'lgOffset', 'xlOffset'],
};

export const SMARTPHONE_MEDIA_QUERY = `all and (max-width: ${defaultTheme.breakpoints.sm - 1}px)`;
