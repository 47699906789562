import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { FontSize, FontFamily, SMARTPHONE_MEDIA_QUERY } from 'theme';
import { getTypography, getTypographyMobile } from 'theme/selectors';
import { omit } from 'ramda';
import { addStyleIfPropTruly } from '../../utils/styledComponents';

export const getTypographyFontSize = ({ size = FontSize.s, theme }: IText) =>
  css`
    font-size: ${getTypography(theme)[size].fontSize};
    line-height: ${getTypography(theme)[size].lineHeight};
  `;

export const getTypographyFontSizeMobile = ({ size = FontSize.s, theme }: IText) =>
  css`
    font-size: ${getTypographyMobile(theme)[size].fontSize};
    line-height: ${getTypographyMobile(theme)[size].lineHeight};
  `;

export interface IText {
  uppercase?: boolean;
  fontWeight?: string;
  color?: string;
  size?: FontSize;
  fontFamily?: FontFamily;
  theme: DefaultTheme;
  overflow?: boolean;
  center?: boolean;
  className?: string;
  $nowrap?: boolean;
  opacity?: number;
}

const TextStyled = styled(props => <div {...omit(['overflow', 'center'], props)} />)<IText>`
  letter-spacing: -0.3px;
  display: inline-block;
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
  ${({ fontWeight }) =>
    css`
      font-weight: ${fontWeight};
    `}
  ${({ color }) =>
    css`
      color: ${color};
    `}
  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
  ${({ fontFamily }) =>
    css`
      font-family: ${fontFamily};
    `}
  ${getTypographyFontSize}
  @media ${SMARTPHONE_MEDIA_QUERY} {
    ${getTypographyFontSizeMobile}
  }
  ${addStyleIfPropTruly(
    'overflow',
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
  )}
  ${({ $nowrap }) =>
    $nowrap &&
    css`
      white-space: nowrap;
    `}
`;

export default React.memo(TextStyled);
