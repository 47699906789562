import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from 'Components/Grid/Flex';
import LogoIcon from 'Components/Header/Logo/LogoIcon';
import { mediaSmDown } from 'theme/helpers/css';
export const LANDING_LOGO_SLOGAN_TEST_ID = 'LANDING_LOGO_SLOGAN_TEST_ID';

export const Logo = styled(LogoIcon)`
  max-width: 140px;
  height: auto;
  margin-right: 20px;

  ${mediaSmDown`
    max-width: 80px;
    margin-right: 0;
  `}
`;

const LogoWithSlogan: FC = () => {
  return (
    <Flex notShrink middle>
      <Logo />
    </Flex>
  );
};

export default LogoWithSlogan;
