import { colors } from 'constants/colors';
import { FontSize, FontWeight, Transitions, FontFamily } from 'theme';

const InputThemeNames = {
  main: 'main',
  mainSecondary: 'mainSecondary',
  round: 'round',
  roundSecondary: 'roundSecondary',
  roundBorder: 'roundBorder',
  roundBorderGrayPlaceholder: 'roundBorderGrayPlaceholder',
};

export type TInputTheme = keyof typeof InputThemeNames;

const roundTheme = {
  borderRadius: {
    large: 58,
    normal: 48,
    small: 40,
  },
  height: {
    default: {
      large: 58,
      normal: 48,
      small: 40,
    },
    mobile: {
      large: 48,
      normal: 40,
      small: 40,
    },
  },
  padding: {
    large: '5px 5px 5px 20px',
    normal: '5px 5px 5px 16px',
    small: '5px 5px 5px 16px',
  },
  fontSize: {
    large: FontSize.s,
    normal: FontSize.s,
    small: FontSize.s,
  },
  bgColor: colors.white,
  borderColor: 'transparent',
  hover: {
    bgColor: colors.white,
  },
  disabled: {
    bgColor: colors.white,
    color: colors.manatee,
    placeholderColor: colors.manatee,
  },
  error: {
    bgColor: colors.white,
    borderColor: colors.brinkPink,
  },
  focus: {
    bgColor: colors.white,
  },
  focusError: {
    bgColor: colors.white,
  },
  transition: Transitions.bg,
  label: {
    color: colors.warmGrey,
    fontSize: {
      large: FontSize.m,
      normal: FontSize.s,
      small: FontSize.xs,
    },
  },
  errorText: {
    color: colors.white,
    fontSize: {
      large: FontSize.s,
      normal: FontSize.xs,
      small: FontSize.xs,
    },
    withPadding: true,
    bgColor: colors.brinkPink,
  },
  helperText: {
    color: colors.warmGrey,
    fontSize: {
      large: FontSize.m,
      normal: FontSize.s,
      small: FontSize.xs,
    },
  },
  placeholder: {
    color: colors.manatee,
    asteriskColor: colors.reddishPink,
  },
  addonWidth: 'auto',
  addonHeight: '40px',
};

const mainTheme = {
  borderRadius: {
    large: 12,
    normal: 12,
    small: 8,
  },
  height: {
    default: {
      large: 50,
      normal: 40,
      small: 30,
    },
    mobile: {
      large: 35,
      normal: 35,
      small: 30,
    },
  },
  padding: {
    large: 16,
    normal: 12,
    small: 8,
  },
  fontSize: {
    large: FontSize.m,
    normal: FontSize.s,
    small: FontSize.xs,
  },
  bgColor: colors.light200,
  hover: {
    bgColor: colors.light300,
  },
  disabled: {
    bgColor: colors.light200,
    color: colors.dark100,
    placeholderColor: colors.dark100,
  },
  error: {
    bgColor: colors.light200,
  },
  focus: {
    bgColor: colors.white,
    shadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05)',
  },
  focusError: {
    bgColor: colors.white,
  },
  transition: Transitions.bg,
  label: {
    color: colors.dark200,
    fontSize: {
      large: FontSize.m,
      normal: FontSize.s,
      small: FontSize.xs,
    },
  },
  errorText: {
    color: colors.primaryAction,
    fontSize: {
      large: FontSize.s,
      normal: FontSize.xs,
      small: FontSize.xs,
    },
    withPadding: true,
  },
  helperText: {
    color: colors.dark200,
    fontSize: {
      large: FontSize.m,
      normal: FontSize.s,
      small: FontSize.xs,
    },
  },
  placeholder: {
    color: colors.dark100,
    fontWeight: FontWeight.normal,
    fontFamily: FontFamily.mainFont,
    asteriskColor: colors.primaryAction,
  },
};

export const inputThemeStyles = {
  main: mainTheme,
  mainSecondary: {
    ...mainTheme,
    bgColor: colors.white,
    hover: {
      bgColor: colors.white,
    },
    borderRadius: {
      large: 4,
      normal: 4,
      small: 3,
    },
    error: {
      bgColor: colors.white,
      borderColor: colors.brinkPink,
    },
    errorText: {
      ...mainTheme.errorText,
      absolute: true,
    },
  },
  round: roundTheme,
  roundSecondary: {
    ...roundTheme,
    bgColor: colors.light200,
    hover: {
      bgColor: colors.light200,
    },
    error: {
      bgColor: colors.light200,
      borderColor: colors.primaryMain,
    },
    focus: {
      bgColor: colors.light200,
    },
    focusError: {
      bgColor: colors.light200,
    },
    errorText: {
      color: colors.primaryAction,
      fontSize: {
        large: FontSize.s,
        normal: FontSize.xs,
        small: FontSize.xs,
      },
      withPadding: true,
      bgColor: colors.brinkPink,
    },
    placeholder: {
      color: colors.dark100,
      fontWeight: FontWeight.normal,
      fontFamily: FontFamily.mainFont,
      asteriskColor: colors.primaryAction,
    },
  },
  roundBorder: {
    borderRadius: {
      large: 58,
      normal: 48,
      small: 40,
    },
    height: {
      default: {
        large: 58,
        normal: 48,
        small: 40,
      },
      mobile: {
        large: 48,
        normal: 40,
        small: 40,
      },
    },
    padding: {
      large: '5px 5px 5px 20px',
      normal: '5px 5px 5px 16px',
      small: '5px 5px 5px 16px',
    },
    fontSize: {
      large: FontSize.s,
      normal: FontSize.s,
      small: FontSize.s,
    },
    bgColor: colors.white,
    borderColor: colors.black,
    hover: {
      bgColor: colors.white,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.manatee,
      placeholderColor: colors.manatee,
    },
    error: {
      bgColor: colors.white,
      borderColor: colors.brinkPink,
    },
    focus: {
      bgColor: colors.white,
    },
    focusError: {
      bgColor: colors.white,
    },
    transition: Transitions.bg,
    label: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    errorText: {
      color: colors.white,
      fontSize: {
        large: FontSize.s,
        normal: FontSize.xs,
        small: FontSize.xs,
      },
      withPadding: true,
      bgColor: colors.brinkPink,
    },
    helperText: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    placeholder: {
      color: colors.primaryMain,
      asteriskColor: colors.primaryMain,
    },
    addonWidth: 'auto',
    addonHeight: '40px',
  },
  roundBorderGrayPlaceholder: {
    borderRadius: {
      large: 58,
      normal: 48,
      small: 40,
    },
    height: {
      default: {
        large: 58,
        normal: 48,
        small: 40,
      },
      mobile: {
        large: 48,
        normal: 40,
        small: 40,
      },
    },
    padding: {
      large: '5px 5px 5px 20px',
      normal: '5px 5px 5px 16px',
      small: '5px 5px 5px 16px',
    },
    fontSize: {
      large: FontSize.s,
      normal: FontSize.s,
      small: FontSize.s,
    },
    bgColor: colors.white,
    borderColor: colors.black,
    hover: {
      bgColor: colors.white,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.manatee,
      placeholderColor: colors.manatee,
    },
    error: {
      bgColor: colors.white,
      borderColor: colors.brinkPink,
    },
    focus: {
      bgColor: colors.white,
    },
    focusError: {
      bgColor: colors.white,
    },
    transition: Transitions.bg,
    label: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    errorText: {
      color: colors.white,
      fontSize: {
        large: FontSize.s,
        normal: FontSize.xs,
        small: FontSize.xs,
      },
      withPadding: true,
      bgColor: colors.brinkPink,
    },
    helperText: {
      color: colors.warmGrey,
      fontSize: {
        large: FontSize.m,
        normal: FontSize.s,
        small: FontSize.xs,
      },
    },
    placeholder: {
      color: colors.dark100,
      asteriskColor: colors.dark100,
    },
    addonWidth: 'auto',
    addonHeight: '40px',
  },
};
