import { IActiveOrdersState, ICartState } from 'Modules/Cart/types';
import { ICategoriesState, IFavoritesCategoriesState } from 'Modules/Categories/reducer';
import { ICity } from 'Modules/SearchAddress/types';
import { IProduct, IProductsSetsState } from 'Modules/Products/types';

import { Action } from 'redux';
import { IAuthState } from 'Modules/AAA/reducer';
import { ICardsState } from '../Modules/PaymentCards/reducer';
import { ICheckout } from 'Modules/Checkout/types';
import { IFavoritesState } from '../Modules/Favorites/reducer';
import { IModalState } from 'Modules/Modals/reducer';
import { INotificationState } from 'Modules/Notifications/reducer';
import { IOrdersHistoryState } from '../Modules/OrdersHistory/types';
import { IPlace } from 'Modules/Places/types';
import { IRateLastOrder } from '../Modules/RateLastOrder/types';
import { ISearchResultsState } from 'Modules/SearchResults/types';
import { IShop } from 'Modules/Shops/types';
import { IStaticNotificationState } from 'Modules/StaticNotifications/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { IEntity } from '../Modules/Entity/types';
import Http from 'lib/http';
import { IUserSetsState } from 'Modules/UserSets/reducer';

export interface IApplicationState {
  auth: IAuthState;
  currentAddress: IPlace;
  currentShop: IShop;
  currentCity: ICity;
  cities: ICity[];
  currentProduct: IProduct;
  modals: IModalState;
  cart: ICartState;
  categories: ICategoriesState;
  favoritesCategories: IFavoritesCategoriesState;
  productsSets: IProductsSetsState;
  notifications: INotificationState;
  staticNotifications: IStaticNotificationState;
  cards: ICardsState;
  ordersHistory: IOrdersHistoryState;
  checkout: ICheckout;
  searchResults: ISearchResultsState;
  favorites: IFavoritesState;
  rateLastOrder: IRateLastOrder;
  activeOrders: IActiveOrdersState;
  entity: IEntity;
  userSets: IUserSetsState;
}

export type ReduxDispatch = ThunkDispatch<IApplicationState, any, Action>;
export type Dispatch = (T: Action<any>) => any;
export type GetState = () => IApplicationState;
export type ThunkAction = (dispatch: ReduxDispatch, getState: GetState, fetcher: typeof Http) => any;

export const useThunkDispatch = () => useDispatch<ReduxDispatch>();
