export const colors = {
  macaroniAndCheese: '#f5b431',
  papayaWhip: '#fdf0d6',
  skyBlue: '#63adf7',
  lightBlue: '#a1cdfa',
  lightSkyBlue: '#82bdf8',
  aliceBlue: '#e8f3fe',
  aliceBlueTwo: '#F9FBFE',
  aliceBlueThree: '#D8EBFE',
  paleOliveGreen: '#9dd681',
  paleGreen: '#1BA39A',
  blackRussian: '#1A1A2C',
  paleBlackRussian: 'rgba(26, 26, 44, .6)',
  lightBlackRussian: 'rgba(26, 26, 44, .1)',
  paleBlackRussianWeak: 'rgba(26, 26, 44, .5)',
  ghostWhite: '#F7F7FF',
  ghostWhiteTwo: '#F0F0FA',
  ghostWhiteThree: '#F3F3FF',
  ghostWhiteFour: '#F2F2FA',
  quickSilver: '#A3A3AC',
  lightGrey: '#E1E4E6',
  lightGreyTwo: '#C8CACC',
  lightGreyThree: '#E9E9F5',
  lightGreyFour: '#CFD0E5',
  lightGreyFive: 'rgba(26, 26, 44, 0.32)',
  lightGreySix: '#7F7F88',
  lighterGreyGreen: '#D8EFCC',
  dullGreen: '#78a362',
  honeydew: '#E9FDEA',
  green: '#17b831',
  panache: '#ebf7e6',
  paleGreen2: '#83C0BC',
  lightBluishGreen: '#affab3',
  reddishPink: '#ff3377',
  mistyRose: '#fde1eb',
  lightPink: '#fff2f7',
  greyish: '#b3b3b3',
  greyishTwo: '#b2b2b2',
  whiteSmoke: '#f0f0f0',
  whiteSmokeTwo: '#f2f2f2',
  whiteSmokeThree: '#f3f3f3',
  white: '#ffffff',
  whiteTwo: '#e6e6e6',
  whiteThree: '#f7f7f7',
  whiteFour: '#e6e6e6',
  whiteFive: '#A6A6A6',
  darkishPink: '#e6455f',
  salmonPink: '#ff6780',
  smokedGrey: '#C1C1D7',
  warmGrey: '#999999',
  warmGreyThree: '#818181',
  almostBlack2: '#1a1a2c52',
  almostWhite: '#FAFAFF',
  blackTwo5: 'rgba(0, 0, 0, 0.05)',
  paleGrey: '#f0f1f2',
  lightPaleGrey: 'rgba(240, 241, 242, 0.95)',
  paleGreyTwo: '#f5f7f7',
  paleGreyThree: '#f5f6f7',
  silver: '#ebeced',
  darkGrey: '#333333',
  darkGreyTwo: '#808080',
  greyishBrown: '#555555',
  greyishBrownTwo: '#4c4c4c',
  bubbleGumPink: '#ff6699',
  pinkishGrey: '#cccccc',
  silverFour: '#e5e5e5',
  silverFive: '#e3e4e5',
  black: '#000',
  favoritesSelected: '#FFE8F0',
  favoritesPurple: '#CD68FF',
  mediumOrchid: '#C45EF7',
  carouselPink: '#FFE1E6',
  lichenTwo: '#8ec274',
  smoothieGreen: '#CDFF96',
  greenMint: '#AFFAB3',
  offWhite: '#EDFAEF',
  paleGreySix: '#fafbfc',
  lightSage: '#d7f7dd',
  lightGreyGreenTwo: '#d9f0dd',
  lightGreyGreenThree: '#cdebd2',
  lightGreyGreenFour: '#c3e0c8',
  lightGreyGreenFive: '#F0FFF2',
  lightBlueGreen: '#B3C9C7',
  paleBlueGreen: '#669390',
  darkBlueGreen: '#004b46',
  darkBlueGreenTwo: '#276460',
  darkBlueGreenThree: '#003C38',
  darkBlueGreenFive: '#00857C',
  mediumGreen: '#2ea341',
  watermelon: '#ff4d6a',
  watermelonTwo: '#ff4b69',
  watermelonLight: '#FF627C',
  lightHover: 'rgba(0,0,0,.056)',
  maize: '#ffe14b',
  watermelonGrey: '#d2c1d6',
  darkHover: 'hsla(0,0%,100%,.1)',
  loading: '#bfc0c1',
  mint: '#bbebd6',
  mintTwo: '#E8F2EF',
  rose: '#FFE0EB',
  roseTwo: '#FFD6E4',
  pink: 'rgba(255, 51, 119, 0.2)',
  paleYellow: 'rgba(245, 180, 49, 0.2)',
  blackHalfOpacity: 'rgba(0,0,0, .5)',
  brightBlue: '#00aaff',
  simpleBlue: 'rgba(0,170,255,0.5)',
  simpleOrdinaryBlue: 'rgba(0,170,255,0.25)',
  simpleOrdinaryBlueHex: '#00abff',
  simpleLightBlue: 'rgba(0,170,255,0.15)',
  simpleLightBlueHex: '#d9f2ff',
  offWhiteBlue: 'rgba(0,170,255,0.05)',
  pencilGrey: '#808080',
  whiteOpacity: 'rgba(255,255,255,.8)',
  transparentWhite: 'rgba(255, 255, 255, .2)',
  brandYellow: '#ffe100',
  grederlevy: '#E0E9E9',
  grederlevyLight: '#EBF1F0',
  grederlevyLightTwo: '#F5F8F8',
  grederlevyDark: '#D6E2E1',
  jucyPlum: '#642373',
  jucyPlumLight: '#A65FB8',
  brightOrange: '#FCAF1B',
  simpleOrange: '#F68B1F',
  simpleLightOrange: '#FFF2DB',
  sizzlingRed: '#F23C5A',
  brightRed: '#e53232',
  lavender: '#EDEDF5',
  lavenderBlush: '#FFEDF0',
  lavenderBlushTwo: '#fff0f5',
  whiteLily: '#FFF2F2',
  orange: '#ffaa00',
  apricotOrange: '#FFBC6E',
  japaneseLaurel: '#1BAD03',
  pineGreen: '#00736B',
  pineGreenpale: 'rgba(0, 115, 107, 1)',
  pastelGreen: '#88EB8D',
  pear: '#C9EB44',
  candleLight: '#FDD123',
  sunshade: '#FF9E2E',
  dodgerBlue: '#468CFF',
  trout: '#515A65',
  teal: '#008077',
  titanWhite: '#F7F7FF',
  pictonBlue: '#49E8F2',
  persianGreen: '#00A499',
  hintOfGreen: '#F0FFF1',
  snowyMint: '#E5FFE7',
  manatee: '#8D8D96',
  brinkPink: '#FF6982',
  brinkPinkTwo: '#F75E78',
  tickleMePink: '#FF788F',
  pastelPink: '#FFCCD5',
  chinaIvory: '#FFF7D2',
  lavenderLight: '#E8E8EA',
  sweetCorn: '#ffe468',
  bananaFlavouredLemonade: '#FFE24E',
  algaeGreen: '#CFFFD1',
  geyser: '#cfe3e1',
  lochinvar: '#338f89',
  paleTara: 'rgba(215, 253, 217, 0.22)',
  heliotrope: '#DD54F8',
  heliotropeTwo: '#8F68FF',
  mintHaze: '#D7FDD9',
  nobel: '#969595',
  paleSnowyMint: '#f6fef6',
  surfCrest: '#CFCFE5',
  paleSurfCrest: 'rgba(207,207,229,0.4)',
  silverSand: '#C4C4C4',
  smoke: '#A3A3AB',
  selectiveYellow: '#ffb900',
  transparent: 'transparent',
  fauxCoral: '#F7546F',
  darkPink: '#F7546F',
  mainGray: '#B5B5BB',
  warmGreyTwo: '#9C9D9D',
  mistyRoseLight: 'rgba(255, 105, 130, 0.25)',
  lightGray: '#D9D9D9',
  buttonLightGreen: 'rgba(131, 192, 188, 0.8)',
  primaryMain: '#DA1F2A',
  dark100: '#A8B1B7',
  dark200: '#6D778B',
  dark300: '#363A47',
  primaryAction: '#C53038',
  light200: '#F9F9F9',
  light300: '#F2F2F2',
  light400: '#E0E0E0',
  field100: '#F9FBFF',
  field200: '#F1F3F9',
  field300: '#D8DDEC',
  informationLink: '#1390DF',
  warning400: '#F2994A',
  warning300: '#FFC438',
  informationLinkLight: '#E6F7FF',
  danger100: '#FFF2F0',
  danger300: '#FFCCC7',
  success100: '#F6FFED',
  success200: '#B7EB8F',
  lite300: '#E0E0E0',
  primaryActionDisabled: 'rgba(197, 48, 56, 0.6)',
  primaryMainDisabled: 'rgba(218, 31, 42, 0.6)',
  mainLandingPurple: '#E2DDFF',
  mainLandingYellow: '#FFED8D',
  mainLandingGreen: '#DDF7E9',
  mainLandingYellow2: '#F3E7BB',
};
