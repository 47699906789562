import { IDeliveryDate } from '../Shops/types';

export enum BehaviorTypes {
  call = 'call',
  trust = 'trust',
  hide = 'hide',
}

export enum PaymentTypes {
  cash = 'cash',
  card = 'card',
  online = 'online',
  sbp = 'sbp',
  google_pay = 'google_pay',
  apple_pay = 'apple_pay',
  cashless = 'cashless',
  shipment_on_credit = 'shipment_on_credit',
}

export enum BehaviorCallTypes {
  call_or_trust = 'call_or_trust',
  call_or_hide = 'call_or_hide',
}

export interface ICheckoutComment {
  text: string;
  phone: string;
}

export interface ICheckoutBehavior {
  type: BehaviorTypes;
  call_type: BehaviorCallTypes;
}

export interface ICheckoutPayment {
  type: PaymentTypes;
  change_from?: string;
}

export interface ICheckout {
  delivery_date: IDeliveryDate;
  delivery_early: boolean;
  comment: ICheckoutComment;
  behavior: ICheckoutBehavior;
  payment: ICheckoutPayment;
}
