import { useEffect, useState } from 'react';

export const useServer = () => {
  const [isServer, setServer] = useState(true);

  useEffect(() => {
    setServer(false);
  }, []);

  return isServer;
};
