import { useEffect, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import LocalStorageService from '../../lib/localStorage';
import { useDebounceCallback } from '../hooks';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../Modules/AAA/selectors';

const useFormikAutosave = (lsKey: string) => {
  // нужно трекать смену учеток чтобы дропать значения из лс
  const { id } = useSelector(getCurrentUser);

  // дергаем нужные данные из формика
  const formik = useFormikContext();
  const LSFormValues = useRef(new LocalStorageService(lsKey));
  const formikValues = formik?.values;
  const isResetted = formik?.status === 'resetted'; // при клике на отмену юзер переводит форму в статус resetted, значения из лс удаляются
  const dirty = formik?.dirty; // если юзер трогал своими грязными руками форму - true
  const isSubmitting = formik?.isSubmitting;

  useEffect(() => {
    const lsValue = LSFormValues.current.get() as { id: number; values: any } | undefined;
    if (lsValue?.id !== id) {
      LSFormValues.current.remove();
    }
  }, [id]);

  const setLSValuesWithDebounce = useDebounceCallback(values => {
    LSFormValues.current.set(values);
  }, 500);

  useEffect(() => {
    dirty && setLSValuesWithDebounce({ id, values: formikValues });
    (isSubmitting || isResetted) && LSFormValues.current.remove();
  }, [formikValues, dirty, isSubmitting, LSFormValues, id, isResetted, setLSValuesWithDebounce]);
};

export const useFormikAutosaveValues = <T>(lsKey: string): T | undefined => {
  const [values, setValues] = useState<{ id: number; values: T }>();
  useEffect(() => {
    const LSFormValues = new LocalStorageService(lsKey);
    setValues(LSFormValues.get() as { id: number; values: T } | undefined);
  }, [lsKey]);
  return values?.values;
};

export default useFormikAutosave;
