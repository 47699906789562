import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from 'Modules/Modals/actions';
import { IUser } from 'Modules/AAA/types';
import AAAactions, { setCurrentUser, setUserTests } from 'Modules/AAA/actions';
import AAAHttp from 'Modules/AAA/service';
import AddressActions from 'Modules/SearchAddress/actions';
import OrderActions from 'Modules/Cart/actions';
import ShopActions from 'Modules/Shops/actions';
import PaymentCardsActions from 'Modules/PaymentCards/actions';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { setEntityAction } from '../../../Modules/Entity/actions';
import http from 'Modules/Shops/http';
import { IS_KOMUS } from '../../../../config/config';
import { fetchEntityInfo } from '../../../Modules/Entity/http';

const useAAA = () => {
  const dispatch = useDispatch();

  const closeModal = useCallback(() => dispatch(ModalActions.closeModal()), [dispatch]);
  const updateProfile = useCallback((postData: Partial<IUser>) => dispatch(AAAactions.updateProfile(postData)), [
    dispatch,
  ]);
  const validateCode = useCallback((code: string) => dispatch(AAAactions.validateCode(code)), [dispatch]);

  const login = useCallback((postData: Partial<IUser>) => dispatch(AAAactions.login(postData)), [dispatch]);

  const getFullProfile = useCallback(async () => {
    const data = await AAAHttp.getFullProfile();
    const {
      shop,
      place,
      order,
      user,
      user: { as_entity },
      city,
    } = data;

    if (!IS_KOMUS) {
      const userTests = await http.getUserABTests(city?.id);

      if (userTests) {
        dispatch(setUserTests(userTests));
      }
    }

    if (user) {
      dispatch(setCurrentUser(user));
    }
    if (place) dispatch(AddressActions.setCurrentAddress(place));
    if (order) dispatch(OrderActions.setOrder(order));
    if (as_entity) {
      const data = await fetchEntityInfo();
      if (data) {
        dispatch(setEntityAction(data));
      }
    }
    if (shop) {
      dispatch(ShopActions.setCurrentShop(shop));
      if (!IS_KOMUS) {
        await dispatch(PaymentCardsActions.getCardsListAction());
      }
    }

    return data;
  }, [dispatch]);

  const currentCity = useSelector(getCurrentCity);

  return {
    closeModal,
    updateProfile,
    validateCode,
    getFullProfile,
    currentCity,
    login,
  };
};

export default useAAA;
