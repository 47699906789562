import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import apiService from '@igooods/api-service';
import ThemeButton from 'Components/ThemedButton';
import Flex from 'Components/Grid/Flex';
import Box from 'Components/Grid/Box';
import Input from 'Components/Input';
import { useOnClickOutside } from '../../utils/hooks';
import Text from 'Components/Typography/Text';
import { FontSize } from 'theme';
import { KeyCode } from 'constants/enums';
import { BorderedModalStyles } from '../Modal/styled';
import {
  CONFIG_CHANGE_BUTTON_TEST_ID,
  API_CHANGE_SUBMIT_TEST_ID,
  API_INPUT_TEST_ID,
  CURRENT_API_TEST_ID,
  WRAPPER_TEST_ID,
  API_RESET_TEST_ID,
} from './constants';
import { EnumTheme } from 'constants/enums';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: #fff;
  ${BorderedModalStyles}
`;

const ConfigChanger = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showInput, setInputVisibility] = useState(false);
  const [value, setValue] = useState('');

  useOnClickOutside(wrapperRef, () => setInputVisibility(false));

  const onSubmitHandler = () => {
    setInputVisibility(false);
    apiService.set(value);
    window.location.reload();
  };

  const getApiFromStorage = () => apiService.get();

  const resetApi = () => {
    apiService.remove();
    window.location.reload();
  };

  return (
    <Wrapper ref={wrapperRef} data-testid={WRAPPER_TEST_ID}>
      {showInput && (
        <Box py={4} px={4}>
          {getApiFromStorage() && (
            <Box mb={2}>
              <Text data-testid={CURRENT_API_TEST_ID} size={FontSize.xs}>
                {' '}
                Текущее апи {getApiFromStorage()}
              </Text>
            </Box>
          )}

          <Flex width="300px">
            <Box width="150px" mr={4}>
              <Input
                placeholder="master"
                value={value}
                testId={API_INPUT_TEST_ID}
                onChangeInput={inputValue => {
                  setValue(inputValue);
                }}
                onKeyDownInput={e => {
                  if (e.keyCode === KeyCode.Enter) {
                    onSubmitHandler();
                  }
                }}
              />
            </Box>
            <ThemeButton
              data-testid={API_CHANGE_SUBMIT_TEST_ID}
              use={EnumTheme.danger}
              size="normal"
              onClick={onSubmitHandler}
            >
              Изменить
            </ThemeButton>
          </Flex>
          <Box mt={2}>
            <ThemeButton use={EnumTheme.secondary} size="small" data-testid={API_RESET_TEST_ID} onClick={resetApi}>
              Сбросить API
            </ThemeButton>
          </Box>
        </Box>
      )}
      <ThemeButton
        data-testid={CONFIG_CHANGE_BUTTON_TEST_ID}
        size="small"
        onClick={() => setInputVisibility(!showInput)}
      >
        Config
      </ThemeButton>
    </Wrapper>
  );
};

export default ConfigChanger;
