import { colors } from 'constants/colors';
import darken from 'polished/lib/color/darken';
import { FontWeight, FontFamily, FontSize } from 'theme';

export const buttonThemeNames = {
  main: 'main',
  secondary: 'secondary',
  secondaryLight: 'secondaryLight',
  danger: 'danger',

  textMain: 'textMain',
  textSecondary: 'textSecondary',
  textDanger: 'textDanger',
  textSecondaryMain: 'textSecondaryMain',

  link: 'link',
  white: 'white',
  borderWhite: 'borderWhite',
  quaternary: 'quaternary',

  roundGreen: 'roundGreen',
  roundGhostWhite: 'roundGhostWhite',
  roundWhite: 'roundWhite',
  roundGreenText: 'roundGreenText',
  roundPink: 'roundPink',
  roundBorderGray: 'roundBorderGray',
  beveledMint: 'beveledMint',
  beveledWhite: 'beveledWhite',
  roundBlack: 'roundBlack',
  roundBorderWhite: 'roundBorderWhite',
  roundGhostGray: 'roundGhostGray',
};

export type TButtonTheme = keyof typeof buttonThemeNames;

export const buttonThemeSize = {
  mainHeightMobile: {
    small: 30,
    normal: 35,
    large: 40,
  },
  secondHeightMobile: {
    small: 40,
    normal: 40,
    large: 48,
  },
};

const borderRadius = {
  large: 12,
  normal: 12,
  small: 10,
};

const roundBlack = {
  color: colors.white,
  bgColor: colors.blackRussian,
  fontFamily: FontFamily.secondFont,
  height: {
    large: 58,
    normal: 48,
    small: 40,
  },
  borderRadius: {
    large: 50,
    normal: 50,
    small: 50,
  },
  hover: {
    bgColor: colors.blackRussian,
  },
  active: {
    bgColor: colors.blackRussian,
  },
  focus: {
    bgColor: colors.blackRussian,
  },
  disabled: {
    bgColor: colors.blackRussian,
  },
  loading: {
    bgColor: colors.blackRussian,
  },
};

export const buttonThemeStyles = {
  redesign: {
    height: {
      small: 38,
      normal: 38,
      large: 42,
    },
    bgColor: colors.white,
    borderRadius: {
      small: '100px',
      normal: '100px',
      large: '100px',
    },
    borderColor: colors.lightBluishGreen,
    color: colors.pineGreen,
    active: {
      bgColor: colors.pineGreen,
      borderColor: colors.pineGreen,
      color: colors.white,
    },
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.white,
      borderColor: colors.lightBluishGreen,
      color: colors.pineGreen,
    },
    hover: {
      bgColor: colors.pineGreen,
      borderColor: colors.pineGreen,
      color: colors.white,
    },
    loading: {
      bgColor: colors.darkBlueGreenThree,
      color: colors.offWhite,
    },
    fontFamily: FontFamily.secondFont,
  },

  main: {
    active: {
      bgColor: colors.darkBlueGreenThree,
    },
    bgColor: colors.darkBlueGreen,
    borderRadius,
    color: colors.white,
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.darkBlueGreenThree,
    },
    hover: {
      bgColor: colors.darkBlueGreenTwo,
    },
    loading: {
      bgColor: colors.darkBlueGreenThree,
      color: colors.offWhite,
    },
  },

  secondary: {
    active: {
      bgColor: colors.grederlevyDark,
    },
    bgColor: colors.grederlevy,
    borderRadius,
    color: colors.darkBlueGreen,
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.grederlevy,
    },
    hover: {
      bgColor: colors.grederlevyLight,
    },
    loading: {
      bgColor: colors.grederlevyDark,
      color: colors.offWhite,
    },
  },

  danger: {
    active: {
      bgColor: colors.watermelon,
    },
    bgColor: colors.watermelon,
    borderRadius,
    color: colors.white,
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.watermelon,
    },
    hover: {
      bgColor: colors.watermelonLight,
    },
    loading: {
      bgColor: colors.watermelon,
      color: colors.offWhite,
    },
  },

  secondaryLight: {
    active: {
      bgColor: colors.grederlevy,
    },
    bgColor: colors.grederlevyLightTwo,
    borderRadius,
    color: colors.darkBlueGreen,
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.grederlevy,
    },
    hover: {
      bgColor: colors.grederlevy,
    },
    loading: {
      bgColor: colors.grederlevyLightTwo,
    },
  },

  textMain: {
    color: colors.primaryAction,
    bgColor: 'transparent',
    active: {
      bgColor: 'transparent',
      color: colors.primaryAction,
    },
    disabled: {
      bgColor: 'transparent',
      color: colors.dark100,
    },
    focus: {
      bgColor: 'transparent',
      color: colors.primaryAction,
    },
    hover: {
      bgColor: 'transparent',
      color: colors.primaryAction,
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  textSecondary: {
    color: colors.greyishBrown,
    bgColor: 'transparent',
    active: {
      bgColor: 'transparent',
      color: colors.black,
    },
    disabled: {
      bgColor: 'transparent',
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: 'transparent',
      color: colors.greyishBrown,
    },
    hover: {
      bgColor: 'transparent',
      color: colors.warmGrey,
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  textDanger: {
    color: colors.watermelon,
    bgColor: 'transparent',
    active: {
      bgColor: 'transparent',
      color: colors.watermelon,
    },
    disabled: {
      bgColor: 'transparent',
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: 'transparent',
      color: colors.watermelon,
    },
    hover: {
      bgColor: 'transparent',
      color: colors.watermelonLight,
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  textSecondaryMain: {
    color: colors.almostBlack2,
    bgColor: 'transparent',
    active: {
      bgColor: 'transparent',
      color: colors.almostBlack2,
    },
    disabled: {
      bgColor: 'transparent',
      color: colors.almostBlack2,
    },
    focus: {
      bgColor: 'transparent',
      color: colors.almostBlack2,
    },
    hover: {
      bgColor: 'transparent',
      color: colors.almostBlack2,
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  link: {
    color: colors.darkBlueGreen,
    bgColor: 'transparent',
    active: {
      bgColor: colors.silverFour,
    },
    borderRadius,
    disabled: {
      bgColor: 'transparent',
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: colors.paleGrey,
    },
    hover: {
      bgColor: colors.paleGrey,
    },
    loading: {
      bgColor: colors.paleGrey,
    },
  },

  white: {
    bgColor: colors.white,
    color: colors.greyishBrown,
    borderRadius,
    active: {
      bgColor: darken(0.05, colors.white),
    },
    disabled: {
      bgColor: 'transparent',
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: darken(0.05, colors.white),
    },
    hover: {
      bgColor: darken(0.05, colors.white),
      color: colors.darkGrey,
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  borderWhite: {
    bgColor: colors.white,
    color: colors.dark300,
    borderColor: colors.silver,
    borderRadius,
    disabled: {
      bgColor: colors.white,
      color: colors.pinkishGrey,
    },
    active: {
      bgColor: darken(0.05, colors.field300),
    },
    focus: {
      bgColor: darken(0.05, colors.field300),
    },
    hover: {
      bgColor: colors.field300,
      borderColor: 'transparent',
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  quaternary: {
    bgColor: colors.paleGrey,
    color: colors.darkGrey,
    active: {
      bgColor: darken(0.05, colors.paleGrey),
    },
    disabled: {
      bgColor: colors.paleGrey,
      color: colors.pinkishGrey,
    },
    focus: {
      bgColor: darken(0.05, colors.paleGrey),
    },
    hover: {
      bgColor: darken(0.05, colors.paleGrey),
    },
    loading: {
      bgColor: 'transparent',
    },
  },

  roundGreen: {
    color: colors.white,
    bgColor: colors.pineGreen,
    borderColor: 'transparent',
    fontFamily: FontFamily.secondFont,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.teal,
      borderColor: 'transparent',
    },
    active: {
      bgColor: colors.teal,
      borderColor: colors.pineGreen,
    },
    focus: {
      bgColor: colors.teal,
      borderColor: colors.pineGreen,
    },
    disabled: {
      bgColor: colors.pineGreen,
      color: colors.white,
    },
    loading: {
      bgColor: colors.teal,
      color: colors.white,
      borderColor: 'transparent',
    },
  },

  roundGreenText: {
    color: colors.pineGreen,
    bgColor: colors.white,
    borderColor: colors.algaeGreen,
    fontFamily: FontFamily.secondFont,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    padding: {
      large: '0 54px 2px',
      normal: '0 40px 2px',
      small: '0 18px 2px',
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.white,
    },
    active: {
      bgColor: colors.white,
    },
    focus: {
      bgColor: colors.white,
    },
    disabled: {
      bgColor: colors.white,
    },
    loading: {
      bgColor: colors.white,
      color: colors.pineGreen,
    },
  },

  roundWhite: {
    color: colors.dark300,
    bgColor: colors.white,
    fontFamily: FontFamily.mainFont,
    fontSize: FontSize.m,
    fontWeight: FontWeight.medium,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    padding: {
      large: '0 54px',
      normal: '0 40px',
      small: '0 18px',
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.white,
    },
    active: {
      bgColor: colors.white,
    },
    focus: {
      bgColor: colors.white,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.dark300,
    },
    loading: {
      bgColor: colors.white,
      color: colors.dark300,
    },
  },

  roundPink: {
    color: colors.white,
    bgColor: colors.brinkPink,
    fontFamily: FontFamily.secondFont,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    padding: {
      large: '0 54px 2px',
      normal: '0 40px 2px',
      small: '0 18px 2px',
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.tickleMePink,
    },
    active: {
      bgColor: colors.tickleMePink,
    },
    focus: {
      bgColor: colors.tickleMePink,
    },
    disabled: {
      bgColor: colors.brinkPink,
      color: colors.white,
    },
    loading: {
      bgColor: colors.brinkPink,
      color: colors.white,
    },
  },
  roundGhostWhite: {
    color: colors.blackRussian,
    bgColor: colors.white,
    fontFamily: FontFamily.secondFont,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.lightGreyThree,
    },
    active: {
      bgColor: colors.lightGreyThree,
      borderColor: colors.surfCrest,
    },
    focus: {
      bgColor: colors.lightGreyThree,
      borderColor: colors.surfCrest,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.blackRussian,
    },
    loading: {
      bgColor: colors.white,
      color: colors.blackRussian,
    },
    fontWeight: FontWeight.extraBold,
  },
  roundBorderWhite: {
    color: colors.blackRussian,
    bgColor: colors.white,
    borderColor: colors.lightGreyThree,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    padding: {
      large: '0 54px 2px',
      normal: '0 40px 2px',
      small: '0 18px 2px',
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.mintHaze,
      borderColor: colors.mintHaze,
    },
    active: {
      bgColor: colors.mintHaze,
      borderColor: colors.mintHaze,
    },
    focus: {
      bgColor: colors.mintHaze,
      borderColor: colors.mintHaze,
    },
    disabled: {
      bgColor: colors.white,
      borderColor: colors.lightGreyThree,
      height: {
        large: 58,
        normal: 48,
        small: 40,
      },
      padding: {
        large: '0 54px 2px',
        normal: '0 40px 2px',
        small: '0 18px 2px',
      },
      borderRadius: {
        large: 50,
        normal: 50,
        small: 50,
      },
      hover: {
        bgColor: colors.dark100,
        borderColor: colors.dark100,
      },
      active: {
        bgColor: colors.dark200,
        borderColor: colors.dark200,
      },
      focus: {
        bgColor: colors.dark200,
        borderColor: colors.dark200,
      },
      disabled: {
        bgColor: colors.white,
        color: colors.blackRussian,
      },
      loading: {
        bgColor: colors.white,
        color: colors.blackRussian,
      },
    },
    roundBorderGray: {
      color: colors.blackRussian,
    },
    loading: {
      bgColor: colors.white,
      color: colors.blackRussian,
    },
  },
  roundBorderGray: {
    color: colors.blackRussian,
    bgColor: colors.white,
    fontFamily: FontFamily.secondFont,
    borderColor: colors.lavenderLight,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    padding: {
      large: '0 13px',
      normal: '0 13px',
      small: '0 13px',
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.white,
    },
    active: {
      bgColor: colors.white,
    },
    focus: {
      bgColor: colors.white,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.blackRussian,
    },
    loading: {
      bgColor: colors.white,
      color: colors.blackRussian,
    },
  },

  beveledMint: {
    color: colors.pineGreen,
    bgColor: colors.hintOfGreen,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: '100% 50%',
      normal: '100% 50%',
      small: '100% 50%',
    },
    hover: {
      bgColor: colors.snowyMint,
    },
    active: {
      bgColor: colors.snowyMint,
    },
    focus: {
      bgColor: colors.snowyMint,
    },
    disabled: {
      bgColor: colors.hintOfGreen,
      color: colors.pineGreen,
    },
    loading: {
      bgColor: colors.hintOfGreen,
      color: colors.pineGreen,
    },
  },

  beveledWhite: {
    color: colors.pineGreen,
    bgColor: colors.white,
    height: {
      large: 58,
      normal: 48,
      small: 30,
    },
    borderRadius: {
      large: '100% 50%',
      normal: '100% 50%',
      small: '100% 50%',
    },
    hover: {
      bgColor: colors.geyser,
    },
    active: {
      bgColor: colors.geyser,
    },
    focus: {
      bgColor: colors.geyser,
    },
    disabled: {
      bgColor: colors.white,
      color: colors.pineGreen,
    },
    loading: {
      bgColor: colors.white,
      color: colors.pineGreen,
    },
  },
  roundGhostGray: {
    color: colors.blackRussian,
    bgColor: colors.ghostWhite,
    borderColor: 'transparent',
    fontFamily: FontFamily.secondFont,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.lightGreyThree,
      borderColor: 'transparent',
    },
    active: {
      bgColor: colors.lightGreyThree,
      borderColor: colors.surfCrest,
    },
    focus: {
      bgColor: colors.silverFive,
      borderColor: colors.silverFive,
    },
    disabled: {
      bgColor: colors.white,
      borderColor: colors.lightGreyThree,
      height: {
        large: 58,
        normal: 48,
        small: 40,
      },
      padding: {
        large: '0 54px 2px',
        normal: '0 40px 2px',
        small: '0 18px 2px',
      },
      borderRadius: {
        large: 50,
        normal: 50,
        small: 50,
      },
      hover: {
        bgColor: colors.dark200,
        borderColor: colors.dark200,
      },
      active: {
        bgColor: colors.dark200,
        borderColor: colors.dark200,
        color: colors.white,
      },
      focus: {
        bgColor: colors.dark200,
        borderColor: colors.dark200,
      },
      disabled: {
        bgColor: colors.white,
        color: colors.blackRussian,
      },
      loading: {
        bgColor: colors.white,
        color: colors.blackRussian,
      },
    },

    roundBorderGray: {
      color: colors.blackRussian,
    },
    loading: {
      bgColor: colors.white,
      color: colors.blackRussian,
      borderColor: 'transparent',
    },
  },
  roundRed: {
    color: colors.white,
    bgColor: colors.primaryMain,
    borderColor: 'transparent',
    fontFamily: FontFamily.mainFont,
    fontWeight: FontWeight.medium,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.primaryAction,
      borderColor: 'transparent',
    },
    active: {
      bgColor: colors.primaryMain,
      borderColor: colors.primaryMain,
    },
    focus: {
      bgColor: colors.primaryMain,
      borderColor: colors.primaryMain,
    },
    disabled: {
      bgColor: colors.primaryMainDisabled,
      color: colors.white,
    },
    loading: {
      bgColor: colors.primaryMain,
      color: colors.white,
      borderColor: 'transparent',
    },
  },
  beveledGray: {
    color: colors.dark200,
    bgColor: colors.light200,
    height: {
      large: 58,
      normal: 48,
      small: 40,
    },
    borderRadius: {
      large: '100% 50%',
      normal: '100% 50%',
      small: '100% 50%',
    },
    hover: {
      bgColor: colors.light300,
    },
    active: {
      bgColor: colors.dark300,
    },
    focus: {
      bgColor: colors.dark300,
    },
    disabled: {
      bgColor: colors.light200,
      color: colors.dark100,
    },
    loading: {
      bgColor: colors.light200,
      color: colors.dark200,
    },
  },
  roundDarkGray: {
    color: colors.dark300,
    bgColor: colors.light300,
    borderColor: 'transparent',
    fontFamily: FontFamily.mainFont,
    fontWeight: FontWeight.medium,
    height: {
      large: 58,
      normal: 48,
      small: 38,
    },
    borderRadius: {
      large: 50,
      normal: 50,
      small: 50,
    },
    hover: {
      bgColor: colors.primaryMain,
      borderColor: 'transparent',
      color: colors.white,
    },
    active: {
      bgColor: colors.primaryMain,
      borderColor: colors.primaryMain,
      color: colors.white,
    },
    focus: {
      bgColor: colors.light300,
      borderColor: 'transparent',
      color: colors.dark300,
    },
    disabled: {
      bgColor: colors.light300,
      color: colors.white,
    },
    loading: {
      bgColor: colors.light300,
      color: colors.white,
      borderColor: 'transparent',
    },
  },
  roundBlack,
};
