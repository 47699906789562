import React from 'react';
import { useDispatch } from 'react-redux';
import ModalActions from 'Modules/Modals/actions';
import { ModalType } from 'constants/enums';
import { UserButton, ButtonText } from './styles';

export const AUTH_BTN_TEST_ID = 'AUTH_BTN_TEST_ID';

const TempUserButton: React.FC = () => {
  const dispatch = useDispatch();
  const openAuthModal = () => dispatch(ModalActions.openModal(ModalType.Auth));

  return (
    <UserButton onClick={openAuthModal}>
      <ButtonText>Войти</ButtonText>
    </UserButton>
  );
};

export default TempUserButton;
