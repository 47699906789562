import { IUser } from 'Modules/AAA/types';
import { IProduct } from 'Modules/Products/types';
import { IDeliveryDate, IDeliveryPoint, IShop, IShopServiceInfo } from 'Modules/Shops/types';
import { BehaviorCallTypes, BehaviorTypes, PaymentTypes } from '../Checkout/types';
import { IPlace } from '../Places/types';

export enum EnumCurrency {
  rur = 'rur',
}

export enum EnumOrderType {
  delivery = 'delivery',
  pickup = 'pickup', // deprecated
}

export enum CartActionNames {
  ADD_IN_ORDER = 'ADD_ORDER',
  UPDATE_PRODUCT_IN_ORDER = 'UPDATE_PRODUCT_IN_ORDER',
  REMOVE_FROM_ORDER = 'REMOVE_FROM_ORDER',
  CHANGE_ORDER = 'CHANGE_ORDER',
  GET_ORDER = 'GET_ORDER',
  SET_ORDER = 'SET_ORDER',
  CLEAR_ORDER = 'CLEAR_ORDER',
  MARK_AS_REMOVED_IN_ORDER = 'MARK_AS_REMOVED_IN_ORDER',
  UNDO_REMOVE_IN_ORDER = 'UNDO_REMOVE_IN_ORDER',
  CLEAR_CART = 'CLEAR_CART',
  LOADING_ACTIVE_ORDERS = 'LOADING_ACTIVE_ORDERS',
  SET_ACTIVE_ORDERS = 'SET_ACTIVE_ORDERS',
  UPDATE_ORDER_INFO = 'UPDATE_ORDER_INFO',
  CHANGE_PENDING_PRODUCTS_ADD = 'CHANGE_PENDING_PRODUCTS_ADD',
  CHANGE_PENDING_PRODUCTS_REMOVE = 'CHANGE_PENDING_PRODUCTS_REMOVE',
}

interface IDiscount {
  discount: number;
  type: string;
}

export interface IOrder {
  id: number;
  shop_id: number;
  products: IProduct[];
  weight: number;
  total_price: number;
  min_price: number;
  service_info: IShopServiceInfo[];
  comment?: string;
  points?: number;
  discounts?: IDiscount[];
  user_points?: number;
  max_points?: number;
  delivery_date: IDeliveryDate;
  last_modified: string; // ISO_8601
  miss_products?: IProduct[];
  currency: EnumCurrency;
  delivery_point?: IDeliveryPoint; // deprecated
  zone_id: number;
  first_order?: boolean;
  promocode?: string;
  promocodeType?: string;
  payment_method: PaymentTypes;
  fewer_plastic_bags?: boolean;
  shop?: IShop;
  model_limits: IModelLimit[];
  total_sale_discount?: number;
}

export interface IModelLimit {
  id: number;
  name: string;
  model_ids: number[];
  limit: number;
  unit: 'gram' | 'piece';
}

export interface IPendingProductIds {
  add: number[];
  remove: number[];
}

export interface ICartState extends IOrder {
  pendingProducts: IPendingProductIds;
}

export interface IChangeOrderResponse {
  order: IOrder;
}

export interface IAddPromocodeResponse {
  order: IOrder;
  referral: boolean;
}

export interface ICreateOrderResponse {
  order: IOrder;
  shop?: IShop;
}

export interface IClearOrderResponse {
  order: IOrder;
}

export interface ICategories {
  id: number;
  name: string;
}

export interface IPromoDiscount {
  shipping_cost?: number;
  discount?: number;
  points?: number;
  type: 'fixed' | 'percent';
}

export type PromoKind = 'invite' | 'shipping_cost' | 'discount' | 'points';

export interface IPromocode {
  code: string;
  kind: PromoKind;
  min_sum_order: number;
  start_at: string;
  expire_at: string;
  categories: ICategories[];
  min_sum_categories: number;
  discount: IPromoDiscount;
  current_discount: number;
  description: string;
  special_conditions: string;
}

export interface IGetPromocodesResponse {
  promocodes: IPromocode[];
}

export interface IFillOrder {
  id: number;
  first_order: boolean;
  last_modified: string;
  delivery_date: IDeliveryDate;
}

export interface ISuccessLastOrder {
  id: number;
  delivery_date: IDeliveryDate;
}

export interface IFillOrderResponse {
  order: IFillOrder;
  user: IUser;
}

export interface IFillOrderRequest {
  user: {
    name: string;
    phone: string;
    email?: string;
  };
  payment_type: PaymentTypes;
  deliver_at: string;
  kind: string;
  behavior: BehaviorTypes | BehaviorCallTypes;
  place?: IPlace;
  deliver_end_date?: string;
  early?: boolean;
  change_from?: string;
  comment?: string;
  bank_card_id?: number;
  last_modified: string;
  success_url: string;
  failure_url: string;
  elevator?: boolean;
}

export interface IActiveOrdersList {
  list: IOrder[];
}

export interface IAddProductToOrderRequest {
  orderId: number;
  product_id: number;
  amount: number;
  weight?: number;
  comment?: string;
}

export enum OperationTypes {
  INC = 'inc',
  DEC = 'dec',
  SET = 'set',
}

export interface IActiveOrdersState {
  list: IOrder[];
  isLoading: boolean;
  isSuccess: boolean;
}

export interface ICartProductUpdate {
  type: CartActionNames;
  payload: IProduct;
}

export interface IActiveOrdersAction {
  type: CartActionNames;
  payload: IOrder[];
}
export interface IOrderPublicOfferResponse {
  offer_url: string;
  service_offer_url: string;
  delivery_offer_url: string;
}
