import { URL } from 'constants/urlMaps';

interface IFaqLink {
  title: string;
  url: URL;
}

export const links: IFaqLink[] = [
  {
    title: 'Как это работает',
    url: URL.how_it_works,
  },
  {
    title: 'Выбор товара и оплата',
    url: URL.payment,
  },
  { title: 'Доставка', url: URL.delivery },
  { title: 'Обмен и возврат товара', url: URL.obmen_i_vozvrat_tovara },
];

export const subLinks: IFaqLink[] = [
  {
    title: 'Публичная оферта',
    url: URL.offer,
  },
  {
    title: 'Договор оферты для ЭДО ',
    url: URL.EDO_oferta,
  },
  {
    title: 'Реквизиты ООО «Комус-Ростов»',
    url: URL.KOMUS_Rostov,
  },
];
