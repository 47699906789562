import { addStyleIfPropTruly } from 'utils/styledComponents';
import { SVGAttributes } from 'react';
import styled from 'styled-components';

export interface IIcon extends SVGAttributes<SVGElement> {
  hoverColor?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  onClick?: (e: any) => void;
}

const Icon = styled.svg<IIcon>`
  color: ${({ color }) => color || 'currentColor'};
  fill: ${({ fill }) => fill || 'currentColor'};
  ${({ hoverColor }) => (hoverColor ? `&:hover { color: ${hoverColor};}` : '')}

  ${({ width = 18 }) => `width: ${width}px;`}
  ${({ height = 18 }) => `height: ${height}px;`}
  ${addStyleIfPropTruly('onClick', 'cursor: pointer;')}
`;

export default Icon;
