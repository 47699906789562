import React from 'react';
import { EnumButtonSize, EnumTheme, ModalType } from 'constants/enums';
import { ModalFooter, ModalHeader, ModalTitle } from 'Containers/Modals/styled';
import ThemeButton from 'Components/ThemedButton';
import { useDispatch } from 'react-redux';
import ModalActions from 'Modules/Modals/actions';
import { colors } from 'constants/colors';
import { Container, Description } from './styled';

interface IAccountActivationModalProps {
  type: ModalType;
}

const AccountActivationModal: React.FC<IAccountActivationModalProps> = () => {
  const dispatch = useDispatch();

  const handleButtonClick = () => dispatch(ModalActions.closeModal());

  return (
    <Container>
      <ModalHeader>
        <ModalTitle>Регистрация прошла успешно!</ModalTitle>
        <Description color={colors.dark300}>
          Заказ с оплатой банковской картой или через СБП можно оформить уже сейчас.
          <br />
          Возможность оплаты по счету с отсрочкой платежа появится после проверки вашей компании в течение часа.
          <br />
          Когда будет готово — сообщим через СМС и почту.
        </Description>
      </ModalHeader>
      <ModalFooter>
        <ThemeButton fluid size={EnumButtonSize.large} onClick={handleButtonClick} use={EnumTheme.roundDarkGray}>
          Понятно
        </ThemeButton>
      </ModalFooter>
    </Container>
  );
};

export default AccountActivationModal;
