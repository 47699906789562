import { IApplicationState } from 'reduxStore/types';
import { checkIsExistPhoneAndNameUser } from 'Modules/AAA/selectors';
import { checkIsFullAddress } from 'Modules/SearchAddress/selectors';
import { ICheckout, ICheckoutPayment } from 'Modules/Checkout/types';
import { getAvailableShopPaymentTypes } from '../Shops/selectors';
import { getCartState } from '../Cart/selectors';
import { getEntityBalance } from '../Entity/selectors';

export const getCheckout = (state: IApplicationState) => state.checkout as ICheckout;

export const getCheckoutPayment = (state: IApplicationState) => {
  const defaultPaymentType = getCheckout(state).payment as ICheckoutPayment;
  const availablePaymentTypes = getAvailableShopPaymentTypes(state);

  return availablePaymentTypes.includes(defaultPaymentType.type)
    ? defaultPaymentType
    : ({ type: availablePaymentTypes[0] } as ICheckoutPayment);
};

export const isEnoughBalanceForCheckout = (state: IApplicationState): boolean =>
  (getEntityBalance(state) as number) >= getCartState(state).total_price;

export const checkIsReadyToCheckout = (state: IApplicationState) =>
  checkIsExistPhoneAndNameUser(state) && checkIsFullAddress(state);
