import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCityByIpIsDifferent } from 'Modules/SearchAddress/hooks';
import { removeProvince } from 'Components/Header/CitiesButton/utils';
import { DropdownContent } from 'Components/Dropdown';
import { colors } from 'constants/colors';
import { getTypographyFontStyle } from 'theme/selectors';
import { FontSize, FontWeight, FontFamily } from 'theme';
import Text from 'Components/Typography/Text';
import ThemedButton from 'Components/ThemedButton';
import { Box } from '@igooods/ui-kit';
import Flex from 'Components/Grid/Flex';
import { useUserAgentContext } from 'utils/userAgent/context';
import CloseIcon from 'Components/Icon/CloseIcon';
import { mediaSmDown } from 'theme/helpers/css';
import useModalState from 'utils/hooks/useModalState';
import { ICity } from 'Modules/SearchAddress/types';
import Cookie from 'js-cookie';
import { REJECTED_CITY_CHECK, getCookieDomain } from 'constants/cookie';
import { EnumButtonSize, EnumTheme } from 'constants/enums';

const CityConfirm = styled.div`
  position: relative;
  background: ${colors.white};
  padding: 16px 38px 16px 16px;
  border: 1px solid ${colors.paleGrey};
  box-sizing: border-box;
  box-shadow: 0px 6px 12px ${colors.blackTwo5};
  border-radius: 4px;
  ${getTypographyFontStyle(FontSize.s)};
  white-space: nowrap;
  display: inline-block;
  margin-top: 4px;
  ${mediaSmDown`
    transform: translateX(-20%);
  `};
`;

const CloseIconButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  cursor: pointer;
`;

interface ICheckCity {
  onCityChange: (city: ICity) => void;
}

const CheckCity: React.FC<ICheckCity> = ({ onCityChange }) => {
  const differentCity = useCityByIpIsDifferent();
  const { isMobile } = useUserAgentContext();
  const buttonSize = isMobile ? EnumButtonSize.small : EnumButtonSize.normal;
  const { isVisible, hideModal, showModal } = useModalState();
  const onDiscardClick = () => {
    hideModal();

    Cookie.set(REJECTED_CITY_CHECK, 'true', getCookieDomain());
  };

  useEffect(() => {
    // don't show check city modal if rejected once
    if (!Cookie.get(REJECTED_CITY_CHECK)) {
      showModal();
    }
  }, [showModal]);

  const onAccept = () => {
    hideModal();
    differentCity && onCityChange(differentCity);
  };
  return differentCity?.id && isVisible ? (
    <DropdownContent isOpen $position="bottom">
      <CityConfirm>
        <Box mb={2}>
          <Text
            fontWeight={FontWeight.semiBold}
            fontFamily={FontFamily.secondFont}
            color={colors.black}
            size={FontSize.s}
          >
            Ваш&nbsp;город&nbsp;—&nbsp;{removeProvince(differentCity.name)}?
          </Text>
        </Box>
        <Flex>
          <Box mr={2.5}>
            <ThemedButton use={EnumTheme.roundRed} size={buttonSize} onClick={onAccept}>
              Да
            </ThemedButton>
          </Box>
          <ThemedButton use={EnumTheme.roundRed} size={buttonSize} onClick={onDiscardClick}>
            Нет
          </ThemedButton>
        </Flex>
        <CloseIconButton onClick={hideModal}>
          <CloseIcon width="12" height="12" color={colors.warmGrey} />
        </CloseIconButton>
      </CityConfirm>
    </DropdownContent>
  ) : null;
};

export default CheckCity;
