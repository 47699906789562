import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Grid/Flex';
import Text from 'Components/Typography/Text';
import { Grid } from '@igooods/ui-kit';
import { useSelector } from 'react-redux';
import { getFullCityInfo } from 'Modules/SearchAddress/selectors';
import { FontFamily, FontSize, FontWeight, Transitions } from 'theme';
import { colors } from 'constants/colors';
import { mediaLgUp, mediaMdUp, mediaMdDown, mediaSmDown } from 'theme/helpers/css';
import ExternalLink from 'Components/ExternalLink';
import { URL } from 'constants/urlMaps';
import NewSocialsList from 'Components/Footer/NewSocialsList';
import { getTypographyFontStyle } from 'theme/selectors';
import { useSupportBtnWithInvertStyles } from 'utils/hooks';
import { isKomus } from '../../../config/config';

export const CATALOG_FOOTER_ID = 'CATALOG_FOOTER_ID';
export const FOOTER_CONTAINER_TEST_ID = 'FOOTER_CONTAINER_TEST_ID';
export const FOOTER_CITY_PHONE_WRAPPER_TEST_ID = 'FOOTER_CITY_PHONE_WRAPPER_TEST_ID';
export const FOOTER_LINKS_WRAPPER_TEST_ID = 'FOOTER_LINKS_WRAPPER_TEST_ID';

export const FOOTER_HEIGHT = 92;

const FooterWrapper = styled.footer`
  background-color: ${colors.dark200};
  padding-right: 97px;
  padding-left: 43px;
  /* убрать после того, как будет готова сетка */
  ${mediaLgUp`
    margin-left: 270px;
  `}

  ${mediaMdDown`
    padding-right: 114px;
    padding-left: 52px;
  `}
  ${mediaSmDown`
    padding-right: 20px;
    padding-left: 20px;
  `}
`;

const FooterContainer = styled(Grid).attrs({ col: { xs: 2 } })`
  height: ${FOOTER_HEIGHT}px;
  ${mediaMdUp`
    grid-template-columns: 1fr 3fr 1fr
  `}
  ${mediaSmDown`
    height: ${FOOTER_HEIGHT - 10}px;
  `}
`;

const ColorLink = styled(ExternalLink)`
  color: ${colors.white};
  transition: ${Transitions.opacity};
  ${getTypographyFontStyle(FontSize.s)};

  &:hover {
    opacity: 0.8;
  }
`;

const InnerLink = styled(ColorLink)`
  display: inline-block;
  & + ${ExternalLink} {
    margin-left: 32px;
  }
`;

const AdditionalText = styled(Text)`
  ${getTypographyFontStyle(FontSize.s)};
  color: ${colors.white};
`;

interface IInfoLink {
  name: string;
  url: string;
}

export const igooodsInfoLinks: IInfoLink[] = [
  {
    url: URL.how_it_works,
    name: 'Как мы работаем',
  },
];

const Footer = () => {
  const city = useSelector(getFullCityInfo);

  useSupportBtnWithInvertStyles();
  return (
    <FooterWrapper data-testid={FOOTER_CONTAINER_TEST_ID} id={CATALOG_FOOTER_ID}>
      <FooterContainer>
        {!isKomus && <NewSocialsList />}
        <Flex center middle grow hideBefore="md">
          <div data-testid={FOOTER_LINKS_WRAPPER_TEST_ID}>
            {igooodsInfoLinks.map((link: IInfoLink) => (
              <InnerLink key={link.name} href={link.url} newTab>
                <Text size={FontSize.s} fontWeight={FontWeight.normal} fontFamily={FontFamily.mainFont}>
                  {link.name}
                </Text>
              </InnerLink>
            ))}
          </div>
        </Flex>
        <Flex column center bottom>
          <Text size={FontSize.s} fontWeight={FontWeight.normal} data-testid={FOOTER_CITY_PHONE_WRAPPER_TEST_ID}>
            {city?.phone && <ColorLink href={`tel:+74957295471`}>8 (495) 729-54-71</ColorLink>}
          </Text>
          <AdditionalText size={FontSize.s}>8:00–18:00</AdditionalText>
        </Flex>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
