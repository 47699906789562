import { SESSION_START } from 'constants/cookie';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const useSessionStart = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!Cookies.get(SESSION_START) && pathname !== '/') {
      const coockieValue = new Date().getTime().toString();

      Cookies.set(SESSION_START, coockieValue, {
        expires: 1048576,
      });
    }
  }, [pathname]);
};

export default useSessionStart;
