import { AnyAction } from 'redux';
import { SET_RATE_LAST_ORDER } from './actions';
import { IRateLastOrder } from './types';

export const initialState = { show: false };

export default function currentShopReducer(state: IRateLastOrder = initialState, action: AnyAction): IRateLastOrder {
  switch (action.type) {
    case SET_RATE_LAST_ORDER:
      return { show: action.payload };
    default:
      return state;
  }
}
