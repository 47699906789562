export const TIME_NOT_AVAILABLE_TEST_ID = 'TIME_NOT_AVAILABLE_TEST_ID';
export const FORM_ADDRESS_TEST_ID = 'FORM_ADDRESS_TEST_ID';
export const FORM_APRT_TEST_ID = 'FORM_APRT_TEST_ID';
export const FORM_STAGE_TEST_ID = 'FORM_STAGE_TEST_ID';
export const FORM_PORCH_TEST_ID = 'FORM_PORCH_TEST_ID';
export const LIST_PLACES_TEST_ID = 'LIST_PLACES_TEST_ID';
export const ONLINE_WRAPPER_MOBILE_ID = 'ONLINE_WRAPPER_MOBILE_ID';

export const BEHAVIOR_RADIO_DESKTOP = 'BEHAVIOR_RADIO_DESKTOP';
export const BEHAVIOR_RADIO_MOBILE = 'BEHAVIOR_RADIO_MOBILE';
export const FULL_BEHAVIOR_REPLACE_TEST_ID = 'FULL_BEHAVIOR_REPLACE_TEST_ID';
export const NOTIFY_FIRST_ORDER_TEST_ID = 'NOTIFY_FIRST_ORDER_TEST_ID';
export const PANEL_TITLE_DESKTOP = 'PANEL_TITLE_DESKTOP';
export const PANEL_TITLE_MOBILE = 'PANEL_TITLE_MOBILE';
export const CHECKOUT_ORDER_TESTID_MOBILE = 'CHECKOUT_ORDER_TESTID_MOBILE';
export const CHECKOUT_WRAPPER_MOBILE = 'CHECKOUT_WRAPPER_MOBILE';
export const CONTACT_TEST_ID = 'CONTACT_TEST_ID';
export const CONTACT_PHONE_TEST_ID = 'CONTACT_PHONE_TEST_ID';
export const CONTACT_NAME_TEST_ID = 'CONTACT_NAME_TEST_ID';
export const NOTE_PHONE_TEST_ID = 'NOTE_PHONE_TEST_ID';
export const PANEL_CHEVRON_TEST_ID = 'PANEL_CHEVRON_TEST_ID';
export const PLACES_LIST_ADD_BUTTON_TEST_ID = 'PLACES_LIST_ADD_BUTTON_TEST_ID';
export const FORM_NOTE_TEST_ID = 'FORM_NOTE_TEST_ID';
export const DATE_BUTTON_TEST_ID = 'DATE_ITEM_TEST_ID';
export const DATE_LIST_ITEM_TEST_ID = 'DATE_LIST_ITEM_TEST_ID';
export const TIME_BUTTON_TEST_ID = 'TIME_ITEM_TEST_ID';
export const TIME_LIST_ITEM_TEST_ID = 'TIME_LIST_ITEM_TEST_ID';
export const CAN_DELIVERY_EARLIER_TEST_ID = 'CAN_DELIVERY_EARLYER_TEST_ID';
export const ORDER_NOTE_TEST_ID = 'ORDER_NOTE_TEST_ID';
export const REGISTRATION_CONFIRMATION_ERROR = 'Нельзя оформить заказ до подтверждения личного кабинета';
export const ERROR_CODE = {
  SELECTED_PAYMENT_TYPE_ERROR: 1488,
  SELECTED_TIME_DELIVERY_ERROR: 1402,
  PHONE_NOT_CONFIRMED_ERROR: 1403,
  LAST_MODIFIED_CART_ERROR: 1404,
  NOT_VALID_PROMOCODE_ERROR: false, //пока нет промокодов, когда появятся нужно узнать код ошибки у бэка
  NOT_ENOUGH_BALANCE_ERROR: 1405,
  PHONE_CONFIRMED_ERROR: 1406,
  MIN_ORDER_PRICE: 10,
  NEEDS_ADD_NEW_BANK_CARD: 1101,
  SHOP_CLOSED: 1401,
};

export const PAYMENT_ONLINE_REDIRECT_ERROR = 'Операция невозможна, какие-то проблемы с картой оплаты';
export const NOT_AVAILABLE_TIME_MESSAGE = 'Выбранное время недоступно';
export const CART_NOT_FRESH_MESSAGE = 'Добавили в корзину товары, которые вы выбрали ранее';
export const NOT_ENOUGH_BALANCE_MESSAGE = 'Недостаточно средств';
export const PROMOCODE_NOT_AVALIBLE_MESSAGE = 'Промокод не действителен';
export const CONFIRM_PHONE_MESSAGE = 'Подтвердите телефон';
export const MIN_CART_PRICE = 'Не хватает покупок до минимальной суммы заказа';
export const INCORRECT_PHONE = 'некорректный формат номера';
export const NOT_COMPLETED_REGISTRATION = 'Нельзя оформить заказ до подтверждения личного кабинета';
export const CHANGE_FROM_ERROR_MESSAGE = 'Введите числовое значение в поле "нужна сдача с"';
export const ERROR_MESSAGE = 'Произошла ошибка';

export const SSR_KEY = 'Checkout.DeliveryDate';
