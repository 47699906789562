import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import { truncateObjValues } from 'utils/helpers';

// Клик по ркламному баннеру
export default function selectBanner(promotionId: string, promotionName = '', pos = 1): void {
  analyticsManager.gtag(
    EnumGtagEvents.SELECT_BANNER,
    truncateObjValues({
      promotion_id: promotionId,
      promotion_name: promotionName,
      index: pos,
    })
  );
}
