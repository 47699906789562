import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { FontSize, Icons, SMARTPHONE_MEDIA_QUERY } from 'theme';
import { getTypography, getTypographyMobile } from 'theme/selectors';
import { IText } from './Text';

const getTypographyFontSize = ({ size = FontSize.s, theme }: IIcon) =>
  css`
    font-size: ${getTypography(theme)[size].fontSize};
  `;

const getTypographyFontSizeMobile = ({ size = FontSize.s, theme }: IText) =>
  css`
    font-size: ${getTypographyMobile(theme)[size].fontSize};
    line-height: ${getTypographyMobile(theme)[size].lineHeight};
  `;

interface IIcon {
  name: Icons;
  color?: string;
  size?: FontSize;
  theme: DefaultTheme;
}

const IconStyled = styled.i<IIcon>`
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${({ name }) =>
    css`
      &:before {
        display: inline-block;
        content: '${name}';
        color: currentColor;
      }
    `}
  ${({ color }) =>
    css`
      color: ${color};
    `}
  ${getTypographyFontSize}
  @media ${SMARTPHONE_MEDIA_QUERY} {
    ${getTypographyFontSizeMobile}
  }
`;

export default React.memo(IconStyled);
