export const TEST_ID_SAVE_BUTTON = 'TEST_ID_SAVE_BUTTON';
export const AVATAR_LOADING_CONTAINER_TEST_ID = 'AVATAR_LOADING_CONTAINER_TEST_ID';
export const NO_AVATAR_LOADING_CONTAINER_TEST_ID = 'NO_AVATAR_LOADING_CONTAINER_TEST_ID';
export const HAVE_AVATAR_CONTAINER_TEST_ID = 'HAVE_AVATAR_CONTAINER_TEST_ID';
export const AVATAR_INPUT_TEST_ID = 'AVATAR_INPUT_TEST_ID';
export const AVATAR_IMAGE_TEST_ID = 'AVATAR_IMAGE_TEST_ID';
export const EDIT_BUTTON_TEST_ID = 'EDIT_BUTTON_TEST_ID';
export const DELETE_BUTTON_TEST_ID = 'DELETE_BUTTON_TEST_ID';
export const DELETE_CONFIRM_TEST_ID = 'DELETE_CONFIRM_TEST_ID';
export const ADD_AVATAR_BUTTON_TEST_ID = 'ADD_AVATAR_BUTTON_TEST_ID';
export const LOGOUT_BUTTON_TEST_ID = 'LOGOUT_BUTTON_TEST_ID';
export const UNCONFIRMED_EMAIL_TEST_ID = 'UNCONFIRMED_EMAIL_TEST_ID';

export const INPUT_NAME_TEST_ID = 'INPUT_NAME_TEST_ID';
export const INPUT_EMAIL_TEST_ID = 'INPUT_EMAIL_TEST_ID';
export const INPUT_PHONE_TEST_ID = 'INPUT_PHONE_TEST_ID';

export const PLACEHOLDER_NAME = 'Имя Фамилия';
export const PLACEHOLDER_EMAIL = 'E-mail';
export const PLACEHOLDER_PHONE = 'Телефон';
export const PLACEHOLDER_BIRTHDATE = 'ДД.ММ.ГГГГ';
export const SAVE_SUCCESS_TEXT = 'Данные успешно обновлены';
export const UNCONFIRMED_EMAIL_TEXT = 'Подтвердите почту, перейдя по ссылке из письма, которое мы отправили на';

export const AVATAR_MAX_SIDE = 500;

export enum EnumStatesAvatar {
  loading = 'loading_image',
  add_image = 'add_image',
  image = 'image',
}
