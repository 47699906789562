import styled from 'styled-components';
import { colors } from '../../constants/colors';
import Header from 'Components/Header/HeaderContainer';
import { mediaSmDown } from 'theme/helpers/css';
import Flex from 'Components/Grid/Flex';

export const Container = styled.div`
  height: 100vh;
  background-color: ${colors.paleGreySix};
`;

export const StyledHeader = styled(Header)`
  background-color: ${colors.paleGreySix};
  box-shadow: none;
  position: relative;
  padding: 0 24px;

  ${mediaSmDown`
    padding: 0 16px;
  `}
`;

export const Content = styled(Flex).attrs({
  middle: true,
  center: true,
  column: true,
})`
  margin-top: 110px;
`;

export const MascotImage = styled.img`
  min-height: 100px;
`;

export const Message = styled.div`
  max-width: 640px;
  text-align: center;
  line-height: 24px;
`;

export const Browsers = styled(Flex).attrs({
  middle: true,
  between: true,
})`
  min-width: 325px;
`;

export const BrowsersLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: ${colors.primaryMain};
`;

export const BrowserIcon = styled.img`
  min-height: 61px;
`;
