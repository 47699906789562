import { Action, AnyAction } from 'redux';
import { Dispatch, ThunkAction, ReduxDispatch } from 'reduxStore/types';
import { ICity } from './types';
import { CITY_COOKIE, getCookieDomain } from 'constants/cookie';
import Cookie from 'js-cookie';
import { IPlace } from '../Places/types';
import SearchAddressHttp from 'Modules/SearchAddress/http';
import http from './http';
import { getCityForCookies } from 'utils/cookies';
import debounce from 'lodash.debounce';
import { setNotificationAction } from '../Notifications/actions';
import { EnumNotification } from 'constants/enums';
import { clearCurrentShopAction } from 'Modules/Shops/actions';
import GeogrinderHttp from 'Modules/GeoGrinder/http';
import geoGrinderPlaceToPlace from '../GeoGrinder/utils/geoGrinderPlaceToPlace';

export enum EnumCurrentAddressTypes {
  SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS',
  CLEAR_CURRENT_ADDRESS = 'CLEAR_CURRENT_ADDRESS',
  SET_CITY = 'SET_CITY',
  SET_CITIES = 'SET_CITIES',
  SET_IGOR = 'SET_IGOR',
}

export const setCurrentAddressAction = (address: Partial<IPlace>): AnyAction => {
  return {
    type: EnumCurrentAddressTypes.SET_CURRENT_ADDRESS,
    address,
  };
};

export const setCurrentCityAction = (city: ICity): AnyAction => ({
  type: EnumCurrentAddressTypes.SET_CITY,
  city,
});

export const clearCurrentAddressAction = (): Action => ({
  type: EnumCurrentAddressTypes.CLEAR_CURRENT_ADDRESS,
});

export const setCitiesAction = (list: ICity[]): AnyAction => ({
  type: EnumCurrentAddressTypes.SET_CITIES,
  list,
});

const setCurrentCity = (city: ICity): ThunkAction => (dispatch: Dispatch) => {
  Cookie.set(CITY_COOKIE, JSON.stringify(getCityForCookies(city)), getCookieDomain());

  return dispatch(setCurrentCityAction(city));
};

export const setIgor = (): AnyAction => ({
  type: EnumCurrentAddressTypes.SET_IGOR,
});

const updateCurrentAddressFromCoords = (coords: number[]): ThunkAction => async (
  dispatch: Dispatch
): Promise<IPlace> => {
  const [latitude, longitude] = coords;
  const data = await GeogrinderHttp.fetchPlaceByPoint(longitude, latitude);
  const placeToSet = geoGrinderPlaceToPlace(data);
  const cityData = await http.getCityFromCoord({
    latitude,
    longitude,
  });
  if (cityData?.city) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(setCurrentCity(cityData.city));
  }
  dispatch(setCurrentAddressAction(placeToSet as IPlace));
  dispatch(clearCurrentShopAction());
  return placeToSet;
};

export const currentPositionPermissionDenied =
  'Разрешите нам определять ваше местоположение: это делается в настройках устройства';
export const currentPositionDefaultError = 'Что-то пошло не так. Попробуйте ещё раз';

export const getCurrentPosition = debounce(
  (dispatch: ReduxDispatch, cityId: number, successCallback?: () => void, errorCallback?: () => void) => {
    navigator.geolocation?.getCurrentPosition(
      async position => {
        try {
          const { latitude, longitude } = position.coords;
          const data = await http.getCityFromCoord({
            latitude,
            longitude,
          });

          if (data?.city) {
            const cityIdByCoords = data.city.id;
            if (cityIdByCoords !== cityId) {
              await dispatch(setCurrentCity(data.city));
            }
            await dispatch(updateCurrentAddressFromCoords([latitude, longitude]));
            successCallback && successCallback();
          } else {
            errorCallback && errorCallback();
            dispatch(setNotificationAction(currentPositionDefaultError, EnumNotification.danger, 5000));
          }
        } catch (e) {
          errorCallback && errorCallback();
          dispatch(setNotificationAction(currentPositionDefaultError, EnumNotification.danger, 5000));
        }
      },
      err => {
        errorCallback && errorCallback();
        const errorMessage = {
          [err.PERMISSION_DENIED]: currentPositionPermissionDenied,
          [err.POSITION_UNAVAILABLE]: currentPositionDefaultError,
          [err.TIMEOUT]: currentPositionDefaultError,
        };
        dispatch(
          setNotificationAction(errorMessage[err.code] || currentPositionDefaultError, EnumNotification.danger, 5000)
        );
      }
    );
  },
  400
);

export default {
  getCities: (): ThunkAction => async (dispatch, getState, fetcher) => {
    const data = await SearchAddressHttp.getCities(fetcher);

    dispatch(setCitiesAction(data.list));
  },
  setIgor: () => (dispatch: Dispatch) => {
    dispatch(setIgor());
  },
  setCurrentAddress: (address: Partial<IPlace>): ThunkAction => (dispatch: Dispatch) => {
    dispatch(setCurrentAddressAction(address));
  },
  setCurrentCity,
  clearCurrentAddress: (): ThunkAction => (dispatch: Dispatch) => dispatch(clearCurrentAddressAction()),

  updateCurrentAddressFromCoords,
};
