import * as easings from 'd3-ease';

import React, { ReactChild, ReactNode, useEffect, useRef } from 'react';
import { UseSpringProps, animated, config, useSpring, useTransition } from 'react-spring';

import CloseIcon from 'Components/Icon/CloseIcon';
import Portal from './Portal';
import { ThemeButton } from 'Components/ThemedButton';
import { Transitions } from 'theme';
import { colors } from 'constants/colors';
import { hideAfterSm } from 'theme/helpers';
import { mediaSmDown } from 'theme/helpers/css';
import styled from 'styled-components';
import { EnumTheme } from 'constants/enums';
import { isBrowser } from 'utils/helpers';

interface IAnimation {
  [index: string]: UseSpringProps<any>;
}

export const Animation: IAnimation = {
  top: {
    config: { duration: 400, easing: easings.easeCubic },
    from: { transform: 'translateY(-100%)' },
    to: { transform: 'translateY(0)' },
    delay: 400,
  },
  left: {
    config: { duration: 400, easing: easings.easeCubic },
    from: { transform: 'translateX(-100%)' },
    to: { transform: 'translateX(0)' },
    delay: 400,
  },
  right: {
    config: { duration: 400, easing: easings.easeCubic },
    from: { transform: 'translateX(150%)' },
    to: { transform: 'translateX(0)' },
    delay: 400,
  },
  center: {
    config: { duration: 400 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 16,
  },
};

interface IModalStyled {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  mobileTop?: number;
  zIndex?: number;
}

const ModalStyled = styled.div<IModalStyled>`
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: ${({ top }) => top || 0};
  left: ${({ left }) => left || 0};
  bottom: ${({ bottom }) => bottom || 0};
  right: ${({ right }) => right || 0};
  z-index: ${({ zIndex }) => zIndex || 10};

  ${mediaSmDown`
    // height: calc(100% + 1px) - hack for bugfix overlapping fixed buttons bellow the modal in Chrome mobile
    // somehow relates to hiding address bar on scroll
    ${({ mobileTop }: { mobileTop: number }) =>
      mobileTop ? `top: ${mobileTop}; height: calc(100% - ${mobileTop} + 1px);` : 'height: calc(100% + 1px);'}
  `}
`;

const ModalOverlay = styled.div<{ overlayColor: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ overlayColor }) => overlayColor};
  z-index: 1;
`;

export const ModalContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 100%;
  overflow-x: hidden;
`;

export const ModalContainer = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: auto;
`;

export const CloseButton = styled(ThemeButton)`
  position: absolute;
  top: 0;
  right: 0;
  transition: ${Transitions.filter};
  z-index: 1;

  &:hover {
    filter: brightness(0.65);
  }

  ${hideAfterSm}
`;

interface IModalChildren {
  animation?: UseSpringProps<any>;
  children: ReactNode | ReactChild;
  className?: string;
}

const ModalChildren: React.FC<IModalChildren> = ({ children, animation = Animation.center, className }) => {
  const transitions = useSpring(animation);

  return (
    <animated.div className={className} style={{ ...transitions }}>
      {children}
    </animated.div>
  );
};

const StyledModalChildren = styled(ModalChildren)`
  height: 100%;
`;

export interface IModalProps extends IModalStyled {
  container: HTMLElement;
  children: React.ReactNode | ReactChild;
  show?: boolean;
  animation?: UseSpringProps<any>;
  className?: string;
  onClick?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  isHideCloseBtn?: boolean;
  testId?: string;
  customCloseBtn?: React.ReactNode | ReactChild;
  headerOnMobileHeight?: number;
  zIndex?: number;
  overlayColor?: string;
}

const Modal: React.FC<IModalProps> = props => {
  const {
    container,
    children,
    top,
    left,
    right,
    bottom,
    show,
    animation,
    customCloseBtn,
    isHideCloseBtn = false,
    headerOnMobileHeight,
    zIndex,
    overlayColor = 'rgba(26, 26, 44, 0.5)',
  } = props;
  const { className, onClick, onOpen, onClose, testId } = props;
  const prevShow = useRef(false);
  const transitions = useTransition(show, {
    config: { ...config.wobbly, duration: 100 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  useEffect(() => {
    if (prevShow.current !== show) {
      prevShow.current = !!show;

      if (!show) {
        if (onClose) onClose();
      } else {
        if (onOpen) onOpen();
      }
    }
  }, [onClose, onOpen, show]);

  const customCloseButton =
    customCloseBtn && typeof customCloseBtn === 'function' ? customCloseBtn({ onClick }) : customCloseBtn;

  return isBrowser ? (
    <Portal container={container}>
      {transitions((style, active) => {
        return (
          active && (
            <animated.div style={style}>
              <ModalStyled {...{ top, left, right, bottom, zIndex }} mobileTop={headerOnMobileHeight}>
                <ModalContainer className={className} data-testid={testId}>
                  <ModalOverlay {...{ onClick, overlayColor }} />
                  <ModalContent>
                    {customCloseButton ||
                      (!isHideCloseBtn && (
                        <CloseButton use={EnumTheme.textSecondary} icon {...{ onClick }}>
                          <CloseIcon color={colors.warmGrey} width="16" height="16" />
                        </CloseButton>
                      ))}
                    <StyledModalChildren animation={animation}>{children}</StyledModalChildren>
                  </ModalContent>
                </ModalContainer>
              </ModalStyled>
            </animated.div>
          )
        );
      })}
    </Portal>
  ) : null;
};

export default Modal;
