import { NotificationAction, INotificationAction } from './actions';
import { EnumNotification } from 'constants/enums';
export interface INotificationState {
  list: INotification[];
}

export interface INotification {
  id: number;
  text: string;
  theme: EnumNotification;
  timeout?: number;
  link?: string;
}

export const notificationInitialState: INotificationState = {
  list: [],
};

export default function notificationReducer(
  state: INotificationState = notificationInitialState,
  action: INotificationAction
) {
  switch (action.type) {
    case NotificationAction.SET_NOTIFICATION:
      if (state.list.find(item => item.text === action.text)) {
        return state;
      }
      return {
        list: [
          ...state.list,
          {
            id: Math.random(),
            text: action.text,
            theme: action.theme,
            timeout: action.timeout,
            link: action.link,
          },
        ],
      };
    case NotificationAction.REMOVE_NOTIFICATION:
      const newList = [...state.list];
      newList.shift();

      return {
        list: newList,
      };
    default:
      return state;
  }
}
