import { ThunkAction } from 'reduxStore/types';
import { isNil } from 'ramda';
import { AnyAction } from 'redux';
import { IPaymentCard } from './types';
import PaymentCardsHttp from './service';

export enum EnumPaymentCardsTypes {
  SET_CURRENT_CARD = 'SET_CURRENT_CARD',
  SET_CURRENT_CARDS_LIST = 'SET_CURRENT_CARDS_LIST',
}

export const setCardsList = (cardsList: IPaymentCard[]): AnyAction => ({
  type: EnumPaymentCardsTypes.SET_CURRENT_CARDS_LIST,
  cardsList,
});

export default {
  getCardsListAction: (orderId?: number): ThunkAction => async dispatch => {
    const data = isNil(orderId)
      ? await PaymentCardsHttp.getUserCards()
      : await PaymentCardsHttp.getUserCardsByOrderId(orderId);

    dispatch(setCardsList(data));

    return Promise.resolve(data);
  },

  setCurrentCard: (card?: IPaymentCard): AnyAction => ({
    type: EnumPaymentCardsTypes.SET_CURRENT_CARD,
    card,
  }),

  deleteCard: (card: IPaymentCard): ThunkAction => async () => {
    const data = await PaymentCardsHttp.deleteUserCard(card);
    return Promise.resolve(data);
  },
};
