import styled, { css } from 'styled-components';
import { colors } from 'constants/colors';
import Flex from 'Components/Grid/Flex';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { BorderedModalStyles } from 'Components/Modal/styled';
import { FontSize, FontWeight } from 'theme';
import Text from 'Components/Typography/Text';
import Title from 'Components/Typography/Title';
import { getTypographyFontStyle, getTypographyFontStyleMobile } from 'theme/selectors';
import Box from 'Components/Grid/Box';
import ColoredLinkWrapper from 'Components/Link/ColoredLinkWrapper';
import ThemeButton from 'Components/ThemedButton';

// TODO Jira: https://igooods.atlassian.net/browse/FS-175
export const AuthModalStyled = styled.div`
  width: 400px;
  height: 100%;
  padding: 60px 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white};

  ${mediaSmDown`
    width: auto;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
  `}
`;

interface IAuthModalContainer {
  state: string;
}

export const AuthModalContainer = styled.div<IAuthModalContainer>`
  width: 480px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  ${BorderedModalStyles}

  ${({ state }) => state === 'attempts' && `height: 480px;`}

  ${mediaSmUp`
    margin-bottom: 40px;
  `}

  ${mediaSmDown`
    width: 100%;
    height: 100%;
    align-items: flex-start;
  `}

  ${({ state }) =>
    state === 'loading' &&
    css`
      ${mediaSmDown`
        padding-top: 200px;
      `}
    `}
`;

interface IAuthModalBody {
  isError?: boolean;
}

export const AuthModalBody = styled(Flex).attrs({
  middle: true,
  center: true,
  column: true,
})<IAuthModalBody>`
  ${({ isError }) =>
    isError &&
    `
      input {
        color: ${colors.reddishPink} !important;
      }
      div {
        visibility: visible;
      }
    `}
`;

export const AuthModalFooter = styled.div`
  color: ${colors.pinkishGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  a {
    color: ${colors.primaryMain};
    text-decoration: none;
  }

  ${mediaSmDown`
    text-align: center;
  `}
`;

export const CodeInput = styled.input`
  font-size: 34px;
  font-weight: ${FontWeight.bold};
  line-height: 40px;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

//ENTER_PHONE

export const PhoneHeader = styled(Flex)`
  ${mediaSmDown`
    margin-bottom: 24px;
  `}
`;

export const PhoneImg = styled.img`
  height: 60px;
  margin-bottom: 20px;
`;

export const ErrorText = styled(Text).attrs({
  color: colors.primaryAction,
  size: FontSize.xs,
  center: true,
})`
  font-size: 13px;
  padding-left: 10%;
  padding-right: 10%;
`;

export const PhoneText = styled(Title)`
  text-align: center;
`;

export const PhoneInputGroup = styled.div`
  display: flex;
  width: 350px;
  padding: 22px 24px 14px;
  margin: 0 auto;

  div {
    width: 40px;
    margin-right: 12px;
    color: ${colors.dark100};

    ${mediaSmDown`
        margin-right: 4px;
    `}
  }
  input {
    width: 100%;
    caret-color: ${colors.informationLink};
  }

  ${mediaSmDown`
    width: 300px;
  `}
`;

export const PhoneEnterInput = styled.input`
  margin: 0;
  font-weight: ${FontWeight.bold};
  ${getTypographyFontStyle(FontSize.xxxl)}
  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.xxxl)}
  `}
`;

//FORM

export const SubmitBtn = styled(ThemeButton)<{ $fullPage: boolean }>`
  ${mediaSmDown`
    margin-top: 0;
    justify-self: flex-end;
  `}
  ${({ $fullPage }) => `margin-top: ${$fullPage ? 0 : 'auto'}`}
  &:disabled {
    opacity: 0.5;
  }
`;

export const FooterAgreementText = styled(Text)`
  letter-spacing: -0.3px;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;

export const ModalTitle = styled(Box).attrs({ mt: 5, mb: 10 })`
  text-align: center;
`;

export const RegistrationModal = styled(AuthModalStyled)`
  ${mediaSmDown`
    padding-left: 16px;
    padding-right: 16px;
  `}
`;

export const AuthModalForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LinkContent = styled(ColoredLinkWrapper)`
  margin-left: 3px;
  color: ${colors.primaryAction};
`;

export const AuthModalWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RegisterButtonWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;
