import { IOrdersHistoryState, OrderHistoryTypes, IOrderHistoryAction } from './types';
import { IItems } from '../Products/types';

export const itemsInitialState: IItems = {
  list: [],
  replaced: [],
  missing: [],
  returned: [],
};

export const initialState: IOrdersHistoryState = {
  isFetchingOrder: false,
  currentOrder: null,
  products: [],
  items: itemsInitialState,
};

export default (state = initialState, action: IOrderHistoryAction) => {
  switch (action.type) {
    case OrderHistoryTypes.START_GETTING_ORDER_INFO:
      return { ...state, isFetchingOrder: true };
    case OrderHistoryTypes.FINISH_GETTING_ORDER_INFO:
      return { ...state, isFetchingOrder: false, currentOrder: action.payload };
    case OrderHistoryTypes.SET_LAST_SUCCESS_ORDER:
      return { ...state, lastSuccessOrder: action.payload };
    case OrderHistoryTypes.FINISH_GETTING_ORDER_PRODUCTS:
      return { ...state, products: action.payload };
    case OrderHistoryTypes.FINISH_GETTING_ORDER_ITEMS:
      return {
        ...state,
        items: {
          ...action.payload,
        },
      };
    case OrderHistoryTypes.SET_COMMENT_TO_PRODUCT:
      const updatedProductsList = state.items?.list.map(product => {
        if (product.id === action.payload.product_id) {
          return { ...product, comment: action.payload.comment };
        }
        return product;
      });
      return {
        ...state,
        items: { ...state.items, list: updatedProductsList },
      };
    case OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_TYPE:
      return { ...state, currentOrder: { ...state.currentOrder, payment_type: action.payload } };
    case OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_CARD:
      return { ...state, currentOrder: { ...state.currentOrder, payment_card: action.payload } };
    case OrderHistoryTypes.CLEAR_ORDER_INFO:
      return { ...state, isFetchingOrder: false, currentOrder: null };
    default:
      return state;
  }
};
