import { isBrowser } from './helpers';
import { DEPLOY_ENV_NOT_PROD } from '../../config/config';

export const TOP_MAIL_KEY = 3140966;

enum EnumTopMailEventTypes {
  reachGoal = 'reachGoal',
}

export enum EnumTopMailGoals {
  add_to_cart = 'add_to_cart',
  begin_checkout = 'begin_checkout',
  sign_up = 'sign_up',
  purchase = 'purchase',
  first_purchase = 'first_purchase',
}

interface ITopMailEvent {
  type: EnumTopMailEventTypes;
  goal: EnumTopMailGoals;
}

export const topMail = (event: ITopMailEvent) => {
  if (!isBrowser) return;

  if (DEPLOY_ENV_NOT_PROD) {
    return console.log('Top.Mail ---->', event);
  }

  const _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ ...event, id: TOP_MAIL_KEY });
};

export const topMailReachGoal = (goal: EnumTopMailGoals) => topMail({ type: EnumTopMailEventTypes.reachGoal, goal });
