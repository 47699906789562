import { Box, BoxProps, Indent } from '@igooods/ui-kit';
import { DefaultTheme } from 'styled-components';

export interface IBox {
  pyXs?: number;
  pxXs?: number;
  pbXs?: number;
  ptXs?: number;
  prXs?: number;
  plXs?: number;
  mxXs?: Indent;
  myXs?: Indent;
  mbXs?: Indent;
  mtXs?: Indent;
  mrXs?: Indent;
  mlXs?: Indent;
}

export interface IBoxProps extends Omit<BoxProps, 'theme'> {
  theme: DefaultTheme;
}

export default Box;
