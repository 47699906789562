import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { when, isNil } from 'ramda';
import { useOnClickOutside, useDebounce } from 'utils/hooks';
import usePositionDetection from 'utils/hooks/usePositionDetection';
import { Transitions } from 'theme';
import { addStyleIfPropTruly } from 'utils/styledComponents';

export enum EnumContentPosition {
  right = 'right',
  left = 'left',
}
interface IDropdownToggle {
  fluid?: boolean;
  testId?: string;
}

interface IDropdownContent extends IDropdownToggle {
  isOpen: boolean;
  $position: string;
}

interface IDropdownContainer extends IDropdownToggle {
  onKeyDown?: (e: KeyboardEvent) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}
export interface IDropdown extends IDropdownContainer {
  disabled?: boolean;
  toggle: React.ReactChild | React.ReactNode;
  children: React.ReactChild | React.ReactNode;
  onToggle?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  onClickOutside?: () => void;
  hoverable?: boolean;
  fluidContent?: boolean;
  className?: string;
  testId?: string;
  testIdContent?: string;
  testIdToggle?: string;
}

export const DROPDOWN_CONTAINER_TEST_ID = 'DROPDOWN_CONTAINER_TEST_ID';
export const DROPDOWN_CONTENT_TEST_ID = 'DROPDOWN_CONTENT_TEST_ID';
export const DROPDOWN_TOGGLE_TEST_ID = 'DROPDOWN_TOGGLE_TEST_ID';

const DropdownContainer = styled.div<IDropdownContainer>`
  position: relative;
`;

export const DropdownToggle = styled.div<IDropdownToggle>`
  position: relative;
  display: ${({ fluid }) => (fluid ? 'flex' : 'inline-flex')};
  flex-direction: column;
  cursor: pointer;
`;

export const DropdownContent = styled.div<IDropdownContent>`
  position: absolute;
  top: 100%;
  display: block;
  width: ${({ fluid }) => (fluid ? 'auto' : '100%')};
  transform: translateY(-10px);
  opacity: 0;
  ${({ $position }) => $position}: 0;
  transition: ${Transitions.transform}, ${Transitions.opacity};
  visibility: hidden;
  max-height: calc(100vh - 100%);

  ${addStyleIfPropTruly(
    'isOpen',
    `
      transform: translateY(0);
      opacity: 1;
      z-index: 105;
      visibility: visible;
    `
  )}
`;

const Dropdown: React.FC<IDropdown> = ({
  disabled,
  children,
  toggle,
  isOpen,
  hoverable,
  onKeyDown,
  onClickOutside,
  onToggle,
  onClose,
  onMouseOver,
  testId = DROPDOWN_CONTAINER_TEST_ID,
  testIdContent = DROPDOWN_CONTENT_TEST_ID,
  testIdToggle = DROPDOWN_TOGGLE_TEST_ID,
  onMouseOut,
  fluid,
  fluidContent,
  className,
}) => {
  const [isOpenState, setIsOpen] = useState(false);
  const { positionX, elementRef, wrapperRef } = usePositionDetection({
    eventListener: 'mousedown',
    xPadding: 16,
  });

  const escFunction = useCallback(
    (e: KeyboardEvent) => {
      if (onKeyDown) {
        onKeyDown(e);
        return;
      }
      if (e.key === 'Escape') {
        onClose && onClose();
        setIsOpen(false);
      }
    },
    [onClose, onKeyDown]
  );
  const changeOpenIfNotDisabled = when(() => !disabled, setIsOpen);
  const changeOpenIfHoverable = when(() => !!hoverable, setIsOpen);
  const toggleHandler =
    onToggle ||
    ((e: React.MouseEvent) => {
      e.stopPropagation();
      changeOpenIfNotDisabled(!isOpenState);
    });

  const onMouseOverHandler = useCallback(() => {
    if (onMouseOver) {
      onMouseOver();
    }
    changeOpenIfHoverable(true);
  }, [changeOpenIfHoverable, onMouseOver]);

  const onMouseOutHandler = useCallback(() => {
    if (onMouseOut) {
      onMouseOut();
    }
    changeOpenIfHoverable(false);
  }, [changeOpenIfHoverable, onMouseOut]);

  useOnClickOutside(
    wrapperRef,
    onClickOutside ||
      (() => {
        onClose && onClose();
        changeOpenIfNotDisabled(false);
      })
  );

  const isOpenMain = isNil(isOpen) ? isOpenState : isOpen;
  const debouncedIsOpenMain = useDebounce(isOpenMain, 300);
  const isOpenDropdown = hoverable ? debouncedIsOpenMain : isOpenMain;

  useEffect(() => {
    if (isOpenMain) {
      document.addEventListener('keydown', escFunction, false);
    } else {
      document.removeEventListener('keydown', escFunction, false);
    }
  }, [escFunction, isOpenMain]);

  return (
    <DropdownContainer
      ref={wrapperRef}
      onMouseOut={onMouseOutHandler}
      onMouseOver={onMouseOverHandler}
      data-testid={testId}
      fluid={fluid}
      className={className}
    >
      <DropdownToggle fluid={fluid} onClick={toggleHandler} data-testid={testIdToggle}>
        {typeof toggle === 'function' ? toggle({ isActive: isOpenDropdown }) : toggle}
      </DropdownToggle>
      <DropdownContent
        isOpen={isOpenDropdown}
        ref={elementRef}
        $position={positionX}
        data-testid={testIdContent}
        fluid={fluidContent}
      >
        {children}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default Dropdown;
