import { DEPLOY_ENV_NOT_PROD, NODE_ENV_IS_TEST } from '../../config/config';

export enum FbqEventsEnum {
  CompleteRegistration = 'CompleteRegistration',
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  Purchase = 'Purchase',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fbqTrack = (...args: any[]) => {
  if (DEPLOY_ENV_NOT_PROD && !NODE_ENV_IS_TEST) {
    // for testing QA
    console.log('fbq ----> ', [...args]);
  } else {
    window?.fbq && window.fbq('track', ...args);
  }
};
