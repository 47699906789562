import { DEPLOY_ENV_NOT_PROD } from '../../config/config';
import { isBrowser } from 'utils/helpers';

if (DEPLOY_ENV_NOT_PROD && isBrowser)
  window.ym = (...args) => {
    // for testing QA
    console.log(args, 'yandexMetrics');
  };

export const YA_KEY = 95659248;
export const YA_KEY_GLOBUS = 70190122;

enum EnumYaMethods {
  reachGoal = 'reachGoal',
}

export enum EnumYaCounters {
  sign_up = 'sign_up',
  order_first_item_added = 'order_first_item_added',
  first_order_filled = 'first_order_filled',
  business_form_inn = 'business_form_inn',
  business_form_auchan_inn = 'business_form_auchan_inn',
  click_mobile_download = 'click_mobile_download',
  view_catalog = 'view_catalog',
  add_to_cart = 'add_to_cart',
  view_cart = 'view_cart',
  begin_checkout = 'begin_checkout',
  purchase = 'purchase',
  first_purchase = 'first_purchase',
}

export function ymReachGoal(payload: EnumYaCounters) {
  return window.ym(YA_KEY, EnumYaMethods.reachGoal, payload);
}
