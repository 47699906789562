import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchAddressActions from 'Modules/SearchAddress/actions';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { ICity } from 'Modules/SearchAddress/types';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import CitiesModal from './CitiesModal';
import { updateProvince } from 'Components/Header/CitiesButton/utils';
import useModalState from 'utils/hooks/useModalState';
import { CITIES_BUTTON_ID } from './constants';
import GeoLocationIcon from 'Components/Icon/GeoLocationIcon';
import CheckCity from './CheckCity';
import { StyledButton, StyledButtonText, Wrapper } from './styled';

interface ICitiesButton {
  onCityChange?: (city: ICity) => void;
  className?: string;
  checkCity?: boolean;
}

const CitiesButton: FC<ICitiesButton> = ({ className, onCityChange, checkCity }) => {
  const currentCity = useSelector(getCurrentCity);
  const { isVisible, showModal, hideModal } = useModalState();
  const dispatch = useDispatch();

  const { name, id } = currentCity;
  const selectedCity = useMemo(() => updateProvince(name, id), [name, id]);

  const handleSelectItem = useCallback(
    (city: ICity) => {
      if (currentCity.id === city.id) {
        return hideModal();
      }

      dispatch(SearchAddressActions.clearCurrentAddress());
      dispatch(SearchAddressActions.setCurrentCity(city));
      if (onCityChange) {
        onCityChange(city);
      }
      hideModal();
    },
    [currentCity, dispatch, hideModal, onCityChange]
  );

  return (
    <Wrapper>
      <StyledButton
        testId={CITIES_BUTTON_ID}
        use={EnumTheme.white}
        size={EnumButtonSize.normal}
        onClick={showModal}
        before={<GeoLocationIcon />}
        className={className}
      >
        <StyledButtonText>{selectedCity}</StyledButtonText>
      </StyledButton>
      {checkCity && <CheckCity onCityChange={handleSelectItem} />}
      <CitiesModal
        currentCity={currentCity}
        isModalShown={isVisible}
        closeModal={hideModal}
        onSelectItem={handleSelectItem}
      />
    </Wrapper>
  );
};

export default memo(CitiesButton);
