import styled from 'styled-components';
import { Flex } from '@igooods/ui-kit';
import ChevronLeftIcon from '../../Icon/ChevronLeftIcon';
import { FontSize, Transitions } from 'theme';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import Text from '../../Typography/Text';
import { mediaSmDown } from 'theme/helpers/css';
import { getTypographyFontStyleMobile } from '../../../theme/selectors';
import ThemeButton from 'Components/ThemedButton';

export const Button = styled(Flex).attrs({
  middle: true,
  maxWidth: '230px',
  px: { xs: 1, sm: 3 },
})`
  user-select: none;
  cursor: pointer;
`;

export const Chevron = styled(ChevronLeftIcon)<{ isOpen: boolean }>`
  transition: ${Transitions.transform};
  margin-left: 8px;
  transform: rotate(-90deg);

  ${addStyleIfPropTruly('isOpen', 'transform: rotate(90deg);')}
`;

export const ButtonText = styled(Text).attrs({ size: FontSize.s, $nowrap: true })`
  overflow: hidden;
  text-overflow: ellipsis;
`;

//CITIES_BUTTON

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledButton = styled(ThemeButton)`
  max-width: 230px;
  padding-left: 12px;
  padding-right: 12px;
  user-select: none;

  ${mediaSmDown`
    max-width: 200px;
    min-width: unset;
    padding-left: 8px;
    padding-right: 8px;
  `}

  i {
    margin-right: 5px;
    color: currentColor;
  }

  div {
    text-align: left;
  }
`;

export const StyledButtonText = styled(Text).attrs({ size: FontSize.s })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.xs)}
  `}
`;
