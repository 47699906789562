import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const ListIcon: FC<IIcon> = () => (
  <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path
        d="M5.33333 2.66666H14V3.99999H5.33333V2.66666ZM3 4.33333C2.73478 4.33333 2.48043 4.22797 2.29289 4.04044C2.10536 3.8529 2 3.59854 2 3.33333C2 3.06811 2.10536 2.81376 2.29289 2.62622C2.48043 2.43869 2.73478 2.33333 3 2.33333C3.26522 2.33333 3.51957 2.43869 3.70711 2.62622C3.89464 2.81376 4 3.06811 4 3.33333C4 3.59854 3.89464 3.8529 3.70711 4.04044C3.51957 4.22797 3.26522 4.33333 3 4.33333ZM3 9C2.73478 9 2.48043 8.89464 2.29289 8.7071C2.10536 8.51957 2 8.26521 2 8C2 7.73478 2.10536 7.48042 2.29289 7.29289C2.48043 7.10535 2.73478 7 3 7C3.26522 7 3.51957 7.10535 3.70711 7.29289C3.89464 7.48042 4 7.73478 4 8C4 8.26521 3.89464 8.51957 3.70711 8.7071C3.51957 8.89464 3.26522 9 3 9ZM3 13.6C2.73478 13.6 2.48043 13.4946 2.29289 13.3071C2.10536 13.1196 2 12.8652 2 12.6C2 12.3348 2.10536 12.0804 2.29289 11.8929C2.48043 11.7054 2.73478 11.6 3 11.6C3.26522 11.6 3.51957 11.7054 3.70711 11.8929C3.89464 12.0804 4 12.3348 4 12.6C4 12.8652 3.89464 13.1196 3.70711 13.3071C3.51957 13.4946 3.26522 13.6 3 13.6ZM5.33333 7.33333H14V8.66666H5.33333V7.33333ZM5.33333 12H14V13.3333H5.33333V12Z"
        fill="black"
      />
    </g>
  </Icon>
);

export default ListIcon;
