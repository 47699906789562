import styled from 'styled-components';

export const BorderedModalStyles = `
  border-radius: 4px;
  box-shadow:
    0 4px 12px 0 rgba(0, 0, 0, 0.05),
    0 2px 6px 0 rgba(0, 0, 0, 0.05),
    0 0 4px 0 rgba(0, 0, 0, 0.05);
`;

export const FullscreenModalStyles = `
  width: auto;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  border-radius: 0;
`;

export const ModalIcon = styled.div<{ src: string }>`
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-image: url(${({ src }) => src});
`;
