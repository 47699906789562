import { css } from 'styled-components';

export const resetStyles = css`
  html,
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #portal-root {
    z-index: 1000;
    position: relative;
  }

  body {
    direction: ltr;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-rendering: optimizeLegibility;
    text-transform: none;
    vertical-align: top;
    word-wrap: normal;
    color: #1a1a2c;
    margin: 0;
    text-size-adjust: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a,
  button,
  input {
    outline: none;
    font: inherit;
    border: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0 !important;
    border: 0;
    display: inline;
    font: inherit !important;
    vertical-align: baseline;
    width: inherit;
    height: inherit;
  }

  h6 {
    padding-bottom: calc(22px / 2);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  h5 {
    padding-bottom: calc(26px / 2);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  h4 {
    padding-bottom: calc(34px / 2);
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }

  h3 {
    padding-bottom: calc(34px / 2);
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
  }

  div,
  input,
  textarea {
    box-sizing: border-box;
    background-color: transparent;
    font-family: inherit;
  }

  mark {
    background-color: transparent;
  }
`;
