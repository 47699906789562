import Http, { IHttp } from 'lib/http';
import axios, { CancelTokenSource } from 'axios';
import { IPlace } from 'Modules/Places/types';
import {
  IChangeOrderResponse,
  IClearOrderResponse,
  IFillOrderResponse,
  IFillOrderRequest,
  IActiveOrdersList,
  IAddProductToOrderRequest,
  IAddPromocodeResponse,
  IOrderPublicOfferResponse,
  ICreateOrderResponse,
  IGetPromocodesResponse,
} from './types';
import { OperationTypes } from 'Modules/OrdersHistory/types';
import { IProductRequest } from 'Modules/Products/types';

export const getPromocodes = (fetcher = Http): Promise<IGetPromocodesResponse> => {
  return fetcher.get('cart/promocodes');
};

export interface IOrdersHttp {
  changeOrder(shop_id: number, products: IProductRequest[], zone_id?: number): Promise<IChangeOrderResponse>;

  createOrder(
    shop_id: number,
    place?: IPlace,
    brand_label?: string,
    fetcher?: typeof Http
  ): Promise<ICreateOrderResponse>;

  fillOrder(orderId: number, order: IFillOrderRequest): Promise<IFillOrderResponse>;

  clearOrder(shop_id: number): Promise<IClearOrderResponse>;

  getActiveOrders(shop_id: number): Promise<IActiveOrdersList>;

  addProductToOrder(props: IAddProductToOrderRequest): Promise<IChangeOrderResponse>;

  getOrderPublicOffer(orderId: number, fetcher?: typeof Http): Promise<IOrderPublicOfferResponse>;

  addCommentToProduct(product_id: number, comment: string, order_id: number): Promise<{}>;
}
const cancelTokens: Map<number, CancelTokenSource> = new Map<number, CancelTokenSource>();
export class OrdersHttp implements IOrdersHttp {
  private readonly http: IHttp = Http;

  public cancelProductChangeRequest(product_id: number) {
    cancelTokens.get(product_id)?.cancel();
  }

  public changeOrder(
    shop_id: number,
    products: { product_id: number; operation: OperationTypes; amount?: number; weight?: number }[],

    zone_id?: number
  ): Promise<IChangeOrderResponse> {
    /**
     * отмена запросов нужна вот для какого кейса:
     * - юзер натыкивает один товар
     * - уходит запрос
     * - пока запрос резолвится юзер меняет количество товара
     * - уходит второй запрос
     * - если запрос не отменен, то количество товара начинает плясать, потому
     * что зарезолвился первый запрос и количество взялось из него, а потом
     * из второго запроса
     *
     * при этом нужно чтобы отменялись запросы только для одного товара, поэтому
     * они раскидываются по айдишникам товаров
     */
    this.cancelProductChangeRequest(products.length);
    cancelTokens.set(products.length, axios.CancelToken.source());
    return this.http.post(
      `/cart/update_items`,
      {
        shop_id,
        zone_id,
        products,
      },
      {
        cancelToken: cancelTokens.get(products.length)?.token,
      }
    );
  }

  public createOrder = (
    shop_id: number,
    place?: IPlace,
    brand_label?: string,
    fetcher = Http
  ): Promise<ICreateOrderResponse> =>
    fetcher.post(
      'cart',
      { shop_id, address_id: place?.address_id, place_id: place?.id, ...(brand_label && { brand_label }) },
      { apiVersion: 'komus/v1' }
    );

  public clearOrder(): Promise<IClearOrderResponse> {
    return this.http.post('cart/clear', undefined, { apiVersion: 'komus/v1' });
  }

  public addPromocode(promocode: string): Promise<IAddPromocodeResponse> {
    return this.http.post(`cart/promocode`, {
      promocode,
    });
  }

  public removePromocode(): Promise<IChangeOrderResponse> {
    return this.http.delete(`cart/promocode`, undefined);
  }

  public spendPoints(points: number): Promise<IChangeOrderResponse> {
    return this.http.patch(`cart/points`, {
      points,
    });
  }

  public addCommentToProduct(
    product_id: number,
    comment: string,
    order_id: number
  ): Promise<{ order: { last_modified: string } }> {
    return this.http.patch(`orders/${order_id}/products/${product_id}/comment`, {
      comment,
    });
  }

  public fillOrder(orderId: number, order: IFillOrderRequest): Promise<IFillOrderResponse> {
    return this.http.post(`orders/${orderId}/checkout`, order, { apiVersion: 'komus/v1' });
  }

  public productClaim(orderID: number, productIDS: number[], comment: string, photos: Blob[]): Promise<{}> {
    const formData = new FormData();

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    formData.append('product_ids', JSON.stringify(`${[...productIDS]}`));
    formData.append('comment', comment);

    photos.map((p, i: number) => formData.append(`photo${i}`, p, '.jpg'));

    return this.http.post(`/orders/${orderID}/claim`, formData);
  }

  public getActiveOrders(shop_id: number): Promise<IActiveOrdersList> {
    return this.http.get(`orders/active?shop_id=${shop_id}`);
  }

  public toogleFewerPlasticBags(isFewer: boolean): Promise<IChangeOrderResponse> {
    return this.http.patch('cart/fewer_plastic_bags', {
      fewer_plastic_bags: isFewer,
    });
  }

  public addProductToOrder({
    orderId,
    product_id,
    amount,
    weight,
    comment,
  }: IAddProductToOrderRequest): Promise<IChangeOrderResponse> {
    return this.http.post(`/orders/${orderId}/update_items`, {
      products: [{ product_id, amount, weight, comment, operation: 'set' }],
    });
  }

  public getOrderPublicOffer(orderId: number, fetcher = this.http): Promise<IOrderPublicOfferResponse> {
    return fetcher.get(`orders/${orderId}/public_offer`);
  }
}

export default new OrdersHttp();
