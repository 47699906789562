import styled from 'styled-components';
import { Modal, ModalSubTitle } from '../styled';
import { mediaSmUp } from '../../../theme/helpers/css';

export const Container = styled(Modal)`
  ${mediaSmUp`
    max-width: 480px;
    min-height: 200px;
  `}
`;

export const Description = styled(ModalSubTitle)`
  max-width: none;
  margin-bottom: 20px;
`;
