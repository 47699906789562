import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import { AuthTokenService } from '@igooods/axios-helpers';
import { DEPLOY_ENV, GITHUB_COMMIT_HASH } from '../../config/config';

const bugsnagOptions = {
  appVersion: GITHUB_COMMIT_HASH,
  maxBreadcrumbs: 40,
  releaseStage: DEPLOY_ENV,
  appType: 'igdesktop',
  logger: null,
};

const bugsnagClient = bugsnag({
  apiKey: '764822860c5f7e2f6ae40bd6c6e42bff',
  ...bugsnagOptions,
  beforeSend(report) {
    report.groupingHash = `${report.context}::${report.errorMessage}`;
    const token = AuthTokenService.get();
    if (token) {
      report.user = { id: token.id, token: token.token };
    }
    report.updateMetaData('redux log', window.__IG_REDUX_LOGGER__?.slice(0, 10) ?? []);
  },
});

export const serverBugsnag = bugsnag({
  apiKey: 'd4cb495d6c08e9aceedaa10711c7c8d6',
  beforeSend(report) {
    report.groupingHash = `${report.context}::${report.errorMessage}`;
  },
  ...bugsnagOptions,
});

bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
