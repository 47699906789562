import { IProduct, IProductRequest } from 'Modules/Products/types';
import OrdersHttp from 'Modules/Cart/http';
import { Dispatch, GetState, ThunkAction } from 'reduxStore/types';
import {
  CartActionNames,
  IActiveOrdersList,
  IAddPromocodeResponse,
  ICartState,
  IChangeOrderResponse,
  IFillOrderRequest,
  IOrder,
} from 'Modules/Cart/types';
import { EnumFromName, ModalType } from 'constants/enums';
import addProductInOrderAnalitic from 'utils/analitic/addProductInOrderAnalitic';
import enterPromocodeAnalitic from 'utils/analitic/enterPromocodeAnalitic';
import spendPointsAnalitic from 'utils/analitic/spendPointsAnalitic';
import { IPlace } from '../Places/types';
import { FbqEventsEnum, fbqTrack } from 'utils/fbq';
import { OperationTypes } from './types';
import { setLastSuccessOrder } from 'Modules/OrdersHistory/actions';
import { setCurrentUser } from '../AAA/actions';
import ModalActions, { openModalAction } from '../Modals/actions';
import { URL } from '../../constants/urlMaps';
import { setNotificationAction } from '../Notifications/actions';
import { RUB } from '../../constants/constants';
import history from 'configureHistory';
import { getCartId, getCartState, getNotAvailableProducts, isReadyCartForCheckout } from 'Modules/Cart/selectors';
import { EnumYaCounters, ymReachGoal } from 'lib/yandexMetrics';
import { TagManagerAddProductToCart, TagManagerRmProductFromCart } from 'utils/tagManager';
import { IAdditionalParams, IAnalyticsParams } from 'utils/tagManager/types';
import { setCurrentShopAction } from '../Shops/actions';
import { sendErrorNotification } from 'utils/errors';
import { checkIsNeedPassportModal } from 'Components/Product/helpers';
import axios from 'axios';
import { EnumTopMailGoals, topMailReachGoal } from 'utils/topMail';
/**
 * TODO https://igooods.atlassian.net/browse/FS-89
 */
export const updateProductInOrder = (payload: IProduct) => ({
  type: CartActionNames.UPDATE_PRODUCT_IN_ORDER,
  payload,
});

const set = (order: IOrder) => ({
  type: CartActionNames.SET_ORDER,
  payload: order,
});
/**
 * TODO https://igooods.atlassian.net/browse/FS-89
 */
const remove = (payload: number) => ({
  type: CartActionNames.REMOVE_FROM_ORDER,
  payload,
});

const change = (payload: IOrder) => ({
  type: CartActionNames.CHANGE_ORDER,
  payload,
});

const get = (payload: IOrder) => ({
  type: CartActionNames.GET_ORDER,
  payload,
});

const clear = (payload: IOrder) => ({
  type: CartActionNames.CLEAR_ORDER,
  payload,
});

const loadingActiveOrders = () => ({
  type: CartActionNames.LOADING_ACTIVE_ORDERS,
});

export const setActiveOrders = (payload: IOrder[]) => ({
  type: CartActionNames.SET_ACTIVE_ORDERS,
  payload,
});

/**
 * TODO https://igooods.atlassian.net/browse/FS-89
 */
const updateInfo = (payload: Partial<IOrder>) => ({
  type: CartActionNames.UPDATE_ORDER_INFO,
  payload,
});

const createOrder = (shopId?: number, place?: IPlace, branding?: string): ThunkAction => async (
  dispatch: Dispatch,
  getState,
  fetcher
): Promise<IChangeOrderResponse> => {
  const brandLabel = branding || getState().currentShop.branding;
  const data = await OrdersHttp.createOrder(
    shopId || getState().currentShop.id,
    place || getState().currentAddress,
    brandLabel === 'utkonos' ? brandLabel : '',
    fetcher
  );

  await dispatch(set(data.order));

  if (!!data.shop) {
    await dispatch(setCurrentShopAction(data.shop));
  }

  return Promise.resolve(data);
};

function isFirstItemAddedInCart(prevCart: ICartState, nextCart: IOrder) {
  return prevCart.products.length === 0 && nextCart.products.length === 1;
}

export const changePendingProductsAddAction = (payload: number[]) => {
  return {
    type: CartActionNames.CHANGE_PENDING_PRODUCTS_ADD,
    payload,
  };
};

export const changePendingProductsRemoveAction = (payload: number[]) => {
  return {
    type: CartActionNames.CHANGE_PENDING_PRODUCTS_REMOVE,
    payload,
  };
};

export default {
  setOrder: (order: IOrder): ThunkAction => dispatch => {
    return dispatch(set(order));
  },

  removeUnavailableProducts: (callbacks?: {
    onSuccess?: () => void;
    onError?: (message?: string) => void;
  }): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    const unavailableOrderProducts = getState().cart.products.filter(orderProduct => orderProduct.available === false);

    const orderProductDecremented = getState().cart.products.map(orderProduct => {
      const product = unavailableOrderProducts.find(payloadProduct => payloadProduct.id === orderProduct.id);
      if (product) {
        return {
          ...orderProduct,
          amount: 0,
          weight: 0,
        };
      }
      return orderProduct;
    });

    const restPayloadProducts = unavailableOrderProducts.filter(
      payloadProduct => !orderProductDecremented.map(product => product.id).includes(payloadProduct.id)
    );

    const orderProductsToSet = [...orderProductDecremented, ...restPayloadProducts];

    const requestData = orderProductsToSet.map(product => ({
      operation: OperationTypes.SET,
      product_id: product.id,
      amount: product.amount,
      weight: product.weight,
    }));

    const pendingProductsAdd = [...getState().cart.pendingProducts.add];

    dispatch(changePendingProductsAddAction([...pendingProductsAdd, ...orderProductsToSet.map(product => product.id)]));

    try {
      const data = await OrdersHttp.changeOrder(getState().currentShop.id, requestData);
      await dispatch(set(data.order));
      if (callbacks?.onSuccess) callbacks.onSuccess();
    } catch (err) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      if (callbacks?.onError) callbacks.onError((err as any)?.response?.data?.error);
    } finally {
      dispatch(changePendingProductsAddAction(pendingProductsAdd));
    }
  },

  addToOrderMultiple: (
    payloadProducts: IProduct[],
    callbacks?: {
      onSuccess?: () => void;
      onError?: (message?: string) => void;
    }
  ): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    const orderProductIncremented = getState().cart.products.map(orderProduct => {
      const product = payloadProducts.find(payloadProduct => payloadProduct.id === orderProduct.id);
      if (product) {
        return {
          ...orderProduct,
          amount: product.amount + orderProduct.amount,
          weight: product.weight + orderProduct.weight,
        };
      }
      return orderProduct;
    });

    const restPayloadProducts = payloadProducts.filter(
      payloadProduct => !orderProductIncremented.map(product => product.id).includes(payloadProduct.id)
    );

    const orderProductsToSet = [...orderProductIncremented, ...restPayloadProducts];

    const requestData = orderProductsToSet.map(product => ({
      operation: OperationTypes.SET,
      product_id: product.id,
      amount: product.amount,
      weight: product.weight,
    }));

    const pendingProductsAdd = [...getState().cart.pendingProducts.add];

    dispatch(changePendingProductsAddAction([...pendingProductsAdd, ...orderProductsToSet.map(product => product.id)]));

    try {
      const data = await OrdersHttp.changeOrder(getState().currentShop.id, requestData);
      await dispatch(set(data.order));
      if (callbacks?.onSuccess) callbacks.onSuccess();
    } catch (err) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      if (callbacks?.onError) callbacks.onError((err as any)?.response?.data?.error);
    } finally {
      dispatch(changePendingProductsAddAction(pendingProductsAdd));
    }
  },

  addToOrder: (
    product: IProductRequest,
    productForAnalitic: IProduct,
    from: EnumFromName,
    productsSetId?: number,
    additionalParams: IAdditionalParams = {},
    analyticsParams: IAnalyticsParams = {}
  ): ThunkAction => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const cart = getCartState(getState());

    dispatch(changePendingProductsAddAction([...cart.pendingProducts.add, product.product_id]));

    try {
      const data = await OrdersHttp.changeOrder(getState().currentShop.id, [
        {
          product_id: product.product_id,
          operation: OperationTypes.SET,
          amount: product.amount,
          weight: product.weight,
        },
      ]);
      const oldAmount = productForAnalitic.amount;
      fbqTrack(FbqEventsEnum.AddToCart);
      if (isFirstItemAddedInCart(cart, data.order)) {
        ymReachGoal(EnumYaCounters.order_first_item_added);
      }

      const { products, ...orderInfo } = data.order;
      /**
       * чтобы не было косяков нужно обновлять только количество товара, а не полностью весь список, как было раньше
       */
      dispatch(updateInfo(orderInfo));
      const updatedProduct = products.find(el => el.id === product.product_id);
      if (updatedProduct) dispatch(updateProductInOrder(updatedProduct));

      if (!oldAmount) {
        addProductInOrderAnalitic(productForAnalitic, data.order);
        ymReachGoal(EnumYaCounters.add_to_cart);
        topMailReachGoal(EnumTopMailGoals.add_to_cart);
      }
      TagManagerAddProductToCart(productForAnalitic, additionalParams, from, analyticsParams, cart.id, productsSetId);
    } catch (e) {
      if (!axios.isCancel(e)) {
        sendErrorNotification(e, dispatch);
        return Promise.reject(e);
      }
    } finally {
      dispatch(
        changePendingProductsAddAction(
          getCartState(getState()).pendingProducts.add.filter(productId => productId !== product.product_id)
        )
      );
    }
  },

  removeFromOrder: (
    product: IProductRequest,
    productForAnalitic: IProduct,
    from: EnumFromName,
    productsSetId?: number,
    additionalParams: IAdditionalParams = {},
    analyticsParams: IAnalyticsParams = {}
  ): ThunkAction => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(changePendingProductsRemoveAction([...getState().cart.pendingProducts.remove, product.product_id]));

    const cart = getCartState(getState());

    try {
      const data = await OrdersHttp.changeOrder(getState().currentShop.id, [
        {
          product_id: product.product_id,
          operation: OperationTypes.SET,
          amount: product.amount,
          weight: product.weight,
        },
      ]);

      const { products, ...orderInfo } = data.order;

      dispatch(updateInfo(orderInfo));
      const updatedProduct = products.find(el => el.id === product.product_id);
      if (updatedProduct) {
        dispatch(updateProductInOrder(updatedProduct));
      } else {
        dispatch(remove(product.product_id));
      }
      TagManagerRmProductFromCart(productForAnalitic, additionalParams, from, analyticsParams, cart.id, productsSetId);
    } catch (e) {
      if (!axios.isCancel(e)) {
        sendErrorNotification(e, dispatch);
        return Promise.reject(e);
      }
    } finally {
      dispatch(
        changePendingProductsRemoveAction(
          getState().cart.pendingProducts.remove.filter(productId => productId !== product.product_id)
        )
      );
    }
  },

  changeOrder: (
    product: IProductRequest,
    productForAnalitic: IProduct,
    from: EnumFromName,
    productsSetId?: number,
    analyticsParams: IAnalyticsParams = {}
  ): ThunkAction => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(changePendingProductsRemoveAction([...getState().cart.pendingProducts.remove, product.product_id]));

    try {
      const prevAmount = productForAnalitic.amount;
      const newAmount = product.amount || 0;
      const amountOfChange = newAmount - prevAmount;
      const cart = getCartState(getState());
      if (amountOfChange !== 0) {
        const data = await OrdersHttp.changeOrder(getState().currentShop.id, [
          {
            product_id: product.product_id,
            operation: OperationTypes.SET,
            amount: product.amount,
            weight: product.weight,
          },
        ]);

        amountOfChange > 0
          ? TagManagerAddProductToCart(
              productForAnalitic,
              { amountOfChange },
              from,
              analyticsParams,
              cart.id,
              productsSetId
            )
          : TagManagerRmProductFromCart(
              productForAnalitic,
              { amountOfChange: Math.abs(amountOfChange) },
              from,
              analyticsParams,
              cart.id,
              productsSetId
            );

        dispatch(change(data.order));
      }
    } catch (e) {
      sendErrorNotification(e, dispatch);
    } finally {
      dispatch(
        changePendingProductsRemoveAction(
          getState().cart.pendingProducts.remove.filter(productId => productId !== product.product_id)
        )
      );
    }
  },

  createOrder,

  clearOrder: (): ThunkAction => async (dispatch: Dispatch): Promise<IChangeOrderResponse> => {
    const data = await OrdersHttp.clearOrder();

    await dispatch(clear(data.order));
    return Promise.resolve(data);
  },

  fillOrder: (orderRequest: IFillOrderRequest): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    const orderId = getState().cart.id;

    const { user, order } = await OrdersHttp.fillOrder(orderId, orderRequest);

    if (getState().cart.first_order) ymReachGoal(EnumYaCounters.first_order_filled);
    await dispatch(setCurrentUser(user));
    await dispatch(
      setLastSuccessOrder({
        id: order.id,
        delivery_date: order.delivery_date,
      })
    );
  },

  addPromocode: (promocode: string): ThunkAction => async (dispatch: Dispatch): Promise<IAddPromocodeResponse> => {
    const data = await OrdersHttp.addPromocode(promocode);

    await dispatch(get(data.order));
    enterPromocodeAnalitic(data.order, promocode, data.referral);
    return Promise.resolve(data);
  },

  removePromocode: (): ThunkAction => async (dispatch: Dispatch): Promise<IChangeOrderResponse> => {
    const data = await OrdersHttp.removePromocode();

    await dispatch(get(data.order));
    return Promise.resolve(data);
  },

  spendPoints: (points: number): ThunkAction => async (dispatch: Dispatch): Promise<IChangeOrderResponse> => {
    const data = await OrdersHttp.spendPoints(points);

    await dispatch(get(data.order));
    spendPointsAnalitic(points);
    return Promise.resolve(data);
  },

  toogleFewerPlasticBags: (isFewer: boolean): ThunkAction => async (dispatch: Dispatch) => {
    const data = await OrdersHttp.toogleFewerPlasticBags(isFewer);

    await dispatch(get(data.order));
  },

  addCommentToProduct: (product_id: number, comment: string, order_id?: number): ThunkAction => async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<IChangeOrderResponse> => {
    const response = await OrdersHttp.addCommentToProduct(product_id, comment, order_id || getCartId(getState()));

    const updatedProducts = getCartState(getState()).products.map(product =>
      product.id === product_id ? { ...product, comment } : product
    );

    const updatedCartState = {
      ...getCartState(getState()),
      total_sale_discount: getCartState(getState()).total_sale_discount,
      last_modified: response.order?.last_modified,
      products: updatedProducts,
    };

    await dispatch(get(updatedCartState));
    return Promise.resolve({ order: updatedCartState });
  },

  getOrderForCheckout: (): ThunkAction => async (dispatch, getState) => {
    await dispatch(createOrder());

    const currentCart = getCartState(getState());
    const notAvailableProducts = getNotAvailableProducts(getState());
    const isReadyToCheckout = isReadyCartForCheckout(getState());

    if (isReadyToCheckout) {
      if (notAvailableProducts.length) {
        dispatch(ModalActions.openModal(ModalType.CartRunsOut));
      } else {
        fbqTrack(FbqEventsEnum.InitiateCheckout);
        history.push(URL.checkout);
      }
    } else {
      dispatch(setNotificationAction(`Минимальная сумма заказа без учета доставки - ${currentCart.min_price} ${RUB}`));
    }
  },

  getActiveOrders: (): ThunkAction => async (dispatch: Dispatch, getState: GetState): Promise<IActiveOrdersList> => {
    await dispatch(loadingActiveOrders());

    const data = await OrdersHttp.getActiveOrders(getState().currentShop.id);

    await dispatch(setActiveOrders(data.list));

    return Promise.resolve(data);
  },

  checkIsNeedPassport: (category_id: number, products?: IProduct[]): ThunkAction => async (
    dispatch: Dispatch,
    getState: GetState
  ) => {
    checkIsNeedPassportModal(
      products || getCartState(getState()).products,
      () => dispatch(openModalAction(ModalType.Passport)),
      category_id
    );
  },
};
