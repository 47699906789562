import styled from 'styled-components';
import { Box, Flex } from '@igooods/ui-kit';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';

export const HEADER_HEIGHT = 94;
const HEADER_HEIGHT_MOBILE = 66;
const FOOTER_HEIGHT = 110;
const FOOTER_HEIGHT_MOBILE = 154;

export const Main = styled(Box)`
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  ${mediaSmDown`
    min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px - ${FOOTER_HEIGHT_MOBILE}px);
  `}
`;

export const FaqContent = styled(Flex).attrs({ column: true, pl: { xs: 0, lg: 8 }, ml: { xs: 0, lg: 43 } })<{
  noSidebar?: boolean;
}>`
  box-sizing: content-box;
  ${({ noSidebar }) => noSidebar && `margin-left: 0;`}
`;

export const FaqListNumbered = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: li;
`;

export const FaqListItemNumbered = styled.li`
  position: relative;
  counter-increment: li;
  margin-bottom: 16px;
  &:before {
    content: counter(li);
    position: absolute;
    left: -32px;
    top: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: ${colors.light200};
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    background-color: ${colors.primaryMain};
  }
`;
