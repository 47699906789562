export enum URL {
  root = '/',

  signin = '/sign_in',

  signin_entity = '/sign_in_entity',

  select_address = '/select_address/:from?',

  email_confirmation = '/email_confirmation',

  coverage_zone = '/coverage_zone',

  catalog = '/catalog',

  profile = '/profile',

  profile_settings = '/profile/settings',

  profile_points = '/profile/points',

  profile_orders = '/profile/orders',

  profile_order = '/profile/orders/:id?',

  profile_places = '/profile/places',

  profile_places_add = '/profile/places/add',

  profile_payments = '/profile/payment_methods',

  entity_cash = '/profile/cash',

  entity_info = '/profile/info',

  entity_info_fill = '/profile/info/fill',

  entity_info_contract = '/profile/info/contract',

  entity_info_approval = '/profile/info/approval',

  guide = '/guide',

  cart = '/cart',

  catalogByCategories = '/catalog/categories',

  checkout = '/checkout',

  checkout_finish = '/catalog/checkout_finish',

  catalogFavorites = '/catalog/favorites',

  catalogFavoritesWithParams = '/catalog/favorites/:categoryId?',

  category = '/catalog/categories/:categoryId/:subCategoryId?',

  products_sets = '/catalog/selection',

  products_set = '/catalog/selection/:setId',

  products_sale = '/catalog/sale',

  product = '/products/:modelName',

  product_params = '/products',

  generic_payment_apply_card = '/payment/apply_card',

  generic_payment_success = '/payment/success',

  generic_payment_failure = '/payment/failure',

  buy_in_igooods = '/buy_in_igooods',

  online_payment_success = '/tinkoff/payment/success',

  online_payment_failure = '/tinkoff/payment/failure',

  about_us = 'https://igooods.ru/kak-eto-rabotaet',

  delivery_price = '/dostavka-oplata',

  agreement = '/agreement',

  agreement_page = 'https://igooods.ru/pages/agreement',

  how_it_works = '/kak-eto-rabotaet',

  promotions = '/akczii-i-skidki',

  payment = '/vybor-tovara-i-oplata',

  delivery = '/dostavka',

  wl_return_policy = '/return-policy',

  igooods_return_policy = '/infos/orders#return-policy',

  offer = 'https://yest.komus.ru/komus_yest_oferta.pdf',

  igooods_points_rules = 'https://igooods.ru/Правила%20участия%20в%20программе%20начисления%20баллов%20и%20использования%20промокодов%20igooods.pdf',

  entity_offer = 'https://yest.komus.ru/komus_yest_oferta.pdf',

  searchResults = '/catalog/search',

  notFoundPage = '/404',

  orders = '/profile/orders',

  order = '/profile/orders/:orderId',

  order_events = '/profile/orders/:orderId/events',

  business = '/business',

  rabota_v_igooods = '/rabota-v-igooods',

  obmen_i_vozvrat_tovara = '/obmen-i-vozvrat-tovara',

  partnership = '/partnerstvo',

  fr_details = '/pages/fr_details',

  franchisee = 'http://franchise.igooods.ru',

  careers = 'https://rabota.igooods.ru',

  huawei_app = 'https://appgallery.huawei.com/app/C107188291',

  google_play = 'http://play.google.com/store/apps/details?id=ru.handh.igooods',

  app_store = 'https://itunes.apple.com/ru/app/igooods-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2-%D0%B8%D0%B7-%D0%B3%D0%B8%D0%BF%D0%B5%D1%80%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%BE%D0%B2/id1176196585?mt=8',

  mobile_appmetrica = 'https://redirect.appmetrica.yandex.com/serve/314328726202498783',

  shops_groups = '/shops/groups',

  mobile_app = '/mobile_app',

  personal_data = '/pages/personal_data',

  single_product = '/product',

  user_set = '/catalog/user_sets/:id',

  EDO_oferta = 'https://yest.komus.ru/komus_yest_oferta_edo.pdf',

  KOMUS_Rostov = 'https://yest.komus.ru/komus_yest_account_details.pdf',
}

export enum API {
  ab_test = '/ab_testing',
}
