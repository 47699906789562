import axios from 'axios';
import { Http } from 'lib/http';

const geoGrinderApiUrl = '/geo-grinder/api/';

const headers = {
  Application: 'web',
  'API-Key': '26b39129-4086-4b13-9b52-4ab1d84cbf92',
};

const axiosInstance = axios.create({ baseURL: geoGrinderApiUrl, headers });

class GeoGrinder extends Http {
  public constructor() {
    super(axiosInstance, 'v1');
  }
}

export default new GeoGrinder();
