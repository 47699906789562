import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import TempUserButton from 'Components/Header/TempUserButton';
import UserButton from 'Components/Header/UserButton';
import ProfileDropdown from 'Components/Header/ProfileDropdown/ProfileDropdown';
import styled from 'styled-components';
import { ContactsButton } from '../../../Containers/SelectAddress/styled';
import { useHistory } from 'react-router';

interface IProfileButton {
  withUserDropdownMenu?: boolean;
  className?: string;
}

const ButtonWrapper = styled.div`
  margin-left: auto;
  position: relative;
`;

const StyledContactsButton = styled(ContactsButton)`
  position: absolute;
  right: 60px;
  bottom: 0px;
`;

const ProfileButton: React.FC<IProfileButton> = ({ withUserDropdownMenu, className }) => {
  const isTempUser = useSelector(checkIsTempUser);
  const history = useHistory();
  const isSelectAddress = history.location.pathname.includes('select_address');

  const getButton = useMemo(() => {
    if (isTempUser) {
      return <TempUserButton />;
    } else if (withUserDropdownMenu) {
      return (
        <ProfileDropdown>
          <UserButton />
        </ProfileDropdown>
      );
    }
    return <UserButton />;
  }, [isTempUser, withUserDropdownMenu]);

  return (
    <ButtonWrapper className={className}>
      {isSelectAddress && <StyledContactsButton />}
      {getButton}
    </ButtonWrapper>
  );
};

export default ProfileButton;
