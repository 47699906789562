import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { useSelector } from 'react-redux';

type TUseRootUrlWithSubdomain = () => {
  mainRoute: string;
};

const useRootUrlWithSubdomain: TUseRootUrlWithSubdomain = () => {
  const city = useSelector(getCurrentCity);
  const subdomainPath = city?.subdomain ? `${city.subdomain}.` : '';
  const url = `https://${subdomainPath}igooods.ru`;

  return { mainRoute: url };
};

export default useRootUrlWithSubdomain;
