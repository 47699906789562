import AAAactions from 'Modules/AAA/actions';
import ThemedButton from 'Components/ThemedButton';
import { TButtonTheme } from 'Components/ThemedButton/themes';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FontSize } from 'theme';
import { getTypographyFontSize } from 'theme/selectors';
import { mediaSmDown } from 'theme/helpers/css';
import { sendErrorNotification } from 'utils/errors';
import { EnumTheme } from 'constants/enums';

interface ILogoutProps {
  theme?: TButtonTheme;
  className?: string;
  children: React.ReactNode;
}

// TODO Jira: https://igooods.atlassian.net/browse/FS-177
const LogoutButton = styled(ThemedButton)`
  padding: 0;
  * {
    overflow: visible;
  }
  ${getTypographyFontSize(FontSize.s)};
  ${mediaSmDown`
    ${getTypographyFontSize(FontSize.xs)}
  `}
`;

const Logout: React.FC<ILogoutProps> = ({ theme = EnumTheme.textSecondary, className, children }: ILogoutProps) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(AAAactions.logout());
      window.location.pathname = '/select_address';
    } catch (e) {
      sendErrorNotification(e, dispatch);
    }
  };

  return (
    <LogoutButton className={className} onClick={handleLogout} use={theme}>
      {children}
    </LogoutButton>
  );
};

export default React.memo(Logout);
