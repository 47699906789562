import { EMPTY_STRING } from 'constants/constants';
import { InputFormat } from 'constants/enums';
import { InputFormatType } from 'constants/types';
import { GAP_STRING, NUMBER_RGXP } from 'constants/constants';
import { conformToMask } from 'text-mask-core';

export const PHONE_MASK = [
  '+',
  '7',
  GAP_STRING,
  '(',
  /[1-9]/,
  NUMBER_RGXP,
  NUMBER_RGXP,
  ')',
  GAP_STRING,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  GAP_STRING,
  NUMBER_RGXP,
  NUMBER_RGXP,
  GAP_STRING,
  NUMBER_RGXP,
  NUMBER_RGXP,
];

export const PHONE_MAST_NO_BRACKETS = [
  '+',
  '7',
  GAP_STRING,
  /[1-9]/,
  NUMBER_RGXP,
  NUMBER_RGXP,
  GAP_STRING,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  '-',
  NUMBER_RGXP,
  NUMBER_RGXP,
  '-',
  NUMBER_RGXP,
  NUMBER_RGXP,
];

export const INPUT_RULES = {
  [InputFormat.default]: {
    type: 'text',
    rgxp: /./,
    msg: '',
  },
  [InputFormat.email]: {
    type: 'text',
    rgxp: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,})+$/,
    msg: 'Разрешен e-mail вида name@mail.com',
  },
  [InputFormat.tel]: {
    type: 'tel',
    rgxp: /^((\9)+([0-9]){9})$/,
    msg: 'Введите российский номер телефона',
  },
  [InputFormat.inn]: {
    type: 'text',
    rgxp: /^(\d{10}|\d{12})$/,
    msg: 'Введите корректный ИНН',
  },
  [InputFormat.number]: {
    type: 'number',
    rgxp: /^\d*$/,
    msg: '',
  },
  [InputFormat.placeName]: {
    type: 'text',
    rgxp: /^[a-zA-Zа-яА-Я0-9,.:+-№%()#\s]*$/,
    msg: 'Разрешены буквы, цифры и ,.:+-№%()#',
  },
  [InputFormat.street]: {
    type: 'text',
    rgxp: /^[а-я\sА-Яa-zA-Z0-9ёЁ(),/.-]*$/,
    msg: 'Разрешены цифры, буквы и ./-,()',
  },
  [InputFormat.apartment]: {
    type: 'text',
    rgxp: /^\s*([A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9 ,.:()#№-]*|-)\s*$/,
    msg: 'Разрешены буквы, цифры и ,.:-()',
  },
  [InputFormat.stage]: {
    type: 'text',
    rgxp: /^(\s*|\d+|-)$/,
    msg: 'Разрешены цифры или -',
  },
  [InputFormat.porch]: {
    type: 'text',
    rgxp: /^(\s*|\d+|-)$/,
    msg: 'Разрешены цифры или -',
  },
  [InputFormat.change]: {
    type: 'text',
    rgxp: /^[0-9]+$/,
    msg: 'В это поле нельзя вводить буквы',
  },
};

const defaultPhoneMask = [
  /[1-9]/,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
  NUMBER_RGXP,
];

const MOBILE_PHONE_REGEX = /^(?:8)(9\d{0,9})$/;

export const maskPhoneToValue = (value: string, mask?: (string | RegExp)[]): string => {
  const trimmedValue = value.replace(/\s|^(\+7)/g, EMPTY_STRING);
  const isMobilePhone = MOBILE_PHONE_REGEX.test(trimmedValue);
  const formattedMobilePhone = trimmedValue.replace(MOBILE_PHONE_REGEX, '$1');
  const formattedPhone = isMobilePhone ? formattedMobilePhone : trimmedValue;
  // не используем inputMask, потому что ее сложно подружить с регулярками и дополнительными условиями форматирования
  const { conformedValue } = conformToMask(formattedPhone, mask || defaultPhoneMask, {
    placeholderChar: '\u2000',
  });
  // без replace маска начинает схоить с ума и кидает курсор в самый конец инпута
  return conformedValue.replace(/\s+$/, '');
};

export const formatValueByType = (value: string, type: InputFormatType): string => {
  if (type === InputFormat.tel) {
    return maskPhoneToValue(value);
  }

  if (type === InputFormat.inn) {
    return value.replace(/\D/g, EMPTY_STRING).substring(0, 12);
  }

  if (type === InputFormat.email) {
    return value.replace(/\s/g, EMPTY_STRING);
  }

  return value;
};

export const isValid = (
  value: string,
  format: InputFormatType,
  isRequired = true
): { result: boolean; message: string } => {
  if (format !== 'change' && !value.trim().length) {
    return {
      result: !isRequired,
      message: '',
    };
  }

  const result = INPUT_RULES[format].rgxp.test(value);

  return { result: result, message: INPUT_RULES[format].msg };
};

export const fieldValidation = {
  latin: {
    regex: /([a-zA-Z]+)/,
    errText: 'Смените язык',
  },
  email: {
    regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,})+$/,
    errText: 'Укажите почту в формате tomat@igooods.ru',
    emptyErrText: 'Введите почту',
  },
  phone: {
    regex: /\d+/,
    errText: 'Разрешен телефон вида +7 999 999 99 99',
    emptyErrText: 'Введите телефон',
    length: 10,
  },
  nomination: {
    regex: /^[a-zA-Zа-яА-Я0-9,.:+-№%()#\s]*$/,
    errText: 'Разрешены буквы, цифры и ,.:+-№%()#',
    emptyErrText: 'Введите название',
  },
  number: {
    regex: /^\d*$/,
  },
  userName: {
    regex: /./,
    emptyErrText: 'Введите имя',
  },
  addressName: {
    length: 30,
    regex: /^[a-zA-Zа-яА-Я0-9,.:+-№%()#\s]*$/,
    errText: 'Разрешены буквы, цифры и ,.:+-№%()#',
  },
  street: {
    emptyErrText: 'Обязтельное поле',
    regex: /^[а-я\sА-Яa-zA-Z0-9ёЁ(),/.-]*$/,
    errText: 'Разрешены цифры, буквы и ./-,()',
  },
  apartment: {
    emptyErrText: 'Обязтельное поле',
    errText: 'Разрешены буквы, цифры и ,.:-()',
    length: 20,
    regex: /^\s*([A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9 ,.:()#№-]*|-)\s*$/,
  },
  stageAndPorch: {
    emptyErrText: 'Обязтельное поле',
    errText: 'Разрешены цифры или -',
    length: 3,
    regex: /^\s*(\d+|-)\s*$/,
  },
  note: {
    length: 150,
  },
  birthDate: {
    errText: 'Необходимо ввести дату в формате ДД.ММ.ГГГГ',
    length: 10,
    regex: /^\d{2}[.]\d{2}[.]\d{4}$/,
  },
};

export const getEmailError = (error?: string, touched?: boolean) => {
  if (error) {
    return error === fieldValidation.latin.errText ? error : touched && error;
  }
};

export const getBirthDateError = (error?: string) => {
  if (error?.includes('must be less')) {
    return 'Согласно правилам сервиса вам должно быть больше 18 лет';
  }
  return 'Некорректная дата';
};
