import styled from 'styled-components';
import { Box, Grid } from '@igooods/ui-kit';
import { mediaCustomUp, mediaLandscape, mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { Link } from 'react-router-dom';
import ArrowRightIcon from 'Components/Icon/ArrowRightIcon';
import ContactsModal from '../../Components/landings/Header/ContactsModal';
import { hideBeforeSm } from '../../theme/helpers';

//SELECT_ADDRESS
export const GridContainer = styled(Grid)<{ $isScale: boolean }>`
  height: 100%;
  overflow: hidden;
  min-height: 100%;

  grid-template:
    'header header header' 82px
    'search search search' 250px
    'map map map' 1fr / 1fr;

  ${mediaCustomUp(1920)`
    grid-template:
    'header header header' 82px
    'search search search' 280px
    'map map map' 1fr / 1fr;
  `}

  ${addStyleIfPropTruly(
    '$isScale',
    `&& {
      grid-template:
      'header header header' 82px
      'map map map' 1fr / 1fr;
    }
    `
  )}

  ${mediaSmDown`
    grid-template:
      'header header header' 72px
      'map map map' 1fr
      'mobileSearch mobileSearch mobileSearch' 270px / 1fr;
    ${mediaLandscape`
       grid-template:
        'header header header' 72px
        'search search search' 200px
        'map map map' 1fr / 1fr;
    `}
  `}
`;

export const Map = styled.div`
  grid-area: map;
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
`;

export const BusinessLink = styled(Link)`
  text-decoration: none;
`;

export const ArrowIconStyled = styled(ArrowRightIcon)`
  transform: rotate(-45deg);
`;

export const LogoContainer = styled(Box)`
  flex: 0 0 auto;
  position: relative;

  ${mediaSmUp`
    min-width: 100px;
  `}

  ${mediaSmDown`
    width: 64px;
  `}
`;

export const ContactsButton = styled(ContactsModal)`
  margin: 0 20px;

  ${hideBeforeSm}
`;
