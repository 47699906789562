import React from 'react';
import Icon, { IIcon } from './Icon';

interface ICartIconNewProps extends IIcon {
  filled?: boolean;
}

const CartIconNew: React.FC<ICartIconNewProps> = ({ filled = false, ...props }) => (
  <Icon width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.34406 17.6559C2.72485 17.0367 2.96425 15.7933 3.87878 14.8788C4.79337 13.9642 6.03673 13.7249 6.65594 14.344C7.27515 14.9632 7.03575 16.2066 6.12117 17.1212C5.20663 18.0358 3.96327 18.2752 3.34406 17.6559Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3441 17.6559C10.7249 17.0367 10.9642 15.7933 11.8788 14.8788C12.7934 13.9642 14.0367 13.7249 14.6559 14.344C15.2752 14.9632 15.0358 16.2066 14.1212 17.1212C13.2066 18.0358 11.9633 18.2752 11.3441 17.6559Z"
      fill="currentColor"
    />
    <path d="M1 1C1.66667 1.38095 4 2 4 6" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" />
    <path
      d="M16.941 5H5C4.44772 5 4 5.44772 4 6V10.5C4 11.0523 4.44772 11.5 5 11.5H13.882C14.2607 11.5 14.607 11.286 14.7764 10.9472L17.3882 5.72361C17.5544 5.39116 17.3127 5 16.941 5Z"
      fill={filled ? 'currentColor' : 'transparent'}
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </Icon>
);

export default CartIconNew;
