import React, { ReactChild } from 'react';
import { URL } from 'constants/urlMaps';
import ListIcon from 'Components/Icon/ListIcon';
import MapPinIcon from 'Components/Icon/MapPinIcon';
import CoinsIcon from 'Components/Icon/CoinsIcon';
import CardIcon from 'Components/Icon/CardIcon';
import SettingsIcon from 'Components/Icon/SettingsIcon';
import loadable from '@loadable/component';
import Flex from 'Components/Grid/Flex';
import Loader from 'Components/Loader';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import styled from 'styled-components';
import { ThemeButton } from '../../Components/ThemedButton';
import { hideBeforeLg } from '../../theme/helpers';

const Fallback = () => (
  <Flex grow middle>
    <Loader />
  </Flex>
);

const FakeButton = styled(ThemeButton).attrs({ size: EnumButtonSize.small, use: EnumTheme.secondary })`
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  ${hideBeforeLg};
`;

const Orders = loadable(() => import(/* webpackChunkName: "ProfileOrders" */ './Orders'), { fallback: <Fallback /> });
const Settings = loadable(() => import(/* webpackChunkName: "ProfileSettings" */ './Settings'), {
  fallback: <Fallback />,
});
const Places = loadable(() => import(/* webpackChunkName: "ProfilePlaces" */ './Places'), { fallback: <Fallback /> });
const Payments = loadable(() => import(/* webpackChunkName: "ProfilePayments" */ './Payments'), {
  fallback: <Fallback />,
});
const EntityCash = loadable(() => import(/* webpackChunkName: "EntityCash" */ './EntityCash'), {
  fallback: <Fallback />,
});
const EntityInfo = loadable(() => import(/* webpackChunkName: "EntityInfo" */ './EntityInfo'), {
  fallback: <Fallback />,
});

export interface IRoute {
  name: string | JSX.Element;
  component: any;
  path: string;
  subPath: string;
  icon: ReactChild;
  addon?: (blocked?: boolean) => ReactChild;
  show?: {
    forEntity?: boolean;
  };
  block?: {
    forEntity?: boolean;
  };
  isBlocked?: boolean;
  redirect_path?: URL;
}

export interface IAuthedRoute extends IRoute {
  isAuthed: boolean;
  redirect_path: URL;
}

const routes: IRoute[] = [
  {
    name: 'Заказы',
    component: Orders,
    path: URL.profile_orders,
    subPath: URL.profile_order,
    icon: <ListIcon />,
    block: {
      forEntity: true,
    },
  },
  {
    name: 'Адреса',
    component: Places,
    path: URL.profile_places,
    // TODO Jira: https://igooods.atlassian.net/browse/FS-198
    subPath: URL.profile_places,
    icon: <MapPinIcon />,
    block: {
      forEntity: true,
    },
  },
  {
    name: 'Способы оплаты',
    component: Payments,
    path: URL.profile_payments,
    subPath: URL.profile_payments,
    redirect_path: URL.entity_cash,
    icon: <CardIcon />,
    show: {
      forEntity: true,
    },
  },
  {
    name: 'Мои данные',
    component: Settings,
    path: URL.profile_settings,
    subPath: URL.profile_settings,
    redirect_path: URL.entity_info,
    icon: <SettingsIcon />,
    show: {
      forEntity: false,
    },
  },
  {
    name: 'Кредитный лимит',
    component: EntityCash,
    // eslint-disable-next-line react/display-name
    addon: (disabled = false) => <FakeButton disabled={disabled}>Сформировать счёт</FakeButton>,
    path: URL.entity_cash,
    subPath: URL.entity_cash,
    redirect_path: URL.entity_cash,
    icon: <CoinsIcon />,
    show: {
      forEntity: true,
    },
    block: {
      forEntity: true,
    },
  },
  {
    name: 'Мои данные',
    component: EntityInfo,
    path: URL.entity_info,
    subPath: URL.entity_info,
    redirect_path: URL.profile_settings,
    icon: <SettingsIcon />,
    show: {
      forEntity: true,
    },
  },
];

export default routes;
