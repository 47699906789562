import React from 'react';
import Icon, { IIcon } from './Icon';

const SocialFBIcon: React.FC<IIcon> = props => (
  <Icon width="8" height="16" viewBox="0 0 8 16" {...props}>
    <path d="M7.99219 0.392857C7.75112 0.357142 6.92076 0.285713 5.95647 0.285713C3.93862 0.285713 2.55469 1.51786 2.55469 3.77678V5.72321H0.277902V8.36607H2.55469V15.1429H5.28683V8.36607H7.55469L7.9029 5.72321H5.28683V4.03571C5.28683 3.27678 5.49219 2.75 6.5904 2.75H7.99219V0.392857Z" />
  </Icon>
);

export default SocialFBIcon;
