import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { omit } from 'ramda';

interface IServerData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any };
  removeFromData: (key: string) => void;
}

export const ServerDataContext = React.createContext<IServerData>({
  data: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeFromData: () => {},
});

export const ServerDataProvider: React.FC<{ value: object; children: React.ReactNode }> = ({ value, children }) => {
  const [data, setData] = useState(value);
  const removeFromData = useCallback(
    (key: string) => {
      setData(omit([key], data));
    },
    [data]
  );

  const providerValue = { data, removeFromData };

  return <ServerDataContext.Provider value={providerValue}>{React.Children.only(children)}</ServerDataContext.Provider>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useServerData = (key: string, deps: any[] = []) => {
  const { data, removeFromData } = useContext(ServerDataContext);
  const deleted = useRef(false);
  const serverResult = data[key];
  useEffect(() => {
    if (serverResult) {
      return () => {
        deleted.current = true;
        removeFromData(key);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, removeFromData, serverResult, ...deps]);
  return !deleted.current && serverResult;
};
