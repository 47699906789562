import { AnyAction } from 'redux';
import { EntityActionTypes } from './actions';
import { IEntity } from './types';
import { EMPTY_STRING } from 'constants/constants';
import { EnumEntityStatus } from '../../constants/enums';

const initialState: IEntity = {
  inn: EMPTY_STRING,
  opf_short: EMPTY_STRING,
  state: EnumEntityStatus.unfilled,
  name: EMPTY_STRING,
  balance: null,
};

export default function entityReducer(state: IEntity = initialState, action: AnyAction): IEntity {
  switch (action.type) {
    case EntityActionTypes.SET_ENTITY:
      return {
        ...state,
        ...action.payload,
      };
    case EntityActionTypes.SET_ENTITY_STATUS:
      return {
        ...state,
        state: action.payload,
      };
    default:
      return state;
  }
}
