import { AnyAction } from 'redux';

export const SHOW_RATE_LAST_ORDER = 'SHOW_RATE_LAST_ORDER ';
export const HIDE_RATE_LAST_ORDER = 'HIDE_RATE_LAST_ORDER ';
export const SET_RATE_LAST_ORDER = 'SET_RATE_LAST_ORDER';

export const setLastOrderAction = (value: boolean): AnyAction => ({
  type: SET_RATE_LAST_ORDER,
  payload: value,
});
