import { ModalAction } from './actions';
import { AnyAction } from 'redux';

export interface IModalState {
  isOpened: boolean;
  modalType: string;
  isBlockedClose?: boolean;
}

export const modalInitialState: IModalState = {
  isOpened: false,
  modalType: '',
  isBlockedClose: false,
};

export default function modalReducer(state: IModalState = modalInitialState, action: AnyAction): IModalState {
  switch (action.type) {
    case ModalAction.BlockClose:
      return {
        isOpened: state.isOpened,
        modalType: state.modalType,
        isBlockedClose: true,
      };
    case ModalAction.UnblockClose:
      return {
        isOpened: state.isOpened,
        modalType: state.modalType,
        isBlockedClose: false,
      };
    case ModalAction.Open:
      return {
        isOpened: true,
        modalType: action.payload,
      };
    case ModalAction.Close:
      return modalInitialState;
    default:
      return state;
  }
}
