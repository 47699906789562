import styled from 'styled-components';
import ClosableModal from './ClosableModal';
import { mediaSmDown } from 'theme/helpers/css';
import { ModalContent } from './Modal';
import { mediaSmUp } from 'theme/helpers/css';

const CenteredClosableModal = styled(ClosableModal)`
  position: relative;

  ${ModalContent} {
    ${mediaSmDown`
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: auto;
  `}
  }

  ${mediaSmDown`
    padding: 0;
  `}

  ${mediaSmUp`
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export default CenteredClosableModal;
