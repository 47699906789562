import { AnyAction } from 'redux';
import { any, append, assoc, ifElse, map, propEq, when, pipe } from 'ramda';
import {
  CartActionNames,
  IActiveOrdersAction,
  IActiveOrdersState,
  ICartProductUpdate,
  ICartState,
  IPendingProductIds,
} from 'Modules/Cart/types';
import { EnumShopServiceInfoType } from 'Modules/Shops/types';

const initialState: Partial<ICartState> = {
  total_price: 0,
  products: [],
  service_info: [
    {
      id: 0,
      price: 0,
      name: '',
      description: '',
      type: EnumShopServiceInfoType.delivery,
    },
  ],
  pendingProducts: {
    add: [],
    remove: [],
  },
  model_limits: [],
};

export default function cartReducer(state: Partial<ICartState> = initialState, action: AnyAction): Partial<ICartState> {
  switch (action.type) {
    case CartActionNames.SET_ORDER:
    case CartActionNames.CHANGE_ORDER:
    case CartActionNames.CLEAR_ORDER:
    case CartActionNames.GET_ORDER:
      return {
        ...action.payload,
        pendingProducts: { ...state.pendingProducts },
        model_limits: action.payload?.model_limits || (state.model_limits && [...state.model_limits]),
      };
    case CartActionNames.UPDATE_ORDER_INFO:
    case CartActionNames.ADD_IN_ORDER:
      return { ...state, ...action.payload };
    case CartActionNames.REMOVE_FROM_ORDER:
      return { ...state, products: state.products?.filter(el => el.id !== (action.payload as number)) };
    case CartActionNames.CLEAR_CART:
      return { ...initialState };
    case CartActionNames.UPDATE_PRODUCT_IN_ORDER:
      /**
       * TODO https://igooods.atlassian.net/browse/FS-89
       *
       * изначально в этом редьюсере не было обновления количества товара в сторе
       * и редьюсер работал только с экшном 1 типа. Сейчас логика экшнов усложнилась, так
       * что будет нужен рефакторинг. Ставлю 100 песо, что https://igooods.atlassian.net/browse/FS-89
       * поможет решить проблему, потому что она упирается в долбоёбскую архитектуру самого стора,
       * где у нас сущность списка товаров создается на каждый чих своя, и потом они мерджатся с
       * товарами из корзины. Если же управление списком товаров и обновление отдельных товаров в списке
       * вынести в отдельные редьюсеры, и связять эту красоту с нужными сущностями (категория товара,
       * подборка, заказ, корзина и т.д.) мы получим очень много архитектурного профита
       */
      const {
        payload: { id, amount, weight },
      } = action as ICartProductUpdate;
      const findById = propEq('id', id);
      const setUpdatedInfo = pipe(assoc('amount', amount), assoc('weight', weight));
      const setAmountOrAppend = ifElse(any(findById), map(when(findById, setUpdatedInfo)), append(action.payload));
      return { ...state, products: setAmountOrAppend(state.products) };
    case CartActionNames.CHANGE_PENDING_PRODUCTS_ADD:
      return {
        ...state,
        pendingProducts: {
          ...(state.pendingProducts as IPendingProductIds),
          add: action.payload,
        },
      };
    case CartActionNames.CHANGE_PENDING_PRODUCTS_REMOVE:
      return {
        ...state,
        pendingProducts: {
          ...(state.pendingProducts as IPendingProductIds),
          remove: action.payload,
        },
      };
    default:
      return state;
  }
}

const initialActiveOrderState: IActiveOrdersState = {
  list: [],
  isLoading: false,
  isSuccess: false,
};

export function activeOrdersReducer(
  state: IActiveOrdersState = initialActiveOrderState,
  action: IActiveOrdersAction
): IActiveOrdersState {
  switch (action.type) {
    case CartActionNames.LOADING_ACTIVE_ORDERS:
      return { ...state, isLoading: true, isSuccess: false };
    case CartActionNames.SET_ACTIVE_ORDERS:
      return { ...state, isLoading: false, isSuccess: true, list: action.payload };
    default:
      return state;
  }
}
