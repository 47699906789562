import Http, { IHttp } from 'lib/http';
import { ICoordinate, IPlace } from 'Modules/Places/types';
import { ICity } from 'Modules/SearchAddress/types';
import { IProduct } from 'Modules/Products/types';
import {
  IShop,
  IShopDeliveryDates,
  IShopForPlace,
  IShopWithZone,
  IShopWithZones,
  IProductsGroup,
  IEmployee,
} from './types';
import { URL, API } from 'constants/urlMaps';
import { ITest } from 'Modules/AAA/types';

export interface IShopsForPlaceResponse {
  list: IShopForPlace[];
  place: IPlace;
}

export interface IShopsWithZonesResponse {
  city?: ICity;
  list: IShopWithZones[];
  total: number;
}

export interface IShopsWithZoneResponse {
  list: IShopWithZone[];
  total: number;
}

export interface IShopsResponse {
  list: IShop[];
}

export interface IShopResponse {
  shop: IShop;
}

export interface IPreviewChangeShopResponse {
  items: IProduct[];
}

export interface IShopShiftResponse {
  employees: IEmployee[];
}

export interface IShopsHttp {
  getShopsForPlace(coordinate: ICoordinate): Promise<IShopsForPlaceResponse>;
  getShopsWithZones(city_id: number, coordinate?: ICoordinate): Promise<IShopsWithZonesResponse>;
  getShopDeliveryDates(shop_id: number, zone_id: number): Promise<IShopDeliveryDates>;
  getShops(city_id: number, coordinate?: ICoordinate): Promise<IShopsResponse>;
  getShop(shopId: number, brand_label?: string): Promise<IShopResponse>;
  previewChangeShop(shop: IShop): Promise<IPreviewChangeShopResponse>;
  getShopShift(id: number): Promise<IShopShiftResponse>;
}

type TypeFilterShops = IShop | IShopWithZone | IShopWithZones;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterShopsByWl = <T extends TypeFilterShops, K extends keyof TypeFilterShops>(shops: T[], key: K): T[] => {
  const searchParams = new URLSearchParams(location.search);
  const shopGroup = searchParams.get('group');
  const shopsByGroup = shopGroup ? shops.filter(s => (s.group_en || s.group) === shopGroup) : [];
  return shopsByGroup.length ? shopsByGroup : shops;
};

export class ShopsHttp implements IShopsHttp {
  private readonly http: IHttp = Http;

  public async getShopsForPlace(coordinate: ICoordinate): Promise<IShopsForPlaceResponse> {
    const data = (await this.http.get('/shops', {
      lng: coordinate.longitude,
      lat: coordinate.latitude,
      branding_support: true,
    })) as IShopsForPlaceResponse;
    return Promise.resolve({
      ...data,
      shops: filterShopsByWl(data.list, 'group'),
    });
  }

  public async getShopsWithZones(city_id?: number, coordinate?: ICoordinate): Promise<IShopsWithZonesResponse> {
    const data = (await this.http.get('/shops', {
      city_id,
      need_all_zones: true,
      ...(coordinate && {
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      }),
    })) as IShopsWithZonesResponse;
    return Promise.resolve({
      ...data,
      list: filterShopsByWl(data.list, 'group_en'),
    });
  }

  public getShopDeliveryDates(shop_id: number, zone_id?: number, fetcher = Http): Promise<IShopDeliveryDates> {
    return fetcher.get(`/shops/${shop_id}/delivery?zone_id=${zone_id}`);
  }

  public async getShops(city_id: number, coordinate?: ICoordinate): Promise<IShopsWithZoneResponse> {
    const data = (await this.http.get('/shops', {
      city_id,
      ...(coordinate && {
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      }),
    })) as IShopsWithZoneResponse;
    return Promise.resolve({
      ...data,
      list: filterShopsByWl(data.list, 'group_en'),
    });
  }

  public getShop(shopId: number, brand_label?: string, fetcher = Http): Promise<IShopResponse> {
    return fetcher.get(`/shops/${shopId}`, { brand_label });
  }

  public previewChangeShop(shop: IShop): Promise<IPreviewChangeShopResponse> {
    return this.http.get('/items_lost_on_shop_change', { shop_id: shop.id });
  }

  public getCityShopGroups(cityId: number, fetcher = Http): Promise<IProductsGroup[]> {
    return fetcher.get(URL.shops_groups, { city_id: cityId });
  }

  public getShopShift(shopId: number, fetcher = Http): Promise<IShopShiftResponse> {
    return fetcher.get(`/shops/${shopId}/on_shift`);
  }

  public getUserABTest(testName: string, city_id = 1): Promise<ITest> {
    return this.http.post(`${API.ab_test}/${testName}`, { city_id });
  }

  public getUserABTests(city_id = 1, fetcher = this.http): Promise<ITest[]> {
    return fetcher.get(`${API.ab_test}`, { city_id });
  }
}

export default new ShopsHttp();
