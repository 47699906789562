export const FAVORITE_ICON_TEST_ID = 'FAVORITE_ICON_TEST_ID';
export const FAVORITE_AUTH_POPUP_TEST_ID = 'FAVORITE_AUTH_POPUP_TEST_ID';
export const REPORT_ERR_MODAL_TEST_ID = 'product-report-err-modal';
export const PRODUCT_TEST_ID = 'PRODUCT_TEST_ID';
export const PRODUCT_CONTENT_WRAPPER_TEST_ID = 'PRODUCT_CONTENT_WRAPPER_TEST_ID';
export const PRODUCT_NAME_TEST_ID = 'PRODUCT_NAME_TEST_ID';
export const PRODUCT_UNIT_TEST_ID = 'PRODUCT_UNIT_TEST_ID';
export const PRODUCT_LINK_TEST_ID = 'PRODUCT_LINK_TEST_ID';
export const PRODUCT_IMAGE_TEST_ID = 'PRODUCT_IMAGE_TEST_ID';
export const PRODUCT_IMAGE_WRAPPER_TEST_ID = 'PRODUCT_IMAGE_WRAPPER';
export const PRODUCT_COMMENT_BUTTON_TEST_ID = 'PRODUCT_COMMENT_BUTTON_TEST_ID';
export const PRODUCT_COMMENT_WRAPPER_TEST_ID = 'PRODUCT_COMMENT_WRAPPER_TEST_ID';
export const PRODUCT_NOTE_TEST_ID = 'PRODUCT_NOTE_TEST_ID';
export const PRODUCT_ADD_BUTTON_TEST_ID = 'PRODUCT_ADD_BUTTON_TEST_ID';
export const PRODUCT_ADD_TO_ORDER_BUTTON_DESKTOP_TEST_ID = 'PRODUCT_ADD_TO_ORDER_BUTTON_DESKTOP_TEST_ID';
export const PRODUCT_ADD_TO_ORDER_BUTTON_MOBILE_TEST_ID = 'PRODUCT_ADD_TO_ORDER_BUTTON_MOBILE_TEST_ID';
export const PRODUCT_ADD_TO_ORDER_MODAL_TEST_ID = 'PRODUCT_ADD_TO_ORDER_MODAL_TEST_ID';
export const FAVORITE_AUTH_BUTTON_TEST_ID = 'FAVORITE_AUTH_BUTTON_TEST_ID';
export const CHANGE_AMOUNT_INPUT_TEST_ID = 'CHANGE_AMOUNT_INPUT_TEST_ID';
export const CHANGE_AMOUNT_BUTTON_PLUS_TEST_ID = 'CHANGE_AMOUNT_BUTTON_PLUS_TEST_ID';
export const CHANGE_AMOUNT_BUTTON_MINUS_TEST_ID = 'CHANGE_AMOUNT_BUTTON_MINUS_TEST_ID';
export const PRODUCT_BACK_IN_CART_BUTTON_TEST_ID = 'PRODUCT_BACK_IN_CART_BUTTON_TEST_ID';
export const PRODUCT_NOT_AVALIBLE_BUTTON_TEST_ID = 'PRODUCT_NOT_AVALIBLE_BUTTON_TEST_ID';
export const PRODUCT_NOT_AVALIBLE_BUTTON_REMOVE_TEST_ID = 'PRODUCT_NOT_AVALIBLE_BUTTON_REMOVE_TEST_ID';
export const PRODUCT_FIND_REPLACEMENT_BUTTON_TEST_ID = 'PRODUCT_FIND_REPLACEMENT_BUTTON_TEST_ID';
export const PRODUCT_COMMENT_BUTTON_MOBILE_TEST_ID = 'PRODUCT_COMMENT_BUTTON_MOBILE_TEST_ID';
export const REMOVE_BUTTON_TEST_ID = 'REMOVE_BUTTON_TEST_ID';
export const ADD_TO_ORDER_BUTTON_TEST_ID = 'ADD_TO_ORDER_BUTTON_TEST_ID';
export const ADD_TO_ORDER_DROPDOWN_TEST_ID = 'ADD_TO_ORDER_DROPDOWN_TEST_ID';
export const ADD_TO_ORDER_INPUT_TEST_ID = 'ADD_TO_ORDER_INPUT_TEST_ID';
export const CHANGE_AMOUNT_CONTAINER_TEST_ID = 'CHANGE_AMOUNT_CONTAINER_TEST_ID';
export const COMMENT_PRODUCT_CONTAINER_TEST_ID = 'COMMENT_PRODUCT_CONTAINER_TEST_ID';
export const CHANGE_PRODUCT_AMOUNT_DELAY_MS = 1000;

//для первого энергетика (category_id === 109) должны показывать модалку про паспорт
export const ENERGY_DRINK_CATEGORY_ID = 109;
