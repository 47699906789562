import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { getMultipliedIndentFromProp, getTypographyFontStyle, getTypographyFontStyleMobile } from 'theme/selectors';
import { FontSize, Transitions } from 'theme';
import { mediaSmDown } from 'theme/helpers/css';
import { addStyleIfPropTruly, addXsStyleIfPropTruly } from '../../utils/styledComponents';
import Textarea from 'react-autosize-textarea';
import { shadows } from 'constants/shadows';

const TextareaAutosize = styled(Textarea).attrs({ async: true })<{ withMb?: boolean }>`
  resize: none;
  background: ${colors.paleGrey};
  padding: 9px 12px;
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: ${Transitions.bg};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${getTypographyFontStyle(FontSize.s)}

  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.m)};
    padding: ${getMultipliedIndentFromProp(2)};
  `}
 
  &:focus {
    background: ${colors.white};
    box-shadow: ${shadows.boxShadow};
  }
  &::placeholder {
    color: ${colors.warmGrey};
  }

  ${addStyleIfPropTruly('withMb', 'margin-bottom: 20px;')}
  ${addXsStyleIfPropTruly('withMb', 'margin-bottom: 8px;')}
  &:disabled {
    color: ${colors.pinkishGrey};
    -webkit-text-fill-color: ${colors.pinkishGrey};
    opacity: 1;

    &::placeholder {
      color: ${colors.pinkishGrey};
    }

    &:hover {
      background-color: ${colors.paleGrey};
    }
  }
`;

export default TextareaAutosize;
