import dayjs from 'dayjs';
import * as locale from 'dayjs/locale/ru';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import { IDeliveryDate } from 'Modules/Shops/types';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.locale(locale);

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(isSameOrBefore);

const DAYJS_FORMAT_DAY_MONTH_TIME = 'D MMMM, HH:mm';
const DAYJS_FORMAT_HOURS_AND_MINUTE = 'HH:mm';
const DAYJS_FORMAT_DAY_MONTH = 'DD.MM';
const DAYJS_FORMAT_FOMAT_FULL_DATE = 'DD.MM.YYYY';
const removeTimeZone = (date: string) => date.slice(0, -6);
const getFormatForDate = (date: Date) => {
  if (dayjs(date).isToday()) {
    return 'HH:mm[, cегодня]';
  }

  if (dayjs(date).isTomorrow()) {
    return 'HH:mm[, завтра]';
  }

  if (dayjs(date).isYesterday()) {
    return 'HH:mm[, вчера]';
  }

  return DAYJS_FORMAT_DAY_MONTH_TIME;
};

export const formatDateToMonth = (date: string) => {
  const format = getFormatForDate(new Date(date));
  return dayjs(removeTimeZone(date)).format(format);
};

export const toTimeHRDate = (date: string) => dayjs(removeTimeZone(date)).format(DAYJS_FORMAT_HOURS_AND_MINUTE);
export const toDateHRDate = (date: string) => dayjs(removeTimeZone(date)).format(DAYJS_FORMAT_DAY_MONTH);

interface IDayjsRelativeFormat {
  sameDay: string;
  nextDay: string;
  dayAfterTomorrow: string;
  lastDay: string;
  sameElse: string;
  nextWeek: string;
  lastWeek: string;
}

const shortRelativeFormat: IDayjsRelativeFormat = {
  sameDay: 'Сегодня',
  lastDay: 'Вчера',
  nextDay: 'Завтра',
  dayAfterTomorrow: 'D MMMM',
  nextWeek: 'D MMMM',
  lastWeek: 'D MMMM',
  sameElse: 'D MMMM',
};

const longRelativeFormat: IDayjsRelativeFormat = {
  sameDay: '[Сегодня,] D MMMM',
  nextDay: '[Завтра,] D MMMM',
  dayAfterTomorrow: '[Послезавтра,] D MMMM',
  lastDay: '[Вчера,] D MMMM',
  lastWeek: '[На прошлой неделе,] D MMMM',
  sameElse: 'DD[.]MM[.]YYYY',
  nextWeek: 'dddd, D MMMM',
};

const monthNamePrepositional = [
  'январе',
  'феврале',
  'марте',
  'апреле',
  'мае',
  'июне',
  'июле',
  'августе',
  'сентябре',
  'октябре',
  'ноябре',
  'декабре',
];
const dateRelativeFormat = (date: string, relativeFormat: IDayjsRelativeFormat): string => {
  const dayJS = dayjs(date);
  const diff = dayJS.diff(dayjs().startOf('day'), 'day', true);
  const key =
    diff < -6
      ? 'sameElse'
      : diff < -1
      ? 'lastWeek'
      : diff < 0
      ? 'lastDay'
      : diff < 1
      ? 'sameDay'
      : diff < 2
      ? 'nextDay'
      : diff < 3
      ? 'dayAfterTomorrow'
      : diff < 7
      ? 'nextWeek'
      : 'sameElse';
  return dayJS.format(relativeFormat[key || 'sameElse']);
};

export const formattedDay = (date: string): string => dayjs(date).format('D MMMM');
export const relativeDateTime = (date: string): string => dayjs(date).format('dd, HH:mm');
export const dayMonthFormat = (date: string): string => dayjs(date).format('D MMMM');
export const shortRelativeDate = (date: string): string =>
  dateRelativeFormat(removeTimeZone(date), shortRelativeFormat);
export const longRelativeDate = (date: string): string => dateRelativeFormat(removeTimeZone(date), longRelativeFormat);
export const timeFormatted = (date: string): string => `${dayjs(removeTimeZone(date)).format('HH:mm')}`;
export const timePeriod = (start: string, end?: string): string => {
  return end ? `${timeFormatted(start)} – ${timeFormatted(end)}` : `${timeFormatted(start)}`;
};

export const formatDeliveryDate = ({ date, end_date }: IDeliveryDate) => {
  return `${shortRelativeDate(date)}, ${timePeriod(date, end_date)}`;
};

export const dateInDDMMFormat = (dateTime: string) => {
  const date = new Date(dateTime);
  const dd = date.getDate();
  const mm = date.getMonth() + 1;

  return `${dd < 10 ? '0' + dd : dd}.${mm < 10 ? '0' + mm : mm}`;
};

export const getCurrentMonthNamePrepositional = () => {
  const month = new Date().getMonth();
  return monthNamePrepositional[month];
};

export const formatFullDate = (date: string | number): string => dayjs(date).format(DAYJS_FORMAT_FOMAT_FULL_DATE);

export const getMonth = (date: Date) => dayjs(date).get('month');
export const getYear = (date?: Date) => dayjs(date).get('year');
export const getDay = (date?: Date) => dayjs(date).get('date');

export const getFullCurrentDate = () => {
  const date = new Date();

  return dayjs(date).format('YYYY-MM-DD HH:mm:ss ZZ');
};

export const getCurrentDate = () => {
  const date = new Date();

  return dayjs(date).format('YYYY-MM-DD');
};

export const getDaysTo = (deadline: string) => dayjs(deadline).diff(new Date(), 'day');
