import { EnumAFEvents } from 'constants/enums';
import { FbqEventsEnum, fbqTrack } from 'utils/fbq';
import { TagManagerGoToCheckoutFinishPage } from 'utils/tagManager';
import analyticsManager from '../tagManager/manager';
import { EnumYaCounters, ymReachGoal } from 'lib/yandexMetrics';
import { ICheckoutOrder } from './types';
import { EnumTopMailGoals, topMailReachGoal } from 'utils/topMail';

export default function checkoutSuccessAnalytic(order: ICheckoutOrder): void {
  const { id, products, total_price } = order;
  TagManagerGoToCheckoutFinishPage(id, products);

  analyticsManager.af(EnumAFEvents.AF_PURCHASE, {
    af_order_id: String(id),
    af_price: total_price,
  });

  order.first_order &&
    analyticsManager.af(EnumAFEvents.AF_FIRST_PURCHASE, {
      af_order_id: String(id),
      af_price: total_price,
    });

  fbqTrack(FbqEventsEnum.Purchase, {
    value: total_price,
    currency: 'RUB',
  });

  ymReachGoal(EnumYaCounters.purchase);
  topMailReachGoal(EnumTopMailGoals.purchase);

  if (order.first_order) {
    ymReachGoal(EnumYaCounters.first_purchase);
    topMailReachGoal(EnumTopMailGoals.first_purchase);
  }
}
