import { PaymentTypes } from '../Checkout/types';
import { pickBy } from 'ramda';
import { IPaymentTypes } from 'Modules/Shops/types';

const BASE_PAYMENT_TYPES = ['online', 'card', 'cash', 'shipment_on_credit'];

export const getAvailablePaymentTypesList = (types?: IPaymentTypes) => {
  const availablePaymentTypes =
    types &&
    Object.keys(pickBy(val => val === true, types)).filter(
      item => item !== PaymentTypes.google_pay && item !== PaymentTypes.apple_pay
    );

  return availablePaymentTypes?.length ? availablePaymentTypes : BASE_PAYMENT_TYPES;
};
