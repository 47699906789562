import React from 'react';
import Icon, { IIcon } from './Icon';

const SocialVKIcon: React.FC<IIcon> = props => (
  <Icon width="18" height="11" viewBox="0 0 18 11" {...props}>
    <path d="M17.5458 0.928571C17.5458 0.928571 17.4208 0.571428 16.644 0.669642L14.0725 0.6875C13.8761 0.660714 13.7333 0.741071 13.7333 0.741071C13.7333 0.741071 13.5815 0.821428 13.51 1.01786C13.0904 2.09821 12.5547 3.02679 12.5547 3.02679C11.4118 4.97321 10.9475 5.08036 10.76 4.95536C10.3225 4.67857 10.4386 3.83036 10.4386 3.22321C10.4386 1.33036 10.7243 0.544642 9.87612 0.339285C9.59933 0.276785 9.39397 0.232142 8.67076 0.223214C7.75112 0.214285 6.97433 0.223214 6.53683 0.4375C6.24219 0.580357 6.01897 0.901785 6.1529 0.919642C6.32255 0.946428 6.70647 1.02678 6.91183 1.30357C6.91183 1.30357 7.13505 1.66964 7.17076 2.47321C7.26897 4.69643 6.81362 4.97321 6.81362 4.97321C6.4654 5.16071 5.85826 4.84821 4.9654 3.00893C4.9654 3.00893 4.44755 2.09821 4.04576 1.09821C3.97433 0.910714 3.83147 0.8125 3.83147 0.8125C3.83147 0.8125 3.67076 0.6875 3.43862 0.651785L0.992188 0.669642C0.617188 0.669642 0.483259 0.839285 0.483259 0.839285C0.483259 0.839285 0.34933 0.982142 0.47433 1.27678C2.38504 5.76786 4.56362 8.01786 4.56362 8.01786C4.56362 8.01786 6.55469 10.0982 8.81362 9.95536H9.8404C10.1529 9.92857 10.3136 9.75893 10.3136 9.75893C10.3136 9.75893 10.4565 9.59821 10.4475 9.30357C10.4297 7.92857 11.0725 7.72321 11.0725 7.72321C11.6975 7.52679 12.5011 9.05357 13.3583 9.64286C13.3583 9.64286 14.01 10.0893 14.5011 9.99107L16.7868 9.95536C17.9922 9.94643 17.4208 8.94643 17.4208 8.94643C17.3761 8.86607 17.0904 8.25 15.7154 6.97321C14.2868 5.64286 14.4743 5.85714 16.2065 3.55357C17.26 2.15179 17.6797 1.29464 17.5458 0.928571Z" />
  </Icon>
);

export default SocialVKIcon;
