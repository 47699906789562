import React, { FC } from 'react';
import { IBox } from 'Components/Grid/Box';
import logo from './img/komusLogo.svg';
import { BoxProps } from '@igooods/ui-kit';
import { LogoIconContainer } from './styles';

interface ILogoIcon extends IBox, Omit<BoxProps, 'theme'> {
  onClick?: () => void;
  className?: string;
}

const LogoIcon: FC<ILogoIcon> = props => {
  return (
    <LogoIconContainer {...props}>
      <img src={logo} alt="komus logo" />
    </LogoIconContainer>
  );
};

export default LogoIcon;
