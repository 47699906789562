import { defaultTheme } from 'theme';
import { getMediaQuery, mediaQueryDevices } from '../theme/helpers/css';

export const HIDE_FOR_KOMUS = true;
export const MIN_LENGTH_SEARCH_QUERY = 3;

export const metaPageDescription =
  'Заказывайте в Komus Pitanie с доставкой на дом продукты и товары по лучшей цене из METRO, Лента, Ашан, Super Babylon. Квалифицированные сотрудники igooods выберут для Вас самые свежие продукты и качественные товары, аккуратно привезут их в удобное для Вас время.';

export const EMPTY_STRING = '';
export const DEFAULT_CATEGORY_ID = 0;
export const EMPTY_FUNC = Function.prototype;

export const EMPTY_ARRAY = [];

export const TABBAR_ITEM_MARGIN = 16;

export const GAP_STRING = ' ';
export const NUMBER_RGXP = /\d/;

export const NUMBER_OF_ITEMS_IN_SEARCH_HISTORY_LIST = 5;

export const RUB = '₽';
export const ZERO = '0';
export const DOUBLE_ZERO = '00';
export const COMMA = ',';
export const DOT = '.';
export const MINUS = '-';
export const NBSP = `\u00A0`;
export const NUMBER_SIGN = '\u2116';

export const APARTMENNT_HINT = ' кв.';
export const BUILDING_HINT = ' д.';

export const DECLENSION_PRODUCT = ['товар', 'товара', 'товаров'];
export const DECLENSION_POINTS = ['балл', 'балла', 'баллов'];
export const DECLENSION_SHOPS = ['магазин', 'магазина', 'магазинов'];
export const DECLENSION_AVAILABLE = ['Доступен', 'Доступно', 'Доступно'];

export const INVITE_URL = 'igooods.ru/invite';
export const SHARE_BENEFITS =
  'Вы получите 250 баллов за первый заказ каждого друга (1 балл = 1 ₽ ), а друзья получат первую доставку за 99 ₽';
export const SHARE_TITLE =
  'Используйте мой промокод #PROMO# и получите первую доставку продуктов всего за 99 рублей. Избавьте себя от похода в магазин и общайтесь с друзьями!';

export const TELEGRAM_LINK = 'https://teleg.run/igooodssupportbot';
export const MOBILE_APP_LINK = 'https://go.onelink.me/D6v8/3b5db707';
export const MAIL_SUPPORT_LINK = 'yest@komus.net';

export const FAKE_CITY_ID = 666;
export const FAKE_SA_REQUEST = 'где рома?';
export const FAKE_CITY = {
  id: FAKE_CITY_ID,
  name: FAKE_SA_REQUEST,
  phone: FAKE_CITY_ID.toString(),
  pickup_enable: true,
  coordinate: {
    latitude: 53.243325,
    longitude: 34.363731,
  },
  declensions: {
    dative: 'скучаешь по роме?',
    genitive: 'не хватает ромы?',
    nominative: 'очень нужен рома?',
    prepositional: 'все время думаешь о роме?',
  },
};

export const MOBILE_MEDIA_QUERY = `all and (max-width: ${defaultTheme.breakpoints.lg}px)`;
export const SM_DOWN_MEDIA_QUERY = `all and (max-width: ${defaultTheme.breakpoints.sm - 1}px)`;

export const MOBILE_MEDIA = getMediaQuery(mediaQueryDevices.mobile);
export const DESKTOP_MEDIA = getMediaQuery(mediaQueryDevices.desktop);

export const UPDATE_USER_MESSAGE = 'UPDATE_USER_MESSAGE';
export const UPDATE_CART_MESSAGE = 'UPDATE_CART_MESSAGE';

export const INITIAL_CITIES = {
  list: [],
  total: 0,
};

export const CUSTOM_MEDIA_SIZES = {
  smartphone: 560,
};

export const INPUT_PHONE_PLACEHOLDER = '+7(___)___-__-__';

export const COMPANY_NAME = 'igooods';
export const ENTITY_INDIVIDUAL = 'ИП';
export const DEFAULT_USER_NAME = 'Пользователь';

export const DYNAMIC_PRICES_AB_TEST_NAME = 'dynamic_price_test_1';

export const MAIN_DOMAIN = 'https://igooods.ru';

export const SUPPORT_BUTTON_CLASSNAME = 'uw__messenger-layout__buttons';
