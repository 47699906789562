import { IProfileResponse, IUpdateProfileData } from 'Modules/AAA/types';
import { IEntity, IEntityInfo } from 'Modules/Entity/types';
import { Dispatch, ThunkAction } from 'reduxStore/types';
import { EnumEntityStatus } from '../../constants/enums';
import EntityHttp, { fetchEntityInfo } from './http';
import { setCurrentUser } from 'Modules/AAA/actions';

export enum EntityActionTypes {
  SET_ENTITY = 'SET_ENTITY',
  SET_ENTITY_STATUS = 'SET_ENTITY_STATUS',
}

export interface ISetEntityAction {
  type: EntityActionTypes;
  payload: IEntity;
}

export interface ISetEtnityStatusAction {
  type: EntityActionTypes;
  payload: EnumEntityStatus;
}

export function setEntityAction(payload: IEntity): ISetEntityAction {
  return {
    type: EntityActionTypes.SET_ENTITY,
    payload,
  };
}

export function setEntityStatusAction(payload: EnumEntityStatus): ISetEtnityStatusAction {
  return {
    type: EntityActionTypes.SET_ENTITY_STATUS,
    payload,
  };
}

export default {
  createEntity: (authData: IUpdateProfileData): ThunkAction => async (
    dispatch: Dispatch
  ): Promise<IProfileResponse> => {
    return EntityHttp.createEntity(authData).then(data => {
      dispatch(setCurrentUser(data.user));
      return data;
    });
  },

  getEntity: (): ThunkAction => async (dispatch: Dispatch): Promise<IEntityInfo> => {
    return fetchEntityInfo().then(data => {
      dispatch(setEntityAction(data));
      return data;
    });
  },
};
