import { EnumNotification } from 'constants/enums';

export enum StaticNotificationAction {
  SET_NOTIFICATION = 'SET_STATIC_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_STATIC_NOTIFICATION',
}

export interface IStaticNotificationPayload {
  text: string;
  buttonText?: string;
  onClick?: () => void;
}

export interface IStaticNotificationAction {
  type: string;
  payload: IStaticNotificationPayload;
  theme: EnumNotification;
  index?: number;
}

export const setStaticNotificationAction = (
  payload: IStaticNotificationPayload,
  theme: EnumNotification = EnumNotification.danger
): IStaticNotificationAction => ({
  type: StaticNotificationAction.SET_NOTIFICATION,
  payload,
  theme,
});

export const removeStaticNotificationAction = (index: number): Partial<IStaticNotificationAction> => ({
  type: StaticNotificationAction.REMOVE_NOTIFICATION,
  index,
});

export default {
  setStaticNotificationAction,
  removeStaticNotificationAction,
};
