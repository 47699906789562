import styled, { DefaultTheme } from 'styled-components';
import { SizeToHeightMobile, SizeToHeight, SizeToFs, SizeToBr, SizeToPadding } from './index';
import { Size } from 'constants/types';
import { mediaSmDown } from '../../theme/helpers/css';
import { EnumSize } from 'constants/enums';

interface IAsteriskPlaceholder {
  onClick: () => void;
  $sizeName?: Size;
  theme: DefaultTheme;
}

const AsteriskPlaceholder = styled.div<IAsteriskPlaceholder>`
  position: absolute;
  top: 0;
  color: #999999;
  height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  cursor: text;

  &:after {
    content: '*';
    padding-left: 5px;
    color: #ff3377;
    font-style: inherit;
  }
  ${({ $sizeName = EnumSize.md }: IAsteriskPlaceholder) => `
    border-radius: ${SizeToBr[$sizeName]}};
    padding: 0 ${SizeToPadding[$sizeName]};
    height: ${SizeToHeight[$sizeName]};
    font-size: ${SizeToFs[$sizeName]};
  `}
  ${mediaSmDown`
    font-size: 12px;
    ${({ $sizeName = EnumSize.md }: IAsteriskPlaceholder) => `
      height: ${SizeToHeightMobile[$sizeName]};
    `}
  `}
`;

export default AsteriskPlaceholder;
