import { useState, useCallback } from 'react';

type TUseModalState = (
  isOpen?: boolean
) => {
  isVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
  toggleModal: () => void;
};

const useModalState: TUseModalState = (isOpen = false) => {
  const [isVisible, setVisible] = useState<boolean>(isOpen);
  const showModal = useCallback(() => setVisible(true), []);
  const hideModal = useCallback(() => setVisible(false), []);
  const toggleModal = useCallback(() => setVisible(!isVisible), [isVisible]);

  return { isVisible, showModal, hideModal, toggleModal };
};

export default useModalState;
