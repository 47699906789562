import { css, DefaultTheme } from 'styled-components';
import { getBreakpoints } from '../selectors';
import { defaultTheme } from '../index';
import { EnumDeviceOrientation } from 'constants/enums';

export const mediaSmDown = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { sm } = getBreakpoints(theme);

  return css`
    @media (max-width: ${sm - 1}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaSmUp = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { sm } = getBreakpoints(theme);

  return css`
    @media (min-width: ${sm}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const hideBeforeSm = mediaSmDown`display: none`;
export const hideAfterSm = mediaSmUp`display: none`;

export const mediaMdDown = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { md } = getBreakpoints(theme);

  return css`
    @media (max-width: ${md - 1}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaMdUp = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { md } = getBreakpoints(theme);

  return css`
    @media (min-width: ${md}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaLgDown = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { lg } = getBreakpoints(theme);

  return css`
    @media (max-width: ${lg - 1}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaLgUp = (...args: any[]) => ({ theme }: { theme: DefaultTheme }) => {
  const { lg } = getBreakpoints(theme);

  return css`
    @media (min-width: ${lg}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaXlDown = (...args: any[]) => () => {
  return css`
    @media (max-width: 1599px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaXlUp = (...args: any[]) => () => {
  return css`
    @media (min-width: 1600px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaCustomDown = (width: number) => (...args: any[]) => {
  return css`
    @media (max-width: ${width - 1}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaCustomUp = (width: number) => (...args: any[]) => {
  return css`
    @media (min-width: ${width}px) {
      ${(css as any)(...args)}
    }
  `;
};

export const mediaLandscape = (...args: any[]) => {
  return css`
    @media screen and (min-device-aspect-ratio: 1/1) and (min-aspect-ratio: 13/9) {
      ${(css as any)(...args)}
    }
  `;
};

enum EnumMediaOptions {
  minWidth = 'min-width',
  maxWidth = 'max-width',
  orientation = 'orientation',
  maxHeight = 'max-height',
  minHeight = 'min-height',
}

interface IMediaOptions {
  [EnumMediaOptions.minWidth]?: number;
  [EnumMediaOptions.maxWidth]?: number;
  [EnumMediaOptions.orientation]?: EnumDeviceOrientation;
  [EnumMediaOptions.maxHeight]?: number;
  [EnumMediaOptions.minHeight]?: number;
}

interface IMediaQueryDevices {
  desktop: Array<IMediaOptions>;
  mobile: Array<IMediaOptions>;
  betweenLgAndXl: Array<IMediaOptions>;
}

export const mediaQueryDevices: IMediaQueryDevices = {
  desktop: [
    {
      [EnumMediaOptions.minWidth]: defaultTheme.breakpoints.sm,
      [EnumMediaOptions.minHeight]: defaultTheme.breakpoints.sm,
    },
    {
      [EnumMediaOptions.minWidth]: defaultTheme.breakpoints.md,
    },
  ],
  mobile: [
    {
      [EnumMediaOptions.maxWidth]: defaultTheme.breakpoints.sm - 1,
    },
    {
      [EnumMediaOptions.maxWidth]: defaultTheme.breakpoints.md - 1,
      [EnumMediaOptions.maxHeight]: defaultTheme.breakpoints.sm - 1,
    },
  ],
  betweenLgAndXl: [
    {
      [EnumMediaOptions.maxWidth]: 1599,
      [EnumMediaOptions.minWidth]: 1366,
    },
  ],
};

export const getMediaRule = (option: IMediaOptions) =>
  Object.keys(option)
    .map(key => {
      switch (key) {
        case EnumMediaOptions.minWidth:
          return `(${EnumMediaOptions.minWidth}: ${option[key]}px)`;
        case EnumMediaOptions.maxWidth:
          return `(${EnumMediaOptions.maxWidth}: ${option[key]}px)`;
        case EnumMediaOptions.orientation:
          return `(${EnumMediaOptions.orientation}: ${option[key]})`;
        case EnumMediaOptions.maxHeight:
          return `(${EnumMediaOptions.maxHeight}: ${option[key]}px)`;
        case EnumMediaOptions.minHeight:
          return `(${EnumMediaOptions.minHeight}: ${option[key]}px)`;
      }
    })
    .join(' and ');

export const getMediaQuery = (options: Array<IMediaOptions>) => options.map(option => getMediaRule(option)).join(', ');

export const getMediaForOptions = (options: Array<IMediaOptions>, ...args: any[]) =>
  css`
    @media ${getMediaQuery(options)} {
      ${(css as any)(...args)}
    }
  `;

export const mediaMobile = (...args: any[]) => getMediaForOptions(mediaQueryDevices.mobile, ...args);
export const mediaDesktop = (...args: any[]) => getMediaForOptions(mediaQueryDevices.desktop, ...args);
export const mediaBetweenLgAndXl = (...args: any[]) => getMediaForOptions(mediaQueryDevices.betweenLgAndXl, ...args);

export const coverParent = `
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const stretchParent = `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const inEDGE = (...args: any[]) => () => {
  return css`
    @supports (-ms-ime-align: auto) {
      ${(css as any)(...args)}
    }
  `;
};

// возможно когда-нибудь решение перестанет работать =)
export const onSafari = (...args: any[]) => () => {
  return css`
    @supports (-webkit-touch-callout: none) {
      ${(css as any)(...args)}
    }
  `;
};

export const onRetina = (...args: any[]) => () => {
  return css`
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (min--moz-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 2.6/2),
      only screen and (min-device-pixel-ratio: 1.3),
      only screen and (min-resolution: 124.8dpi),
      only screen and (min-resolution: 1.3dppx) {
      ${(css as any)(...args)}
    }
  `;
};
