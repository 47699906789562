import React from 'react';
import Icon, { IIcon } from './Icon';

const UserIcon: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M9.04311 5.84717C8.02226 7.55062 8.5251 9.67154 10.1662 10.5844C11.8074 11.4972 13.9653 10.8563 14.9862 9.15283C16.007 7.44938 15.5042 5.32846 13.8631 4.41563C12.2219 3.50279 10.064 4.14372 9.04311 5.84717Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 18H5C5.12559 17.7181 5.27465 17.4402 5.44776 17.1685C7.30818 14.2483 11.2409 13.1496 14.2318 14.7145C15.6361 15.4492 16.5831 16.6405 17 18Z"
      fill="currentColor"
    />
    <path
      d="M17 18V18.85C17.2696 18.85 17.5232 18.7221 17.6835 18.5053C17.8438 18.2885 17.8917 18.0085 17.8126 17.7508L17 18ZM5 18L4.22359 17.654C4.10642 17.917 4.13031 18.2214 4.28706 18.4628C4.44381 18.7043 4.71213 18.85 5 18.85V18ZM5.44776 17.1685L4.73088 16.7118H4.73088L5.44776 17.1685ZM14.2318 14.7145L14.6258 13.9613V13.9613L14.2318 14.7145ZM17 17.15H5V18.85H17V17.15ZM4.73088 16.7118C4.53544 17.0186 4.36642 17.3335 4.22359 17.654L5.77641 18.346C5.88475 18.1028 6.01386 17.8619 6.16463 17.6252L4.73088 16.7118ZM14.6258 13.9613C11.2281 12.1835 6.81876 13.4346 4.73088 16.7118L6.16463 17.6252C7.79761 15.0621 11.2538 14.1156 13.8377 15.4676L14.6258 13.9613ZM17.8126 17.7508C17.3293 16.1747 16.2319 14.8016 14.6258 13.9613L13.8377 15.4676C15.0403 16.0968 15.8369 17.1064 16.1874 18.2492L17.8126 17.7508Z"
      fill="currentColor"
    />
  </Icon>
);

export default UserIcon;
