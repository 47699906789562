import React from 'react';
import Header from 'Components/landings/Header';
import Footer from 'Components/landings/FooterWithSeoLinks';
import BlockContainer from 'Components/landings/BlockContainer';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { useServerData } from 'serverDataContext';
import { useRequest } from 'swr.config';
import { IProductsGroup } from 'Modules/Shops/types';
import { URL } from 'constants/urlMaps';
import fetchDataOnServer from './index.server';
import { dataForMetaTags } from './utils';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import FullPageLoading from 'Containers/Loading/FullPageLoading';

const PersonalDataPage = loadable(() => import(/* webpackChunkName: "PersonalData" */ 'Containers/PersonalData'), {
  fallback: <FullPageLoading />,
});

export const landingURLs = [URL.personal_data];

export const SSR_KEY = 'LandingPages';

export const LandingPages: React.FC = () => {
  const city = useSelector(getCurrentCity);
  const dataFromServer = useServerData(SSR_KEY, [city.id]);

  const { data: groups } = useRequest<IProductsGroup[]>([URL.shops_groups, { city_id: city.id }], {
    initialData: dataFromServer?.groups,
  });

  const { title, content_description, content_title } = dataForMetaTags({ groups, city });

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta content={content_description} name="description" />
        <meta content={content_description} property="og:description" />
        <meta content={content_description} name="twitter:description" />
        <meta content={content_title} name="twitter:title" />
        <meta content={title} name="og:title" />
      </Helmet>
      <Header />

      <Switch>
        <Route path={URL.personal_data} component={PersonalDataPage} />
      </Switch>

      <BlockContainer>
        <Footer />
      </BlockContainer>
    </>
  );
};

LandingPages.fetchOnServer = fetchDataOnServer;

export default LandingPages;
