import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  memo,
  MutableRefObject,
  RefAttributes,
  RefForwardingComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EnumSize, InputFormat, InputState } from 'constants/enums';
import { formatValueByType } from './utils';
import styled, { css } from 'styled-components';
import { InputFormatType, Size } from 'constants/types';
import Text from 'Components/Typography/Text';
import AsteriskPlaceholder from './asterisk';
import { EMPTY_STRING } from 'constants/constants';
import { FontFamily, FontSize, FontWeight, Transitions } from 'theme';
import { colors } from 'constants/colors';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { getTypographyFontStyleMobile } from 'theme/selectors';
import { addStyleIfPropTruly, addXsStyleIfPropTruly } from 'utils/styledComponents';
import CloseIcon from '../Icon/CloseIcon';

export const SizeToFs = {
  lg: '16px',
  md: '14px',
  sm: '12px',
  xs: '12px',
};

export const SizeToBr = {
  lg: '4px',
  md: '4px',
  sm: '3px',
  xs: '2px',
};

export const SizeToPadding = {
  lg: '16px',
  md: '12px',
  sm: '8px',
  xs: '8px',
};

export const SizeToError = {
  lg: '8px',
  md: '8px',
  sm: '8px',
  xs: '8px',
};

export const SizeToHeight = {
  lg: '50px',
  md: '40px',
  sm: '30px',
  xs: '25px',
};

export const SizeToHeightMobile = {
  lg: '35px',
  md: '35px',
  sm: '30px',
  xs: '25px',
};

interface ISizable {
  $sizeName: Size;
}

export interface IInput extends ISizable {
  invalid?: boolean;
  withClear?: boolean;
  withoutActiveStyles?: boolean;
}

const InputCloseWrapper = styled.span<ISizable>`
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.m)}
    ${({ $sizeName }: IInput) => `
      height: ${SizeToHeightMobile[$sizeName]};
    `}
  `}
`;

interface IInputMsg {
  $error?: boolean;
  $sizeName?: Size;
}

export const InputMsg = styled(Text).attrs({ size: FontSize.xs })<IInputMsg>`
  display: block;
  color: ${({ $error }) => ($error ? colors.primaryAction : colors.dark100)};
  padding-top: 2px;

  ${({ $sizeName = EnumSize.md }: IInputMsg) => `
    padding-left: ${SizeToPadding[$sizeName]};
  `}

  ${mediaSmUp`
    top: 100%;
  `}
`;

const InputMsgError = styled(Text).attrs({ size: FontSize.xs })<IInputMsg>`
  display: block;
  color: ${colors.primaryAction};
  padding-top: 2px;
  padding-left: 8px;
  line-height: 14px;

  ${mediaSmUp`
    top: 100%;
  `}
`;

export const StyledInput = styled.input<IInput>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* фиксит ебалу с символами типа g,@ и других, у которых есть выносные части которые обрезаются при определенном размере шрифта */
  text-rendering: optimizeLegibility;
  width: 100%;
  transition: ${Transitions.bg};
  /* фиксит баг на мобильном сафари, когда инпут начинает елозить при скролле, если у его родителя position: relative */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform: translate3d(0, 0, 0);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${colors.dark100};
    font-family: ${FontFamily.mainFont};
    font-weight: ${FontWeight.normal};
    font-size: 14px;
  }

  &:focus {
    ${({ withoutActiveStyles }) =>
      !withoutActiveStyles
        ? css`
            background-color: ${colors.white};
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);
          `
        : null}

    &::placeholder {
      color: ${colors.dark100};
    }

    &::-ms-clear {
      display: none;
    }
  }

  &:disabled {
    color: ${colors.dark100};
    -webkit-text-fill-color: ${colors.dark100};
    opacity: 1;

    &::placeholder {
      color: ${colors.dark100};
    }

    &:hover {
      background-color: ${colors.paleGrey};
    }
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ $sizeName, invalid, withClear }) => `
    border-radius: ${SizeToBr[$sizeName]}};
    padding: 0 ${SizeToPadding[$sizeName]};
    height: ${SizeToHeight[$sizeName]};
    background-color: ${invalid ? colors.danger100 : colors.light200};
    font-size: ${SizeToFs[$sizeName]};
    padding-right: ${withClear ? '40px' : 0};
    font-family: ${FontFamily.mainFont}
  `}

  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.m)}
    ${({ $sizeName }: IInput) => `
      height: ${SizeToHeightMobile[$sizeName]};
    `}
  `}
`;

interface IInputContainer {
  withMb?: boolean;
  withoutActiveStyles?: boolean;
}

export const InputContainer = styled.div<IInputContainer>`
  position: relative;
  height: inherit;
  /* фиксит баг на мобильном сафари, когда инпут начинает елозить при скролле, если у его родителя position: relative */
  -webkit-transform: translate3d(0, 0, 0);

  ${({ withoutActiveStyles }) =>
    !withoutActiveStyles
      ? css`
          &:hover ${StyledInput}:not(:focus):not(:disabled) {
            background-color: ${colors.silverFive};
          }
        `
      : null}

  ${addStyleIfPropTruly('withMb', 'margin-bottom: 20px;')}
  ${addXsStyleIfPropTruly('withMb', 'margin-bottom: 8px;')}
`;

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement>, RefAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  sizeName?: Size;
  onFocusInput?: () => void;
  onBlurInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDownInput?: (event: KeyboardEvent) => void;
  onChangeInput?: (value: string) => void;
  className?: string;
  wrapperClassName?: string;
  checkIsValid?: () => { result: boolean; message: string };
  value?: string;
  mask?: any;
  withClear?: boolean;
  format?: InputFormatType;
  errorMsg?: JSX.Element | string;
  defaultMsg?: JSX.Element | string;
  testId?: string;
  inputRef?: (value: MutableRefObject<any>) => void;
  width?: string;
  withMb?: boolean;
  readOnly?: boolean;
  withoutActiveStyles?: boolean;
  type?: string;
  pattern?: string;
  inputMode?: string;
}

const InputComponent: RefForwardingComponent<any, IInputProps> = (
  {
    placeholder = '',
    required,
    disabled = false,
    sizeName = EnumSize.md,
    className,
    wrapperClassName,
    onChangeInput = () => null,
    onFocusInput = () => null,
    onBlurInput = () => null,
    onKeyDownInput = () => null,
    defaultValue = EMPTY_STRING,
    withClear = true,
    format = InputFormat.default,
    errorMsg = '',
    defaultMsg = '',
    checkIsValid = () => ({ result: true, message: '' }),
    testId,
    inputRef,
    autoFocus,
    maxLength,
    width,
    withMb = false,
    withoutActiveStyles = false,
    readOnly,
    type,
    pattern,
    inputMode,
  },
  ref
) => {
  const inputEl: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [state, setInputState] = useState(InputState.Clean);
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [invalidMsg, setInvalidMsg] = useState<JSX.Element | string>(EMPTY_STRING);
  const [isFocused, setFocus] = useState(false);
  // TODO Jira: https://igooods.atlassian.net/browse/FS-162
  const setRefs = (value: HTMLInputElement) => {
    inputEl.current = value;
    inputRef && inputRef(inputEl);

    if (!ref) return;

    if (typeof ref === 'function') {
      ref(value);
    } else {
      (ref as MutableRefObject<any>).current = value;
    }
  };

  const focusInput = useCallback(() => inputEl.current && inputEl.current.focus(), []);

  const isPhone = format === InputFormat.tel;
  const isInn = format === InputFormat.inn;

  const handleFocus = () => {
    if (state === InputState.Clean) setInputState(InputState.CleanBlur);
    if (state === InputState.Invalid) setInputState(InputState.DirtyInvalid);
    setInputState(InputState.Dirty);
    setInvalidMsg(EMPTY_STRING);
    onFocusInput();
    setFocus(true);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!value.length && inputValue) {
      setInputState(InputState.ClearedFocus);
    }

    const rawValue = formatValueByType(value, format);
    setInputValue(rawValue);
    onChangeInput(rawValue);
    if (state === InputState.CleanFocus) setInputState(InputState.Dirty);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (state === InputState.CleanFocus) setInputState(InputState.CleanBlur);
    const { result, message } = checkIsValid();
    if (result) {
      setInputState(InputState.Valid);
    } else if (!result && typeof result !== 'undefined') {
      setInputState(InputState.Invalid);
      setInvalidMsg(message);
    }
    onBlurInput(e);
    setFocus(false);
  };
  const handleClear = useCallback(() => {
    setInputValue(EMPTY_STRING);
    onChangeInput(EMPTY_STRING);
    focusInput();
  }, [focusInput, onChangeInput]);

  const handlePlaceholderClick = useCallback(() => {
    if (inputValue.length === 0 && inputEl.current && document.activeElement !== inputEl.current) focusInput();
  }, [focusInput, inputValue]);

  useEffect(() => setInputValue(defaultValue), [defaultValue]);
  useEffect(() => {
    if (errorMsg || errorMsg.length) {
      setInvalidMsg(errorMsg);
      setInputState(InputState.Invalid);
    } else {
      setInvalidMsg(EMPTY_STRING);
      setInputState(InputState.Clean);
    }
  }, [errorMsg]);
  const showValueWithPhoneCode = isPhone && (isFocused || inputValue);
  const isShowPlaceholder = isInn ? !isFocused && !inputValue.length : required && !inputValue.length;
  return (
    <InputContainer
      className={wrapperClassName}
      style={{ width }}
      withMb={withMb}
      withoutActiveStyles={withoutActiveStyles}
    >
      <StyledInput
        required={required}
        $sizeName={sizeName}
        data-testid={testId}
        ref={setRefs}
        type={type}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={onKeyDownInput}
        placeholder={required ? '' : placeholder}
        className={className}
        disabled={disabled}
        value={showValueWithPhoneCode ? `+7${inputValue}` : inputValue || ''}
        autoFocus={autoFocus}
        maxLength={maxLength}
        invalid={state === InputState.Invalid}
        withClear
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        readOnly={readOnly}
        withoutActiveStyles={withoutActiveStyles}
        pattern={pattern}
        inputMode={inputMode}
      />
      {isShowPlaceholder && !(isFocused && isPhone) && (
        <AsteriskPlaceholder $sizeName={sizeName} onClick={handlePlaceholderClick}>
          {placeholder}
        </AsteriskPlaceholder>
      )}

      {!invalidMsg && defaultMsg && <InputMsg data-testid={`${testId}--default`}>{defaultMsg}</InputMsg>}
      {invalidMsg && (
        <InputMsgError data-testid={`${testId}--error`} $sizeName={sizeName} $error>
          {invalidMsg}
        </InputMsgError>
      )}
      {!disabled && withClear && !!inputValue && (
        <InputCloseWrapper onClick={handleClear} $sizeName={sizeName}>
          <CloseIcon data-testid="input-clear" width="13" height="13" color={colors.warmGrey} />
        </InputCloseWrapper>
      )}
    </InputContainer>
  );
};

const Input = memo(forwardRef(InputComponent));
Input.displayName = 'Input';

export { Input };
export default Input;
