import React, { FC } from 'react';
import styled from 'styled-components';
import LogoOval from '../Icon/LogoOval';
import { Flex } from '@igooods/ui-kit';
import { EnumSpinnerSize } from 'constants/enums';
import { colors } from 'constants/colors';
import { bounce, parameters, marginXBySize } from './styled';

export const SPINNER_TEST_ID = 'SPINNER_TEST_ID';

export const Spin = styled(LogoOval)<{ $size?: EnumSpinnerSize }>`
  &:nth-child(1) {
    animation: ${bounce} 1.08s linear 0s infinite;
  }
  &:nth-child(2) {
    animation: ${bounce} 1.08s linear 0.36s infinite;
    ${({ $size }) => $size && `margin: 0 ${marginXBySize[$size]}px;`}
  }
  &:nth-child(3) {
    animation: ${bounce} 1.08s linear 0.72s infinite;
  }
`;

interface ISpinner {
  color?: string;
  className?: string;
  testId?: string;
  size?: EnumSpinnerSize;
}

const Spinner: FC<ISpinner> = ({
  size = EnumSpinnerSize.normal,
  color = colors.primaryMain,
  className,
  testId = SPINNER_TEST_ID,
}) => {
  const parametersBySize = parameters[size];
  return (
    <Flex className={className} data-testid={testId} center middle width="100%">
      <Spin {...parametersBySize} color={color} />
      <Spin {...parametersBySize} color={color} $size={size} />
      <Spin {...parametersBySize} color={color} />
    </Flex>
  );
};

export default Spinner;
