import styled from 'styled-components';
import Text from '../Typography/Text';
import { FontFamily, FontSize, FontWeight, Transitions } from 'theme';
import { colors } from 'constants/colors';
import ThemeButton from 'Components/ThemedButton';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import { Flex } from '@igooods/ui-kit';
import { addStyleIfPropTruly } from '../../utils/styledComponents';
import { mediaLgDown, mediaMdDown, mediaMobile, mediaSmDown } from '../../theme/helpers/css';
import BlockContainer from '../landings/BlockContainer';
import { IDropdownContent } from './types';
import Box from '../Grid/Box';
import { hideAfterMd, hideBeforeMd, hideBeforeSm } from 'theme/helpers';
import LinkWrapper from '../Link/LinkWrapper';
import CartIconNew from '../Icon/CartIconNew';
import Icon from '../Icon/Icon';
import ContactsModal from '../landings/Header/ContactsModal';
import { BUTTON_WIDTH } from '../AppMenuMobile/constants';

export const AUTH_BTN_TEST_ID = 'AUTH_BTN_TEST_ID';

//BACK_BUTTON

export const ChildrenContainer = styled.div`
  margin-left: 6px;
  margin-right: 4px;
`;

export const BackButtonStyled = styled(ThemeButton)`
  margin-left: 42px;

  span {
    display: flex;
    align-items: center;
  }

  ${mediaSmDown`
    margin: 0;

    ${Icon} {
      height: 10px;
    }
  `}
`;

//TEMP_USER_BUTTON

export const UserButton = styled(ThemeButton).attrs({
  use: EnumTheme.roundWhite,
  size: EnumButtonSize.small,
  testId: AUTH_BTN_TEST_ID,
})`
  &:hover {
    div::after {
      display: block;
    }
  }
`;

export const ButtonText = styled(Text).attrs({
  size: FontSize.xs,
})`
  position: relative;
  padding: 0 3px;
  z-index: 1;

  &::after {
    content: '';
    display: none;
    position: absolute;
    border-radius: 2.5px;
    height: 5px;
    width: 100%;
    left: 0;
    bottom: 3px;
    background-color: ${colors.light400};
    z-index: -1;
  }
`;

//USER_BUTTON

export const ProfileButton = styled(ThemeButton).attrs({
  use: EnumTheme.white,
  icon: true,
  circle: true,
})`
  width: 36px;
  height: 36px;
  overflow: hidden;
  margin-right: 11px;
  margin-left: 10px;
  &:hover {
    background-color: transparent;
  }
`;

export const PersonAvatar = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: ${Transitions.opacity};
  background-color: ${colors.titanWhite};

  ${({ image }) =>
    image &&
    `
      background-position: center;
      background-size: cover;
      background-image: url(${image});
    `}

  &:hover {
    opacity: 0.8;
  }
`;

export const UnconfirmedIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.watermelon};
  position: absolute;
  top: 7px;
  left: 7px;
`;

//HEADER_DROPDOWN

export const CloseBtn = styled(ThemeButton).attrs({ icon: true })`
  position: absolute;
  top: 40px;
  right: 24px;
  z-index: 10;

  ${mediaSmDown`
    top: 8px;
    right: 24px;
  `}
`;

export const DropdownContent = styled(Flex).attrs({
  width: '100%',
  column: true,
  middle: true,
})<IDropdownContent>`
  position: absolute;
  left: 0;
  overflow-y: auto;
  transition: ${Transitions.transform}, ${Transitions.opacity};
  background-color: ${colors.white};
  z-index: 0;
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  align-items: flex-start;

  ${({ $top }) => `
    top: calc(${$top} - 1px);
    max-height: calc(100vh - ${$top});
  `}

  ${addStyleIfPropTruly(
    '$isOpen',
    `
      transform: translateY(0);
      opacity: 1;
      z-index: 4;
      visibility: visible;
    `
  )}

  ${addStyleIfPropTruly(
    '$fullHeight',
    `
      height: 100vh;
    `
  )}

  ${mediaSmDown`
    padding-bottom: 50px;
    height: 100vh;
  `}
`;

export const Block = styled(BlockContainer)`
  height: 100%;
`;

export const ContentWrapper = styled(Flex).attrs({
  pt: { xs: 1, sm: 20 },
  px: { xs: 0.5, sm: 0 },
  pb: { xs: 10, sm: 20 },
  grow: true,
})`
  height: 100%;
`;

export const Content = styled(Flex)`
  flex: 1 1 auto;
`;

//FAVORITES_BUTTON

export const Container = styled(Box).attrs({ mr: 8, pb: 0.75 })`
  ${hideBeforeMd}
`;

//CART_BUTTON

export const Link = styled(LinkWrapper)`
  ${mediaMdDown`  
    margin-left: 15px;
    margin-right: -2px;
  `}
`;

export const CartButtonContainer = styled(Flex).attrs({
  grow: true,
  middle: true,
  end: true,
})`
  position: relative;
`;

export const Price = styled(Text).attrs({
  fontFamily: FontFamily.secondFont,
})`
  color: ${colors.dark300};
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  margin-left: 6px;
  line-height: 16px;

  ${hideBeforeMd}
`;

export const DiscountPrice = styled(Text).attrs({
  fontFamily: FontFamily.secondFont,
  fontWeight: FontWeight.semiBold,
})`
  text-align: right;
  white-space: nowrap;
  margin-left: 3px;
  line-height: 9px;
  font-size: 9px;
  text-decoration: line-through;
  color: ${colors.blackRussian};
  opacity: 32%;
  vertical-align: bottom;

  ${hideBeforeMd}
`;

export const CartIconDesktop = styled(CartIconNew)`
  padding-bottom: 4px;
  ${hideBeforeMd}
`;

export const PriceContainer = styled(Flex)`
  flex-direction: row;
  align-items: baseline;
`;

export const CartIconMobile = styled(CartIconNew)`
  position: relative;
  ${hideAfterMd}
`;

export const MobileIndicator = styled(Flex)`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${colors.apricotOrange};
  position: absolute;
  top: -4px;
  right: -8px;
  z-index: 1;
  ${hideAfterMd}
`;

//HEADER

export const StyledProfileBtn = styled(ProfileButton)`
  margin-right: -10px;
  margin-left: 21px;

  ${mediaMdDown`
    margin-right: -18px;
    margin-left: 15px;  
  `}

  ${hideBeforeMd};
`;

export const IconsWrapper = styled(Flex).attrs({
  middle: true,
  end: true,
  ml: { sm: true, md: 16, lg: true },
})`
  width: auto;
`;

export const ContentContainer = styled(Flex).attrs({
  fluid: true,
  middle: true,
  pr: { xs: 6, sm: 6, md: 9 },
  pl: { xs: 6, sm: 7.25, md: 9 },
})`
  ${mediaMobile`
    width: calc(100vw - ${BUTTON_WIDTH}px);
  `};
`;

export const LogoWrapper = styled.div`
  ${mediaMdDown`
    padding-top: 4px;
  `}
`;

export const DesktopProductSearch = styled(Flex)`
  ${mediaLgDown`
    width: 100%;
  `}
  ${hideBeforeMd};
`;
export const MobileProductSearch = styled.div`
  ${hideAfterMd};
`;

export const ApplicationButtonContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  margin-right: 32px;
  gap: 5px;
  cursor: pointer;
`;

export const ContactsButton = styled(ContactsModal)`
  margin: 0 20px;

  ${hideBeforeSm}
`;
