import { AnyAction } from 'redux';
import { IProduct } from 'Modules/Products/types';
import { ThunkAction, Dispatch, GetState } from 'reduxStore/types';
import { OperationTypes } from 'Modules/Cart/types';
import { setNotificationAction } from 'Modules/Notifications/actions';
import { EnumNotification } from 'constants/enums';
import { colors } from 'constants/colors';
import UserSetsHttp from './http';
import {
  ICreateUserSetRequest,
  IEditUserSetRequest,
  IUserSetEditor,
  IUserSetListItem,
  IUserSetProductUpdate,
} from './types';

export const SET_USER_SETS_LIST = 'SET_USER_SETS_LIST';
export const ADD_USER_SET_LIST_ITEM = 'ADD_USER_SET_LIST_ITEM';
export const EDIT_USER_SET_LIST_ITEM = 'EDIT_USER_SET_LIST_ITEM';
export const REMOVE_USER_SET_LIST_ITEM = 'REMOVE_USER_SET_LIST_ITEM';
export const TOGGLE_EDITOR = 'TOGGLE_EDITOR';

export const setUserSetsListAction = (userSetList: IUserSetListItem[]): AnyAction => ({
  type: SET_USER_SETS_LIST,
  payload: userSetList,
});

const createUserSetListItemAction = (userSet: IUserSetListItem): AnyAction => ({
  type: ADD_USER_SET_LIST_ITEM,
  payload: userSet,
});

const editUserSetListItemAction = (userSet: IUserSetListItem): AnyAction => ({
  type: EDIT_USER_SET_LIST_ITEM,
  payload: userSet,
});

const removeUserSetListItemAction = (id: number): AnyAction => ({
  type: REMOVE_USER_SET_LIST_ITEM,
  payload: id,
});

const toggleEditorAction = (editorConfig: IUserSetEditor): AnyAction => ({
  type: TOGGLE_EDITOR,
  payload: editorConfig,
});

export default {
  setUserSetsList: (): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    try {
      const data = await UserSetsHttp.getUserSetsList(getState().currentShop.id);

      await dispatch(setUserSetsListAction(data.list.reverse()));
    } catch (err) {
      console.warn('Не смогли получить список наборов');
    }
  },

  createUserSetListItem: (requestData: ICreateUserSetRequest): ThunkAction => async (dispatch: Dispatch) => {
    try {
      const data = await UserSetsHttp.createUserSet(requestData);

      const { id, name, description, color } = data.user_set;

      await dispatch(
        createUserSetListItemAction({
          id,
          name,
          description,
          color,
          total_price: 0,
          missing_products_count: 0,
          total_count: requestData.products?.length || 0,
          model_ids: requestData.products?.map(product => product.model_id) || [],
        })
      );

      dispatch(
        setNotificationAction(
          `Вы создали набор <font color="${colors.sweetCorn}">«${name}»</font>${
            requestData.products?.length ? ' и добавили товар' : ''
          }`,
          EnumNotification.success,
          2000,
          `/catalog/user_sets/${id}`
        )
      );

      return data.user_set;
    } catch (err) {
      dispatch(
        setNotificationAction(
          `Не смогли создать набор <font color="${colors.sweetCorn}">«${requestData.name}»</font>. Проверьте подключение к интернету`,
          EnumNotification.danger,
          5000
        )
      );
    }
  },

  editUserSetListItem: (
    userSetListItem: IUserSetListItem,
    product?: IProduct,
    payload?: IUserSetProductUpdate[],
    missingCount?: number,
    showNotification = true
  ): ThunkAction => async (dispatch: Dispatch) => {
    const xhrPayload: IEditUserSetRequest = { name: userSetListItem.name, color: userSetListItem.color };
    let modelIds = [...userSetListItem.model_ids];

    if (product) {
      xhrPayload.products = [
        {
          operation: OperationTypes.SET,
          model_id: product.model_id,
          amount: 1,
          weight: product.item_weight,
        },
      ];
      modelIds.push(product.model_id);
    }

    if (payload) {
      xhrPayload.products = payload;

      payload.forEach(({ model_id, amount }) => {
        if (modelIds.includes(model_id) && !amount)
          modelIds = userSetListItem.model_ids.filter(modelId => model_id !== modelId);

        if (!modelIds.includes(model_id) && amount) modelIds.push(model_id);
      });
    }

    try {
      await UserSetsHttp.editUserSet(userSetListItem.id, xhrPayload);

      await dispatch(
        editUserSetListItemAction({
          ...userSetListItem,
          missing_products_count: missingCount || userSetListItem.missing_products_count,
          total_count: modelIds.length,
          model_ids: modelIds,
        })
      );

      if (showNotification) {
        const notificationText = `Вы ${product ? 'добавили товар в' : 'изменили'} набор <font color="${
          colors.sweetCorn
        }">«${userSetListItem.name}»</font>`;

        await dispatch(
          setNotificationAction(
            notificationText,
            EnumNotification.success,
            5000,
            `/catalog/user_sets/${userSetListItem.id}`
          )
        );
      }
    } catch (err) {
      if (showNotification) {
        dispatch(
          setNotificationAction(
            `Не смогли изменить набор <font color="${colors.sweetCorn}">«${userSetListItem.name}»</font>. Проверьте подключение к интернету`,
            EnumNotification.danger,
            5000
          )
        );
      }
    }
  },

  removeUserSetListItem: (userSetId: number): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    const userSetToRemove = getState().userSets.list.find(userSet => userSet.id === userSetId);
    try {
      await UserSetsHttp.removeUserSet(userSetId);

      await dispatch(removeUserSetListItemAction(userSetId));

      await dispatch(
        setNotificationAction(
          `Вы удалили набор <font color="${colors.sweetCorn}">«${userSetToRemove?.name}»</font>`,
          EnumNotification.success
        )
      );
    } catch (err) {
      dispatch(
        setNotificationAction(
          `Не смогли удалить набор <font color="${colors.sweetCorn}">«${userSetToRemove?.name}»</font>. Проверьте подключение к интернету`,
          EnumNotification.danger,
          5000
        )
      );
    }
  },

  openUserSetEditor: (currentSet: IUserSetListItem | null = null, modelsToAdd: number[] = [], hasPrevPath = false) =>
    toggleEditorAction({
      show: true,
      currentSet,
      modelsToAdd,
      hasPrevPath,
    }),

  closeUserSetEditor: () =>
    toggleEditorAction({
      show: false,
      currentSet: null,
      modelsToAdd: [],
      hasPrevPath: false,
    }),
};
