import { createBrowserHistory, createMemoryHistory, Location, History } from 'history';
import { gtagUniversalCounterConfig, gtagConfig } from 'utils/gtag';
import { isBrowser } from 'utils/helpers';

export type TLocationState = {
  prev?: Location;
  check?: boolean;
};

const history = isBrowser ? createBrowserHistory<TLocationState>() : createMemoryHistory<TLocationState>();

let prevLocation: Location<History.PoorMansUnknown> | undefined;

const scrollTopOnLocationChange = (location: Location<History.PoorMansUnknown>) => {
  if (prevLocation && prevLocation.pathname !== location.pathname) {
    window.root.scrollTo({ top: 0, behavior: 'smooth' });
  }

  prevLocation = location;
};

const listen = [scrollTopOnLocationChange];

listen.push((location: Location<History.PoorMansUnknown>) => {
  const data = { page_path: location.pathname };
  gtagUniversalCounterConfig(data);
  gtagConfig(data);
});

history.listen(location => {
  listen.forEach(func => func(location));
});

export default history;
