import http, { IHttp } from 'lib/http';
import { IProfileResponse, IUpdateProfileData } from 'Modules/AAA/types';
import { IEntityInfo } from './types';

export const fetchEntityInfo = (fetcher = http): Promise<IEntityInfo> =>
  fetcher.get('profile/entity', undefined, { apiVersion: 'komus/v1' });
export const saveEntityInfo = (data: IEntityInfo): Promise<IEntityInfo> =>
  http.patch('profile', data, { apiVersion: 'komus/v1' });
export const getInvoice = (amount: number): Promise<{}> =>
  http
    .get('profile/entity/invoice.pdf', { amount }, { responseType: 'arraybuffer' })
    .then((blob: any) => new Blob([blob as Blob], { type: 'application/pdf' }));

interface IEntityHttp {
  createEntity(authData: IUpdateProfileData): Promise<IProfileResponse>;
  fetchEntityInfo?(fetcher?: IHttp): Promise<IEntityInfo>;
  getInvoice?(amount: number): Promise<unknown>;
}
export class EntityHttp implements IEntityHttp {
  private readonly http: IHttp = http;

  public createEntity(entity: IUpdateProfileData): Promise<IProfileResponse> {
    return this.http.post('profile/entity', entity, { apiVersion: 'komus/v1' });
  }

  public getEntity(fetcher = http): Promise<IEntityInfo> {
    return fetcher.get('profile/entity');
  }
}

export default new EntityHttp();
