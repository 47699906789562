import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { mediaSmDown } from 'theme/helpers/css';
import Title from 'Components/Typography/Title';
import Row from 'Components/Grid/Row';
import Col from 'Components/Grid/Col';
import { FontWeight } from 'theme';
import Text from 'Components/Typography/Text';
import { EMPTY_STRING, ENTITY_INDIVIDUAL, NBSP } from 'constants/constants';
import { EnumButtonSize, EnumNotification, EnumTheme } from 'constants/enums';
import Button from 'Components/ThemedButton';
import { Field, FieldConfig, Formik } from 'formik';
import { number, object, string } from 'yup';
import useFormikAutosave from 'utils/hooks/useFormikAutosave';
import { IEntityInfo } from 'Modules/Entity/types';
import { withNumberValidation, withPhoneMask, withSpaceTrim } from '../../../Components/Input/hocs';
import { useDispatch } from 'react-redux';
import { setNotificationAction } from '../../../Modules/Notifications/actions';
import { saveEntityInfo } from '../../../Modules/Entity/http';
import { omit } from 'ramda';
import AAAActions from '../../../Modules/AAA/actions';
import { colors } from '../../../constants/colors';
import TextareaAutosize from '../../../Components/Textarea/TextareaAutosize';
import ConfirmationModal from '../../Checkout/Confirmation';
import useModalState from '../../../utils/hooks/useModalState';
import { SAVE_SUCCESS_TEXT, UNCONFIRMED_EMAIL_TEXT } from '../Settings/constants';
import AAAService from '../../../Modules/AAA/service';
import Input from 'Components/Input/Input';
import { getEntity } from 'Modules/Entity/selectors';
import { useSelector } from 'react-redux';
import { EnumEntityStatus } from 'constants/enums';

export const ENTITY_NEED_RESIGN = 'ENTITY_NEED_RESIGN';

const StyledForm = styled.form`
  max-width: 680px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  ${mediaSmDown`
      margin-bottom: 8px;
  `}
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  & + & {
    margin-top: 28px;
    ${mediaSmDown`
        margin-top: 14px;
    `}
  }
`;

const FormRow = styled(Row)`
  & + & {
    margin-top: 10px;
    ${mediaSmDown`
      margin-top: 8px;
    `}
  }
`;

const Legend = styled.legend`
  margin-bottom: 32px;
  ${mediaSmDown`
    margin-bottom: 16px;
  `}
`;

const LabelCol = styled(Col).attrs({ lg: 4, md: 4, sm: 4, xs: 12 })`
  ${mediaSmDown`
    margin-bottom: 8px;
  `}
`;

const PhoneInput = withPhoneMask(StyledInput);
const NumberInput = withNumberValidation(StyledInput);
const EmailInput = withSpaceTrim(StyledInput);

export enum EnumBasisOfAuthority {
  charter = 'charter', // устав
  poa = 'poa', // доверенность
  directive = 'directive', // приказ
}

export enum FormFields {
  name = 'name',
  correspondent_account = 'correspondent_account',
  user_name = 'user_name',
  address = 'address',
  management_name = 'management_name',
  inn = 'inn',
  kpp = 'kpp',
  ogrn = 'ogrn',
  checking_account = 'checking_account',
  bik = 'bik',
  bank_name = 'bank_name',
  user_phone = 'user_phone',
  user_email = 'user_email',
}

export const FormFieldLabels = {
  name: 'Название компании',
  correspondent_account: 'Корреспондентский счет',
  user_name: 'Имя',
  address: 'Юридический адрес',
  management_name: `Подписант / уполномоченное${NBSP}лицо`,
  inn: 'ИНН',
  kpp: 'КПП',
  ogrn: 'ОГРН',
  checking_account: 'Расчетный счет',
  bik: 'БИК',
  bank_name: 'Банк',
  user_phone: 'Моб. телефон',
  user_email: 'E-mail',
};

export const authorityType = [EnumBasisOfAuthority.charter, EnumBasisOfAuthority.poa, EnumBasisOfAuthority.directive];

export const authorityTypeValues = {
  [EnumBasisOfAuthority.charter]: 'Устав',
  [EnumBasisOfAuthority.poa]: 'Доверенность',
  [EnumBasisOfAuthority.directive]: 'Приказ',
};

// эта штука нужна для того, чтобы можно было подсосаться к контексту формика (т.е. должна быть внутри <Formik />)
const Autosave = () => {
  useFormikAutosave('entityForm');
  return null;
};

export interface IFormData extends IEntityInfo {
  user_phone: string;
  user_email: string;
  user_name: string;
}

export const isEntityIndividual = (ofp?: string): boolean => ofp === ENTITY_INDIVIDUAL;
export const isAuthorityCharter = (authorityType: EnumBasisOfAuthority): boolean =>
  authorityType === EnumBasisOfAuthority.charter;
export const formatEmptyStringToNull = (value: string | null | undefined): string | null | undefined =>
  value === EMPTY_STRING ? null : value;

interface IFormFieldRow extends FieldConfig {
  label: string;
  disabled?: boolean;
  required?: boolean;
  withMb?: boolean;
  inputmode?: string;
}

const entityValidationSchema = object().shape({
  [FormFields.user_name]: string().required(),
  [FormFields.user_email]: string().email().required(),
  [FormFields.user_phone]: string().matches(/\d+/).length(10).required(),
  [FormFields.ogrn]: number().required(),
  [FormFields.checking_account]: number().required(),
  [FormFields.correspondent_account]: number().required(),
  [FormFields.bank_name]: string().required(),
  [FormFields.address]: string().required(),
});

const FormFieldRow: FC<IFormFieldRow> = ({ label, name, required, ...props }) => (
  <FormRow>
    <LabelCol>
      <Text fontWeight={FontWeight.semiBold}>
        <label htmlFor={name}>{label} </label>
      </Text>
      {required && <Text color={colors.primaryAction}>{NBSP}*</Text>}
    </LabelCol>
    <Col lg={8} md={8} sm={8} xs={12}>
      <Field id={name} name={name} {...props} />
    </Col>
  </FormRow>
);

interface IEntityFormProps {
  initialData: Partial<IFormData>;
  onSubmit: (values: IFormData) => void;
  onCancel: () => void;
  cancelable?: boolean;
}

const EntityForm: FC<IEntityFormProps> = ({ initialData, onSubmit, cancelable, onCancel }) => {
  const dispatch = useDispatch();
  const { state } = useSelector(getEntity);
  const submitValues = useRef<IFormData>();
  const { isVisible, showModal, hideModal } = useModalState();
  const initialValuesWithAutosaved = {
    ...initialData,
  };

  const handleSuccessPhoneConfirmation = useCallback(async () => {
    hideModal();
    dispatch(setNotificationAction(SAVE_SUCCESS_TEXT, EnumNotification.success));
    submitValues.current && onSubmit(submitValues.current);
  }, [dispatch, hideModal, onSubmit]);

  return (
    <>
      <Formik
        initialValues={initialValuesWithAutosaved}
        validationSchema={entityValidationSchema}
        enableReinitialize
        validateOnMount
        onSubmit={async (values, { setErrors }) => {
          const handleErrors = (errors: { [key in FormFields]: string }) => {
            setErrors(errors);
            for (const err in errors) {
              // typescript выводит строку вместо нужнеого типа в for ... in, пришлось указать тип вручную
              dispatch(setNotificationAction(errors[err as FormFields]));
            }
          };
          try {
            const entityValues = omit(['user_phone', 'user_name', 'user_email'], values);
            submitValues.current = values as IFormData;
            /**
             * в Formik поля приходят с Partial типом (потому что из SWR их так конвертирует),
             * но результатом submit всегда будет полный контракт IEntityInfo
             * поэтому приходится явно указывать тип
             */
            await saveEntityInfo(entityValues as IEntityInfo);
            await dispatch(
              AAAActions.updateProfile({
                name: values.user_name,
                phone: values.user_phone,
                email: values.user_email,
              })
            );
            if (initialData.user_phone === values.user_phone) {
              /**
               * Здесь аналогичная история
               */
              onSubmit(values as IFormData);
              dispatch(setNotificationAction('Данные успешно отправлены', EnumNotification.success));
              if (initialData.user_email !== values.user_email) {
                dispatch(
                  setNotificationAction(`${UNCONFIRMED_EMAIL_TEXT} ${values.user_email}`, EnumNotification.danger)
                );
              }
              if (state === EnumEntityStatus.contract_approval_waiting || state === EnumEntityStatus.confirmed) {
                localStorage.setItem(ENTITY_NEED_RESIGN, 'true');
              }
            } else {
              await AAAService.sendCode();
              showModal();
            }
          } catch (e) {
            const { errors, data, error } = e.response.data;
            const hasProfileErrors = Boolean(error);
            const hasEntityErrors = Boolean(errors);

            if (hasProfileErrors) {
              const errors: any = {};
              if (data.phone) {
                errors.user_phone = data.phone;
              }

              if (data.email) {
                errors.user_email = data.email;
              }
              handleErrors(errors);
            } else if (hasEntityErrors) {
              handleErrors(errors);
            } else {
              dispatch(setNotificationAction('Произошла ошибка, попробуйте позже'));
            }
          }
        }}
      >
        {({ handleSubmit, isValid, isSubmitting, setStatus }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Autosave />
            <Fieldset>
              <Legend>
                <Title level="2">Контактное лицо</Title>
              </Legend>
              <FormFieldRow required label={FormFieldLabels.user_name} name={FormFields.user_name} as={StyledInput} />
              <FormFieldRow required label={FormFieldLabels.user_phone} name={FormFields.user_phone} as={PhoneInput} />
              <FormFieldRow
                required
                label={FormFieldLabels.user_email}
                name={FormFields.user_email}
                as={EmailInput}
                type="text"
                inputmode="email"
              />
            </Fieldset>
            <Fieldset>
              <Legend>
                <Title level="2">Данные об организации</Title>
              </Legend>
              <FormFieldRow label={FormFieldLabels.name} name={FormFields.name} as={TextareaAutosize} disabled withMb />
              <FormFieldRow
                label={FormFieldLabels.address}
                name={FormFields.address}
                as={TextareaAutosize}
                required
                withMb
              />
            </Fieldset>
            <Fieldset>
              <Legend>
                <Title level="2">Реквизиты</Title>
              </Legend>
              <FormFieldRow label={FormFieldLabels.inn} name={FormFields.inn} as={StyledInput} disabled />
              <FormFieldRow label={FormFieldLabels.kpp} name={FormFields.kpp} as={StyledInput} disabled />
              <FormFieldRow label={FormFieldLabels.ogrn} name={FormFields.ogrn} as={StyledInput} disabled />
              <FormFieldRow
                type="tel"
                label={FormFieldLabels.checking_account}
                name={FormFields.checking_account}
                as={NumberInput}
                required
              />
              <FormFieldRow type="tel" label={FormFieldLabels.bik} name={FormFields.bik} as={NumberInput} required />
              <FormFieldRow label={FormFieldLabels.bank_name} name={FormFields.bank_name} as={StyledInput} required />
              <FormFieldRow
                type="tel"
                label={FormFieldLabels.correspondent_account}
                name={FormFields.correspondent_account}
                as={NumberInput}
                required
              />
            </Fieldset>

            <Fieldset>
              <FormRow>
                <LabelCol>
                  <Button
                    use={EnumTheme.roundRed}
                    type="submit"
                    size={EnumButtonSize.normal}
                    fluid
                    disabled={!isValid}
                    isLoading={isSubmitting}
                  >
                    Сохранить
                  </Button>
                </LabelCol>
                <LabelCol>
                  {cancelable && (
                    <Button
                      type="reset"
                      use={EnumTheme.roundDarkGray}
                      size={EnumButtonSize.normal}
                      fluid
                      onClick={() => {
                        setStatus('resetted');
                        onCancel();
                      }}
                    >
                      Отмена
                    </Button>
                  )}
                </LabelCol>
              </FormRow>
            </Fieldset>
          </StyledForm>
        )}
      </Formik>
      <ConfirmationModal
        show={isVisible}
        closeModal={hideModal}
        onSuccessConfirmation={handleSuccessPhoneConfirmation}
      />
    </>
  );
};

export default EntityForm;
