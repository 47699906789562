import Http, { IHttp } from 'lib/http';
import { IPaymentCardsHttp, IPaymentCard } from './types';

export class PaymentCardsHttp implements IPaymentCardsHttp {
  private readonly http: IHttp = Http;

  public getUserCards(fetcher = Http): Promise<IPaymentCard[]> {
    return fetcher.get('/cards');
  }

  public getUserCardsByOrderId(orderId: number): Promise<IPaymentCard[]> {
    return this.http.get(`/cards/for_order?order_id=${orderId}`);
  }

  public deleteUserCard(card: IPaymentCard): Promise<IPaymentCard[]> {
    return this.http.delete(`/cards/${card.id}`);
  }
}

export default new PaymentCardsHttp();
