import styled from 'styled-components';
import RightClosableModal from '../../Modal/RightClosableModal';
import { CloseButton } from '../../Modal/Modal';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import Flex from 'Components/Grid/Flex';

export const Modal = styled(RightClosableModal)`
  && ${CloseButton} {
    display: none;
  }
`;

export const Content = styled(Flex).attrs({
  column: true,
  px: 10,
  pxXs: 4,
})`
  background-color: ${colors.white};
  height: 100%;
  min-width: 350px;
  position: relative;
  overflow-y: auto;

  ${mediaSmDown`
        min-width: 272px;
    `}
`;

export const Header = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.silver};
  height: 80px;

  ${mediaSmDown`
        height: 60px;
        padding-top: 20px;
        padding-bottom: 12px;
    `}
`;

export const AuthButton = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const AuthIcon = styled.img`
  height: 15px;
  margin-right: 12px;
  vertical-align: -2px;
`;

export const Main = styled(Flex).attrs({
  column: true,
})`
  padding: 20px 0;
  flex-grow: 1;

  ${mediaSmDown`
        padding-top: 12px;
        padding-bottom: 12px;
    `}
`;

export const Footer = styled.div`
  padding-top: 20px;
  padding-bottom: 24px;
  border-top: 1px solid ${colors.silver};

  ${mediaSmDown`
        padding-top: 12px;
        padding-bottom: 24px;
    `}
`;
