import { colors } from 'constants/colors';
import React from 'react';
import Icon, { IIcon } from './Icon';

const ExternalLinkIcon: React.FC<IIcon> = ({ color = colors.blackRussian, ...props }) => (
  <Icon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M7 2H12V7" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 2L2 12" stroke={color} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default ExternalLinkIcon;
