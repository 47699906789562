export interface ILocalStorageService<T> {
  get(): T | null;
  set(value: T): void;
  remove(): void;
}

export default class LocalStorageService<T> implements ILocalStorageService<T> {
  private readonly storageKey: string;

  public constructor(keyName: string) {
    this.storageKey = `igooods:${keyName}`;
  }

  public get(): T | null {
    const value = localStorage.getItem(this.storageKey);
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
    return value;
  }

  public set(value: T): void {
    localStorage.setItem(this.storageKey, JSON.stringify(value));
  }

  public remove(): void {
    localStorage.removeItem(this.storageKey);
  }
}
