import React from 'react';
import Icon, { IIcon } from './Icon';

const NewSocialWAIcon: React.FC<IIcon> = props => (
  <Icon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.639326 10.072C0.419691 10.6983 0.200053 11.3246 0 11.9705C0.419225 11.8698 0.792726 11.7454 1.18406 11.615C1.31966 11.5699 1.45741 11.524 1.59995 11.4781C1.74888 11.4302 1.9109 11.3653 2.07816 11.2982C2.47835 11.1376 2.90858 10.965 3.2615 10.9858C3.4805 10.9987 3.70789 11.121 3.92186 11.236C4.05774 11.3091 4.18821 11.3792 4.30766 11.4166C8.38477 12.6921 12.0094 9.55391 12 5.81665C11.991 2.27474 9.02854 -0.320524 5.41537 0.0320725C1.5885 0.405472 -1.23689 4.9006 1.04616 8.89358C0.913732 9.28958 0.77653 9.68081 0.639326 10.072ZM7.56937 8.77195C5.97748 8.69871 3.78964 6.68758 3.2617 5.20272C2.94843 4.32169 3.30873 2.63656 4.67708 3.04886C4.68401 3.06337 4.69103 3.07802 4.6981 3.0928C5.04429 3.81595 5.54142 4.8544 4.67708 5.32581C5.12501 6.27276 5.86553 6.92705 6.83088 7.35658C7.04191 7.217 7.20531 7.02978 7.3687 6.84257C7.45386 6.745 7.53901 6.64743 7.63091 6.55661C8.19123 6.77571 8.71498 7.03143 9.16932 7.35658C9.21603 8.41523 8.32392 8.80667 7.56937 8.77195Z"
      fill={props.color}
    />
  </Icon>
);

export default NewSocialWAIcon;
