import React, { useEffect, useState, cloneElement, ReactElement, FC, useCallback } from 'react';
import { Modal, ModalContainer, ModalCloseMobile } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from './actions';
import { IApplicationState } from 'reduxStore/types';
import Portal from 'Components/Modal/Portal';

interface IModalComponent {
  type: string;
  closeModal?: () => void;
}

interface IModalModuleProps {
  children: ModalChildren;
}

type ModalChildren = ReactElement<IModalComponent> | ReactElement<IModalComponent>[];

export const MODAL_TEST_ID = 'modal';
export const MODAL_CLOSE_ICON_TEST_ID = 'modal-close-icon';

export const ModalModuleContainer: FC<IModalModuleProps> = ({ children }: IModalModuleProps) => {
  const [renderedOnClient, setRenderedOnClient] = useState(false);
  const [isWindowOpened, setWindowOpened] = useState(false);
  const [isBackdropOpened, setBackdropOpened] = useState(false);
  const { isOpened, modalType, isBlockedClose } = useSelector((state: IApplicationState) => state.modals);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalType === 'Auth' && !isBackdropOpened) {
      setBackdropOpened(true);
    }
  }, [modalType, isBackdropOpened]);

  const closeModalHandler = useCallback(() => {
    setTimeout(() => {
      setBackdropOpened(false);
      if (isOpened) dispatch(ModalActions.closeModal());
    }, 200);
    setTimeout(() => setWindowOpened(false), 50);
  }, [dispatch, isOpened]);

  const animateOpen = () => {
    setTimeout(() => setBackdropOpened(true), 50);
    setTimeout(() => setWindowOpened(true), 200);
  };

  useEffect(() => {
    setRenderedOnClient(true);
  }, []);

  useEffect(() => {
    if (isOpened) animateOpen();
    if (!isOpened) closeModalHandler();
  }, [closeModalHandler, isOpened]);

  const renderModalByType = (
    modalType: string,
    children: ModalChildren
  ): undefined | null | ReactElement<IModalComponent> => {
    if (Array.isArray(children)) {
      return children.find((c: ReactElement<IModalComponent>) => c.props.type === modalType);
    }
    return children.props.type === modalType ? children : null;
  };

  const ModalComponent = renderModalByType(modalType, children);

  const handleEmptyAction = () => null;
  const handleBlockClose = isBlockedClose ? handleEmptyAction : closeModalHandler;

  return (
    <>
      {renderedOnClient && (
        <Portal container={document.getElementById('portal-root') as HTMLElement}>
          <Modal
            role="presentation"
            onClick={handleBlockClose}
            isBackdropOpened={isBackdropOpened}
            data-testid={MODAL_TEST_ID}
          >
            <ModalContainer onClick={e => e.stopPropagation()} isWindowOpened={isWindowOpened}>
              <ModalCloseMobile data-testid={MODAL_CLOSE_ICON_TEST_ID} onClick={handleBlockClose} />
              {ModalComponent ? cloneElement(ModalComponent, { closeModal: handleBlockClose }) : null}
            </ModalContainer>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default ModalModuleContainer;
