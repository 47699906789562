import React from 'react';
import Icon, { IIcon } from './Icon';

const PhoneIcon: React.FC<IIcon> = props => (
  <Icon width="17" height="16" viewBox="0 0 17 16" {...props}>
    <path
      d="M16.9029 11.9289V15.072C16.903 15.297 16.8177 15.5138 16.6643 15.6784C16.5109 15.843 16.3007 15.9434 16.0762 15.9591C15.6878 15.9858 15.3704 16 15.1251 16C7.27 16 0.902893 9.63289 0.902893 1.77778C0.902893 1.53244 0.916226 1.21511 0.943782 0.826667C0.959535 0.602172 1.05985 0.39201 1.2245 0.238586C1.38914 0.0851616 1.60585 -0.000101718 1.83089 2.28071e-07H4.974C5.08426 -0.000111408 5.19062 0.0407636 5.27243 0.114685C5.35424 0.188606 5.40565 0.290296 5.41667 0.4C5.43712 0.604445 5.45578 0.767111 5.47356 0.890667C5.65021 2.12349 6.01223 3.32252 6.54734 4.44711C6.63178 4.62489 6.57667 4.83733 6.41667 4.95111L4.49845 6.32178C5.6713 9.05462 7.84916 11.2325 10.582 12.4053L11.9509 10.4907C12.0068 10.4124 12.0885 10.3563 12.1816 10.3321C12.2746 10.3079 12.3733 10.3172 12.4602 10.3582C13.5847 10.8923 14.7834 11.2534 16.0158 11.4293C16.1393 11.4471 16.302 11.4667 16.5047 11.4862C16.6142 11.4975 16.7157 11.549 16.7894 11.6307C16.8632 11.7125 16.9039 11.8188 16.9038 11.9289H16.9029Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default PhoneIcon;
