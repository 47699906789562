const NODE_ENV = process.env.NODE_ENV;
export const NODE_ENV_IS_DEVELOPMENT = NODE_ENV === 'development';
export const NODE_ENV_IS_PRODUCTION = NODE_ENV === 'production';
export const NODE_ENV_IS_TEST = NODE_ENV === 'test';
export const isKomus = true;
const env = typeof process !== 'undefined' ? process.env : JSON.parse(decodeURI(window.__SSR_ENV__ || {}));

export const DEPLOY_ENV = env.DEPLOY_ENV;
export const DEPLOY_ENV_PRODUCTION = DEPLOY_ENV === 'production';
export const DEPLOY_ENV_STAGING = DEPLOY_ENV === 'staging';
export const DEPLOY_ENV_NOT_PROD = DEPLOY_ENV !== 'preproduction' && DEPLOY_ENV !== 'production';

export const BACKEND_API_URL = env.BACKEND_API_URL || 'https://komus.jira.igooods.ru/';
export const GEO_GRINDER_API_URL = 'https://geo.api.igooods.ru';
export const PORT = process.env.PORT || 3000;
export const GITHUB_COMMIT_HASH = env.GITHUB_COMMIT_HASH;
export const REDIS_URL = process.env.REDIS_URL || 'redis://localhost:6380';
export const ASSET_PATH = process.env.ASSET_PATH;
export const IS_KOMUS = true;
