import { ICoordinate, IWithCoordinate } from 'Modules/Places/types';
import { IDeclensions } from 'Modules/SearchAddress/types';

export enum EnumShopServiceInfoType {
  delivery = 'delivery', // доствка
  assembly = 'assembly', // упаковка
  packaging = 'packaging', // пакеты
  overweight = 'overweight', //доплата за перевес 60-80кг
  // weight = 'weight'
}

export interface IShopServiceInfo {
  id?: number;
  type: EnumShopServiceInfoType;
  name: string;
  description: string;
  price: number;
  real_price?: number;
  threshold?: number;
}

export enum EnumDeliveryDateState {
  available = 'available', // время доступно для выбора
  na = 'na', // недоступно
  ending = 'ending', // набор заканчивается
}

export interface IDeliveryDate {
  date: string; // ISO_8601
  end_date?: string; // ISO_8601
  state: EnumDeliveryDateState;
  early?: boolean;
}

interface IWithDeliveryDateAndServiceInfo {
  id: number;
  service_info: IShopServiceInfo;
  delivery_date?: IDeliveryDate;
}

export interface IPaymentTypes {
  cash: boolean;
  online: boolean;
  card: boolean;
  google_pay: boolean;
  apple_pay: boolean;
  sbp: boolean;
  shipment_on_credit: boolean;
}

export interface IShop extends IWithCoordinate {
  id: number;
  name: string;
  group: string;
  group_en?: string;
  short_address: string;
  color: string;
  logo: IShopFullLogo;
  payment_types?: IPaymentTypes;
  group_name: string;
  open?: boolean;
  branding?: string;
}

export interface IShopForPlace extends IShop {
  color: string;
  zone: IWithDeliveryDateAndServiceInfo;
}

export interface IDeliveryZone extends IWithDeliveryDateAndServiceInfo {
  id: number;
}

export interface IDeliveryZoneWithCoordinates extends IDeliveryZone {
  coordinates: ICoordinate[];
}

export interface IShopFullLogo {
  logo_path: string;
  svg_simple_logo_path: string;
  svg_o_active_logo_path: string;
  svg_o_logo_path: string;
  png_o_logo_path: string;
  png_full_path: string;
  round_logo_path: string;
  long_arrow_path?: string;
  square_logo_path?: string;
}

export interface IShopWithLogo extends IShop {
  color: string;
  logo: IShopFullLogo;
}

export interface IShopWithLogoExtended extends IShop, IShopFullLogo {
  color: string;
}

export interface IShopWithLogo extends IShop {
  color: string;
  logo: IShopFullLogo;
}

export interface IShopWithZones extends IWithCoordinate, IShopWithLogo, IShopWithZone {
  all_zones: IDeliveryZoneWithCoordinates[];
}

export interface IShopWithZone extends IShopWithLogo {
  zone: IDeliveryZone;
}

export type Job = 'picker' | 'courier';
export type Gender = 'male' | 'female';

export interface IEmployee {
  name: string;
  job: Job;
  gender: Gender;
  photo: string;
  rating: number | null;
  orders: number;
}

// deprecated
export interface IDeliveryPoint {
  id: number;
  photo_path: string;
  description: string;
  short_address: string;
  coordinate: ICoordinate;
  work_hours: string;
  delivery_date: IDeliveryDate;
  service_info: IShopServiceInfo;
}

export interface IShopDeliveryDate {
  date: string; // ISO_8601
  list: IDeliveryDate[];
}

export interface IShopDeliveryDates {
  list: IShopDeliveryDate[];
}

export interface IShopBasic {
  name: string;
  logo: string;
}

export interface IProductsGroup {
  id: number;
  group: string;
  group_en: string;
  seo_name: string;
  logo: {
    logo_shop_path: string;
    logo_shop_grayscale_path: string | null;
  };
  city: IDeclensions;
  group_name: string;
}
