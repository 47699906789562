import { IViewedProduct } from 'utils/tagManager/types';
import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import {
  formattedProductForGTag,
  formattedViewedProductsLists,
  getAdditionalParamsForGTag,
} from 'utils/tagManager/helpers';
import { truncateString } from 'utils/helpers';

// Просмотр товаров в каком-либо перечне
export default function overviewProducts(viewedProducts: IViewedProduct[]): void {
  const { promoProducts, defaultProducts, productsModelIds } = formattedViewedProductsLists(viewedProducts);

  if (promoProducts.length) {
    analyticsManager.gtag(EnumGtagEvents.VIEW_PROMOTION, {
      items: promoProducts.map(productData => {
        const { promotion_id, promotion_name, location_id } = productData.params;
        const additionalParams = {
          ...(promotion_id && { promotion_id: truncateString(promotion_id) }),
          ...(location_id && { location_id: truncateString(location_id) }),
          ...(promotion_name && { promotion_name: truncateString(promotion_name) }),
          ...getAdditionalParamsForGTag(productData),
        };

        return formattedProductForGTag(productData.product, additionalParams);
      }),
    });
  }
  if (defaultProducts.length) {
    analyticsManager.gtag(EnumGtagEvents.VIEW_ITEM_LIST, {
      items: defaultProducts.map(productData => {
        const additionalParams = getAdditionalParamsForGTag(productData);

        return formattedProductForGTag(productData.product, additionalParams);
      }),
    });
  }
  analyticsManager.criteo({
    event: 'viewList',
    item: productsModelIds,
  });
}
