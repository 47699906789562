import { AnyAction } from 'redux';
import { CheckoutActionTypes } from './actions';
import { ICheckout, BehaviorTypes, BehaviorCallTypes, PaymentTypes } from './types';
import { EnumDeliveryDateState } from 'Modules/Shops/types';
import { EMPTY_STRING } from 'constants/constants';

export const initialState: ICheckout = {
  delivery_date: {
    date: '',
    state: EnumDeliveryDateState.na,
  },
  delivery_early: false,
  behavior: {
    type: BehaviorTypes.call,
    call_type: BehaviorCallTypes.call_or_hide,
  },
  payment: {
    type: PaymentTypes.cash,
  },
  comment: {
    text: EMPTY_STRING,
    phone: EMPTY_STRING,
  },
};

export default function checkoutReducer(state: ICheckout = initialState, action: AnyAction): ICheckout {
  switch (action.type) {
    case CheckoutActionTypes.SET_CHECKOUT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
