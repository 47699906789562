import styled from 'styled-components';
import BlockContainer from '../BlockContainer';
import { colors } from '../../../constants/colors';
import { shadows } from '../../../constants/shadows';
import { Box, Flex } from '@igooods/ui-kit';
import LinkWrapper from '../../Link/LinkWrapper';
import { mediaLgDown, mediaSmDown } from '../../../theme/helpers/css';
import CitiesButton from '../../Header/CitiesButton';
import ThemeButton from 'Components/ThemedButton';
import ExternalLink from '../../ExternalLink';
import ContactsModal from './ContactsModal';
import { hideAfterLg, hideBeforeLg, hideBeforeSm } from '../../../theme/helpers';
import SideMenu from '../SideMenu';

export const StickySection = styled(BlockContainer).attrs({
  bgColor: colors.light200,
  maxWidth: '1260px',
})`
  position: sticky;
  z-index: 10;
  top: 0;
  box-shadow: ${shadows.stickyHeader};
`;

export const Container = styled(Flex).attrs({
  pt: { sm: 5, xs: 2 },
  pb: { sm: 6, xs: 2 },
  middle: true,
})`
  height: 50px;
  box-sizing: content-box;
`;

export const LogoLink = styled(LinkWrapper)`
  flex-basis: 240px;
  flex-shrink: 0;
  margin-right: 20px;

  ${mediaSmDown`
    flex-basis: auto;
    margin-right: 8px;
  `}
`;

export const Cities = styled(CitiesButton)`
  ${mediaSmDown`
    flex-basis: auto;
  `}
`;

export const Nav = styled(Flex).attrs({ hideBefore: 'lg', grow: true, center: true })``;

export const ExternalNavLink = styled(ExternalLink)`
  margin-left: 16px;
`;

export const Auth = styled(Box).attrs({ hideBefore: 'lg', ml: 3 })``;

export const ContactsButton = styled(ContactsModal)`
  margin-left: 12px;

  ${mediaLgDown`
    margin-left: auto;
  `}

  ${hideBeforeSm}
`;

export const CatalogButton = styled(LinkWrapper)`
  margin-left: 10px;

  ${hideBeforeLg}
`;

export const BurgerBtn = styled(SideMenu)`
  margin-left: 8px;
  flex-shrink: 0;

  ${hideAfterLg};

  ${mediaSmDown`
    margin-left: auto;
  `}
`;

export const StyledThemeButton = styled(ThemeButton)`
  color: ${colors.light200};
  background-color: ${colors.primaryMain};

  &:focus,
  &:hover {
    background-color: ${colors.primaryAction};
  }
`;
