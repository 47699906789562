import React, { memo, useCallback } from 'react';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import { AuthModalState } from 'constants/enums';
import GenericAuthModal from '../Modals/Auth/GenericModal';
import analyticsManager from 'utils/tagManager/manager';
import { authEvents } from 'Containers/Modals/Auth/constants';

export const ERRROR_MESSAGE = 'Неверный код';

export interface IConfirmationModalProps {
  closeModal: () => void;
  onSuccessConfirmation: () => Promise<void>;
  show: boolean;
  responseTimeout?: number;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  onSuccessConfirmation,
  closeModal,
  show,
  responseTimeout,
}) => {
  const handleSuccess = useCallback(async () => {
    analyticsManager.gtag(authEvents.login);
    await onSuccessConfirmation();
    closeModal();
  }, [onSuccessConfirmation, closeModal]);

  return (
    <CenteredClosableModal show={show} closeModal={closeModal}>
      <GenericAuthModal
        responseTimeout={responseTimeout}
        initialStep={AuthModalState.SMS}
        onSuccess={handleSuccess}
        lastStep={AuthModalState.SMS}
      />
    </CenteredClosableModal>
  );
};

export default memo(ConfirmationModal);
