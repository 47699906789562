import { IGeoGrinderPlace } from 'Modules/GeoGrinder/types';
import { IPlace } from 'Modules/Places/types';

const getCoords = (place: any): { latitude: number; longitude: number } => {
  if (place.coordinates) {
    return { latitude: place.coordinates.latitude, longitude: place.coordinates.longitude };
  }
  return { latitude: place.coordinate.latitude, longitude: place.coordinate.longitude };
};

const geoGrinderPlaceToPlace = (geoGrinderPlace: IGeoGrinderPlace): IPlace => {
  return {
    address_id: geoGrinderPlace.id,
    name: geoGrinderPlace.full_address,
    coordinates: getCoords(geoGrinderPlace),
    address_components: geoGrinderPlace.components,
    ...geoGrinderPlace.components.reduce((prev, curr) => {
      return { ...prev, [curr.type]: curr.name };
    }, {}),
  } as IPlace;
};

export default geoGrinderPlaceToPlace;
