import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import Box, { IBox } from 'Components/Grid/Box';
import { BoxProps } from '@igooods/ui-kit';
import { colors } from 'constants/colors';
import { mediaXlUp } from 'theme/helpers/css';

interface IBlockContainer extends IBox, Omit<BoxProps, 'theme'> {
  children: ReactNode;
  bgColor?: string;
  maxWidth?: string;
}

const Container = styled(Box)<{ $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: 100%;
  ${mediaXlUp`
    padding-left: 160px;
    padding-right: 160px;
  `}
`;

const Content = styled.div<{ $maxWidth: string }>`
  margin: 0 auto;

  ${({ $maxWidth }) => `max-width: ${$maxWidth};`}
`;

const BlockContainer = forwardRef<HTMLDivElement, IBlockContainer>(
  ({ children, bgColor = colors.white, px = { xs: 5, sm: 13, lg: 30 }, maxWidth = '1920px', ...props }, ref) => {
    return (
      <Container $bgColor={bgColor} {...{ px, ...props }} ref={ref}>
        <Content $maxWidth={maxWidth}>{children}</Content>
      </Container>
    );
  }
);

BlockContainer.displayName = 'BlockContainer';

export default BlockContainer;
