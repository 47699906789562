import analyticsManager from '../manager';
import { EnumGtagEvents } from 'constants/enums';
import { PaymentTypes } from 'Modules/Checkout/types';
import { truncateObjValues } from 'utils/helpers';

export const checkoutChangeTime = (asap: boolean, afterCheckout = false) => {
  analyticsManager.gtag(EnumGtagEvents.CHECKOUT_CHANGE_TIME, { afterCheckout, asap });
};

export const checkoutEnterCard = () => {
  analyticsManager.gtag(EnumGtagEvents.CHECKOUT_ENTER_CARD);
};

export const addShippingInfo = () => {
  analyticsManager.gtag(EnumGtagEvents.ADD_SHIPPING_INFO);
};

export const addPaymentInfoEvent = (payment_type: PaymentTypes, coupon?: string) => {
  analyticsManager.gtag(
    EnumGtagEvents.ADD_PAYMENT_INFO,
    truncateObjValues({
      payment_type,
      ...(coupon?.length && { coupon }),
      currency: 'RUB',
    })
  );
};

export const viewShopsEvent = (city_id: number, shop_array: number[] | number = 0) => {
  analyticsManager.gtag(EnumGtagEvents.VIEW_SHOPS, { city_id, shop_array });
};
