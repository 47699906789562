import { IApplicationState } from 'reduxStore/types';
import { isNil } from 'ramda';

export const getOrdersHistoryState = (state: IApplicationState) => state.ordersHistory;

export const getCurrentOrder = (state: IApplicationState) => getOrdersHistoryState(state).currentOrder;

export const getLastSuccessOrder = (state: IApplicationState) => getOrdersHistoryState(state).lastSuccessOrder;

export const getCurrentOrderIsFetching = (state: IApplicationState) => getOrdersHistoryState(state).isFetchingOrder;

export const getCurrentOrderId = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.id;
  }
};

export const getCurrentOrderCard = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.payment_card;
  }
};

export const getCurrentOrderComment = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.comment;
  }

  return '';
};

export const getCurrentOrderDeliveryDate = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.delivery_date;
  }
};

export const getCurrentOrderDeliveryDateEarly = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.delivery_date.early;
  }
};

export const getCurrentOrderShop = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.shop;
  }
};

export const getCurrentOrderPaymentType = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.payment_type;
  }
};

export const getCurrentOrderCashChangeFrom = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.change_from;
  }
};

export const getCurrentOrderPlace = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.place;
  }
};

export const getCurrentOrderStatus = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.status;
  }
};

export const getCurrentOrderProductsCount = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.count_products;
  }
};

export const getCurrentOrderWeight = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.weight;
  }
};

export const getCurrentOrderTotalPrice = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.total_price;
  }
};

export const getCurrentOrderInvoice = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.invoice;
  }
};

export const getCurrentOrderReceipt = (state: IApplicationState) => {
  const currentOrder = getCurrentOrder(state);
  if (!isNil(currentOrder)) {
    return currentOrder.receipt;
  }
};

export const getCurrentOrderShopImage = (state: IApplicationState) => {
  const currentOrderShop = getCurrentOrderShop(state);
  if (!isNil(currentOrderShop)) {
    return currentOrderShop.svg_o_logo_path || currentOrderShop.logo.svg_o_logo_path;
  }
};

export const getCurrentOrderShopName = (state: IApplicationState) => {
  const currentOrderShop = getCurrentOrderShop(state);
  if (!isNil(currentOrderShop)) {
    return currentOrderShop.name;
  }
};

export const getCurrentOrderItems = (state: IApplicationState) => getOrdersHistoryState(state).items;
export const getCurrentOrderProducts = (state: IApplicationState) => getOrdersHistoryState(state).products;
export const getCurrentOrderItemsLength = (state: IApplicationState) => getCurrentOrderItems(state)?.list.length ?? 0;
