import { FIRST_TIME_VISIT } from 'constants/cookie';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { formatFullDate } from 'utils/date';
import { setGtagUserProperties } from 'utils/gtag';

const useFirstTimeVisit = () => {
  useEffect(() => {
    if (!Cookies.get(FIRST_TIME_VISIT)) {
      const date = new Date().getTime();
      const custom_first_open_time = formatFullDate(date).split('.').reverse().join('');

      Cookies.set(FIRST_TIME_VISIT, custom_first_open_time);
      setGtagUserProperties({ custom_first_open_time });
    }
  }, []);
};

export default useFirstTimeVisit;
