import { ICity } from 'Modules/SearchAddress/types';

type InitialCity = Pick<ICity, 'id' | 'name' | 'coordinate'>;

const spb: InitialCity = {
  id: 1,
  name: 'Санкт-Петербург и ЛО',
  coordinate: {
    latitude: 59.93,
    longitude: 30.33,
  },
};

export const currentCityInitialByWL: InitialCity = spb;
