import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Cookie from 'js-cookie';
import {
  AuthTokenService,
  getResponseData,
  getRejectedPromise,
  addUserToken,
  updateParamsSerializer,
} from '@igooods/axios-helpers';
import { getApiUrl } from 'lib/apiService';
import { REF_COOKIE, REFERRER_COOKIE } from '../constants/cookie';
import { getDeviceType } from '@igooods/user-agent';
import { URL } from 'constants/urlMaps';
import { isBrowser } from 'utils/helpers';
import { sendBugsnagReport } from 'utils/errors';

export interface IHttp {
  get<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
}

interface IApiResponseData {
  code: number;
  data: any;
}

const defaultHeaders = {
  'X-Platform': 'web',
};

if (isBrowser) {
  const { isMobile } = getDeviceType(navigator.userAgent);

  Object.assign(defaultHeaders, {
    'X-Type': isMobile ? 'mobile' : 'desktop',
  });
}

export const createAxiosInstance = (apiUrl?: string) =>
  axios.create({
    baseURL: `${apiUrl || getApiUrl()}/api/`,
    headers: defaultHeaders,
  });

export const axiosInstance = createAxiosInstance();

export class Http implements IHttp {
  private readonly axios: AxiosInstance;

  public constructor(instance = axiosInstance, apiVersion?: string) {
    this.axios = instance;

    this.axios.interceptors.request.use(Http.interceptorRequestSuccess, Http.interceptorRequestError);
    this.axios.interceptors.response.use(Http.interceptorResponseSuccess, Http.interceptorResponseError);

    this.axios.interceptors.request.use(config => {
      const defaultVersion = apiVersion || 'komus/v1';
      const version = config.apiVersion || defaultVersion;
      const configWithApi = { ...config };
      configWithApi.baseURL += version;
      return updateParamsSerializer(configWithApi);
    });
  }

  public get<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(url, {
      params,
      ...config,
    });
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.post(url, data, config);
  }

  public patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.patch(url, data, config);
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.delete(url, config);
  }

  private static interceptorRequestSuccess(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = AuthTokenService.get();
    const ref = Cookie.get(REF_COOKIE);
    const referrer = Cookie.get(REFERRER_COOKIE);

    if (token) {
      addUserToken(token, config);
    }

    if (ref) {
      config.headers['X-Ref'] = ref;
    }

    if (referrer) {
      config.headers['X-HTTP-Referrer'] = referrer;
    }

    return config;
  }

  private static interceptorRequestError(error: AxiosError): Promise<never> {
    sendBugsnagReport(error);
    return getRejectedPromise(error);
  }

  private static interceptorResponseSuccess(response: AxiosResponse<IApiResponseData>): any {
    if (response.data instanceof ArrayBuffer) {
      return response.data;
    }

    return getResponseData(response);
  }

  protected static interceptorResponseError(error: AxiosError): Promise<never> {
    if (!axios.isCancel(error)) {
      // temporary hack for unauthorized users with expired token https://igooods.atlassian.net/browse/IE-2006
      if (error.response?.status === 401 && isBrowser) {
        // hack for clear cookie if broken token etc
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf('=');
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
        window.location.href = `${window.location.origin}${URL.select_address}`;
      }
    }
    return Promise.reject(error);
  }
}

export default new Http();
