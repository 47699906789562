import React, { memo } from 'react';
import { URL } from 'constants/urlMaps';
import { formatDateToMonth } from 'utils/date';
import { FontSize } from 'theme';
import { colors } from 'constants/colors';
import { STATES } from 'Containers/Profile/Orders/OrderStatusLabel';
import Box from 'Components/Grid/Box';
import Text from 'Components/Typography/Text';
import { IOrdersMainInfo } from 'Modules/OrdersHistory/types';
import { equals } from 'ramda';
import LinkWrapper from 'Components/Link/LinkWrapper';
import { OrderWrapper, OrderDate, ShopImageWrapper } from './styled';

export const UPCOMING_ORDERS_TEST_ID = 'UPCOMING_ORDERS_TEST_ID';
export const UPCOMING_ORDER_LINK_TEST_ID = 'UPCOMING_ORDER_LINK_TEST_ID';
export const UPCOMING_ORDERS_FETCH_INTERVAL = 300000;

interface IProfileDropdown {
  orders: IOrdersMainInfo[];
}

const UpcomingOrders: React.FC<IProfileDropdown> = ({ orders }) => {
  return (
    <Box data-testid={UPCOMING_ORDERS_TEST_ID}>
      {orders.map(order => (
        <LinkWrapper key={order.id} to={`${URL.profile_orders}/${order.id}`} data-testid={UPCOMING_ORDER_LINK_TEST_ID}>
          <OrderWrapper>
            <ShopImageWrapper image={order.shop_logo} />
            <Box>
              <OrderDate>Заказ на {formatDateToMonth(order.delivery_date)}</OrderDate>
            </Box>
            <Text size={FontSize.xs} color={colors.warmGrey}>
              {STATES[order.status].label}
            </Text>
          </OrderWrapper>
        </LinkWrapper>
      ))}
    </Box>
  );
};

export default memo(UpcomingOrders, equals);
