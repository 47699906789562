import React from 'react';
import { Route, RouteComponentProps, withRouter, Switch } from 'react-router';
import { URL } from './constants/urlMaps';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';
import FullPageLoading from './Containers/Loading/FullPageLoading';
import { checkIsTempUser, getUserIsLoaded, getUserNameIsSet } from './Modules/AAA/selectors';
import PrivateRoute from './Components/PrivateRoute';
import { getIsCartShopIdPresent, isReadyCartForCheckout } from './Modules/Cart/selectors';
import OnlineSuccessPayment from './Containers/Checkout/OnlineSuccessPayment';
import loadable from '@loadable/component';
import { equals } from 'ramda';
import { RedirectWithStatus } from 'html/Routes';
import styled from 'styled-components';
import { fadeInOpacity, fadeOutOpacity } from './Components/Animation';
import { getCurrentShopOpened } from 'Modules/Shops/selectors';
import { getIsShopIdPresent } from 'Modules/Shops/selectors';
import { landingURLs } from './Containers/LandingPages';

const PaymentApplyCardPage = loadable(() => import(/* webpackChunkName: "ApplyCard" */ './Containers/ApplyCard'), {
  fallback: <FullPageLoading />,
});

// const MobileApp = loadable(() => import(/* webpackChunkName: "MobileApp" */ './Containers/MobileApp'), {
//   fallback: <FullPageLoading />,
// });

const BusinessPage = loadable(() => import(/* webpackChunkName: "BusinessPage" */ './Containers/Business'), {
  fallback: <FullPageLoading />,
});

const MainLanding = loadable(() => import(/* webpackChunkName: "MainLanding" */ './Containers/Main'), {
  fallback: <FullPageLoading />,
});

const RegistrationRedirrectPage = loadable(
  () => import(/* webpackChunkName: "RegistrationRedirrectPage" */ './Containers/RegistrationRedirrectPage'),
  {
    fallback: <FullPageLoading />,
  }
);

const SignInPage = loadable(() => import(/* webpackChunkName: "SignInPage" */ './Containers/SignIn'), {
  fallback: <FullPageLoading />,
});

const SignInEntityPage = loadable(
  () => import(/* webpackChunkName: "SignInEntityPage" */ './Containers/SignInEntity'),
  {
    fallback: <FullPageLoading />,
  }
);
const SelectAddress = loadable(() => import(/* webpackChunkName: "SelectAddress" */ './Containers/SelectAddress'), {
  fallback: <FullPageLoading />,
});

const CoverageZone = loadable(
  () => import(/* webpackChunkName: "CoverageZone" */ './Containers/SelectAddress/CoverageZone'),
  { fallback: <FullPageLoading /> }
);
const Catalog = loadable(() => import(/* webpackChunkName: "Catalog" */ './Containers/Catalog'), {
  fallback: <FullPageLoading />,
});
const Checkout = loadable(() => import(/* webpackChunkName: "Checkout" */ './Containers/Checkout'), {
  fallback: <FullPageLoading />,
});

const Product = loadable(() => import(/* webpackChunkName: "Product" */ './Containers/Product'), {
  fallback: <FullPageLoading />,
});

const Cart = loadable(() => import(/* webpackChunkName: "Checkout" */ './Containers/Cart'), {
  fallback: <FullPageLoading />,
});

const Profile = loadable(() => import(/* webpackChunkName: "Profile" */ 'Containers/Profile'), {
  fallback: <FullPageLoading />,
});

const NotFoundPage = loadable(() => import(/* webpackChunkName: "NotFoundPage" */ 'Containers/NotFound'), {
  fallback: <FullPageLoading />,
});

// FAQ PAGES
const HowItWorks = loadable(() => import(/* webpackChunkName: "HowItWorks" */ 'Containers/FaqPages/HowItWorks'), {
  fallback: <FullPageLoading />,
});
const Delivery = loadable(() => import(/* webpackChunkName: "Delivery" */ 'Containers/FaqPages/Delivery'), {
  fallback: <FullPageLoading />,
});
const Payment = loadable(() => import(/* webpackChunkName: "Payment" */ 'Containers/FaqPages/Payment'), {
  fallback: <FullPageLoading />,
});
const ReturnPolicyDefault = loadable(
  () => import(/* webpackChunkName: "ReturnPolicyDefault" */ 'Containers/FaqPages/ReturnPolicy/Default'),
  {
    fallback: <FullPageLoading />,
  }
);

const BuyInIgooodsDeepLink = loadable(
  () => import(/* webpackChunkName: "BuyInIgooodsDeepLink" */ 'Containers/BuyInIgooodsLink'),
  {
    fallback: <FullPageLoading />,
  }
);

const LandingPages = loadable(() => import(/* webpackChunkName: "LandingPages" */ './Containers/LandingPages'), {
  fallback: <FullPageLoading />,
});

const StyledTransitionGroup = styled(TransitionGroup)<{ $transitionName: string }>`
  ${({ $transitionName }) => `.${$transitionName}-enter`} {
    opacity: 0;
  }

  ${({ $transitionName }) => `.${$transitionName}-active`} {
    animation: ${fadeInOpacity} 0.2s linear 0.1s;
  }

  ${({ $transitionName }) => `.${$transitionName}-exit`} {
    height: 0;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  ${({ $transitionName }) => `.${$transitionName}-exit-active`} {
    animation: ${fadeOutOpacity} 0.2s linear forwards;
  }
`;

export const Routes: React.FC<RouteComponentProps> = ({ location }) => {
  const isLoaded = useSelector(getUserIsLoaded);
  const isCartShopIdPresent = useSelector(getIsCartShopIdPresent);
  const isCurrentShopOpened = useSelector(getCurrentShopOpened);
  const isReadyToCheckout = useSelector(isReadyCartForCheckout);
  const isNotTempUser = !useSelector(checkIsTempUser, equals);
  const userNameIsSet = useSelector(getUserNameIsSet, equals);
  const isShopIdPresent = useSelector(getIsShopIdPresent);

  return (
    <StyledTransitionGroup $transitionName="fade-opacity">
      <CSSTransition key={location.pathname.split('/')[1]} classNames="fade-opacity" timeout={150}>
        <Switch location={location}>
          {/* Redirects for old web */}
          <RedirectWithStatus status={303} exact from={'/orders'} to={URL.profile_orders} />
          <RedirectWithStatus status={303} exact from={'/bonus'} to={URL.profile_points} />
          <RedirectWithStatus status={303} exact from={'/partner'} to={URL.profile_points} />
          <RedirectWithStatus status={303} exact from={'/user_cards'} to={URL.profile_payments} />
          <RedirectWithStatus status={303} exact from={'/places'} to={URL.profile_places} />
          <RedirectWithStatus status={303} exact from={'/users'} to={URL.profile} />
          <RedirectWithStatus status={303} exact from={'/users/sign_in'} to={URL.signin} />
          <RedirectWithStatus status={303} exact from={'/product_sets'} to={URL.catalog} />
          <RedirectWithStatus status={303} exact from={'/products'} to={URL.catalog} />
          <RedirectWithStatus status={303} exact from={'/items'} to={URL.cart} />
          <RedirectWithStatus status={303} exact from={URL.catalog} to={'/catalog/categories/1'} />

          <Route path={URL.signin} component={SignInPage} />
          <Route path={URL.select_address} component={SelectAddress} />
          <Route path={URL.coverage_zone} component={CoverageZone} />
          <Route path={URL.product} component={Product} />
          <Route path={URL.email_confirmation} component={RegistrationRedirrectPage} exact />

          {/* FAQ PAGES */}
          <Route path={URL.business} component={BusinessPage} />
          <Route path={URL.payment} component={Payment} />
          <Route path={URL.delivery} component={Delivery} />
          <Route path={URL.how_it_works} component={HowItWorks} />
          <Route path={URL.obmen_i_vozvrat_tovara} component={ReturnPolicyDefault} />

          {isLoaded ? (
            <Switch>
              <PrivateRoute
                isAuthed={!isCartShopIdPresent && !isNotTempUser}
                exact
                path={URL.root}
                component={MainLanding}
                redirect_path={isCartShopIdPresent ? URL.catalog : URL.select_address}
              />
              <Route exact path={landingURLs} component={LandingPages} />

              {/*<PrivateRoute*/}
              {/*  isAuthed={!isNotTempUser}*/}
              {/*  redirect_path={URL.catalog}*/}
              {/*  component={MobileApp}*/}
              {/*  path={URL.mobile_app}*/}
              {/*/>*/}
              <PrivateRoute
                isAuthed={isCurrentShopOpened && isShopIdPresent}
                path={URL.catalog}
                component={Catalog}
                redirect_path={URL.select_address}
              />
              <PrivateRoute
                isAuthed={isNotTempUser}
                path={URL.profile}
                redirect_path={URL.select_address}
                component={Profile}
              />
              <PrivateRoute
                isAuthed={!isNotTempUser || !userNameIsSet}
                redirect_path={isCartShopIdPresent ? URL.catalog : URL.select_address}
                path={URL.signin_entity}
                component={SignInEntityPage}
              />
              <PrivateRoute
                isAuthed={isCurrentShopOpened && isCartShopIdPresent}
                path={URL.cart}
                redirect_path={URL.select_address}
                component={Cart}
              />
              <PrivateRoute
                isAuthed={isCurrentShopOpened && isReadyToCheckout}
                path={URL.checkout}
                redirect_path={URL.cart}
                component={Checkout}
              />
              {/* This success and failure routes for old mobile redirects! */}
              {/* DO NOT DELETE online_payment_success and online_payment_failure! */}
              <PrivateRoute
                isAuthed={isNotTempUser}
                path={URL.online_payment_success}
                component={OnlineSuccessPayment}
                redirect_path={URL.select_address}
              />
              <PrivateRoute
                isAuthed={isReadyToCheckout}
                path={URL.online_payment_failure}
                component={Checkout}
                redirect_path={URL.select_address}
              />
              {/* This is new generic payment routes! Use them in newer versions */}
              <PrivateRoute
                isAuthed={isNotTempUser}
                path={URL.generic_payment_success}
                component={OnlineSuccessPayment}
                redirect_path={URL.select_address}
              />
              <PrivateRoute
                isAuthed={isReadyToCheckout}
                path={URL.generic_payment_failure}
                component={Checkout}
                redirect_path={URL.select_address}
              />
              <Route path={URL.generic_payment_apply_card} component={PaymentApplyCardPage} />
              <Route path={URL.buy_in_igooods} component={BuyInIgooodsDeepLink} />
              <Route component={NotFoundPage} />
            </Switch>
          ) : (
            <FullPageLoading />
          )}
        </Switch>
      </CSSTransition>
    </StyledTransitionGroup>
  );
};

export default withRouter(Routes);
