import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const GeoLocationIcon: FC<IIcon> = props => (
  <Icon width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <path id="prefix__a" d="M5.831 6L0 4.47 13 0 6.318 12z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 3)">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <use fill="#757575" xlinkHref="#prefix__a" />
      <g fill="#CCC" fillRule="nonzero" mask="url(#prefix__b)">
        <path d="M0 0H16V16H0z" transform="translate(-1 -2)" />
      </g>
    </g>
  </Icon>
);

export default GeoLocationIcon;
