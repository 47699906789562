import { CITIES_MODAL_ID, MOBILE_WIDTH, CITIES_MODAL_LIST_TEST_ID } from './constants';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import React, { FC, useEffect, useState } from 'react';
import { getMultipliedIndentFromProp, getTypographyFontStyleMobile } from 'theme/selectors';
import { mediaCustomDown, mediaMdDown, mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ICity } from 'Modules/SearchAddress/types';
import { updateProvince } from 'Components/Header/CitiesButton/utils';
import ThemeButton from 'Components/ThemedButton';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import Flex from 'Components/Grid/Flex';
import { FontSize } from 'theme';
import Title from 'Components/Typography/Title';
import { colors } from 'constants/colors';
import { getCitiesList } from 'Modules/SearchAddress/selectors';
import { getHeightForListCities, isMainCity } from './utils';

interface ICityItem {
  selected: boolean;
  main: boolean;
}

const Container = styled(Flex).attrs({ column: true })`
  width: 872px;
  max-width: 85vw;
  background-color: ${colors.white};
  padding: 60px 40px 80px;

  ${mediaSmUp`
    margin-bottom: 100px;
    border-radius: 4px;
  `}

  ${mediaSmDown`
    min-height: 100%;
    width: 100vw;
    max-width: unset;
    border-radius: 0;
    padding: 48px 16px 60px;
  `}
  
    ${mediaCustomDown(MOBILE_WIDTH)`
     padding: 14px 0px 30px;
  `}
`;

const Header = styled(Flex).attrs({ center: true })`
  ${mediaCustomDown(MOBILE_WIDTH)`
     justify-content: flex-start;
     padding-left: ${getMultipliedIndentFromProp(4)};
  `}
`;

const CitiesTitle = styled(Title).attrs({ center: true })`
  margin-bottom: 30px;
  ${mediaSmDown`
    font-size: 20px;
    line-height: 28px;
  `}
  ${mediaCustomDown(MOBILE_WIDTH)`
    text-align: left;
    margin-bottom: 14px;
    ${getTypographyFontStyleMobile(FontSize.xl)}
  `}
`;

const CitiesList = styled(Flex).attrs({ wrap: true, column: true })<{ length: number }>`
  width: 100%;
  max-height: ${getHeightForListCities(4)}px;
  ${mediaMdDown`max-height: ${getHeightForListCities(3)}px;`}
  ${mediaCustomDown(MOBILE_WIDTH)`
     max-height: unset;
     flex-wrap: nowrap;
  `}
`;

const CityItem = styled(ThemeButton).attrs({ use: EnumTheme.white })<ICityItem>`
  height: 40px;
  max-width: 200px;
  width: 100%;
  color: ${colors.darkGrey};
  user-select: none;
  margin-right: 8px;
  margin-top: 4px;

  div {
    text-align: left;
  }

  ${({ selected }) => selected && `background-color: ${colors.paleGrey};`}
  ${({ main }) => main && `font-weight: 600;`}
  
  ${mediaSmDown`
    font-size: 14px;
 `}
  
  ${mediaCustomDown(MOBILE_WIDTH)`
    max-width: unset;
    margin-right: 0;
    margin-top: 0;
    border-radius: unset;
  `}
`;

interface ICitiesModal {
  onSelectItem: (value: ICity) => void;
  currentCity: ICity;
  closeModal: () => void;
  isModalShown: boolean;
}

const CitiesModal: FC<ICitiesModal> = ({ currentCity, isModalShown, closeModal, onSelectItem }) => {
  const [selectedCity, setSelectedCity] = useState<ICity>(currentCity);
  const citiesResults = useSelector(getCitiesList);

  const { name, id } = currentCity;
  const [cities, setCities] = useState<ICity[]>(
    currentCity ? [{ ...currentCity, name: updateProvince(name, id) }] : []
  );

  useEffect(() => {
    if (citiesResults.length) {
      setCities(citiesResults);
    }
  }, [citiesResults]);

  useEffect(() => {
    if (currentCity && typeof currentCity !== 'string') {
      setSelectedCity(currentCity);
    }
  }, [currentCity]);

  const handlerItem = (city: ICity) => {
    onSelectItem(city);
  };

  return isModalShown ? (
    <CenteredClosableModal show={isModalShown} closeModal={closeModal}>
      <Container data-testid={CITIES_MODAL_ID}>
        <Header>
          <CitiesTitle level="2">Город доставки</CitiesTitle>
        </Header>
        <CitiesList length={cities.length} data-testid={CITIES_MODAL_LIST_TEST_ID}>
          {cities.map(item => (
            <CityItem
              selected={item.id === selectedCity.id}
              main={isMainCity(item)}
              onClick={() => handlerItem(item)}
              use={EnumTheme.white}
              size={EnumButtonSize.normal}
              key={String(item.id)}
            >
              {item.name}
            </CityItem>
          ))}
        </CitiesList>
      </Container>
    </CenteredClosableModal>
  ) : null;
};

export default CitiesModal;
