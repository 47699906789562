import Http, { IHttp } from 'lib/http';
import { ICoordinate } from 'Modules/Places/types';
import { ICitiesResponse, IAddressCoordResponse, IFeedbackNotFoundAddress, ICity } from './types';

export interface ISearchAddressHttp {
  getCities(): Promise<ICitiesResponse>;
  getCoordFromAddress(city_id: number, query: string): Promise<IAddressCoordResponse>;
  getCoordFromKladr(
    city_id: number,
    object_kladr: string,
    house: string,
    query: string
  ): Promise<IAddressCoordResponse>;
  feedbackNotFoundAddress({
    city_id,
    city,
    street,
    building,
    coordinates,
    contact,
  }: IFeedbackNotFoundAddress): Promise<{}>;
}

export class SearchAddressHttp implements ISearchAddressHttp {
  private readonly http: IHttp = Http;

  public getCities(fetcher = Http): Promise<ICitiesResponse> {
    return fetcher.get('/cities', { apiVersion: 'komus/v1' });
  }

  public getCoordFromAddress(city_id: number, query: string, fetcher = this.http): Promise<IAddressCoordResponse> {
    return fetcher.get('/places/address_coordinate', { city_id, query });
  }

  public getCityFromCoord({ longitude, latitude }: ICoordinate): Promise<{ city: ICity }> {
    return this.http.get(
      '/cities/identify_by_coordinate',
      {
        longitude,
        latitude,
      },
      { apiVersion: 'komus/v1' }
    );
  }

  public getCoordFromKladr(
    city_id: number,
    object_kladr: string,
    house: string,
    query: string
  ): Promise<IAddressCoordResponse> {
    return this.http.get('/places/house_coords_by_kladr', {
      city_id,
      object_kladr,
      house,
      query,
    });
  }

  public feedbackNotFoundAddress({
    city_id,
    city,
    street,
    building,
    coordinates,
    contact,
  }: IFeedbackNotFoundAddress): Promise<{}> {
    return this.http.post('/feedback/delivery', {
      city_id,
      city,
      street,
      building,
      coordinates,
      contact,
    });
  }
}

export default new SearchAddressHttp();
