import React, { FC } from 'react';
import Flex from 'Components/Grid/Flex';
import { PROFILE_NAVBAR_TEST_ID } from '../constants';
import { URL } from 'constants/urlMaps';
import ExternalLinkIcon from 'Components/Icon/ExternalLinkIcon';
import { Box } from '@igooods/ui-kit';
import { INavItemLinkProps, ISidebarNav } from './types';
import styled, { css } from 'styled-components';
import { FontSize } from 'theme';
import { colors } from 'constants/colors';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { NavLink } from 'react-router-dom';
import ExternalLink from 'Components/ExternalLink';
import Text from 'Components/Typography/Text';
import { RUB } from 'constants/constants';
import { useSelector } from 'react-redux';
import { getEntityBalance } from 'Modules/Entity/selectors';

export const NavItem = styled(Flex).attrs({ middle: true })`
  letter-spacing: -0.3px;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
`;

export const NavLinkStyles = css`
  white-space: nowrap;
  margin-top: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  color: ${colors.darkGrey};
  text-decoration: none;
  cursor: pointer;
  transition: color 0.048s cubic-bezier(0.4, 0, 0.6, 1) 0.016s;

  &:first-child {
    margin-top: 0;
  }

  &:active,
  &.active {
    text,
    div {
      font-weight: 500;
      color: ${colors.primaryMain};
    }
  }

  ${addStyleIfPropTruly(
    'blocked',
    `
    pointer-events: none;
    color: ${colors.warmGrey};
  `
  )}
`;

export const InternalNavLink = styled(NavLink)<INavItemLinkProps>`
  ${NavLinkStyles};
`;

export const AboutUsNavLink = styled(ExternalLink)`
  ${NavLinkStyles};

  &:hover {
    color: ${colors.darkGrey};
  }
`;

export const NavItemText = styled(Text).attrs({ size: FontSize.s })`
  letter-spacing: -0.3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.dark300};
  position: relative;
  &:hover {
    color: ${colors.primaryMain};
  }
  &:active,
  &.active {
    text,
    div {
      font-weight: 500;
      color: ${colors.primaryMain};
    }
  }
`;

export const Balance = styled.div`
  margin-left: 7px;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.dark200};
`;

export const NAV_ITEM_ADDON_TEST_ID = 'NAV_ITEM_ADDON_TEST_ID';
export const PROFILE_NAVBAR_LINK_NAME_TEST_ID = 'PROFILE_NAVBAR_LINK_NAME_TEST_ID';
export const PROFILE_NAVBAR_LINK_TEST_ID = 'PROFILE_NAVBAR_LINK_TEST_ID';

const Navigation: FC<ISidebarNav> = ({ routes, className }) => {
  const balance = useSelector(getEntityBalance);

  return (
    <Flex column top pt={5.5} pb={2} className={className} data-testid={PROFILE_NAVBAR_TEST_ID}>
      {routes.map(({ path, name, isBlocked }) => (
        <InternalNavLink data-testid={PROFILE_NAVBAR_LINK_TEST_ID} blocked={isBlocked} key={path} to={path}>
          <NavItemText data-testid={PROFILE_NAVBAR_LINK_NAME_TEST_ID}>{name}</NavItemText>
          {name === 'Кредитный лимит' && (
            <Balance>
              {balance} {RUB}
            </Balance>
          )}
        </InternalNavLink>
      ))}
      <AboutUsNavLink href={URL.how_it_works} target="_blank">
        <NavItemText>Как мы работаем</NavItemText>
        <Box ml={2.5}>
          <ExternalLinkIcon />
        </Box>
      </AboutUsNavLink>
    </Flex>
  );
};

export default Navigation;
