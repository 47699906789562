import { useEffect, useRef } from 'react';

interface IPollingOptions {
  interval?: number;
  stopWhen?: boolean;
}

/**
 * @param effect - функция-эффект для поллинга
 * @param deps - массив зависимостей
 * @param [options] - дополнительные опции поллинга
 * @param {number} [options.interval=10000] - интервал поллинга
 * @param {boolean} [options.stopWhen] - условие для остановки поллинга
 */
const useEffectWithPolling = (
  effect: (args: any) => any,
  deps: any[],
  options: IPollingOptions = { interval: 10000 }
) => {
  const pollingInterval = useRef<number | null>(null);
  const { interval, stopWhen } = options;

  const clearIntervalIfExists = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  useEffect(() => {
    // остановка поллинга по условию
    if (stopWhen) {
      clearIntervalIfExists();
    }
  }, [stopWhen]);

  useEffect(() => {
    if (!pollingInterval.current && !stopWhen) {
      // запуск поллинга
      pollingInterval.current = setInterval(effect, interval);
    }

    return () => {
      // остановка поллинга при изменении зависимостей или размонтировании компонента
      clearIntervalIfExists();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useEffectWithPolling;
