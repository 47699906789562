import React from 'react';
import styled from 'styled-components';
import { BorderedModalStyles } from 'Components/Modal/styled';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import Loader from 'Components/Loader';
import Flex from 'Components/Grid/Flex';

export const ModalContainer = styled(Flex).attrs({
  center: true,
  middle: true,
})`
  width: 480px;
  height: 600px;
  background-color: white;

  ${BorderedModalStyles}

  ${mediaSmUp`
    margin-bottom: 40px;
  `}

  ${mediaSmDown`
    width: 100%;
    height: 100%;
  `}
`;

const ModalLoading = () => {
  return (
    <ModalContainer>
      <Loader />
    </ModalContainer>
  );
};

export default ModalLoading;
