import React from 'react';
import Icon, { IIcon } from './Icon';

const NewSocialFBIcon: React.FC<IIcon> = props => (
  <Icon width="6" height="11" viewBox="0 0 6 11" {...props}>
    <path d="M6 .08C5.812.052 5.167 0 4.417 0 2.847 0 1.77.912 1.77 2.585v1.44H0v1.958h1.77V11h2.126V5.983H5.66l.27-1.957H3.897v-1.25c0-.561.16-.951 1.014-.951H6V.079z" />
  </Icon>
);

export default NewSocialFBIcon;
