import { IProduct } from 'Modules/Products/types';
import { EnumFromName, EnumGtagEvents } from 'constants/enums';
import { IProductEventParams } from 'utils/tagManager/types';
import { omit } from 'ramda';
import {
  formattedProductForGTag,
  getAdditionalParamsForGTag,
  getPromotionParamsForGTag,
} from 'utils/tagManager/helpers';
import analyticsManager from 'utils/tagManager/manager';

// Просмотр карточки товара где-либо
export default function productCardView(
  product: IProduct,
  { from, fromId, analyticsParams = {} }: IProductEventParams
): void {
  const additionalParams = getAdditionalParamsForGTag({
    params: { position: 1, from, fromId },
    product,
  });

  if (analyticsParams?.promotionId) {
    analyticsManager.gtag(EnumGtagEvents.SELECT_PROMOTION, {
      items: [
        {
          ...omit(
            ['quantity'],
            formattedProductForGTag(product, {
              ...getPromotionParamsForGTag(analyticsParams),
              ...additionalParams,
            })
          ),
        },
      ],
    });
  } else {
    const event = from === EnumFromName.PRODUCT ? EnumGtagEvents.VIEW_ITEM : EnumGtagEvents.SELECT_ITEM;
    analyticsManager.gtag(event, {
      items: [
        {
          ...formattedProductForGTag(product, additionalParams),
        },
      ],
    });
  }

  analyticsManager.criteo({
    event: 'viewItem',
    item: String(product.model_id),
  });
}
