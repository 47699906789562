import React, { memo, forwardRef, HTMLAttributes } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Box, { IBox, IBoxProps } from './Box';
import { IRow } from './Row';
import { addFlexStyles, useMediaQuery } from './utils/styles';
import { omitProps } from 'utils/hocs';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { rowProps } from '@igooods/ui-kit';

export const FlexStyled = styled(omitProps(['wrap', 'theme', ...rowProps])(Box))`
  display: flex;
  ${addStyleIfPropTruly('wrap', 'flex-wrap: wrap;')}
  ${addStyleIfPropTruly('baseline', 'align-items: baseline;')}
  ${addFlexStyles}
`;

export interface IFlex extends IRow, IBoxProps, IBox, HTMLAttributes<HTMLDivElement> {
  theme: DefaultTheme;
  wrap?: boolean;
  baseline?: boolean;
  notShrink?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const Flex = memo(
  forwardRef<HTMLDivElement, IFlex>((props, ref) => {
    const mediaQuery = useMediaQuery(props.theme);

    return <FlexStyled {...{ ...props, mediaQuery }} ref={ref} />;
  })
);

Flex.displayName = 'Flex';

export default withTheme(Flex);
