export enum EnumDeployEnvironment {
  dev = 'development',
  staging = 'staging',
  preprod = 'preproduction',
  prod = 'production',
}

export enum EnumThemeType {
  dark = 'dark',
  light = 'light',
}

export enum EnumInputTheme {
  main = 'main',
  mainSecondary = 'mainSecondary',
  round = 'round',
  roundSecondary = 'roundSecondary',
  roundBorder = 'roundBorder',
  roundBorderGrayPlaceholder = 'roundBorderGrayPlaceholder',
}

export enum EnumTheme {
  main = 'main',
  secondary = 'secondary',
  secondaryLight = 'secondaryLight',
  danger = 'danger',

  textMain = 'textMain',
  textSecondary = 'textSecondary',
  textDanger = 'textDanger',
  textSecondaryMain = 'textSecondaryMain',

  quaternary = 'quaternary',
  white = 'white',
  borderWhite = 'borderWhite',
  link = 'link',

  roundGreen = 'roundGreen', //redesign main
  roundWhite = 'roundWhite',
  roundBlack = 'roundBlack',
  roundGreenText = 'roundGreenText',
  roundPink = 'roundPink',
  roundBorderGray = 'roundBorderGray',
  beveledMint = 'beveledMint',
  beveledWhite = 'beveledWhite',
  roundBorderWhite = 'roundBorderWhite',
  roundGhostWhite = 'roundGhostWhite',
  roundGhostGray = 'roundGhostGray',
  roundRed = 'roundRed',
  beveledGray = 'beveledGray',
  roundDarkGray = 'roundDarkGray',
}

export enum EnumButtonSize {
  small = 'small',
  normal = 'normal',
  large = 'large',
}

export enum EnumProductStates {
  toBasket = 'to_basket',
  inBasket = 'in_basket',
  revert = 'revert',
  notAvailable = 'not_available',
}

export type ProductState = 'to_basket' | 'in_basket' | 'revert' | 'not_available';

export enum EnumSpinnerSize {
  small = 'small',
  normal = 'normal',
}

export enum EnumInputSize {
  small = 'small',
  normal = 'normal',
  large = 'large',
}

export enum EnumFavoriteSizes {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

export enum EnumSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum EnumPriceOwner {
  CATALOG = 'catalog',
  SEARCH_RESULT = 'search result',
  CART = 'cart',
  CARD = 'card',
}

export enum EnumShape {
  circle = 'circle',
  square = 'square',
}

export enum EnumFetch {
  init = 'init',
  success = 'success',
  fail = 'fail',
}

export enum ModalType {
  Auth = 'Auth',
  Confirmation = 'Confirmation',
  Discount = 'Discount',
  CartRunsOut = 'CartRunsOut',
  NetworkError = 'NetworkError',
  Passport = 'Passport',
  AccountActivation = 'AccountActivation',
}

export enum AuthModalState {
  Phone = 'phone',
  SMS = 'sms',
  FORM = 'form',
  Attempts = 'attempts',
  Loading = 'loading',
  RegisterStep = 'RegisterStep',
}

export enum ConfirmationModalState {
  SMS = 'sms',
  Attempts = 'attempts',
  Loading = 'loading',
}

export enum InputState {
  // {
  //     focus: 0,
  //     clicked: 0,
  //     changed: 0,
  //     valid: 1,
  //     empty: 1
  //   }
  Clean = 'clean',
  // {
  //     focus: 1,
  //     clicked: 1,
  //     changed: 1,
  //     valid: 1,
  //     empty: 0
  //   }
  Dirty = 'dirty',
  // {
  //     focus: 0,
  //     clicked: 1,
  //     changed: 1,
  //     valid: 1,
  //     empty: 0
  //   }
  Valid = 'valid',
  // {
  //     focus: 1,
  //     clicked: 1,
  //     changed: 1,
  //     valid: 0,
  //     empty: 0
  //   }
  DirtyInvalid = 'dirty-invalid',
  // {
  //     focus: 0,
  //     clicked: 1,
  //     changed: 1,
  //     valid: 0,
  //     empty: 0
  //   }
  Invalid = 'invalid',
  // {
  //     focus: 1,
  //     clicked: 1,
  //     changed: 0,
  //     valid: 1,
  //     empty: 1
  //   }
  CleanFocus = 'clean-focus',
  // {
  //     focus: 0,
  //     clicked: 1,
  //     changed: 0,
  //     valid: 1,
  //     empty: 1
  //   }
  CleanBlur = 'clean-blur',
  // {
  //     focus: 1,
  //     clicked: 1,
  //     changed: 1,
  //     valid: 1,
  //     empty: 1
  //   }
  ClearedFocus = 'cleared-focus',
}

export enum InputFormat {
  default = 'default',
  email = 'email',
  tel = 'tel',
  number = 'number',
  placeName = 'placeName',
  street = 'street',
  apartment = 'apartment',
  stage = 'stage',
  porch = 'porch',
  inn = 'inn',
  change = 'change',
}

export enum KeyCode {
  Down = 40,
  Up = 38,
  Left = 37,
  Right = 39,
  Enter = 13,
  Esc = 27,
  Space = 32,
  Backspace = 8,
}

export enum sortStates {
  byAdding = 'by_adding',
  byCategory = 'by_category',
  byPrice = 'by_price',
  byPriceKG = 'by_price_kg',
}

export enum directSortStates {
  asc = 'asc',
  desc = 'desc',
}

export enum ProductStates {
  toBasket = 'to_basket',
  inBasket = 'in_basket',
  revert = 'revert',
  notAvailable = 'not_available',
}

export enum ProductFavoriteStates {
  default = 'default',
  unlogged = 'unlogged',
}

export enum Social {
  FB = 'fb',
  VK = 'vk',
  OK = 'ok',
  WP = 'wp',
  TG = 'tg',
  EMAIL = 'email',
}

export enum SocialLinks {
  vkontakte = 'ВКонтакте',
  facebook = 'Facebook',
  inst = 'Instagram',
  youtube = 'youtube',
  telegram = 'Telegram',
  whatsapp = 'WhatsApp',
}

export enum PaymentCardName {
  Mir = '"Мир"',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
  Maestro = 'Maestro',
}

export enum TooltipTheme {
  exclamation = 'exclamation',
  question = 'question',
}

export enum EnumNotification {
  danger = 'danger',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

export enum EnumFromName {
  CATALOG = 'catalog',
  SEARCH_RESULT = 'search result',
  SEARCH_SUGGEST = 'search suggest',
  SALES = 'sales',
  FAVORITE = 'favorite',
  CART = 'cart',
  HISTORY = 'history',
  SET = 'set',
  AD_SET = 'ad set',
  PRODUCT = 'product',
  ORDER_CATALOG = 'order_catalog',
  ORDER = 'order',
  IMPULSE = 'impulse',
  USER_SET = 'user_set',
}

export enum EnumPromotionType {
  SET = 'product_set',
  LINE = 'product_line',
  LABEL = 'product_label',
}

export enum EnumEvents {
  AUTH_START = 'auth: start auth',
  AUTH_ENTER_PHONE = 'auth: enter phone',
  AUTH_START_SOCIAL = 'auth: start social auth',
  AUTH_CONFIRM_CODE = 'auth: confirm code',
  AUTH_RESEND_CODE = 'auth: resend code',
  AUTH_ENTER_NAME = 'auth: enter name',
  AUTH_ENTER_EMAIL = 'auth: enter email',
  DELIVERY_RATE_ORDER = 'delivery: rate order',
  START_SESSION = 'start session',
  SELECT_ADDRESS = 'select address',
  VIEW_SHOPS = 'view shops',
  SA_TOGGLE_SHOPS = 'select address: toggle shops',
  SA_FAIL_SUGGEST_GEOCODING = 'error: fail suggest geocoding',
  SELECT_SHOP = 'select shop',
  SHOPPING_OPEN_SET = 'shopping: open set',
  SHOPPING_ADD_PRODUCT = 'shopping: add product',
  SHOPPING_VIEW_PRODUCT = 'shopping: view product',
  SHOPPING_LIKE_PRODUCT = 'shopping: like product',
  SHOPPING_SEARCH_PRODUCTS = 'shopping: search products',
  SHOPPING_APPLY_FILTER_PRICE_PRODUCTS = 'shopping: apply filter price',
  SHOPPING_APPLY_FILTER_PRODUCTS = 'shopping: apply filter',
  SHOPPING_APPLY_FILTER_BRAND_PRODUCTS = 'shopping: apply filter brand',
  SHOPPING_VIEW_TIME_MODAL = 'shopping: view time',
  SHOPPING_CHANGE_ADDRESS = 'shopping: change address',
  SHOPPING_CHANGE_STORE = 'shopping: change store',
  SHOPPING_CLICK_HELP = 'shopping: click help',
  SHOPPING_CLICK_SIDEBAR = 'shopping: click sidebar',
  SHOPPING_VIEW_CART = 'shopping: view cart',
  SHOPPING_ENTER_PROMOCODE = 'shopping: enter promocode',
  SHOPPING_SPEND_POINTS = 'shopping: spend points',
  SHOPPING_VIEW_MISSPRODUCTS = 'shopping: view missing products',
  CHECKOUT_START = 'checkout: start checkout',
  CHECKOUT_ENTER_PHONE = 'checkout: enter phone',
  CHECKOUT_ENTER_EMAIL = 'checkout: enter email',
  CHECKOUT_CHANGE_TIME = 'checkout: change time',
  CHECKOUT_ENTER_FULL_ADDRESS = 'checkout: enter full address',
  CHECKOUT_ENTER_NAME = 'checkout: enter name',
  CHECKOUT_CLICK_COMPLETE = 'checkout: click complete',
  CHECKOUT_ENTER_CARD = 'checkout: enter card',
  CHECKOUT_COMPLETE = 'checkout: complete',
  FEEDBACK_SUBMIT_FORM = 'send feedback',
  FAVORITES_BANNER_LOGIN = 'favorites banner login',
  MAIN_PAGE_FEEDBACK = 'main page: send feedback',
  MAIN_PAGE_TIPS = 'main page: tips',
  CLICK_ORDER_PROFILE_DROPDOWN = 'other: click dropdown order',
  OTHER_CLICK_USE_DESK = 'other: web size',
  VIEW_ORDER_INFO = 'ui: view order information',
  VIEW_LANDING = 'view landing',
}

export enum EnumAFEvents {
  AF_ADD_TO_CART = 'af_add_to_cart',
  AF_INITIATED_CHECKOUT = 'af_initiated_checkout',
  AF_PURCHASE = 'af_purchase',
  AF_FIRST_PURCHASE = 'first_purchase',
}

export enum EnumGtagEvents {
  CLICK_ORDER = 'click_order',
  SPEND_POINTS = 'spend_points',
  ENTER_PROMOCODE = 'enter_promocode',
  VIEW_MISSING_PRODUCTS = 'view_missing_products',
  REMOVE_FROM_CART = 'remove_from_cart',
  ADD_TO_CART = 'add_to_cart',
  VIEW_CART = 'view_cart',
  SUCCESFULL_CHECKOUT = 'succesfull_checkout',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_CHANGE_TIME = 'checkout_change_time',
  CHECKOUT_ENTER_CARD = 'checkout_enter_card',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
  PURCHASE_DUPLICATE = 'purchase_duplicate',
  PURCHASE_ERROR = 'purchase_error',
  INIT_PURCHASE = 'init_purchase',
  VIEW_SHOPS = 'view_shops',
  VIEW_BANNER = 'view_banner',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_ITEM_LIST = 'view_item_list',
  SELECT_PROMOTION = 'select_promotion',
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
  SELECT_BANNER = 'select_banner',
  SELECT_SHOP = 'select_shop',
  SHOW_LANDING = 'show_landing',
  SELECT_ADDRESS = 'select_address',
  CLICK_MOBILE_DOWNLOAD = 'click_mobile_download',
  SHOW_MAIN_PAGE = 'show_main_page',
  SHOW_EMPLOYEES_BLOCK = 'show_employees_block',
  WRITE_PRODUCT_COMMENT = 'write_product_comment',
  CLICK_CALL_TO_ACTION = 'click_call_to_action',
  CLICK_MAP = 'click_map',
}

export enum EnumFileTypes {
  jpeg = 'image/jpeg',
}

export enum EnumDeviceOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
}

export enum EnumPaymentTypes {
  cash = 'наличными',
  online = 'картой онлайн',
  card = 'картой курьеру',
  sbp = 'СБП',
  shipment_on_credit = 'постоплата',
}

export enum EnumZones {
  Msk = 'moskow',
  Spb = 'saint-petersburg',
}

export enum EnumCardSize {
  medium = 'medium',
  small = 'small',
}

export enum EnumCardType {
  full = 'full',
  short = 'short',
}

export enum EnumEntityStatus {
  unfilled = 'unfilled',
  contract_waiting = 'contract_waiting',
  contract_approval_waiting = 'contract_approval_waiting',
  confirmed = 'confirmed',
}

export enum EnumBugsnagErrors {
  GEOCODE_MAP_ERROR = 'GEOCODE_MAP_ERROR',
  KLADR_ERROR = 'KLADR_ERROR',
  KLADR_FALLBACK_ERROR = 'KLADR_FALLBACK_ERROR',
}

export enum SearchPlaceholderShops {
  fallback = 'в каталоге',
  prisma = 'в Призме',
  lenta = 'в Ленте',
  auchan = 'в Ашане',
  metro = 'в Metro',
  babylon = 'в Super Babylon',
  land = 'в Лэнде',
  karusel = 'в Карусели',
  spar = 'в Spar',
  vkusvill = 'во ВкусВилле',
  selgros = 'в Selgros',
  metro_city = 'в МетроСити',
  magnit = 'в Магните',
  globus = 'в Глобусе',
  azbukavkusa = 'в Азбуке вкуса',
  okmarket = 'в Окее',
  billa = 'в Billa',
  dona = 'в Мясе Дона',
  utkonos = 'в Утконос',
}

export enum GoBackShops {
  fallback = 'в каталог',
  prisma = 'в Призму',
  lenta = 'в Ленту',
  auchan = 'в Ашан',
  metro = 'в Metro',
  babylon = 'в Super Babylon',
  land = 'в Лэнд',
  karusel = 'в Карусель',
  spar = 'в Spar',
  vkusvill = 'во ВкусВилл',
  selgros = 'в Selgros',
  metro_city = 'в МетроСити',
  magnit = 'в Магнит',
  globus = 'в Глобус',
  azbukavkusa = 'в Азбуку вкуса',
  okmarket = 'в Окей',
  billa = 'в Billa',
  dona = 'в Мясо Дона',
  utkonos = 'в Утконос',
}
