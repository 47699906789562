import styled, { css } from 'styled-components';

interface IExternalLink {
  iconURL?: string;
  href: string;
  newTab?: boolean;
}

export default styled.a.attrs(({ href, newTab }: IExternalLink) => ({
  href,
  ...(newTab && { target: '_blank', rel: 'noopener noreferrer' }),
}))<IExternalLink>`
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;

  ${({ iconURL }) =>
    iconURL &&
    css`
      color: transparent;
      user-select: none;
      background: url('${iconURL}');
      background-size: contain;
    `}
`;
