import { IProduct } from 'Modules/Products/types';
import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import { formattedProductForCriteo, formattedProductForGTag } from 'utils/tagManager/helpers';

export default function goToCartPage(cartProducts: IProduct[]): void {
  analyticsManager.gtag(EnumGtagEvents.VIEW_CART, {
    items: cartProducts
      .filter(product => product.amount > 0 && product.available)
      .map(product => ({
        ...formattedProductForGTag(product),
      })),
  });
  analyticsManager.criteo({
    event: 'viewBasket',
    item: cartProducts
      .filter(product => product.amount > 0 && product.available)
      .map(product => ({
        ...formattedProductForCriteo(product),
      })),
  });
}
