import React from 'react';
import Icon, { IIcon } from './Icon';

const NewSocialVKIcon: React.FC<IIcon> = props => (
  <Icon width="13" height="8" viewBox="0 0 13 8" {...props}>
    <path d="M12.5.516s-.087-.255-.63-.185l-1.8.013c-.138-.02-.238.038-.238.038S9.726.44 9.676.58a11.904 11.904 0 01-.668 1.438c-.8 1.392-1.125 1.469-1.256 1.38-.306-.199-.225-.806-.225-1.24 0-1.354.2-1.916-.394-2.063C6.94.05 6.796.018 6.29.012c-.644-.007-1.187 0-1.493.153-.207.102-.363.332-.269.345.119.02.387.077.531.275 0 0 .156.262.181.837.069 1.59-.25 1.788-.25 1.788-.243.134-.668-.09-1.293-1.405 0 0-.362-.652-.643-1.367-.05-.134-.15-.205-.15-.205S2.79.344 2.629.318L.917.331C.654.331.56.453.56.453S.467.555.554.766c1.337 3.213 2.862 4.822 2.862 4.822S4.809 7.077 6.39 6.975h.718c.219-.02.331-.141.331-.141s.1-.115.094-.326c-.012-.983.438-1.13.438-1.13.437-.14 1 .951 1.599 1.373 0 0 .456.32.8.25l1.6-.026c.843-.007.443-.722.443-.722-.031-.058-.232-.499-1.194-1.412-1-.952-.868-.798.344-2.447.737-1.002 1.03-1.616.937-1.878z" />
  </Icon>
);

export default NewSocialVKIcon;
