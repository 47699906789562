import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Flex } from '@igooods/ui-kit';
import { EMPTY_ARRAY } from 'constants/constants';
import { colors } from 'constants/colors';
import { SocialLinks } from 'constants/enums';
import { Transitions } from 'theme';
import { getFullCityInfo } from 'Modules/SearchAddress/selectors';
import NewSocialVKIcon from 'Components/Icon/NewSocialVK';
import NewSocialFBIcon from 'Components/Icon/NewSocialFacebook';
import NewSocialTGIcon from 'Components/Icon/NewSocialTG';
import NewSocialINSTIcon from 'Components/Icon/NewSocialINST';
import SocialYouTubeIcon from 'Components/Icon/SocialYouTube';
import ExternalLink from 'Components/ExternalLink';
import NewSocialWAIcon from 'Components/Icon/NewSocialWAIcon';

export const SOCIALS_LIST_TEST_ID = 'SOCIALS_LIST_TEST_ID';

interface ILinkProps {
  bgColor?: string;
}

export const SocialLink = styled(ExternalLink)<ILinkProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(45deg);
  transition: ${Transitions.bg};
  background-color: ${({ bgColor }) => bgColor ?? colors.white};

  &:hover {
    background-color: ${colors.grederlevyLight};
  }

  & + & {
    margin-left: 18px;
  }
`;

const IconBg = styled(Flex).attrs({ middle: true, center: true })`
  position: absolute;
  transform: rotate(-45deg);
`;

interface ISocialLink {
  name: SocialLinks;
  link: string;
}

const getSocialIcon = (name: SocialLinks): ReactNode =>
  ({
    [SocialLinks.vkontakte]: <NewSocialVKIcon color={colors.dark200} />,
    [SocialLinks.facebook]: <NewSocialFBIcon color={colors.dark200} />,
    [SocialLinks.inst]: <NewSocialINSTIcon color={colors.dark200} />,
    [SocialLinks.youtube]: <SocialYouTubeIcon color={colors.dark200} />,
    [SocialLinks.telegram]: <NewSocialTGIcon color={colors.dark200} />,
    [SocialLinks.whatsapp]: <NewSocialWAIcon color={colors.dark200} />,
  }[name]);

const SocialsList = ({ bgColor }: { bgColor?: string }) => {
  const city = useSelector(getFullCityInfo);

  const social_links: ISocialLink[] =
    city?.social_networks?.filter(
      ({ name }: ISocialLink) => name !== SocialLinks.facebook && name !== SocialLinks.inst
    ) ?? EMPTY_ARRAY;

  return (
    <Flex middle data-testid={SOCIALS_LIST_TEST_ID}>
      {social_links.map(({ link, name }) => (
        <SocialLink key={name} href={link} newTab bgColor={bgColor}>
          <IconBg>{getSocialIcon(name)}</IconBg>
        </SocialLink>
      ))}
    </Flex>
  );
};

export default SocialsList;
