import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const Loader: React.FC<IIcon> = ({ color = colors.primaryMain, ...props }) => (
  <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C20.4619 2 24.4363 4.08733 27 7.33881V4C27 3.44772 27.4477 3 28 3C28.5523 3 29 3.44772 29 4V10C29 10.5523 28.5523 11 28 11H22C21.4477 11 21 10.5523 21 10C21 9.44772 21.4477 9 22 9H25.7482C23.5675 5.9687 20.0127 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 15.4477 28.4477 15 29 15C29.5523 15 30 15.4477 30 16Z"
      fill={color}
    />
  </Icon>
);

export default Loader;
