export const BUTTON_WIDTH = 62;

export const MOBILE_MENU_INDEX_PAGE_TEST_ID = 'MOBILE_MENU_INDEX_PAGE_TEST_ID';
export const MOBILE_MENU_GOBACK_TITLE_TEST_ID = 'MOBILE_MENU_GOBACK_TITLE_TEST_ID';
export const MOBILE_MENU_ITEM_TEST_ID = 'MOBILE_MENU_ITEM_TEST_ID';

export const MOBILE_MENU_CATALOG_PAGE_TEST_ID = 'MOBILE_MENU_CATALOG_PAGE_TEST_ID';
export const MOBILE_MENU_CATEGORY_TEST_ID = 'MOBILE_MENU_CATEGORY_TEST_ID';

export const MOBILE_MENU_SUB_CATALOG_PAGE_TEST_ID = 'MOBILE_MENU_SUB_CATALOG_PAGE_TEST_ID';
export const MOBILE_MENU_SUB_CATEGORY_TEST_ID = 'MOBILE_MENU_SUB_CATEGORY_TEST_ID';

export const MOBILE_MENU_SHOP_LOADING_TEST_ID = 'MOBILE_MENU_SHOP_LOADING_TEST_ID';
export const MOBILE_MENU_SHOP_PAGE_TEST_ID = 'MOBILE_MENU_SHOP_PAGE_TEST_ID';
export const MOBILE_MENU_SHOP_LIST_TEST_ID = 'MOBILE_MENU_SHOP_LIST_TEST_ID';

export enum MenuPages {
  index = 'MenuIndexPage',
  shop = 'MenuShopPage',
  catalog = 'MenuCatalogPage',
  subcatalog = 'MenuSubcatalogPage',
}

export interface IMenuAuthItem {
  text: string;
  url: string;
  forEntity?: boolean;
}

export const MenuAuthItems: IMenuAuthItem[] = [
  {
    text: 'Заказы',
    url: '/profile/orders',
  },
  {
    text: 'Адреса',
    url: '/profile/places',
  },
  {
    text: 'Кредитный лимит',
    url: '/profile/cash',
    forEntity: true,
  },
  {
    text: 'Способы оплаты',
    url: '/profile/payment_methods',
  },
  {
    text: 'Мои данные',
    url: '/profile/settings',
  },
];
