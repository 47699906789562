import React, { forwardRef } from 'react';
import { Input, InputProps, EnumInputTypes } from '@igooods/ui-kit';
import { TInputTheme, inputThemeStyles } from './themes';
import { defaultTheme } from 'theme';
import { EnumInputTheme } from 'constants/enums';
import { ThemeProvider } from 'styled-components';

export const EnumInputErrorTypes = EnumInputTypes;
export type InputRef = HTMLInputElement;
export interface IThemeInputProps extends InputProps {
  use?: TInputTheme;
}

export interface IInputTheme {
  bgColor: string;
  borderColor?: string;
  shadow?: string;
  disabled: {
    color?: string;
    bgColor?: string;
    shadow?: string;
    placeholderColor?: string;
    opacity?: number;
  };
  error: {
    bgColor: string;
    borderColor?: string;
    shadow?: string;
  };
  focus: {
    bgColor: string;
    borderColor?: string;
    shadow?: string;
    placeholderColor?: string;
  };
  hover?: {
    bgColor: string;
    borderColor?: string;
    shadow?: string;
    placeholderColor?: string;
  };
  focusError?: {
    bgColor: string;
    borderColor?: string;
    shadow?: string;
    placeholderColor?: string;
  };
  height: {
    default: {
      [key: string]: number;
    };
    mobile?: {
      [key: string]: number;
    };
  };
  borderRadius: {
    [key: string]: number;
  };
  padding: {
    [key: string]: string | number;
  };
  fontSize: {
    [key: string]: string;
  };
  transition?: string;
  label?: {
    indent?: number;
    color?: string;
    fontSize?: {
      [key: string]: string;
    };
    withPadding?: boolean;
  };
  errorText?: {
    indent?: number;
    color?: string;
    fontSize?: {
      [key: string]: string;
    };
    withPadding?: boolean;
  };
  helperText?: {
    indent?: number;
    color?: string;
    fontSize?: {
      [key: string]: string;
    };
    withPadding?: boolean;
  };
  placeholder: {
    color: string;
    asteriskColor: string;
  };
  reset?: {
    icon: {
      width: number;
      height: number;
    };
    btn: {
      width: number;
      height: number;
    };
    color: string;
  };
}

export type IInputProps = InputProps;

export const ThemeInput = forwardRef<InputRef, IThemeInputProps>(({ use = EnumInputTheme.main, ...props }, ref) => {
  const inputStyles: IInputTheme = inputThemeStyles[use];
  const theme = {
    ...defaultTheme,
    input: inputStyles,
  };

  return (
    <ThemeProvider theme={theme}>
      <Input ref={ref} {...props} />
    </ThemeProvider>
  );
});

ThemeInput.displayName = 'ThemeInput';

ThemeInput.defaultProps = {
  type: 'text',
  size: 'normal',
  defaultValue: '',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
};

export default ThemeInput;
