import Http, { IHttp } from 'lib/http';
import {
  IAAAService,
  IAuthResponse,
  IFullProfileResponse,
  IProfileResponse,
  IResetPasswordResponse,
  IUpdateProfileData,
  ISendCodeResponse,
} from './types';

export class AAAService implements IAAAService {
  private readonly http: IHttp = Http;

  public authAnonym(device_id?: string, fetcher = Http): Promise<IAuthResponse> {
    return fetcher.post('/auth/device', { device_id });
  }

  public logout(device_uid?: string): Promise<{}> {
    return this.http.post('/profile/logout', { device_uid }, { apiVersion: 'komus/v1' });
  }

  // around password

  public resetPassword(login: string): Promise<IResetPasswordResponse> {
    return this.http.post('/auth/reset_password', { login }, { apiVersion: 'komus/v1' });
  }

  public sendResetPhoneCode(login: string): Promise<{}> {
    return this.http.post(
      '/auth/reset_password/send_reset_phone_code',
      {
        login,
      },
      { apiVersion: 'komus/v1' }
    );
  }

  // around sms-code
  public validateCode(code: string): Promise<IAuthResponse> {
    return this.http.post('/profile/validate', { code }, { apiVersion: 'komus/v1' });
  }

  public sendCode(): Promise<ISendCodeResponse> {
    return this.http.post('/profile/send_code', null, { apiVersion: 'komus/v1' });
  }

  // around profile

  public getFullProfile(fetcher = Http): Promise<IFullProfileResponse> {
    return fetcher.get('/profile/full');
  }

  // may-be split to updateProfile/deleteAvatar/resetPassword...
  public updateProfile(data: IUpdateProfileData): Promise<IProfileResponse> {
    return this.http.patch('/profile', data, { apiVersion: 'komus/v1' });
  }

  public login(data: IUpdateProfileData): Promise<IProfileResponse> {
    return this.http.post('/profile/login', data, { apiVersion: 'komus/v1' });
  }

  //confirmation

  public emailConfirmation(token: string): Promise<{}> {
    return this.http.post(`/profile/confirm_email`, { token }, { apiVersion: 'komus/v1' });
  }

  // mobile app invitation

  public sendInvitation(phone: string): Promise<{}> {
    return this.http.post('/send_mobile_app_invitation', { phone });
  }

  public sendAnalytics(): Promise<{}> {
    return this.http.post('/profile/assign_analytics');
  }
}

export default new AAAService();
