import Http, { IHttp } from 'lib/http';
import {
  IFullProductResponse,
  IListProductsResponse,
  IProduct,
  IProductFavoriteRequest,
  IProductFavoriteResponse,
  IProductsSet,
  IProductsSetRequest,
  IProductsResponse,
  IProductLineResponse,
  IProductSetProductLineQuery,
} from './types';

import { IGetCategoriesResponse } from 'Modules/Categories/types';
import { fetchFavorites } from '../Favorites/http';
import { EnumProductsSets } from 'Modules/Products/types';

export interface IProductHttp {
  getProduct(id: number): Promise<IProduct>;
  getRecommendationsProducts(group: number, shop_id: number, fetcher: typeof Http): Promise<IProductsResponse>;
  getFavoritesProducts(shop_id: number, limit?: number): Promise<IListProductsResponse>;
  getCategoryProducts(shop_id: number, category_id: number, filters: object): Promise<IListProductsResponse>;
  toggleProductToFavorites({ shop_id, model_id, favorite }: IProductFavoriteRequest): Promise<IProductFavoriteResponse>;
  getProductsSets(shop_id: number): Promise<IProductsSet[]>;
  getFullProductsSet(set_id: number, params: IProductsSetRequest): Promise<IProductsSet>;
  getFullProductsSetCategories(set_id: number): Promise<IGetCategoriesResponse>;
}

export const defaultProductListParams = {
  sort: 'popularity',
  sort_order: 'desc',
};

export const getFullInfoProductByShop = (
  model_id: number,
  shop_id?: number,
  fetcher = Http
): Promise<IFullProductResponse> => {
  return fetcher.get('product', { model_id, shop_id });
};

export class ProductHttp implements IProductHttp {
  private readonly http: IHttp = Http;

  public getCategoryProducts(
    shop_id: number,
    category_id: number,
    filters: { [key: string]: unknown }
  ): Promise<IListProductsResponse> {
    const query = Object.keys(filters)
      .map((key: string) => {
        return `${key}=${filters[key]}`;
      })
      .join('&');
    return this.http.get(`shops/${shop_id}/categories/${category_id}?${encodeURIComponent(query)}`);
  }

  public getProduct(id: number): Promise<IProduct> {
    return this.http.get(`products/${id}`);
  }

  public getRecommendationsProducts(group: number, shop_id: number, fetcher = Http): Promise<IProductsResponse> {
    return fetcher.get(`recommendations?group=${group}&shop_id=${shop_id}`);
  }

  public getFavoritesProducts(shop_id: number, limit?: number, fetcher = Http): Promise<IListProductsResponse> {
    return fetchFavorites(shop_id, { limit }, fetcher);
  }

  public toggleProductToFavorites({
    shop_id,
    model_id,
    favorite,
  }: IProductFavoriteRequest): Promise<IProductFavoriteResponse> {
    return this.http.patch(`shops/${shop_id}/favorites/${model_id}/change`, {
      favorite: String(favorite), // TODO Jira: https://igooods.atlassian.net/browse/FS-205
    });
  }

  public getProductsSets(shop_id: number, kind?: EnumProductsSets, fetcher = Http): Promise<IProductsSet[]> {
    return fetcher.get('product_sets/desk', { shop_id, kind, random: true });
  }

  public getTagsProductsSets(shop_id: number, fetcher = Http): Promise<IProductsSet[]> {
    return fetcher.get('product_sets/desk', { shop_id, kind: EnumProductsSets.tag, random: false });
  }

  public getFullProductsSet(set_id: number, params?: IProductsSetRequest, fetcher = Http): Promise<IProductsSet> {
    return fetcher.get(`product_sets/desk_show/${set_id}`, {
      ...defaultProductListParams,
      ...params,
    });
  }

  public getFullProductsSetCategories(set_id: number, fetcher = Http): Promise<IGetCategoriesResponse> {
    return fetcher.get(`product_sets/${set_id}/categories`);
  }

  public getFullProductsSetProductLine(
    set_id: number,
    params?: IProductSetProductLineQuery,
    fetcher = this.http
  ): Promise<IProductLineResponse> {
    return fetcher.get(`product_sets/${set_id}/product_line`, { random: true, ...params });
  }

  public getImpulseGoods(limit: number, fetcher = this.http): Promise<IProductsResponse> {
    return fetcher.get('product_sets/checkout', { limit });
  }
}

export default new ProductHttp();
