import React from 'react';
import Text, { IText } from './Text';
import { FontSize, FontWeight } from 'theme';

const convertLevelToSize = (lvl?: string) => {
  switch (lvl) {
    case '2':
      return FontSize.xxl;
    case '3':
      return FontSize.xl;
    default:
      return FontSize.xxxl;
  }
};

const convertToHTitle = (children: React.ReactNode, lvl?: string) => {
  switch (lvl) {
    case '1':
      return <h1>{children}</h1>;
    case '2':
      return <h2>{children}</h2>;
    case '3':
      return <h3>{children}</h3>;
    case '4':
      return <h4>{children}</h4>;
    default:
      return { children };
  }
};

const defaultProps = {
  level: '1',
};

export interface ITitleProps extends Omit<IText, 'theme'> {
  level?: string;
  titleLevel?: string;
}

const wlFontWeight = FontWeight.bold;

const Title: React.FC<ITitleProps> = ({ children, level, titleLevel, ...otherProps }) => {
  const size = convertLevelToSize(level);

  return (
    <Text size={size} fontWeight={wlFontWeight} {...otherProps} fontFamily={'Montserrat'}>
      {titleLevel ? convertToHTitle(children, titleLevel) : children}
    </Text>
  );
};

Title.defaultProps = defaultProps;

export default Title;
