import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@igooods/ui-kit';
import { Container, LinkStyled, LinkText } from './styled';
import { links } from '../consts';
import SubLinks from './SubLinks';

const FaqSidebar: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      {links.map(({ url, title }) => (
        <Box key={url} py={2}>
          <LinkStyled to={url}>
            <LinkText active={url === pathname}>{title}</LinkText>
          </LinkStyled>
        </Box>
      ))}
      <SubLinks />
    </Container>
  );
};

export default FaqSidebar;
