import styled from 'styled-components';
import Title from 'Components/Typography/Title';
import Text from 'Components/Typography/Text';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import Flex from 'Components/Grid/Flex';
import { colors } from 'constants/colors';

export const Modal = styled(Flex).attrs({
  column: true,
  pt: { sm: 15, xs: 4 },
  pb: { sm: 10, xs: 4 },
  px: { sm: 10, xs: 4 },
  mb: { sm: 15, xs: 0 },
})`
  background-color: ${colors.white};

  ${mediaSmUp`
    min-width: 480px;
    border-radius: 4px;
    max-width: 620px;
  `}

  ${mediaSmDown`
    min-height: 100vh;
    min-width: 100vw;
  `}
`;

export const FullModal = styled(Flex).attrs({
  column: true,
})`
  height: 100%;
  background-color: ${colors.white};
  min-height: 100vh;
  min-width: 100vw;
`;

export const ModalHeader = styled(Flex).attrs({
  column: true,
})`
  align-items: center;

  ${mediaSmDown`
    margin-top: 60px;
  `}
`;

export const ModalImage = styled.img`
  height: 60px;
  width: 60px;
`;

export const ModalTitle = styled(Title).attrs({
  level: '2',
  center: true,
})`
  margin-top: 20px;

  ${mediaSmDown`
    margin-top: 8px;
  `}
`;

export const ModalSubTitle = styled(Text).attrs({ center: true })`
  margin: 20px auto 0;
  max-width: 300px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: auto;
`;
