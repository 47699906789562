import { IProductsGroup } from 'Modules/Shops/types';
import { ICity } from 'Modules/SearchAddress/types';
import { DEPLOY_ENV_PRODUCTION, DEPLOY_ENV_STAGING } from '../../../config/config';

export enum EnumGroupsNames {
  babylon = 'babylon',
  land = 'land',
  vkusvill = 'vkusvill',
  selgros = 'selgros',
  real = 'real',
  azbukavkusa = 'azbukavkusa',
}

export const getGroupsForMetaTags = (filterList: string[], groups?: IProductsGroup[]) =>
  groups?.filter((item: IProductsGroup) => {
    return !filterList.includes(item.group_en);
  });

interface IDataForMetaTagsOptions {
  groups?: IProductsGroup[];
  city?: ICity;
  business?: boolean;
}

export const dataForMetaTags = ({ groups, city, business }: IDataForMetaTagsOptions) => {
  if (business && city) {
    const cityNamePrepositional = city.declensions?.prepositional;

    return {
      title: `Доставка продуктов в офис в ${cityNamePrepositional}`,
      content_description: `Мы доставляем продукты и товары в офисы из крупных гипермаркетов в ${cityNamePrepositional}. У нас вы можете купить продукты и товары для офиса с доставкой без наценки, по цене магазина.`,
      content_title: `Доставка продуктов в офис в ${cityNamePrepositional}`,
      content_site_name: `igooods. Доставка продуктов в офис в ${cityNamePrepositional}`,
    };
  }

  let title = '';
  let content_description = '';
  let content_title = '';
  let content_site_name = '';

  if (groups && city) {
    const filteredGroups = getGroupsForMetaTags(
      [EnumGroupsNames.babylon, EnumGroupsNames.land, EnumGroupsNames.real],
      groups
    );
    const groupsNames = filteredGroups?.map(item => item.group).join(', ');
    const cityNamePrepositional = city.declensions?.prepositional;

    title = `Доставка продуктов на дом в ${cityNamePrepositional}, igooods – сервис доставки`;
    content_description = `Вы заказываете продукты в ${cityNamePrepositional} из ${groupsNames} на igooods.ru по ценам магазинов (мы не делаем наценок). Доставим через 90 минут!`;
    content_title = `Доставка продуктов на дом в ${cityNamePrepositional}, igooods – сервис доставки`;
    content_site_name = `igooods. Доставка продуктов на дом в ${cityNamePrepositional}`;

    if (city.subdomain === 'msk') {
      const mskFilteredGroup = getGroupsForMetaTags(
        [EnumGroupsNames.vkusvill, EnumGroupsNames.selgros],
        filteredGroups
      );
      const mskGroupsNames = mskFilteredGroup?.map(item => item.group).join(', ');

      title = `Доставка продуктов на дом в ${cityNamePrepositional} | igooods – интернет магазин продуктов, купить продукты питания`;
      content_description = `Заказать доставку продуктов на дом в ${cityNamePrepositional} из ${mskGroupsNames} в интернет-магазине продуктов igooods. Продажа продуктов питания и товаров по ценам магазинов. Доставка на дом через 90 минут!`;
      content_title = `Доставка продуктов на дом в ${cityNamePrepositional} | igooods – купить продукты питания в интернет-магазине`;
    }

    //проверяем, если subdomain нет, значит город - СПБ
    if (!city.subdomain) {
      //ТОЛЬКО ДЛЯ ГЛАВНОЙ СТРАНИЦЫ, для остальных нужно использовать первоначальное значение
      const spbFilteredGroup = getGroupsForMetaTags(
        [EnumGroupsNames.azbukavkusa, EnumGroupsNames.vkusvill, EnumGroupsNames.selgros],
        filteredGroups
      );
      const spbGroupsNames = spbFilteredGroup?.map(item => item.group).join(', ');

      title = 'Доставка продуктов на дом СПб | igooods – интернет магазин продуктов, купить продукты питания';
      content_description = `Заказать доставку продуктов на дом в Санкт-Петербурге из ${spbGroupsNames} в интернет-магазине продуктов igooods. Продажа продуктов питания и товаров по ценам магазинов. Доставка на дом через 90 минут!`;
      content_title = 'Доставка продуктов на дом СПб | igooods – купить продукты питания в интернет-магазине';
      content_site_name = 'igooods. Доставка продуктов на дом в СПб';
    }
  }

  return { title, content_description, content_title, content_site_name };
};

export interface IHostnameParts {
  subdomain?: string;
  hostname?: string;
}

export const getHostNameParts = (hostname: string) => {
  if (DEPLOY_ENV_PRODUCTION) {
    return hostname.match(
      /(?<subdomain>\w+)*?[.]?(?<hostname>(preprod)?\.?(ssr.api.)?igooods.ru)\/?(?<path>[\w\/]*)?(?<params>\?[\w=&]*)?/
    );
  }
  if (DEPLOY_ENV_STAGING) {
    return hostname.match(
      /((?<subdomain>\w+)[.])?(?<hostname>(?<branchname>[-\w]+).(?<stagingName>ssr.dev-api.igooods.ru))\/?(?<path>[\w\/]*)?(?<params>\?[\w=&]*)?/
    );
  }
  return hostname.match(/(?<subdomain>\w+)*?[.]?(?<hostname>\.?igooods.ru)\/?(?<path>[\w\/]*)?(?<params>\?[\w=&]*)?/);
};

interface IFilteredCities {
  sityBySubdomain?: ICity;
  mainCity?: ICity;
}

const MAIN_CITY_ID = 4;

export const getFilterCitiesBySubdomain = (cities: ICity[], subdomain?: string) => {
  const filteredCities: IFilteredCities = {};

  cities.some((city: ICity) => {
    if (city.subdomain === subdomain) filteredCities.sityBySubdomain = city;
    if (city.id === MAIN_CITY_ID) filteredCities.mainCity = city;

    return Boolean(filteredCities.sityBySubdomain && filteredCities.mainCity);
  });

  return filteredCities;
};

export const extractHostnameFromSubdomainUrl = (url: string) => {
  return url.split('.').slice(-2).join('.');
};
