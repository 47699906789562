import { useRequest } from '../../swr.config';
import { ICity } from 'Modules/SearchAddress/types';
import { useSelector } from 'react-redux';
import { getCurrentCity } from './selectors';
interface ICityByIp {
  city: ICity;
}

export const useCityByIp = () => useRequest<ICityByIp>('/cities/identify_by_ip');

export const useCityByIpIsDifferent = () => {
  const { data } = useCityByIp();
  const currentCity = useSelector(getCurrentCity);

  if (data?.city?.id !== currentCity?.id) {
    return data?.city;
  }
};
