import { StaticNotificationAction, IStaticNotificationAction } from './actions';
import { EnumNotification } from 'constants/enums';

export interface IStaticNotificationState {
  list: IStaticNotification[];
}

export interface IStaticNotification {
  id: number;
  text: string;
  buttonText?: string;
  onClick?: () => void;
  theme: EnumNotification;
}

export const notificationInitialState: IStaticNotificationState = {
  list: [],
};

export default function notificationReducer(
  state: IStaticNotificationState = notificationInitialState,
  action: IStaticNotificationAction
) {
  switch (action.type) {
    case StaticNotificationAction.SET_NOTIFICATION:
      return {
        list: [...state.list, { id: Math.random(), ...action.payload, theme: action.theme }],
      };
    case StaticNotificationAction.REMOVE_NOTIFICATION:
      const { index = 0 } = action;

      return {
        list: [...state.list.slice(0, index), ...state.list.slice(index + 1)],
      };
    default:
      return state;
  }
}
