import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const CoinsIcon: FC<IIcon> = () => (
  <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path
        d="M7.99996 2.66666C12.05 2.66666 15.3333 4.45733 15.3333 6.66666V9.33333C15.3333 11.5427 12.05 13.3333 7.99996 13.3333C4.02196 13.3333 0.783959 11.606 0.669959 9.45133L0.666626 9.33333V6.66666C0.666626 4.45733 3.94996 2.66666 7.99996 2.66666ZM7.99996 10.6667C5.51996 10.6667 3.32663 9.99533 1.99996 8.96666V9.33333C1.99996 10.588 4.58863 12 7.99996 12C11.34 12 13.892 10.6467 13.9966 9.412L14 9.33333L14.0006 8.96666C12.674 9.99466 10.4806 10.6667 7.99996 10.6667ZM7.99996 4C4.58863 4 1.99996 5.412 1.99996 6.66666C1.99996 7.92133 4.58863 9.33333 7.99996 9.33333C11.4113 9.33333 14 7.92133 14 6.66666C14 5.412 11.4113 4 7.99996 4Z"
        fill="black"
      />
    </g>
  </Icon>
);

export default CoinsIcon;
