import LCService from '../lib/localStorage';
import { Store } from 'redux';
import { IApplicationState } from '../reduxStore/types';

export const STORE_STORAGE_KEY = 'session_snapshot';

export const storeStorage = new LCService<IApplicationState>(STORE_STORAGE_KEY);

export const saveStoreSnapshot = (store: Store) => {
  const currentStore = store.getState();
  storeStorage.set({ ...currentStore });
};
