import { IApplicationState } from 'reduxStore/types';
import { getAvailablePaymentTypesList } from 'Modules/Shops/helpers';

export const getCurrentShop = (state: IApplicationState) => state.currentShop;
export const getCurrentShopId = (state: IApplicationState) => getCurrentShop(state).id;
export const getCurrentShopBrandLabel = (state: IApplicationState) => getCurrentShop(state).branding;
export const getCurrentShopGroupEn = (state: IApplicationState) => getCurrentShop(state).group_en;
export const getCurrentShopGroup = (state: IApplicationState) => getCurrentShop(state).group;
export const getCurrentShopOpened = (state: IApplicationState) => {
  const currentShop = getCurrentShop(state);
  return currentShop?.hasOwnProperty('open') ? Boolean(currentShop.open) : true;
};

export const getAvailableShopPaymentTypes = (state: IApplicationState) => {
  const paymentTypes = getCurrentShop(state).payment_types;
  return getAvailablePaymentTypesList(paymentTypes);
};

export const getIsShopIdPresent = (state: IApplicationState) => Boolean(getCurrentShop(state).id);
