import React, { FC } from 'react';
import useModalState from 'utils/hooks/useModalState';
import styled, { css } from 'styled-components';
import LinkWrapper from 'Components/Link/LinkWithMobileCheck';
import { URL } from 'constants/urlMaps';
import Text from 'Components/Typography/Text';
import { mediaSmDown } from 'theme/helpers/css';
import { FontSize, Transitions, FontWeight } from 'theme';
import { ThemeButton } from 'Components/ThemedButton';
import { EnumTheme, ModalType, EnumButtonSize } from 'constants/enums';
import CloseIcon from 'Components/Icon/CloseIcon';
import entrance from 'assets/images/auth/entrance.svg';
import user from 'assets/images/auth/user.svg';
import ModalActions from 'Modules/Modals/actions';
import { getCurrentUser, checkIsTempUser } from 'Modules/AAA/selectors';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from 'Components/Icon/MenuIcon';
import Contacts from '../Contacts';
import ExternalLink from 'Components/ExternalLink';
import FaqSidebar from 'Components/FaqPage/Sidebar';
import { Modal, Main, AuthIcon, AuthButton, Header, Footer, Content } from './styles';

export const LANDING_MENU_BTN_TEST_ID = 'LANDING_MENU_BTN_TEST_ID';
export const LANDING_MENU_TEST_ID = 'LANDING_MENU_TEST_ID';
export const LANDING_MENU_CLOSE_BTN_TEST_ID = 'LANDING_MENU_CLOSE_BTN_TEST_ID';
export const AUTH_BTN_TEST_ID = 'AUTH_BTN_TEST_ID';
export const USER_BTN_TEST_ID = 'USER_BTN_TEST_ID';

export const CustomCloseButton = styled(ThemeButton)`
  position: absolute;
  top: 20px;
  right: 28px;
  transition: ${Transitions.filter};
  z-index: 1;

  &:hover {
    filter: brightness(0.65);
  }

  ${mediaSmDown`
    top: 8px;
    right: 5px;
  `}
`;

const NavLink = css`
  & + & {
    margin-top: 8px;
  }
`;

export const InternalNavLink = styled(LinkWrapper)`
  ${NavLink};
`;

export const ExternalNavLink = styled(ExternalLink)`
  ${NavLink};
`;

const SideMenu: FC<{ className?: string }> = ({ className }) => {
  const { isVisible, showModal, hideModal } = useModalState();
  const { name } = useSelector(getCurrentUser);
  const isTempUser = useSelector(checkIsTempUser);
  const dispatch = useDispatch();

  const openAuthModal = () => dispatch(ModalActions.openModal(ModalType.Auth));
  return (
    <>
      <ThemeButton
        onClick={showModal}
        icon
        circle
        size={EnumButtonSize.normal}
        use={EnumTheme.textSecondary}
        testId={LANDING_MENU_BTN_TEST_ID}
        className={className}
      >
        <MenuIcon height="15" />
      </ThemeButton>
      <Modal show={isVisible} closeModal={hideModal}>
        <Content data-testid={LANDING_MENU_TEST_ID}>
          <Header>
            {isTempUser ? (
              <AuthButton onClick={openAuthModal} data-testid={AUTH_BTN_TEST_ID}>
                <AuthIcon src={entrance} />
                <Text size={FontSize.s}>Войти</Text>
              </AuthButton>
            ) : (
              <AuthButton data-testid={USER_BTN_TEST_ID}>
                <InternalNavLink to={URL.profile_orders}>
                  <AuthIcon src={user} />
                  <Text size={FontSize.s}>{name?.split(' ')?.[0] || ''}</Text>
                </InternalNavLink>
              </AuthButton>
            )}
            <CustomCloseButton
              use={EnumTheme.textSecondary}
              icon
              onClick={hideModal}
              size={EnumButtonSize.normal}
              testId={LANDING_MENU_CLOSE_BTN_TEST_ID}
            >
              <CloseIcon width="14" height="14" />
            </CustomCloseButton>
          </Header>
          <Main>
            <InternalNavLink check to={URL.select_address}>
              <Text size={FontSize.l} fontWeight={FontWeight.semiBold}>
                Список магазинов
              </Text>
            </InternalNavLink>
            <FaqSidebar />
          </Main>
          <Footer>
            <Contacts />
          </Footer>
        </Content>
      </Modal>
    </>
  );
};

export default SideMenu;
