import styled from 'styled-components';
import Text from '../../Typography/Text';
import Flex from 'Components/Grid/Flex';
import { mediaSmDown } from '../../../theme/helpers/css';
import ExternalLink from '../../ExternalLink';

export const Time = styled(Text)`
  display: block;
`;

export const StyledFlex = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${mediaSmDown`
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledLink = styled(ExternalLink)`
  font-size: 16px;
`;

export const StyledText = styled(Text)`
  padding: 10px 0;
`;
