import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { Transitions } from 'theme';
import { addStyleIfPropTruly } from 'utils/styledComponents';

interface IColoredLinkWrapper extends HTMLAttributes<HTMLSpanElement> {
  color?: string;
  underlineHover?: boolean;
}

const Container = styled.span`
  color: ${({ color }) => color || colors.darkBlueGreen};
  transition: ${Transitions.opacity};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    ${addStyleIfPropTruly('underlineHover', `text-decoration: underline;`)}
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }
`;

const ColoredLinkWrapper: React.FC<IColoredLinkWrapper> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default ColoredLinkWrapper;
