import { ICurrentOrder, OperationTypes, OrderHistoryTypes } from './types';
import { Dispatch, GetState } from 'reduxStore/types';
import { EnumNotification } from 'constants/enums';
import { IItems, IProduct, IProductRequest } from 'Modules/Products/types';
import { changeProductsInOrder, getActiveOrdersIds, getOrderInfo, getOrderItems } from './http';
import { ISuccessLastOrder } from 'Modules/Cart/types';
import OrdersHttp from 'Modules/Cart/http';
import { getCurrentOrder, getCurrentOrderItems } from './selectors';
import { itemsInitialState } from './reducer';
import { sendErrorNotification } from 'utils/errors';
import { setNotificationAction } from '../Notifications/actions';
import { setUserActiveCountOrder } from '../AAA/actions';
import { updatePaymentType } from 'Modules/OrdersHistory/http';
import { PaymentTypes } from 'Modules/Checkout/types';
import { IPaymentCard } from '../PaymentCards/types';
import { AnyAction } from 'redux';

export const startGettingOrderInfo = () => ({
  type: OrderHistoryTypes.START_GETTING_ORDER_INFO,
});

export const clearOrderInfoAction = (): AnyAction => ({
  type: OrderHistoryTypes.CLEAR_ORDER_INFO,
});

export const finishGettingOrderInfo = (orderInfo: ICurrentOrder) => ({
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_INFO,
  payload: orderInfo,
});

export const setLastSuccessOrder = (orderInfo: ISuccessLastOrder) => ({
  type: OrderHistoryTypes.SET_LAST_SUCCESS_ORDER,
  payload: orderInfo,
});

export const finishGettingOrderProducts = (orderProducts: IProduct[]) => ({
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_PRODUCTS,
  payload: orderProducts,
});

export const finishGettingOrderItems = (orderItems: IItems = itemsInitialState) => ({
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_ITEMS,
  payload: orderItems,
});

export const setOrderPaymentType = (selectedPaymentType: PaymentTypes) => ({
  type: OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_TYPE,
  payload: selectedPaymentType,
});

export const setOrderPaymentCard = (card: IPaymentCard) => ({
  type: OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_CARD,
  payload: card,
});

export const setCommentToProduct = (product_id: number, comment: string) => ({
  type: OrderHistoryTypes.SET_COMMENT_TO_PRODUCT,
  payload: {
    product_id,
    comment,
  },
});

export const getOrderInfoAction = (currentOrderId: number) => async (dispatch: Dispatch) => {
  dispatch(startGettingOrderInfo());
  const orderInfo = await getOrderInfo(currentOrderId);

  dispatch(finishGettingOrderInfo(orderInfo.order));
};

export const updatePaymentTypeAction = ({
  currentOrderId,
  selectedPaymentType,
  card,
  changeFrom,
}: {
  currentOrderId: number;
  selectedPaymentType: PaymentTypes;
  card?: number;
  changeFrom?: number;
}) => async (dispatch: Dispatch) => {
  await updatePaymentType({
    orderId: currentOrderId,
    payment_type: selectedPaymentType,
    bank_card_id: card,
    change_from: changeFrom,
  });
  dispatch(setOrderPaymentType(selectedPaymentType));
};

export const getOrderProductsAction = (currentOrderId: number) => async (dispatch: Dispatch): Promise<IProduct[]> => {
  const orderProducts = await getOrderItems(currentOrderId);

  dispatch(finishGettingOrderProducts(orderProducts.list));
  return orderProducts.list;
};

export const getOrderItemsAction = (currentOrderId: number) => async (dispatch: Dispatch) => {
  const orderItems = await getOrderItems(currentOrderId);

  dispatch(finishGettingOrderItems(orderItems));
};

export const addCommentToOrderProduct = (
  product_id: number,
  comment: string,
  currentOrderId: number,
  lastCommet: string
) => async (dispatch: Dispatch) => {
  dispatch(setCommentToProduct(product_id, comment));
  try {
    await OrdersHttp.addCommentToProduct(product_id, comment, currentOrderId);
  } catch (e) {
    dispatch(setCommentToProduct(product_id, lastCommet));
    return Promise.reject(e);
  }
};

export const changeCurrentOrder = (product: IProductRequest, operation: OperationTypes) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const order = getCurrentOrder(getState());
  if (order && order.id) {
    try {
      const response = await changeProductsInOrder(
        order.id,
        product.product_id,
        operation,
        product.amount,
        product.weight
      );
      dispatch(finishGettingOrderInfo(response.order));
      dispatch(
        finishGettingOrderItems({
          ...itemsInitialState,
          list:
            getCurrentOrderItems(getState())
              ?.list.map(listProduct => {
                if (listProduct.id === product.product_id && operation === 'set')
                  return { ...listProduct, amount: product.amount ?? 0 };

                return listProduct;
              })
              .filter(listProduct => Boolean(listProduct.amount)) ?? [],
        })
      );
      dispatch(setNotificationAction('Заказ изменен', EnumNotification.success));
    } catch (e) {
      sendErrorNotification(e, dispatch);
    }
  }
};

export const setProductInOrder = (product: IProductRequest) => changeCurrentOrder(product, OperationTypes.SET);

export const removeProductFromOrder = (product: IProductRequest) =>
  changeCurrentOrder({ ...product, amount: 0 }, OperationTypes.SET);

export const getActiveOrdersCountAction = () => async (dispatch: Dispatch) => {
  const ordersIds = await getActiveOrdersIds();

  dispatch(setUserActiveCountOrder(ordersIds.list.length));
};
