import { IFlags } from './types';
import { useContext } from 'react';
import { FlagsContext } from './provider';
import { DEPLOY_ENV_NOT_PROD, NODE_ENV_IS_TEST } from '../../../config/config';

export const getFlags = (): IFlags => JSON.parse(decodeURI((window as any).__FLAGS__));

const pickFlagWithLogging = (flags: IFlags, flagName: string) => {
  const flag = flags[flagName] || {};
  if (DEPLOY_ENV_NOT_PROD && !NODE_ENV_IS_TEST) {
    console.log(`FLAG ${flagName}:`, flag);
  }
  return flag;
};

/**
 * Get a single flag from list
 * @param flagName – name of a seeking flag
 */
export const getFlag = (flagName: string) => {
  const flags = getFlags();

  return pickFlagWithLogging(flags, flagName);
};

export const useFlag = (flagName: string) => {
  const flags = useContext(FlagsContext);

  return pickFlagWithLogging(flags, flagName);
};
