import { DEPLOY_ENV_NOT_PROD, NODE_ENV_IS_TEST } from '../../config/config';
import { checkIsMobile, isTablet } from '@igooods/user-agent';

const ACCOUNT_ID = 64997;
const deviceType = () => (isTablet(navigator.userAgent) ? 't' : checkIsMobile(navigator.userAgent) ? 'm' : 'd');

export interface ICriteoEvent {
  event: string;
  [key: string]: any;
}

export const criteo = (...args: ICriteoEvent[]) => {
  if (DEPLOY_ENV_NOT_PROD && !NODE_ENV_IS_TEST) {
    // for testing QA
    console.log('Criteo ----> ', [...args]);
  } else {
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push([...args]);
  }
};

interface ICriteoClient {
  defaultEvents: ICriteoEvent[];
  init(email: string): void;
  push(event: ICriteoEvent): void;
}

const CriteoClient: ICriteoClient = {
  defaultEvents: [],

  init(email: string) {
    this.defaultEvents = [
      { event: 'setAccount', account: ACCOUNT_ID },
      { event: 'setEmail', email: email || '' },
      { event: 'setSiteType', type: deviceType() },
    ];
  },

  push(event: ICriteoEvent) {
    criteo(...this.defaultEvents, event);
  },
};

export default CriteoClient;
