import React from 'react';
import Icon, { IIcon } from './Icon';

const SocialINSTIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8.096C4 5.824 5.856 4 8.096 4C10.336 4 12.192 5.856 12.192 8.096C12.192 10.336 10.368 12.192 8.096 12.192C5.824 12.192 4 10.368 4 8.096ZM5.472 8.096C5.472 9.536 6.656 10.72 8.096 10.72C9.536 10.72 10.72 9.536 10.72 8.096C10.72 6.656 9.536 5.472 8.096 5.472C6.656 5.472 5.472 6.656 5.472 8.096Z"
    />
    <path d="M11.928 4.856C12.4405 4.856 12.856 4.44052 12.856 3.928C12.856 3.41548 12.4405 3 11.928 3C11.4155 3 11 3.41548 11 3.928C11 4.44052 11.4155 4.856 11.928 4.856Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.232 0C12.576 0 13.76 0.448 14.592 1.312C15.424 2.144 15.872 3.296 15.872 4.64V11.232C15.872 12.608 15.424 13.792 14.56 14.624C13.728 15.424 12.576 15.872 11.2 15.872H4.672C3.36 15.872 2.208 15.456 1.344 14.624C0.448 13.76 0 12.576 0 11.2V4.64C0 1.856 1.856 0 4.64 0H11.232ZM13.536 13.568C14.112 13.024 14.464 12.224 14.464 11.232V4.64C14.464 3.712 14.144 2.912 13.6 2.336C13.024 1.76 12.224 1.472 11.264 1.472H4.672C3.712 1.472 2.88 1.76 2.336 2.304C1.76 2.88 1.472 3.68 1.472 4.64V11.2C1.472 12.192 1.76 12.992 2.336 13.568C2.912 14.112 3.712 14.4 4.672 14.4H11.2C12.16 14.4 12.96 14.112 13.536 13.568Z"
    />
  </Icon>
);

export default SocialINSTIcon;
