/**  eslint */
import { DefaultTheme } from 'styled-components';
import { FontSize } from 'theme';

export const getBaseIndent = (theme: DefaultTheme) => theme.baseIndent;
export const getMultipliedIndent = (theme: DefaultTheme, coefficient = 1) => `${getBaseIndent(theme) * coefficient}px`;
export const getMultipliedIndentFromProp = (coefficient: number) => ({ theme }: { theme: DefaultTheme }) =>
  `${getBaseIndent(theme) * coefficient}px`;

export const getTypography = (theme: DefaultTheme) => theme.typography;
export const getTypographyMobile = (theme: DefaultTheme) => theme.typographyMobile;

export const getTypographyFontStyle = (size: FontSize = FontSize.s) => ({ theme }: { theme: DefaultTheme }) =>
  `
    font-size: ${getTypography(theme)[size].fontSize};
    line-height: ${getTypography(theme)[size].lineHeight};
  `;

export const getTypographyFontStyleMobile = (size: FontSize = FontSize.s) => ({ theme }: { theme: DefaultTheme }) =>
  `
    font-size: ${getTypographyMobile(theme)[size].fontSize};
    line-height: ${getTypographyMobile(theme)[size].lineHeight};
  `;

export const getTypographyFontSize = (size: FontSize = FontSize.s) => ({ theme }: { theme: DefaultTheme }) =>
  `font-size: ${getTypography(theme)[size].fontSize};`;

export const getBreakpoints = (theme: DefaultTheme) => theme.breakpoints;
export const getContainerIndents = (theme: DefaultTheme) => theme.container.indent;
export const getContainerMaxWidth = (theme: DefaultTheme) => theme.container.maxWidth;
export const getGridSize = (theme: DefaultTheme) => theme.gridSize;
export const getGutterWidth = (theme: DefaultTheme) => theme.gutterWidth;
