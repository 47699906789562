import styled, { css, DefaultTheme, ThemedCssFunction } from 'styled-components';
import { Breakpoints, defaultTheme } from '../index';
import React from 'react';
import {
  mediaDesktop,
  mediaMdDown,
  mediaMobile,
  mediaSmDown,
  mediaSmUp,
  mediaMdUp,
  mediaLgDown,
  mediaLgUp,
  mediaXlUp,
  mediaXlDown,
} from './css';

export interface ICssCollection {
  [key: string]: ThemedCssFunction<DefaultTheme>;
}

export const isInteger = (value: string) => parseFloat(value) === parseInt(value, 10);

export const calculatePercentageGridValue = (value: number, gridSize: number) => `${(100 / gridSize) * value}%`;
export const getLimitedMedia = (breakpoints: { [x: string]: number }, current: string, currentIndex: number) => {
  return currentIndex !== Object.keys(breakpoints).length - 1
    ? `(min-width: ${breakpoints[current]}px) and (max-width: ${
        breakpoints[Object.keys(breakpoints)[currentIndex + 1]] - 1
      }px)`
    : `(min-width: ${breakpoints[current]}px)`;
};

export const mediaQueryFunctionsList = (breakpoints: { [x: string]: number }) =>
  Object.keys(breakpoints).reduce((media, breakpoint, index) => {
    const newMedia: ICssCollection = { ...media };
    newMedia[breakpoint] =
      index === 0
        ? (...args: any[]) => {
            return (css as any)(...args);
          }
        : (...args: any[]) => css`
            @media (min-width: ${breakpoints[breakpoint]}px) {
              ${(css as any)(...args)}
            }
          `;
    return newMedia;
  }, {});

export const mediaQueryFunctionsListWithLimits = (breakpoints: { [x: string]: number }): ICssCollection =>
  Object.keys(breakpoints).reduce((media, breakpoint, index) => {
    const newMedia: ICssCollection = { ...media };
    newMedia[breakpoint] = (...args: any[]) => css`
      @media ${getLimitedMedia(breakpoints, breakpoint, index)} {
        ${(css as any)(...args)}
      }
    `;
    return newMedia;
  }, {});

// TODO Jira: https://igooods.atlassian.net/browse/FS-202
export const hideBefore = (breakpoint: Breakpoints) => <P>(Component: React.ComponentType<P>) => {
  return styled(Component)`
    @media (max-width: ${defaultTheme.breakpoints[breakpoint] - 1}px) {
      display: none;
    }
  ` as typeof Component;
};

// TODO Jira: https://igooods.atlassian.net/browse/FS-202
export const hideAfter = (breakpoint: Breakpoints) => <P>(Component: React.ComponentType<P>) => {
  return styled(Component)`
    @media (min-width: ${defaultTheme.breakpoints[breakpoint]}px) {
      display: none;
    }
  ` as typeof Component;
};

export const hideBeforeSm = mediaSmDown`display: none`;
export const hideAfterSm = mediaSmUp`display: none`;
export const hideBeforeMd = mediaMdDown`display: none`;
export const hideAfterMd = mediaMdUp`display: none`;
export const hideBeforeLg = mediaLgDown`display: none`;
export const hideAfterLg = mediaLgUp`display: none`;
export const hideAfterXl = mediaXlUp`display: none`;
export const hideBeforeXl = mediaXlDown`display: none`;

export const hideOnMobile = mediaMobile`display: none`;
export const hideOnDesktop = mediaDesktop`display: none`;

export interface IMediaProps {
  inMobile?: boolean;
  inDesktop?: boolean;
}

export const getMediaFromProp = ({ inMobile = false, inDesktop = false }: IMediaProps) => () => {
  if (inDesktop) {
    return hideOnMobile;
  } else if (inMobile) {
    return hideOnDesktop;
  }
};
