import React, { forwardRef, memo } from 'react';
import { Button, ButtonProps } from '@igooods/ui-kit';
import { buttonThemeSize, buttonThemeStyles } from './themes';
import styled, { ThemeProvider } from 'styled-components';
import { EMPTY_STRING } from 'constants/constants';
import { getMediaFromProp, IMediaProps } from 'theme/helpers';
import { FontSize, SMARTPHONE_MEDIA_QUERY } from '../../theme';
import { getTypographyFontStyle, getTypographyFontStyleMobile } from '../../theme/selectors';
import { defaultTheme } from 'theme';
import { EnumButtonSize, EnumSpinnerSize, EnumTheme } from 'constants/enums';
import { buttonThemeNames } from 'Components/ThemedButton/themes';
import Spinner from '../Spinner';

export type ButtonRef = HTMLButtonElement;

const themesWithSpecialStyles = [
  buttonThemeNames.roundGreen,
  buttonThemeNames.roundPink,
  buttonThemeNames.roundWhite,
  buttonThemeNames.roundBorderGray,
  buttonThemeNames.roundGreenText,
  buttonThemeNames.roundBlack,
  buttonThemeNames.roundBorderWhite,
  buttonThemeNames.roundGhostWhite,
];

const checkIsThemesWithSpecialStyles = (use: EnumTheme) => {
  return themesWithSpecialStyles.includes(use);
};
export interface IButtonTheme {
  borderRadius?: { small: string | number; normal: string | number; large: string | number };
  height?: { small: number; normal: number; large: number };
  padding?: { small: string; normal: string; large: string };
  bgColor: string;
  color?: string;
  disabled: {
    bgColor: string;
    color?: string;
  };
  hover: {
    bgColor: string;
    color?: string;
  };
  active: {
    bgColor: string;
    color?: string;
  };
  focus: {
    bgColor: string;
    color?: string;
  };
  loading: {
    bgColor: string;
    color?: string;
  };
}

export const getButtonTypographyBySize = (size = 'normal', use = EnumTheme.main) => {
  if (checkIsThemesWithSpecialStyles(use)) {
    return getTypographyFontStyle(FontSize.xs);
  }
  switch (size) {
    case EnumButtonSize.large:
      return getTypographyFontStyle(FontSize.m);
    case EnumButtonSize.normal:
      return getTypographyFontStyle(FontSize.s);
    case EnumButtonSize.small:
      return getTypographyFontStyle(FontSize.xs);
  }
};

export const getButtonTypographyBySizeMobile = (size = 'normal', use = EnumTheme.main) => {
  if (checkIsThemesWithSpecialStyles(use)) {
    return getTypographyFontStyle(FontSize.xxs);
  }
  switch (size) {
    case EnumButtonSize.large:
      return getTypographyFontStyleMobile(FontSize.m);
    case EnumButtonSize.normal:
      return getTypographyFontStyleMobile(FontSize.s);
    case EnumButtonSize.small:
      return getTypographyFontStyleMobile(FontSize.xs);
  }
};

const getButtonSizeMobile = ({ size = 'normal', icon, use = EnumTheme.main }: IThemeButtonProps) => () => {
  if (checkIsThemesWithSpecialStyles(use)) {
    return `
      height: ${buttonThemeSize.secondHeightMobile[size]}px;
      ${icon ? `width: ${buttonThemeSize.secondHeightMobile[size]}px;` : EMPTY_STRING}
    `;
  }
  return `
    height: ${buttonThemeSize.mainHeightMobile[size]}px;
    ${icon ? `width: ${buttonThemeSize.mainHeightMobile[size]}px;` : EMPTY_STRING}
  `;
};
export interface IThemeButtonProps extends ButtonProps, IMediaProps {
  use?: EnumTheme;
  children?: React.ReactElement | React.ReactNode | React.ReactElement[] | string | React.ReactText[];
  inMobile?: boolean;
  inDesktop?: boolean;
  grow?: boolean;
  useDesktopHeight?: boolean;
}

const buttonTheme = buttonThemeStyles;

const loader = (color?: string) => <Spinner size={EnumSpinnerSize.small} color={color} />;

export const ThemeButton = forwardRef<ButtonRef, IThemeButtonProps>(
  ({ use = EnumTheme.main, children, ...props }, ref) => {
    const buttonStyles: IButtonTheme = buttonTheme[use];
    const btnTheme = {
      ...defaultTheme,
      button: buttonStyles,
    };

    return (
      <ThemeProvider theme={btnTheme}>
        <Button ref={ref} loader={loader} {...props}>
          {children}
        </Button>
      </ThemeProvider>
    );
  }
);

ThemeButton.displayName = 'ThemeButton';

const StyledButton = styled(ThemeButton)`
  ${getMediaFromProp}
  flex-shrink: 0;
  ${({ grow }) => grow && `flex-grow: 1;`}
  ${({ size, use }) => getButtonTypographyBySize(size, use)}

  @media ${SMARTPHONE_MEDIA_QUERY} {
    ${({ useDesktopHeight, ...props }) => !useDesktopHeight && getButtonSizeMobile(props)}
    ${({ size, use }) => getButtonTypographyBySizeMobile(size, use)}
  }
`;

export default memo(StyledButton);
