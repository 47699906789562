import { IOrder } from 'Modules/Cart/types';
import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from '../tagManager/manager';
import { truncateObjValues } from 'utils/helpers';

export default function enterPromocodeAnalitic(order: IOrder, promocode: string, referral: boolean) {
  analyticsManager.gtag(
    EnumGtagEvents.ENTER_PROMOCODE,
    truncateObjValues({
      text: promocode,
      ...(referral && { type: 'referral' }),
    })
  );
}
