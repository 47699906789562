import React, { FC } from 'react';
import styled from 'styled-components';
import { Animation } from './Modal';
import ClosableModal, { IClosableModal } from './ClosableModal';
import { ModalContent } from './Modal';

const Modal = styled(ClosableModal)`
  display: flex;
  align-items: start;
  justify-content: flex-end;
  height: 100%;

  ${ModalContent} {
    height: 100%;
  }
`;

const RightClosableModal: FC<IClosableModal> = ({ children, ...rest }) => (
  <Modal animation={Animation.right} {...rest}>
    {children}
  </Modal>
);

export default RightClosableModal;
