import { AnyAction } from 'redux';
import { Dispatch, ThunkAction } from '../../reduxStore/types';

export enum ModalAction {
  Open = 'open',
  Close = 'close',
  BlockClose = 'blockClose',
  UnblockClose = 'unblockClose',
}

export const openModalAction = (payload: string): AnyAction => ({
  type: ModalAction.Open,
  payload,
});

export const closeModalAction = (): AnyAction => ({
  type: ModalAction.Close,
});

export const blockCloseModalAction = (): AnyAction => ({
  type: ModalAction.BlockClose,
});

export const unblockCloseModalAction = (): AnyAction => ({
  type: ModalAction.UnblockClose,
});

export default {
  openModal: (modalType: string): ThunkAction => (dispatch: Dispatch) => dispatch(openModalAction(modalType)),
  closeModal: (): ThunkAction => (dispatch: Dispatch) => dispatch(closeModalAction()),
  blockClose: (): ThunkAction => (dispatch: Dispatch) => dispatch(blockCloseModalAction()),
  unblockClose: (): ThunkAction => (dispatch: Dispatch) => dispatch(unblockCloseModalAction()),
};
