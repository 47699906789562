import { IProduct } from 'Modules/Products/types';
import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import { formattedProductForCriteo, formattedProductForGTag } from 'utils/tagManager/helpers';

export default function goToCheckoutFinishPage(orderId: number, orderProducts: IProduct[]): void {
  analyticsManager.gtag(EnumGtagEvents.PURCHASE_DUPLICATE);
  analyticsManager.gtag(EnumGtagEvents.PURCHASE, {
    items: orderProducts
      .filter(product => product.amount > 0 && product.available)
      .map(product => ({
        ...formattedProductForGTag(product),
      })),
  });
  analyticsManager.criteo({
    event: 'trackTransaction',
    id: orderId,
    item: orderProducts
      .filter(product => product.amount > 0 && product.available)
      .map(product => ({
        ...formattedProductForCriteo(product),
      })),
  });
}
