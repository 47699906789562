import React from 'react';
import Icon, { IIcon } from './Icon';

const ArrowRightIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5581 11.4419C10.314 11.1979 10.314 10.8021 10.5581 10.5581L12.4911 8.625H2C1.65482 8.625 1.375 8.34518 1.375 8C1.375 7.65482 1.65482 7.375 2 7.375H12.4911L10.5581 5.44194C10.314 5.19786 10.314 4.80214 10.5581 4.55806C10.8021 4.31398 11.1979 4.31398 11.4419 4.55806L14.4419 7.55806C14.686 7.80214 14.686 8.19786 14.4419 8.44194L11.4419 11.4419C11.1979 11.686 10.8021 11.686 10.5581 11.4419Z"
    />
  </Icon>
);

export default ArrowRightIcon;
