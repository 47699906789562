import { EMPTY_STRING } from 'constants/constants';
import { EnumProductsSets, IProductsSets, IProductsSetState, EnumProductTypes } from './types';
import { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import { find, propEq, equals } from 'ramda';

export const currentProductsSetInitialState = {
  id: 0,
  shop_id: 0,
  isLoading: true,
  isLoadingCategories: true,
  name: EMPTY_STRING,
  price: {
    from: 0,
    to: 0,
  },
  products: {
    list: [],
    total: 0,
  },
  categories: [],
  kind: EnumProductsSets.model_ids,
  setBanner: { small: '', medium: '', large: '' },
  textColor: '',
  headColor: '',
  setDescription: '',
  category_id: 0,
};

export function currentProductsSetReducer(
  state: IProductsSetState = currentProductsSetInitialState,
  action: AnyAction
): IProductsSetState {
  switch (action.type) {
    case EnumProductTypes.START_GET_PRODUCTS_SET:
      return { ...state, isLoading: true };
    case EnumProductTypes.START_GET_PRODUCTS_SET_CATEGORIES:
      return { ...state, isLoadingCategories: true };
    case EnumProductTypes.SET_PRODUCTS_SET_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: false,
        // TODO Jira: https://igooods.atlassian.net/browse/FS-206
        price: action.payload[0]?.price,
        filters: [action.payload[0]?.filters],
        categories: action.payload,
      };
    case EnumProductTypes.SET_PRODUCTS_SET:
      return {
        ...state,
        isLoading: false,
        isLoadingCategories: false,
        name: action.payload.name,
        id: action.payload.id,
        shop_id: action.payload.shop_id,
        products: {
          list: action.payload.products,
          total: action.payload.total,
        },
        kind: action.payload.kind,
        setBanner: action.payload.set_banner,
        textColor: action.payload.text_color,
        headColor: action.payload.head_color,
        setDescription: action.payload.set_description,
        category_filter_enabled: action.payload.category_filter_enabled,
        productPicture: action.payload.product_picture,
        backgroundColor: action.payload.background_color,
        category_id: action.id,
      };
    case EnumProductTypes.UPDATE_PRODUCTS_SET:
      return {
        ...state,
        products: {
          list: [...state.products.list, ...action.payload.products],
          total: action.payload.total,
        },
      };
    case EnumProductTypes.CLEAR_CURRENT_SET:
      return currentProductsSetInitialState;
    default:
      return state;
  }
}

export const productsSetsInitialState = {
  isLoaded: false,
  list: [],
  tags: [],
};

export function productsSetsListReducer(
  state: IProductsSets = productsSetsInitialState,
  action: AnyAction
): IProductsSets {
  switch (action.type) {
    case EnumProductTypes.SET_PRODUCTS_SETS:
      return {
        ...state,
        isLoaded: true,
        list: [...action.payload],
      };
    case EnumProductTypes.SET_SALE_PRODUCTS_SET:
      const [payloadSaleSet] = action.payload;
      const stateSaleSet = find(propEq('kind', EnumProductsSets.sale_products))(state.list);

      let list;
      if (equals(stateSaleSet, payloadSaleSet)) {
        list = state.list;
      } else {
        list = [...state.list.filter(propEq('kind', EnumProductsSets.sale_products))];
        payloadSaleSet && list.push(payloadSaleSet);
      }

      return {
        ...state,
        isLoaded: true,
        list: [...list],
      };
    case EnumProductTypes.SET_TAGS_SETS:
      return {
        ...state,
        tags: action.payload,
      };
    default:
      return state;
  }
}

export const productsSetsReducer = combineReducers({
  sets: productsSetsListReducer,
  current: currentProductsSetReducer,
});
