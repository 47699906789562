import { IApplicationState } from 'reduxStore/types';
import { ICartState } from 'Modules/Cart/types';

export const getCartState = (state: IApplicationState) => state.cart as ICartState;

export const getCartProducts = (state: IApplicationState) => getCartState(state).products;

export const getCartCountProducts = (state: IApplicationState) =>
  getCartProducts(state).filter(({ available, amount }) => available && amount > 0).length;

export const getCartId = (state: IApplicationState) => getCartState(state).id;

export const getCartShopId = (state: IApplicationState) => getCartState(state).shop_id;

export const getProductCartParams = (id: number) => (state: IApplicationState) => {
  const cartProduct = getCartProducts(state).find(product => product.id === id);

  // amount: 0 это костыль, чтобы товар удалялся при 0 количестве
  return cartProduct
    ? { amount: cartProduct.amount, weight: cartProduct.weight, comment: cartProduct.comment || '' }
    : { amount: 0 };
};

export const getProductCommentFromCart = (id: number) => (state: IApplicationState) => {
  const cartProduct = getCartProducts(state).find(product => product.id === id);
  return cartProduct?.comment;
};

export const getAvailableCartProducts = (state: IApplicationState) =>
  getCartProducts(state)?.filter(({ available }) => available);

export const getCartProductsTotalPrice = (state: IApplicationState) => {
  return getAvailableCartProducts(state)
    ? getAvailableCartProducts(state).reduce((sum, { price, amount }) => sum + price * amount, 0)
    : 0;
};

export const getNotAvailableProducts = (state: IApplicationState) => {
  const products = getCartProducts(state);

  if (products) {
    return products.filter(({ available }) => available === false);
  }

  return [];
};

export const getNotEnoughProductsPrice = (state: IApplicationState): number =>
  getCartState(state).min_price - getCartProductsTotalPrice(state);

export const getCartPoints = (state: IApplicationState): number => getCartState(state).user_points || 0;

export const isReadyCartByMinPrice = (state: IApplicationState): boolean =>
  getCartState(state).min_price <= getCartProductsTotalPrice(state);

export const getDeliveryDate = (state: IApplicationState) => getCartState(state).delivery_date || {};
export const getDeliveryStartDate = (state: IApplicationState) => getDeliveryDate(state)?.date || null;

export const isEnoughPointsForCheckout = (state: IApplicationState): boolean =>
  getCartPoints(state) >= getCartState(state).total_price;

export const isReadyCartForCheckout = (state: IApplicationState): boolean => isReadyCartByMinPrice(state);

export const getActiveOrdersInCurrentShop = (state: IApplicationState) => state.activeOrders;
export const isHaveActiveOrdersInCurrentShop = (state: IApplicationState) =>
  getActiveOrdersInCurrentShop(state).isSuccess && !!getActiveOrdersInCurrentShop(state).list.length;

export const getCartZoneId = (state: IApplicationState) => getCartState(state).zone_id;

export const getIsCartShopIdPresent = (state: IApplicationState) => Boolean(getCartShopId(state));
