import { IProduct, EnumProductType } from 'Modules/Products/types';
import { IAdditionalParams, IAnalyticsParams } from 'utils/tagManager/types';
import { EnumFromName, EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import {
  formattedProductForGTag,
  getAdditionalParamsForGTag,
  getPromotionParamsForGTag,
} from 'utils/tagManager/helpers';
import { truncateString } from 'utils/helpers';

//Добавление товаров в корзину
export default function addProductToCart(
  product: IProduct,
  params: IAdditionalParams,
  from: EnumFromName,
  analyticsParams: IAnalyticsParams = {},
  orderId?: number,
  fromId?: number,
  afterCheckout?: boolean
): void {
  const { amountOfChange = 1 } = params;

  const quantity = product.type === EnumProductType.weight ? amountOfChange * product.item_weight : amountOfChange;

  analyticsManager.gtag(EnumGtagEvents.ADD_TO_CART, {
    items: [
      {
        ...formattedProductForGTag(product, {
          quantity,
          ...getAdditionalParamsForGTag({ params: { position: 1, from, fromId }, product }),
          ...getPromotionParamsForGTag(analyticsParams),
          ...(orderId && { order_id: truncateString(orderId, 36) }),
          ...(afterCheckout && { after_checkout: true }),
        }),
      },
    ],
  });
}
