import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { enablePolyfills } from './polyfills';
import { defaultTheme } from 'theme';
import { Provider } from 'react-redux';
import { configureStore } from 'reduxStore/store';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import history from './configureHistory';
import { loadableReady } from '@loadable/component';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { ServerDataProvider } from 'serverDataContext';
import { FlagsProvider } from 'utils/unleashFlags';
import { getFlags } from 'utils/unleashFlags/helpers';
import IECheck from 'Containers/IE';
import { UserAgentProvider } from './utils/userAgent/context';
import { SWRConfig } from 'swr';
import { SWRGlobalOptions } from './swr.config';

enablePolyfills();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const insertCss = (...styles: any[]) => {
  const removeCss = styles.map(style => style._insertCss());
  return () => removeCss.forEach(dispose => dispose());
};

const SSR_DATA = JSON.parse(decodeURI((window as any).__SSR_DATA__));

const flags = getFlags();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

window.root = document.getElementById('root') as HTMLDivElement;
const PRELOADED_STATE = JSON.parse(decodeURI(window.__PRELOADED_STATE__));
const USER_AGENT = JSON.parse(decodeURI((window as any).__USER_AGENT__));

const store = configureStore({ initialState: PRELOADED_STATE });

const renderApp = (Component: React.ElementType) => {
  ReactDOM.hydrate(
    <ServerDataProvider value={SSR_DATA}>
      <StyleContext.Provider value={{ insertCss }}>
        <SWRConfig value={SWRGlobalOptions}>
          <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
              <FlagsProvider flags={flags}>
                <Router history={history}>
                  <UserAgentProvider value={USER_AGENT}>
                    <IECheck>
                      <Component />
                    </IECheck>
                  </UserAgentProvider>
                </Router>
              </FlagsProvider>
            </ThemeProvider>
          </Provider>
        </SWRConfig>
      </StyleContext.Provider>
    </ServerDataProvider>,
    MOUNT_NODE
  );
};

loadableReady(() => {
  renderApp(App);
});

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
