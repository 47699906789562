import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from '@igooods/ui-kit';
import { Text } from 'Components/Typography';
import { colors } from 'constants/colors';
import { FontSize, FontWeight } from 'theme';
import { mediaLgUp } from 'theme/helpers/css';
import { HEADER_HEIGHT } from 'Components/FaqPage/styled';

export const Container = styled(Flex).attrs({
  pl: { xs: 0, lg: 4.5 },
  column: true,
})`
  width: 250px;
  box-sizing: content-box;
  height: fit-content;
  position: sticky;
  top: calc(${HEADER_HEIGHT}px + 20px);
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.dark100};
`;

export const LinkText = styled(Text).attrs({
  size: FontSize.m,
})<{ active: boolean }>`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  letter-spacing: 0;
  color: ${({ active }) => (active ? colors.primaryMain : colors.dark300)};
  font-weight: ${({ active }) => active && FontWeight.semiBold};

  &:before {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    left: -16px;
    top: 11px;
    background-color: ${colors.primaryMain};
    ${mediaLgUp` 
      ${({ active }: { active: boolean }) => active && `content: ''; background-color: ${colors.primaryMain}`}
    `}
  }
`;

export const SubLinksContainer = styled(Flex).attrs({ column: true, py: 4.75, mt: 4 })`
  border-top: 1px solid ${colors.whiteTwo};
`;
