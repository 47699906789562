import React from 'react';
import Icon, { IIcon } from './Icon';

const NewSocialTGIcon: React.FC<IIcon> = props => (
  <Icon width="11" height="10" viewBox="0 0 11 10" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.692 4.379l5.37-2.512C8.619.712 9.15.51 9.497.505a.571.571 0 01.357.116.435.435 0 01.13.27c.013.078.028.256.016.394-.139 1.581-.738 5.419-1.043 7.19-.13.75-.383 1-.63 1.025-.534.053-.94-.384-1.458-.752l-2.055-1.5c-.91-.65-.32-1.008.198-1.592.136-.153 2.492-2.48 2.537-2.692.006-.026.011-.124-.042-.176-.054-.052-.134-.034-.191-.02-.081.02-1.376.949-3.884 2.788-.368.274-.7.407-.999.4-.329-.008-.96-.202-1.431-.368-.577-.203-1.035-.31-.995-.657.02-.18.249-.364.685-.552z"
    />
  </Icon>
);

export default NewSocialTGIcon;
