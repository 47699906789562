import { AnyAction } from 'redux';
import { SET_CURRENT_SHOP, CLEAR_CURRENT_SHOP } from './actions';
import { IShop } from './types';

const currentShopInitialState = {};

export default function currentShopReducer(
  state: Partial<IShop> = currentShopInitialState,
  action: AnyAction
): Partial<IShop> {
  switch (action.type) {
    case SET_CURRENT_SHOP:
      return { ...action.shop };
    case CLEAR_CURRENT_SHOP:
      return currentShopInitialState;
    default:
      return state;
  }
}
