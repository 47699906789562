import React, { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { EnumTheme, EnumButtonSize } from 'constants/enums';
import PhoneIcon from 'Components/Icon/PhoneIcon';
import { colors } from 'constants/colors';
import { shadows } from 'constants/shadows';
import styled from 'styled-components';
import ThemeButton from 'Components/ThemedButton';
import Contacts from 'Components//landings/Contacts';
import { useOnClickOutside } from 'utils/hooks';
import ExternalLink from 'Components/ExternalLink';
import { hideBeforeSm, hideAfterSm } from 'theme/helpers';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import useModalState from 'utils/hooks/useModalState';
import { useSelector } from 'react-redux';

const Container = styled.div`
  position: relative;
`;

const Modal = styled.div`
  position: absolute;
  background-color: ${colors.white};
  padding: 20px 24px;
  border-radius: 4px;
  box-shadow: ${shadows.boxShadow};
  top: calc(100% + 12px);
  right: 50%;
  transform: translateX(50%);
  min-width: 260px;
`;

const Button = styled(ThemeButton).attrs({
  use: EnumTheme.borderWhite,
  icon: true,
  size: EnumButtonSize.normal,
})``;

const MobileButton = styled(Button)`
  ${hideAfterSm}
`;
const DesktopButton = styled(Button)`
  ${hideBeforeSm}
`;

const ContactsModal: FC<{ className?: string }> = ({ className }) => {
  const { isVisible, hideModal, toggleModal } = useModalState();
  const modalNode = useRef<HTMLDivElement>(null);
  const currentCity = useSelector(getCurrentCity);

  useOnClickOutside(modalNode, hideModal);

  return (
    <Container className={className} ref={modalNode} style={{ zIndex: 100 }}>
      {currentCity?.phone && (
        <MobileButton>
          <ExternalLink href={`tel:+74957295471`}>
            <PhoneIcon color={colors.primaryMain} width="16" height="16" />
          </ExternalLink>
        </MobileButton>
      )}
      <DesktopButton onClick={toggleModal}>
        <PhoneIcon color={colors.primaryMain} width="16" height="16" />
      </DesktopButton>
      <CSSTransition in={isVisible} timeout={0} classNames="drop-modal" unmountOnExit>
        <Modal>
          <Contacts />
        </Modal>
      </CSSTransition>
    </Container>
  );
};

export default ContactsModal;
