import React from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { FontSize } from 'theme';
import ExternalLink from 'Components/ExternalLink';
import { Text } from 'Components/Typography';

const TextStyled = styled(Text).attrs({ color: colors.primaryMain, size: FontSize.m })`
  &:hover {
    color: ${colors.primaryAction};
  }
`;

interface IFaqLinkProps {
  href: string;
}

const FaqLink: React.FC<IFaqLinkProps> = ({ href, children }) => {
  return (
    <ExternalLink href={href} target="_blank">
      <TextStyled>{children}</TextStyled>
    </ExternalLink>
  );
};

export default FaqLink;
