import ApiService from '@igooods/api-service';
import { BACKEND_API_URL, DEPLOY_ENV_NOT_PROD, DEPLOY_ENV_PRODUCTION } from '../../config/config';
import { isBrowser } from 'utils/helpers';

export const getApiUrl = () => {
  if (isBrowser) {
    const apiFromCookie = ApiService.get();
    if (apiFromCookie) {
      return apiFromCookie;
    }
  } else if (DEPLOY_ENV_NOT_PROD || DEPLOY_ENV_PRODUCTION) {
    return BACKEND_API_URL;
  }

  return '';
};
