import { AnyAction } from 'redux';
import { EnumCurrentAddressTypes } from './actions';
import { ICity } from './types';
import { IPlace } from '../Places/types';
import { currentCityInitialByWL } from 'utils/wLabel';
import { AddressComponentsEnum } from 'Modules/GeoGrinder/types';

const currentAddressInitialState = {};

export default function currentAddressReducer(
  state: Partial<IPlace> = currentAddressInitialState,
  action: AnyAction
): Partial<IPlace> {
  switch (action.type) {
    case EnumCurrentAddressTypes.SET_CURRENT_ADDRESS:
      const { address_components } = action.address as IPlace;
      const street = address_components?.find(({ type }) => type === AddressComponentsEnum.STREET)?.name;
      const building = address_components?.find(({ type }) => type === AddressComponentsEnum.BUILDING)?.name;
      const updatedState = {
        ...state,
        ...action.address,
        building: building ?? state.building,
        street: street ?? state.street,
      };
      if (updatedState.coordinate) {
        updatedState.coordinates = updatedState.coordinate;
        delete updatedState.coordinate;
      }
      return updatedState;
    case EnumCurrentAddressTypes.CLEAR_CURRENT_ADDRESS:
      return currentAddressInitialState;
    default:
      return state;
  }
}

export function citiesReducer(state = [], action: AnyAction) {
  switch (action.type) {
    case EnumCurrentAddressTypes.SET_CITIES:
      return [...action.list];
    default:
      return state;
  }
}

export function currentCityReducer(state: Partial<ICity> = currentCityInitialByWL, action: AnyAction): Partial<ICity> {
  switch (action.type) {
    case EnumCurrentAddressTypes.SET_CITY:
      return { ...action.city };
    default:
      return state;
  }
}
