import { Dispatch, ThunkAction } from '../../reduxStore/types';
import { ISearchResults, ISearchCategoriesResults } from './types';
import { fetchSearch } from './http';

export enum EnumSearchTypes {
  START_FETCH_SEARCH_RESULTS = 'START_FETCH_SEARCH_RESULTS',
  FINISH_FETCH_SEARCH_RESULTS = 'FINISH_FETCH_SEARCH_RESULTS',
  UPDATE_FETCH_SEARCH_RESULTS = 'UPDATE_FETCH_SEARCH_RESULTS',
  UPDATE_CATEGORIES_RESULTS = 'UPDATE_CATEGORIES_RESULTS',
}

interface IStartFetchingSearchResults {
  type: EnumSearchTypes.START_FETCH_SEARCH_RESULTS;
}
interface IFinishFetchingSearchResults {
  type: EnumSearchTypes.FINISH_FETCH_SEARCH_RESULTS;
  payload: ISearchResults;
}

interface IUpdateFetchingSearchResults {
  type: EnumSearchTypes.UPDATE_FETCH_SEARCH_RESULTS;
  payload: ISearchResults;
}

interface IUpdateCategoriesResults {
  type: EnumSearchTypes.UPDATE_CATEGORIES_RESULTS;
  payload: ISearchCategoriesResults[];
}

export type SearchActionsType =
  | IStartFetchingSearchResults
  | IFinishFetchingSearchResults
  | IUpdateCategoriesResults
  | IUpdateFetchingSearchResults;

export const startFetchingSearchResults = () => ({
  type: EnumSearchTypes.START_FETCH_SEARCH_RESULTS,
});

export const finishFetchingSearchResults = (search: ISearchResults): IFinishFetchingSearchResults => ({
  type: EnumSearchTypes.FINISH_FETCH_SEARCH_RESULTS,
  payload: search,
});
export const updateFetchingSearchResults = (search: ISearchResults): IUpdateFetchingSearchResults => ({
  type: EnumSearchTypes.UPDATE_FETCH_SEARCH_RESULTS,
  payload: search,
});
export const updateCategoriesResults = (categories: ISearchCategoriesResults[]): IUpdateCategoriesResults => ({
  type: EnumSearchTypes.UPDATE_CATEGORIES_RESULTS,
  payload: categories,
});

export const getSearchResultsAction = (shopId: number, params?: object): ThunkAction => async (
  dispatch: Dispatch,
  _,
  fetcher
) => {
  dispatch(startFetchingSearchResults());

  const searchResults = await fetchSearch(shopId, params, fetcher);

  dispatch(finishFetchingSearchResults(searchResults));

  return searchResults;
};

export const updateSearchResultsAction = (shopId: number, params?: object) => async (dispatch: Dispatch) => {
  const searchResults = await fetchSearch(shopId, params);
  dispatch(updateFetchingSearchResults(searchResults));

  return searchResults;
};
