import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { EMPTY_ARRAY } from 'constants/constants';
import { colors } from 'constants/colors';
import { SocialLinks } from 'constants/enums';
import { getFullCityInfo } from 'Modules/SearchAddress/selectors';
import SocialVKIcon from 'Components/Icon/SocialVK';
import SocialFBIcon from 'Components/Icon/SocialFB';
import SocialINSTIcon from 'Components/Icon/SocialINST';
import SocialTGIcon from 'Components/Icon/SocialTG';
import SocialYouTubeIcon from 'Components/Icon/SocialYouTube';
import NewSocialWAIcon from 'Components/Icon/NewSocialWAIcon';
import { SocialLink, SocialsWrapper } from './styles';

export const SOCIALS_LIST_TEST_ID = 'SOCIALS_LIST_TEST_ID';

interface ISocialLink {
  name: SocialLinks;
  link: string;
}

const getSocialIcon = (name: SocialLinks): ReactNode =>
  ({
    [SocialLinks.vkontakte]: <SocialVKIcon color={colors.primaryMain} />,
    [SocialLinks.facebook]: <SocialFBIcon color={colors.primaryMain} />,
    [SocialLinks.inst]: <SocialINSTIcon color={colors.primaryMain} />,
    [SocialLinks.youtube]: <SocialYouTubeIcon color={colors.primaryMain} />,
    [SocialLinks.telegram]: <SocialTGIcon color={colors.primaryMain} />,
    [SocialLinks.whatsapp]: <NewSocialWAIcon color={colors.primaryMain} />,
  }[name]);

const SocialsList = ({ bgColor, className }: { bgColor?: string; className?: string }) => {
  const city = useSelector(getFullCityInfo);

  const social_links: ISocialLink[] =
    city?.social_networks?.filter(
      ({ name }: ISocialLink) => name !== SocialLinks.facebook && name !== SocialLinks.inst
    ) ?? EMPTY_ARRAY;

  return (
    <SocialsWrapper className={className} data-testid={SOCIALS_LIST_TEST_ID}>
      {social_links.map(({ link, name }) => (
        <SocialLink key={name} href={link} newTab bgColor={bgColor}>
          {getSocialIcon(name)}
        </SocialLink>
      ))}
    </SocialsWrapper>
  );
};

export default SocialsList;
