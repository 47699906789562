import React from 'react';
import Icon, { IIcon } from './Icon';

const ChevronLeftIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      transform="translate(4 1)"
      d="M.17 7.407a.572.572 0 0 1 0-.814L6.563.244a.847.847 0 0 1 1.19 0 .83.83 0 0 1 0 1.18L2.14 7l5.614 5.575a.831.831 0 0 1 0 1.18.847.847 0 0 1-1.19 0L.17 7.408z"
    />
  </Icon>
);

export default ChevronLeftIcon;
