import React from 'react';
import Icon, { IIcon } from './Icon';

const SocialYouTubeIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="12" viewBox="0 0 16 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.251 0.358258C14.9395 0.555387 15.4816 1.13623 15.6656 1.87384C16 3.21068 16 6 16 6C16 6 16 8.78923 15.6656 10.1262C15.4816 10.8638 14.9395 11.4446 14.251 11.6418C13.0034 12 8 12 8 12C8 12 2.99664 12 1.74891 11.6418C1.06045 11.4446 0.518273 10.8638 0.334273 10.1262C0 8.78923 0 6 0 6C0 6 0 3.21068 0.334273 1.87384C0.518273 1.13623 1.06045 0.555387 1.74891 0.358258C2.99664 0 8 0 8 0C8 0 13.0034 0 14.251 0.358258ZM6.5459 3.37523V8.62523L10.5459 6.00033L6.5459 3.37523Z"
    />
  </Icon>
);

export default SocialYouTubeIcon;
