import { AVATAR_MAX_SIDE } from './Settings/constants';
import { EnumFileTypes } from 'constants/enums';
import routes, { IRoute } from './routes';
import { URL } from '../../constants/urlMaps';

export const createCroppedContext = (scaleFactor: number, img: HTMLImageElement): CanvasRenderingContext2D => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  const width = img.width * scaleFactor;
  const height = img.height * scaleFactor;
  canvas.width = width;
  canvas.height = height;
  context.drawImage(img, 0, 0, width, height);
  return context;
};

export const canvasToFile = (context: CanvasRenderingContext2D, fileName: string): Promise<File> => {
  return new Promise(resolve => {
    context.canvas.toBlob(blob => {
      const avatarFile = new File([blob as Blob], fileName, {
        type: EnumFileTypes.jpeg,
        lastModified: Date.now(),
      });

      resolve(avatarFile);
    }, EnumFileTypes.jpeg);
  });
};

export const cropImage = async (
  file: File,
  img: HTMLImageElement
): Promise<{
  croppedFile: File;
  croppedImg: HTMLImageElement;
}> => {
  const minSide = Math.min(img.width, img.height);
  const dimensionIsLarge = minSide > AVATAR_MAX_SIDE;

  if (dimensionIsLarge) {
    const scaleFactor = AVATAR_MAX_SIDE / minSide;
    const context = createCroppedContext(scaleFactor, img);
    const croppedFile = await canvasToFile(context, file.name);

    const croppedImg = new Image(img.width * scaleFactor, img.height * scaleFactor);
    croppedImg.src = context.canvas.toDataURL(EnumFileTypes.jpeg);

    return new Promise(resolve => {
      croppedImg.onload = () => {
        resolve({
          croppedFile: croppedFile,
          croppedImg: croppedImg,
        });
      };
    });
  } else {
    return {
      croppedFile: file,
      croppedImg: img,
    };
  }
};

export const preparePic = (file: File): Promise<File> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise(resolve => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = e.target ? (e.target.result as string) : '';

      img.onload = async () => {
        const { croppedFile } = await cropImage(file, img);
        resolve(croppedFile);
      };
    };
  });
};

export const filterRoutesByInnerProps = (isEntity: boolean) => (route: IRoute) => {
  let showRoute = true;
  if (typeof route.show?.forEntity !== 'undefined') showRoute = route.show.forEntity === isEntity;
  return showRoute;
};

const checkForBlockedRoutes = (isEntity: boolean) => (route: IRoute) => {
  const isBlocked = route.block?.forEntity === isEntity || route.isBlocked;
  return {
    ...route,
    isBlocked,
  };
};

export const checkForAuthedRoutes = (isEntity: boolean) => (route: IRoute) => ({
  ...route,
  isAuthed: !route.isBlocked && filterRoutesByInnerProps(isEntity)(route),
  redirect_path: route.isBlocked ? URL.entity_info : route.redirect_path || URL.entity_info,
});

export const getRoutesList = (blockedForEntity = false): IRoute[] => {
  return routes.map(checkForBlockedRoutes(blockedForEntity));
};
