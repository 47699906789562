import { keyframes } from 'styled-components';
import { EnumSpinnerSize } from '../../constants/enums';

export const bounce = keyframes`
  0% {
    transform: scale(1);
  }

  16.666% {
    transform: scale(1.3333);
  }

  33.333% {
    transform: scale(1);
  }
`;

export const parameters: { [key in EnumSpinnerSize]: { width: string; height: string } } = {
  [EnumSpinnerSize.small]: {
    width: '6',
    height: '6',
  },
  [EnumSpinnerSize.normal]: {
    width: '13.5',
    height: '13.5',
  },
};

export const marginXBySize: { [key in EnumSpinnerSize]: number } = {
  [EnumSpinnerSize.small]: 1,
  [EnumSpinnerSize.normal]: 2.5,
};
