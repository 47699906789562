import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import { getUserSetsListIdle } from 'Modules/UserSets/selectors';
import { useDispatch, useSelector } from 'react-redux';
import UserSetsActions from 'Modules/UserSets/actions';
import { IS_KOMUS } from '../../../config/config';

const useUserSetsListInit = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isTempUser = useSelector(checkIsTempUser);
  const isIdle = useSelector(getUserSetsListIdle);

  const isMathingPage = Boolean(pathname.match(/^\/(catalog|profile\/orders|cart|products)/));

  useEffect(() => {
    if (!isTempUser && !isIdle && isMathingPage && !IS_KOMUS) {
      dispatch(UserSetsActions.setUserSetsList());
    }
  }, [isTempUser, isIdle, dispatch, isMathingPage]);
};

export default useUserSetsListInit;
