export enum AddressComponentsEnum {
  COUNTRY = 'country',
  PROVINCE = 'province',
  LOCALITY = 'locality',
  AREA = 'area',
  STREET = 'street',
  BUILDING = 'building',
}

export interface IAddressComponent {
  name: string;
  type: AddressComponentsEnum;
}

export interface IGeoGrinderPlace {
  coordinate: { latitude: number; longitude: number } | null;
  full_address: string;
  id: string | null;
  components: IAddressComponent[];
  full?: boolean;
}
