import { EnumNotification } from 'constants/enums';

export enum NotificationAction {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export interface INotificationAction {
  type: string;
  text: string;
  theme: EnumNotification;
  timeout?: number;
  link?: string;
}

export const setNotificationAction = (
  text: string,
  theme: EnumNotification = EnumNotification.danger,
  timeout?: number,
  link?: string
): INotificationAction => ({
  type: NotificationAction.SET_NOTIFICATION,
  text,
  theme,
  timeout,
  link,
});

export const removeNotificationAction = (): Partial<INotificationAction> => ({
  type: NotificationAction.REMOVE_NOTIFICATION,
});

export default {
  setNotificationAction,
  removeNotificationAction,
};
