import { FavoritesActionsType, EnumFavoritesType } from './actions';
import { IProduct } from '../Products/types';

export interface IFavoritesState {
  isIdle: boolean;
  isLoadingProducts: boolean;
  products: {
    list: IProduct[];
    total: number;
  };
}

export const favoritesInitialState = {
  isIdle: true,
  isLoadingProducts: false,
  products: {
    list: [],
    total: 0,
  },
};

function favoritesReducer(state: IFavoritesState = favoritesInitialState, action: FavoritesActionsType) {
  switch (action.type) {
    case EnumFavoritesType.START_GET_FAVORITES_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: true,
      };
    case EnumFavoritesType.FINISH_GET_FAVORITES_PRODUCTS:
      return {
        ...state,
        isIdle: false,
        isLoadingProducts: false,
        products: { ...action.payload, list: [...action.payload.list] },
      };
    case EnumFavoritesType.ADD_FAVORITES_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          list: [...state.products.list, ...action.payload],
        },
      };
    default:
      return state;
  }
}

export default favoritesReducer;
