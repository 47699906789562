import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Grid/Flex';
import Loader from 'Components/Loader';

export const PAGE_LOADING_CONTAINER_TEST_ID = 'PAGE_LOADING_CONTAINER_TEST_ID';

const LoaderWrapper = styled(Flex).attrs({
  middle: true,
})`
  height: 90vh;
`;

const FullPageLoading = () => (
  <LoaderWrapper data-testid={PAGE_LOADING_CONTAINER_TEST_ID}>
    <Loader />
  </LoaderWrapper>
);

export default FullPageLoading;
