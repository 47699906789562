import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaSmUp } from 'theme/helpers/css';
import { DESKTOP_MIN_WIDTH } from './constants';
import Flex from 'Components/Grid/Flex';
import Box from '../../Grid/Box';
import { Transitions } from '../../../theme';
import { addStyleIfPropTruly } from 'utils/styledComponents';

export const LogoLink = styled(Link)`
  display: inline-block;
`;

export const LogoContainer = styled(Flex).attrs({ middle: true })`
  width: max-content;

  ${mediaSmUp`
      min-width: ${DESKTOP_MIN_WIDTH}px;
   `}
`;

export const LogoIconContainer = styled(Box)`
  width: 100%;
  transition: ${Transitions.opacity};
  max-width: 151px;

  ${addStyleIfPropTruly(
    'onClick',
    `
    cursor: pointer;

    &:hover {
      filter: opacity(0.7);
    }`
  )}

  img {
    width: 100%;
  }
`;
