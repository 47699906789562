import React from 'react';
import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  children: React.ReactChild | React.ReactNode;
  container: HTMLElement;
}

const Portal: React.FC<IPortalProps> = ({ children, container }) => {
  const appendElementRef = useRef<HTMLElement>(document.createElement('div'));

  useEffect(() => {
    if (appendElementRef.current) {
      const element = appendElementRef.current;
      container.appendChild(element);

      return () => {
        container.removeChild(element);
      };
    }
  }, [container]);

  return createPortal(children, appendElementRef.current);
};

export default Portal;
