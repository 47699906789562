import { AnyAction } from 'redux';
import { Dispatch, GetState, ThunkAction } from '../../reduxStore/types';
import { IShop } from './types';
import ShopsHttp from './http';
import { USER_HAS_SHOP } from 'constants/cookie';
import Cookie from 'js-cookie';

export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP';
export const CLEAR_CURRENT_SHOP = 'CLEAR_CURRENT_SHOP';

export const setCurrentShopAction = (shop: IShop): AnyAction => ({
  type: SET_CURRENT_SHOP,
  shop,
});

export const clearCurrentShopAction = (): AnyAction => ({
  type: CLEAR_CURRENT_SHOP,
});

export default {
  setCurrentShop: (shop: IShop): ThunkAction => (dispatch: Dispatch) => {
    Cookie.set(USER_HAS_SHOP, new Date().getTime().toString(), {
      expires: 1048576,
    });
    return dispatch(setCurrentShopAction(shop));
  },

  clearCurrentShop: (): ThunkAction => (dispatch: Dispatch) => {
    Cookie.remove(USER_HAS_SHOP);
    return dispatch(clearCurrentShopAction());
  },

  updateCurrentShopAction: (): ThunkAction => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const data = await ShopsHttp.getShop(
      getState().currentShop.id,
      getState().currentShop.branding === 'utkonos' ? getState().currentShop.branding : ''
    );

    dispatch(setCurrentShopAction(data.shop));
  },
};
