import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import LoaderIcon from 'Components/Icon/Loader';
import { Flex } from '@igooods/ui-kit';
import { SPINNER_TEST_ID } from '../Spinner';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg)
  }
`;

export const LoaderSpinner = styled(LoaderIcon)`
  animation: ${rotate} 1s linear infinite;
`;

const Loader: FC = () => {
  return (
    <Flex center middle data-testid={SPINNER_TEST_ID} width="100%">
      <LoaderSpinner />
    </Flex>
  );
};

export default Loader;
