import React from 'react';
import { EnumButtonSize, EnumTheme, ModalType } from 'constants/enums';
import { Modal, ModalFooter, ModalHeader, ModalImage, ModalSubTitle, ModalTitle } from 'Containers/Modals/styled';
import styled from 'styled-components';
import monkey from 'assets/images/monkey_not_see.png';
import ThemeButton from 'Components/ThemedButton';
import { useDispatch } from 'react-redux';
import ModalActions from 'Modules/Modals/actions';
import { mediaSmUp } from 'theme/helpers/css';
import { colors } from 'constants/colors';

interface IPassportModalProps {
  type: ModalType;
}

const PASSPORT_MODAL_TEST_ID = 'PASSPORT_MODAL_TEST_ID';
const PASSPORT_MODAL_BUTTON_TEST_ID = 'PASSPORT_MODAL_BUTTON_TEST_ID';

const Container = styled(Modal)`
  ${mediaSmUp`
    max-width: 480px;
    min-height: 480px;
  `}
`;

const Description = styled(ModalSubTitle)`
  max-width: none;
  margin-bottom: 20px;
`;

const PassportModal: React.FC<IPassportModalProps> = () => {
  const dispatch = useDispatch();

  return (
    <Container data-testid={PASSPORT_MODAL_TEST_ID}>
      <ModalHeader>
        <ModalImage src={monkey} />
        <ModalTitle>Нужно будет показать паспорт</ModalTitle>
        <Description color={colors.dark300}>
          Продажа безалкогольных энергетических напитков несовершеннолетним запрещена. Курьер попросит вас показать
          паспорт.
        </Description>
      </ModalHeader>
      <ModalFooter>
        <ThemeButton
          testId={PASSPORT_MODAL_BUTTON_TEST_ID}
          fluid
          size={EnumButtonSize.large}
          onClick={() => dispatch(ModalActions.closeModal())}
          use={EnumTheme.roundRed}
        >
          Хорошо
        </ThemeButton>
      </ModalFooter>
    </Container>
  );
};

export default PassportModal;
