import { Dispatch, GetState, ThunkAction } from '../../reduxStore/types';
import {
  fetchCategoryProducts,
  fetchCategory,
  fetchCategoryProductLine,
  getCategories,
  getFavoritesCategories,
} from './http';
import { IListProductsResponse, IProduct } from 'Modules/Products/types';
import { IGetCategoriesResponse, IGetFavoritesCategoriesResponse, IProductLineResponse } from './types';
import { AnyAction } from 'redux';
import { ICategoryChild } from 'Modules/Categories/types';

export enum EnumCategoriesType {
  SET_CATEGORIES = 'SET_CATEGORIES',
  START_GET_CATEGORY = 'START_GET_CATEGORY',
  FINISH_GET_CATEGORY = 'FINISH_GET_CATEGORY',
  START_GET_CATEGORY_PRODUCTS = 'START_GET_CATEGORY_PRODUCTS',
  START_GET_CATEGORY_PRODUCT_LINE = 'START_GET_CATEGORY_PRODUCT_LINE',
  FINISH_GET_CATEGORY_PRODUCTS = 'FINISH_GET_CATEGORY_PRODUCTS',
  FINISH_GET_CATEGORY_PRODUCT_LINE = 'FINISH_GET_CATEGORY_PRODUCT_LINE',
  RESET_CURRENT_CATEGORY_AND_PRODUCTS = 'RESET_CURRENT_CATEGORY_AND_PRODUCTS',
  ADD_CATEGORY_PRODUCTS = 'ADD_CATEGORY_PRODUCTS',
  SET_FAVORITES_CATEGORIES = 'SET_FAVORITES_CATEGORIES',
  SET_FAVORITES_CATEGORIES_LOADING = 'SET_FAVORITES_CATEGORIES_LOADING',
}

interface IStartGettingCategory {
  type: EnumCategoriesType.START_GET_CATEGORY;
}
interface IStartGettingCategoryProducts {
  type: EnumCategoriesType.START_GET_CATEGORY_PRODUCTS;
}

interface IStartGettingCategoryProductLine {
  type: EnumCategoriesType.START_GET_CATEGORY_PRODUCT_LINE;
}

interface IFinishGettingCategory {
  type: EnumCategoriesType.FINISH_GET_CATEGORY;
  payload: ICategoryChild;
}

interface IFinishGettingCategoryProducts {
  type: EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCTS;
  payload: { list: IProduct[]; total: number };
}

interface IFinishGettingCategoryProductLine {
  type: EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCT_LINE;
  payload: { list: IProduct[] };
}

interface IAddCategoryProducts {
  type: EnumCategoriesType.ADD_CATEGORY_PRODUCTS;
  payload: IProduct[];
}

interface ISetCategories {
  type: EnumCategoriesType.SET_CATEGORIES;
  payload: IGetCategoriesResponse;
}

interface IResetCurrentCategoryAndProducts {
  type: EnumCategoriesType.RESET_CURRENT_CATEGORY_AND_PRODUCTS;
}

export type CategoriesActionsType =
  | IStartGettingCategory
  | IFinishGettingCategory
  | IAddCategoryProducts
  | ISetCategories
  | IFinishGettingCategoryProducts
  | IStartGettingCategoryProducts
  | IStartGettingCategoryProductLine
  | IFinishGettingCategoryProductLine
  | IResetCurrentCategoryAndProducts;

export const setCategoriesAction = (payload: IGetCategoriesResponse): ISetCategories => ({
  type: EnumCategoriesType.SET_CATEGORIES,
  payload,
});

export const startGettingCategory = () => ({
  type: EnumCategoriesType.START_GET_CATEGORY,
});

export const startGettingCategoryProducts = () => ({
  type: EnumCategoriesType.START_GET_CATEGORY_PRODUCTS,
});

export const startGettingCategoryProductLine = () => ({
  type: EnumCategoriesType.START_GET_CATEGORY_PRODUCT_LINE,
});

export const finishGettingCategory = (categoryMeta: ICategoryChild): IFinishGettingCategory => ({
  type: EnumCategoriesType.FINISH_GET_CATEGORY,
  payload: categoryMeta,
});

export const finishGettingCategoryProducts = (
  categoryProducts: IListProductsResponse
): IFinishGettingCategoryProducts => ({
  type: EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCTS,
  payload: categoryProducts,
});

export const finishGettingCategoryProductLine = (
  productLine: IProductLineResponse
): IFinishGettingCategoryProductLine => ({
  type: EnumCategoriesType.FINISH_GET_CATEGORY_PRODUCT_LINE,
  payload: productLine,
});

export const resetCurrentCategoryAndProducts = () => ({
  type: EnumCategoriesType.RESET_CURRENT_CATEGORY_AND_PRODUCTS,
});

export const getCurrentCategoryAction = (shopId: number, categoryId: string, params?: object): ThunkAction => async (
  dispatch: Dispatch,
  _,
  fetcher
) => {
  dispatch(startGettingCategory());

  const category = await fetchCategory(shopId, categoryId, params, fetcher);

  dispatch(finishGettingCategory(category));

  return category;
};

export const getCurrentCategoryProductsAction = (
  shopId: number,
  categoryId: string,
  params?: object
): ThunkAction => async (dispatch, _, fetcher) => {
  dispatch(startGettingCategoryProducts());

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const products = await fetchCategoryProducts(shopId, categoryId, params, fetcher);
    dispatch(finishGettingCategoryProducts(products));
  } catch {
    dispatch(finishGettingCategoryProducts({ list: [], total: 0 }));
  }
};

export const getCurrentCategoryProductLineAction = (shopId: number, categoryId: string): ThunkAction => async (
  dispatch: Dispatch,
  _,
  fetcher
) => {
  dispatch(startGettingCategoryProductLine());
  try {
    const productLine = await fetchCategoryProductLine(shopId, categoryId, fetcher);

    dispatch(finishGettingCategoryProductLine(productLine));
  } catch (e) {
    dispatch(finishGettingCategoryProductLine({ list: [] }));
  }
};

export const addCategoryProducts = (payload: IProduct[]) => ({
  type: EnumCategoriesType.ADD_CATEGORY_PRODUCTS,
  payload,
});

export const updateCurrentCategoryProducts = (shopId: number, categoryId: string, params?: object) => async (
  dispatch: Dispatch
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const category = await fetchCategoryProducts(shopId, categoryId, params);

  dispatch(addCategoryProducts(category.list));
  return category;
};

export const startGetFavoritesCategoriesAction = () => ({
  type: EnumCategoriesType.SET_FAVORITES_CATEGORIES_LOADING,
});

export const setFavoritesCategoriesAction = (payload: IGetFavoritesCategoriesResponse): AnyAction => ({
  type: EnumCategoriesType.SET_FAVORITES_CATEGORIES,
  payload,
});

export default {
  setCategories: (): ThunkAction => async (
    dispatch: Dispatch,
    getState: GetState,
    fetcher
  ): Promise<IGetCategoriesResponse> => {
    const data = await getCategories(getState().currentShop.id, fetcher);

    await dispatch(setCategoriesAction(data));
    return Promise.resolve(data);
  },

  setFavoritesCategories: (): ThunkAction => async (
    dispatch,
    getState,
    fetcher
  ): Promise<IGetFavoritesCategoriesResponse> => {
    dispatch(startGetFavoritesCategoriesAction());

    const data = await getFavoritesCategories(getState().currentShop.id, fetcher);

    await dispatch(setFavoritesCategoriesAction(data));
    return Promise.resolve(data);
  },
};
