import React, { FC } from 'react';
import Link from './LinkWrapper';
import { LinkProps } from 'react-router-dom';

interface ILinkWithMobileCheckProps extends LinkProps {
  check?: boolean;
}

const LinkWithMobileCheck: FC<ILinkWithMobileCheckProps> = ({ to, check, ...props }) => {
  /**
   * эта штука нужна для добавления стейта в локейшн, по которому мы в src/utils/hooks/useMobileLandingRedirect.ts
   * делаем редирект на страницу мобильного баннера
   */
  const locationToRedirect = typeof to === 'string' ? { pathname: to } : to;
  return <Link to={{ ...locationToRedirect, state: { check: check } }} {...props} />;
};

export default LinkWithMobileCheck;
