import { EnumPromotionType, ProductStates } from 'constants/enums';
import { EnumProductType, IProduct } from 'Modules/Products/types';
import { numberThousandSeparator, normalizePrice } from 'utils/helpers';
import { RUB } from 'constants/constants';
import { dateInDDMMFormat } from 'utils/date';
import { IAnalyticsParams } from 'utils/tagManager/types';
import { ENERGY_DRINK_CATEGORY_ID } from 'Components/Product/constants';
import { URL } from 'constants/urlMaps';

export const getCurrentProductState = (amount = 0, available: boolean, revert?: boolean) => {
  if (!available) return ProductStates.notAvailable;

  if (amount > 0) return ProductStates.inBasket;

  if (revert) {
    return ProductStates.revert;
  } else {
    return ProductStates.toBasket;
  }
};

const DOT = '.';

const DOUBLE_ZERO = '00';

const GAP_STRING = ' ';

const COMMA = ',';

const THING = 'шт';

const PACK = 'уп';

const KG = 'кг';

const L = 'л';

const TILDA = '~';

export const ADVERT_LABEL = 'реклама';

export const PRODUCT_UNIT = {
  KG: 'кг',
  G: 'гр',
  L: 'л',
  ML: 'мл',
};

const UNITS: { [prop: string]: { BIG: string; SMALL: string } } = {
  [KG]: {
    BIG: PRODUCT_UNIT.KG,
    SMALL: PRODUCT_UNIT.G,
  },
  [L]: {
    BIG: PRODUCT_UNIT.L,
    SMALL: PRODUCT_UNIT.ML,
  },
};

const DOT_RGXP = /\./;

const dividePriceToIntAndFract = (price = 0, prefix = '') => {
  const [priceInt, priceFract = DOUBLE_ZERO] = price.toString().split(DOT);

  return [`${prefix}${numberThousandSeparator(priceInt)}`, priceFract.length === 1 ? `${priceFract}0` : priceFract];
};

const formatPrice = (price: number) => price.toLocaleString();

const calcShowOldPrice = (amount: number, old_price?: number) => {
  if (!old_price) return [];

  const showOldPrice = normalizePrice(old_price);

  return [showOldPrice, amount ? normalizePrice(old_price * amount) : showOldPrice];
};

const calcShowAmountWeight = (amountItemWeightOrVolume: number, unit_in_price_per_kg: string, prefix = '') => {
  if (amountItemWeightOrVolume >= 1000) {
    return `${prefix} ${amountItemWeightOrVolume / 1000} ${UNITS[unit_in_price_per_kg].BIG}`
      .trim()
      .replace(DOT_RGXP, COMMA);
  }

  return `${prefix} ${amountItemWeightOrVolume} ${UNITS[unit_in_price_per_kg].SMALL}`.trim();
};

interface IProductShowProp {
  showPrice: number;
  showPriceInt: string;
  showPriceFract: string;
  showOldPrice: number;
  amountShowOldPrice: number;
  amountPrice: number;
  amountPriceInt: string;
  amountPriceFract: string;
  showAmountUnit: string; // @note quantity unit
  showAmountWeight: string; // @note quantity weight/volume
  showWeight: string;
  endOfSalePeriod: string;
}

export const calcProductShowProp = (product: IProduct): IProductShowProp => {
  const {
    price,
    old_price,
    price_per_kg,
    sale_end_date,
    display_weight,
    unit_in_price_per_kg,
    type,
    amount,
    item_weight,
    group_quantity = 0,
    weight,
  } = product;

  let endOfSalePeriod = '';

  if (sale_end_date) {
    endOfSalePeriod = dateInDDMMFormat(sale_end_date);
  }

  if (type === EnumProductType.piece) {
    const [showOldPrice, amountShowOldPrice] = calcShowOldPrice(amount, old_price);

    const showPrice = price;

    const amountPrice = normalizePrice(price * amount) || showPrice;

    const [showPriceInt, showPriceFract] = dividePriceToIntAndFract(showPrice);

    const [itemWeightOrVolumeString, unitWeightOrVolume] = display_weight.split(GAP_STRING);

    let itemWeightOrVolume = Number.parseFloat(itemWeightOrVolumeString.replace(COMMA, DOT));

    if (unitWeightOrVolume === KG || unitWeightOrVolume === L) {
      itemWeightOrVolume *= 1000;
    }

    const showAmountWeight = calcShowAmountWeight(amount * itemWeightOrVolume, unit_in_price_per_kg);

    const showAmountUnit = `${amount} ${group_quantity > 1 ? `${PACK} (${group_quantity * amount} ${THING})` : THING}`;

    const [amountPriceInt, amountPriceFract] = dividePriceToIntAndFract(amountPrice);

    return {
      showPrice,
      showPriceInt,
      showPriceFract,
      showOldPrice,
      amountShowOldPrice,
      amountPrice,
      amountPriceInt,
      amountPriceFract,
      showAmountUnit,
      showAmountWeight,
      showWeight: display_weight,
      endOfSalePeriod,
    };
  }

  if (type === EnumProductType.pack) {
    /* С бэка приходит старая цена за кг товара, делаем пересчёт цены на вес одного айтема */
    const singleItemOldPrice = old_price ? (old_price / 1000) * item_weight : undefined;
    const [showOldPrice, amountShowOldPrice] = calcShowOldPrice(amount, singleItemOldPrice);

    const showPrice = price_per_kg;

    const amountPrice = normalizePrice(price * amount) || price;

    const [showPriceInt, showPriceFract] = dividePriceToIntAndFract(showPrice);

    const [itemWeightOrVolumeString, unitWeightOrVolume] = display_weight.split(GAP_STRING);

    let itemWeightOrVolume = Number.parseFloat(itemWeightOrVolumeString);

    if (Number.isNaN(itemWeightOrVolume)) itemWeightOrVolume = item_weight;

    if (unitWeightOrVolume === KG || unitWeightOrVolume === L) {
      itemWeightOrVolume *= 1000;
    }

    const showAmountUnit = `${amount} ${THING}`;
    const showAmountWeight = calcShowAmountWeight(amount * itemWeightOrVolume, unit_in_price_per_kg, TILDA);

    const [amountPriceInt, amountPriceFract] = dividePriceToIntAndFract(amountPrice, TILDA);

    return {
      showPrice,
      showPriceInt: `~${showPriceInt}`,
      showPriceFract,
      showOldPrice,
      amountShowOldPrice,
      amountPrice,
      amountPriceInt,
      amountPriceFract,
      showAmountUnit,
      showAmountWeight,
      showWeight: amount
        ? `${formatPrice(price_per_kg)} ${RUB} за кг, ${display_weight.replace(/\s/g, '\u00A0')}`
        : `за 1 кг, ${display_weight}`,
      endOfSalePeriod,
    };
  }

  const [showOldPrice, amountShowOldPrice] = calcShowOldPrice(weight / 1000, old_price);

  const showPrice = price_per_kg;

  const amountPrice = normalizePrice(price_per_kg * (weight / 1000)) || showPrice;

  const [showPriceInt, showPriceFract] = dividePriceToIntAndFract(showPrice);

  const [amountPriceInt, amountPriceFract] = dividePriceToIntAndFract(amountPrice, TILDA);

  return {
    showPrice,
    showPriceInt,
    showPriceFract,
    showOldPrice,
    amountShowOldPrice,
    amountPrice,
    amountPriceInt,
    amountPriceFract,
    showAmountUnit: display_weight,
    showAmountWeight: display_weight,
    showWeight: display_weight,
    endOfSalePeriod,
  };
};

export const getAnalyticsParams = (analyticsParams: IAnalyticsParams, product: IProduct) => {
  const params = { promotionName: '', ...analyticsParams };
  const { product_line_campaign_id, advertising_label } = product;
  if (product_line_campaign_id) {
    params.promotionId = product_line_campaign_id;
    params.promotionType = EnumPromotionType.LINE;
  }
  if (advertising_label?.id) {
    params.promotionId = advertising_label.id;
    params.promotionType = EnumPromotionType.LABEL;
  }
  return params;
};

export const checkIsNeedPassportModal = (products: IProduct[], callback: () => void, product_category_id?: number) => {
  if (product_category_id === ENERGY_DRINK_CATEGORY_ID) {
    const isUniqCategory = !products.find((item: IProduct) => item.category_id === ENERGY_DRINK_CATEGORY_ID);

    if (isUniqCategory) {
      callback();
    }
  }
};

export const getProductCategoryUrl = (
  category_id: IProduct['category_id'],
  root_category_id?: IProduct['root_category_id']
) => URL.catalogByCategories + (root_category_id && `/${root_category_id}`) + `/${category_id}`;
