import Http from 'lib/http';
import { IUserSet, IUserSetsList, ICreateUserSetRequest, IEditUserSetRequest, ICreateUserSetResponse } from './types';

const ENDPOINT_BASE_URL = 'user_sets';

interface IUserSetsHttp {
  getUserSet(id: number, shop_id: number, fetcher?: typeof Http): Promise<IUserSet>;
  getUserSetsList(shop_id: number, fetcher?: typeof Http): Promise<IUserSetsList>;
  createUserSet(body: ICreateUserSetRequest): Promise<ICreateUserSetResponse>;
  editUserSet(id: number, body: IEditUserSetRequest): Promise<IUserSet>;
  removeUserSet(id: number): Promise<never>;
}

class UserSetsHttp implements IUserSetsHttp {
  private readonly Http = Http;

  public getUserSet(id: number, shop_id: number, fetcher = this.Http): Promise<IUserSet> {
    return fetcher.get(`${ENDPOINT_BASE_URL}/${id}`, { shop_id });
  }

  public getUserSetsList(shop_id: number, fetcher = this.Http): Promise<IUserSetsList> {
    return fetcher.get(`${ENDPOINT_BASE_URL}`, { shop_id });
  }

  public createUserSet(body: ICreateUserSetRequest): Promise<ICreateUserSetResponse> {
    return this.Http.post(`${ENDPOINT_BASE_URL}`, body);
  }

  public editUserSet(id: number, body: IEditUserSetRequest): Promise<IUserSet> {
    return this.Http.patch(`${ENDPOINT_BASE_URL}/${id}`, body);
  }

  public removeUserSet(id: number): Promise<never> {
    return this.Http.delete(`${ENDPOINT_BASE_URL}/${id}`);
  }
}

export default new UserSetsHttp();
