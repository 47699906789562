import React, { FC } from 'react';
import { colors } from 'constants/colors';
import { URL } from 'constants/urlMaps';
import useRootUrlWithSubdomain from 'utils/hooks/useRootUrlWithSubdomain';
import { Container, Company, LogoLink, Rights, Nav, ContactsWrapper, ContactsBlock, ExternalNavLink } from './styled';
import { getYear } from 'utils/date';
import { Flex } from '@igooods/ui-kit';

export const FOOTER_LINKS_TEST_ID = 'FOOTER_LINKS_TEST_ID';
export const LANDING_FOOTER_TEST_ID = 'LANDING_FOOTER_TEST_ID';
const isKomus = true;

const navMap = [
  {
    text: 'Как это работает',
    url: URL.how_it_works,
  },
  {
    text: 'Выбор товара и оплата',
    url: URL.payment,
  },
  {
    text: 'Доставка',
    url: URL.delivery,
  },
  {
    text: 'Реквизиты франчайзи',
    url: URL.fr_details,
  },
];

const FooterWithSeoLinks: FC = () => {
  const { mainRoute } = useRootUrlWithSubdomain();
  const currentYear = getYear();

  return (
    <Container data-testid={LANDING_FOOTER_TEST_ID}>
      <Company column>
        <LogoLink />
        {!isKomus && <Rights color={colors.pencilGrey}>© igooods 2014—{currentYear}</Rights>}
      </Company>
      <Flex ml={0} grow hideBefore="md" data-testid={FOOTER_LINKS_TEST_ID}>
        <Nav>
          {navMap.map(({ text, url }) => (
            <ExternalNavLink key={text} href={`${mainRoute}${url}`}>
              {text}
            </ExternalNavLink>
          ))}
          <ExternalNavLink href={URL.franchisee}>Франшиза</ExternalNavLink>
        </Nav>
      </Flex>
      <ContactsWrapper>
        <ContactsBlock />
      </ContactsWrapper>
    </Container>
  );
};

export default FooterWithSeoLinks;
