import { IApplicationState } from 'reduxStore/types';
import { getCheckoutPayment } from '../Checkout/selectors';
import { PaymentTypes } from '../Checkout/types';

export const getEntity = (state: IApplicationState) => state.entity;

export const getEntityBalance = (state: IApplicationState) => state.entity.balance;

export const getEntityShipmentOnCredit = (state: IApplicationState) => state.entity?.shipment_on_credit;

export const isEntityCanCheckoutWithShipmentOnCredit = (state: IApplicationState) => {
  const paymentType = getCheckoutPayment(state).type;
  const isShipmentOnCredit = getEntityShipmentOnCredit(state);
  if (paymentType === PaymentTypes.shipment_on_credit && !isShipmentOnCredit) return false;
  return true;
};
