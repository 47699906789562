import styled, { css } from 'styled-components';
import { Flex } from '@igooods/ui-kit';
import { Logo } from 'Components/landings/Header/LogoWithSlogan';
import Text from 'Components/Typography/Text';
import { colors } from 'constants/colors';
import { mediaSmDown, mediaMdDown } from 'theme/helpers/css';
import LinkWrapper from 'Components/Link/LinkWrapper';
import Contacts from 'Components/landings/Contacts';
import { getTypographyFontStyle } from 'theme/selectors';
import { FontSize } from 'theme';
import { hideBeforeMd } from 'theme/helpers';
import ExternalLink from 'Components/ExternalLink';

export const Container = styled(Flex).attrs({
  pt: { xs: 6, sm: 11 },
  pb: { xs: 8, sm: 24 },
})`
  ${mediaMdDown`
    padding-bottom: 64px;
    flex-direction: column-reverse;
  `}
`;

export const Company = styled(Flex)`
  flex: 0.2 0 170px;

  ${mediaMdDown`
    flex-basis: auto;
  `}
`;

export const LogoLink = styled(Logo)`
  margin-bottom: 24px;
  max-width: 150px;

  ${hideBeforeMd}
`;

export const Rights = styled(Text)`
  margin-top: auto;
`;

export const Nav = styled.div`
  flex-grow: 1;
`;

export const NavLink = css`
  display: block;
  color: ${colors.dark300};
  margin-bottom: 16px;
  ${getTypographyFontStyle(FontSize.s)}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InternalNavLink = styled(LinkWrapper)`
  ${NavLink};
`;

export const ExternalNavLink = styled(ExternalLink)`
  ${NavLink};
`;

export const Shops = styled.div`
  margin-left: 40px;
  flex-grow: 1;
`;

export const ContactsWrapper = styled(Flex).attrs({
  column: true,
  ml: 10,
})`
  flex-basis: 262px;

  ${mediaMdDown`
    flex-direction: row;
    flex-basis: auto;
    margin-left: 0;
  `}

  ${mediaSmDown`
    flex-direction: column;
  `}
`;

export const ContactsBlock = styled(Contacts)`
  margin-bottom: 32px;

  ${mediaMdDown`
    margin-right: 24px;
  `}

  ${mediaSmDown`
    margin-right: 0;
    margin-bottom: 20px;
  `}
`;

export const Stores = styled(Flex).attrs({
  nowrap: true,
  mt: { sm: 0, md: true },
  mb: { xs: 20, sm: 0 },
  ml: { xs: 0, sm: true, md: 0 },
})``;

export const StoreLink = styled(ExternalLink)`
  & + & {
    margin-left: 10px;
  }
`;

export const StoreImg = styled.img`
  width: 100px;
`;
