import React from 'react';
import Icon, { IIcon } from './../../../Components/Icon/Icon';

const ErrorNotificationIcon: React.FC<IIcon> = props => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      fill="#F95721"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71967 7.71967C8.01256 7.42678 8.48744 7.42678 8.78033 7.71967L12 10.9393L15.2197 7.71967C15.5126 7.42678 15.9874 7.42678 16.2803 7.71967C16.5732 8.01256 16.5732 8.48744 16.2803 8.78033L13.0607 12L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L12 13.0607L8.78033 16.2803C8.48744 16.5732 8.01256 16.5732 7.71967 16.2803C7.42678 15.9874 7.42678 15.5126 7.71967 15.2197L10.9393 12L7.71967 8.78033C7.42678 8.48744 7.42678 8.01256 7.71967 7.71967Z"
      fill="#F95721"
    />
  </Icon>
);

export default ErrorNotificationIcon;
