import { EnumSearchTypes, SearchActionsType } from './actions';
import { ISearchResultsState } from './types';

export const initialstate: ISearchResultsState = {
  isLoading: false,
  total: 0,
  list: [],
  categoriesResult: [],
};

function searchResults(state = initialstate, action: SearchActionsType) {
  switch (action.type) {
    case EnumSearchTypes.START_FETCH_SEARCH_RESULTS:
      return { ...state, isLoading: true };
    case EnumSearchTypes.FINISH_FETCH_SEARCH_RESULTS:
      return { ...state, ...action.payload, isLoading: false };
    case EnumSearchTypes.UPDATE_FETCH_SEARCH_RESULTS:
      return { ...state, list: [...state.list, ...action.payload.list] };
    case EnumSearchTypes.UPDATE_CATEGORIES_RESULTS:
      return { ...state, categoriesResult: [...action.payload] };
    default:
      return state;
  }
}

export default searchResults;
