import { EnumGtagEvents } from 'constants/enums';
import analyticsManager from 'utils/tagManager/manager';
import { truncateObjValues } from 'utils/helpers';

// Просмотр баннера (подборки)
export default function overviewBanner(promotionId: string, promotionName = '', pos = 1): void {
  analyticsManager.gtag(
    EnumGtagEvents.VIEW_BANNER,
    truncateObjValues({ promotion_id: String(promotionId), promotion_name: promotionName, index: pos })
  );
}
