import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../Modules/AAA/selectors';
import { getRoutesList, checkForAuthedRoutes, filterRoutesByInnerProps } from './util';
import { getEntity } from '../../Modules/Entity/selectors';
import { EnumEntityStatus } from '../../constants/enums';
import { IRoute, IAuthedRoute } from './routes';
import { getCurrentPlace } from 'Modules/Places/selectors';

export const useProfileRoutesList = (): { filteredRoutes: IRoute[]; routesWithAuthed: IAuthedRoute[] } => {
  const { as_entity } = useSelector(getCurrentUser);
  const entity = useSelector(getEntity);
  const isRoutesBlockedForEntity = as_entity && entity?.state !== EnumEntityStatus.confirmed;
  const routes = getRoutesList(isRoutesBlockedForEntity);

  return {
    filteredRoutes: routes.filter(filterRoutesByInnerProps(as_entity)),
    routesWithAuthed: routes.map(checkForAuthedRoutes(as_entity)),
  };
};

export const useIsSelectedPlace = (main: boolean | '' | null, placeId: string | number) => {
  const { id } = useSelector(getCurrentPlace);

  return Boolean(main) && `${placeId}` === `${id}`;
};
