import { useState, useRef, RefObject } from 'react';
import { isNil } from 'ramda';
import { useIsomorphicLayoutEffect } from 'utils/hooks';

export enum EnumPositionDirections {
  right = 'right',
  left = 'left',
  center = 'center',
  top = 'top',
  bottom = 'bottom',
}

const calcContentPosition = (
  defaultPosition: EnumPositionDirections,
  avaliablePositions: { left?: boolean; right?: boolean; top?: boolean; bottom?: boolean }
) => {
  switch (defaultPosition) {
    case EnumPositionDirections.left: {
      if (avaliablePositions.left) {
        return defaultPosition;
      } else if (avaliablePositions.right) {
        return EnumPositionDirections.right;
      }
    }
    case EnumPositionDirections.right: {
      if (avaliablePositions.right) {
        return defaultPosition;
      } else if (avaliablePositions.left) {
        return EnumPositionDirections.left;
      }
    }
    case EnumPositionDirections.top: {
      if (avaliablePositions.top) {
        return defaultPosition;
      } else if (avaliablePositions.bottom) {
        return EnumPositionDirections.bottom;
      }
    }
    case EnumPositionDirections.bottom: {
      if (avaliablePositions.bottom) {
        return defaultPosition;
      } else if (avaliablePositions.top) {
        return EnumPositionDirections.top;
      }
    }
  }
  return EnumPositionDirections.center;
};

interface IUsePositionDetection {
  eventListener: string;
  xPadding?: number;
  yPadding?: number;
  defaultPositionX?: EnumPositionDirections;
  defaultPositionY?: EnumPositionDirections;
}

const usePositionDetection = ({
  eventListener,
  xPadding = 0,
  yPadding = 0,
  defaultPositionX = EnumPositionDirections.left,
  defaultPositionY = EnumPositionDirections.bottom,
}: IUsePositionDetection): {
  positionX: EnumPositionDirections;
  positionY: EnumPositionDirections;
  wrapperRef: RefObject<HTMLDivElement>;
  elementRef: RefObject<HTMLDivElement>;
} => {
  const [positionX, setPositionX] = useState(defaultPositionX);
  const [positionY, setPositionY] = useState(defaultPositionY);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    const listener = () => {
      if (!isNil(elementRef.current) && !isNil(wrapperRef.current)) {
        const wrapper = wrapperRef.current;
        const content = elementRef.current;
        const contentWidth = content.getBoundingClientRect().width;
        const documentWidth = document.documentElement.clientWidth;

        const contentHeight = content.getBoundingClientRect().height;
        const documentHeight = document.documentElement.clientHeight;

        const wrapperLeftBorderPosition = wrapper.getBoundingClientRect().left;
        const wrapperRightBorderPosition = wrapper.getBoundingClientRect().right;
        const wrapperTopBorderPosition = wrapper.getBoundingClientRect().top;
        const wrapperBottomBorderPosition = wrapper.getBoundingClientRect().bottom;

        const leftAvaliableWidth = documentWidth - wrapperLeftBorderPosition - xPadding;
        const rightAvaliableWidth = wrapperRightBorderPosition - xPadding;
        const topAvaliableWidth = documentHeight - wrapperBottomBorderPosition - yPadding;
        const bottomAvaliableWidth = wrapperTopBorderPosition - yPadding;

        const avaliableXPositions = {
          left: leftAvaliableWidth >= contentWidth,
          right: rightAvaliableWidth >= contentWidth,
        };

        const avaliableYPositions = {
          top: topAvaliableWidth >= contentHeight,
          bottom: bottomAvaliableWidth >= contentHeight,
        };

        const positionX = calcContentPosition(defaultPositionX, avaliableXPositions);
        const positionY = calcContentPosition(defaultPositionY, avaliableYPositions);
        setPositionX(positionX);
        setPositionY(positionY);
      }
    };
    wrapperRef.current?.addEventListener(eventListener, listener);
    return () => {
      wrapperRef.current?.removeEventListener(eventListener, listener);
    };
  }, [defaultPositionX, eventListener, xPadding]);

  return { positionX, positionY, wrapperRef, elementRef };
};

export default usePositionDetection;
