import React, { FC, useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { RedirectWithStatus } from 'html/Routes';
import FullPageLoading from 'Containers/Loading/FullPageLoading';

interface IPrivateRouteProps extends RouteProps {
  component?: any;
  isAuthed: boolean;
  redirect_path: string;
}

const PrivateRouteCheck: FC<{ redirect_path: string }> = ({ redirect_path }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem('igooods:authToken')) {
      setRedirect(true);
    }
  }, []);

  return redirect ? <RedirectWithStatus status={303} to={redirect_path} /> : <FullPageLoading />;
};

const PrivateRoute: FC<IPrivateRouteProps> = ({ component: Component, isAuthed, redirect_path, ...rest }) => {
  if (!isAuthed) {
    return <PrivateRouteCheck redirect_path={redirect_path} />;
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthed ? <Component {...routeProps} /> : <RedirectWithStatus status={303} to={redirect_path} />
      }
    />
  );
};

export const PrivateRouteWithChildren: FC<IPrivateRouteProps> = ({ children, isAuthed, redirect_path, ...rest }) => {
  return <Route {...rest}>{isAuthed ? children : <RedirectWithStatus status={303} to={redirect_path} />}</Route>;
};

export default PrivateRoute;
