import { AnyAction } from 'redux';
import { EnumAAAActionTypes } from './actions';
import { IUser } from './types';
import { getRandomString } from '../../utils/helpers';
import { ITest } from 'Modules/AAA/types';

export interface IAuthState {
  isLoaded: boolean;
  isLoggedIn: boolean;
  user: Partial<IUser>;
  tests: ITest[];
}

const initialState: IAuthState = {
  isLoaded: false,
  isLoggedIn: false,
  user: {},
  tests: [],
};

export default function authReducer(state: IAuthState = initialState, action: AnyAction): IAuthState {
  switch (action.type) {
    case EnumAAAActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isLoaded: true,
        isLoggedIn: true,
        user: {
          ...action.user,
          avatar_url: action.user.avatar_url
            ? `${action.user.avatar_url}?${getRandomString()}`
            : action.user.avatar_url,
        },
      };
    case EnumAAAActionTypes.CLEAR_CURRENT_USER:
      return { ...initialState };

    case EnumAAAActionTypes.DROP_USERS_ACTIVE_COUNT_ORDERS:
      return {
        ...state,
        user: { ...state.user, active_orders_count: 0 },
      };
    case EnumAAAActionTypes.SET_USERS_ACTIVE_COUNT_ORDERS:
      return {
        ...state,
        user: { ...state.user, active_orders_count: action.count },
      };
    case EnumAAAActionTypes.SET_USER_TESTS:
      return {
        ...state,
        tests: action.tests,
      };
    case EnumAAAActionTypes.SET_USER_TEST:
      const updatedTest = state.tests.findIndex(({ name }) => name === action.testName);
      const updatedList = [...state.tests];
      if (updatedTest >= 0) {
        updatedList[updatedTest] = { ...action.test, name: action.testName };
      } else {
        updatedList.push({ ...action.test, name: action.testName });
      }
      return {
        ...state,
        tests: updatedList,
      };
    case EnumAAAActionTypes.REMOVE_USER_TEST:
      return {
        ...state,
        tests: state.tests.filter(({ name }) => name !== action.testName),
      };
    default:
      return state;
  }
}
