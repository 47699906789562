import GeoGrinder from 'lib/geo-grinder';
import { IGeoGrinderPlace } from './types';

interface IGeoGrinderHttp {
  fetchPlaceByPoint(longitude: number, latitude: number): Promise<IGeoGrinderPlace>;
  fetchPlacesById(id: number): Promise<any>;
  fetchSuggestions(cityId: number, query: string): Promise<IGeoGrinderPlace[]>;
  fetchPlacesSuggestions(query: string): Promise<any>;
  fetchPlaceBySuggestion(fullAddress: string): Promise<IGeoGrinderPlace>;
}

class GeoGrinderHttp implements IGeoGrinderHttp {
  public fetchPlaceByPoint(longitude: number, latitude: number): Promise<IGeoGrinderPlace> {
    return GeoGrinder.get(`/places/by_point?longitude=${longitude}&latitude=${latitude}`);
  }
  public fetchPlacesById(id: number): Promise<any> {
    return GeoGrinder.get(`/places/${id}`);
  }
  public fetchSuggestions(cityId: number, query: string): Promise<IGeoGrinderPlace[]> {
    return GeoGrinder.get(`/suggestions?city_id=${cityId}&query=${query}`);
  }
  public fetchPlacesSuggestions(query: string): Promise<any> {
    return GeoGrinder.get(`places/suggestions?query=${query}`);
  }
  public fetchPlaceBySuggestion(fullAddress: string): Promise<IGeoGrinderPlace> {
    return GeoGrinder.get(`places/by_suggestion?full_address=${fullAddress}`);
  }
}

export default new GeoGrinderHttp();
