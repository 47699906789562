import { isNil } from 'ramda';
import { pipe } from 'ramda';
import { setNotificationAction } from 'Modules/Notifications/actions';
import { NODE_ENV_IS_TEST } from '../../config/config';
import { ERROR_CODE } from '../Containers/Checkout/constants';

const getErrorFromResponse = (res: any): string | null => (res.data.error ? res.data.error : null);

const getErrorMessage = (e: any): string | null => {
  return e.response ? getErrorFromResponse(e.response) : null;
};

export const sendBugsnagReport = (e: any) => {
  const orderFillNormalCodes = new Set([...Object.values(ERROR_CODE)]);
  const errorResponseCode = e.response?.data.code;
  const config = e.config;
  const isOrderFillNormalErrorEvent = orderFillNormalCodes.has(errorResponseCode) && config?.url.includes('order/fill');
  const isProductCardGetEndpoint = config?.url?.includes('product');
  const isPromocodeEndpoint = config?.url?.includes('order/promocode');
  if (!NODE_ENV_IS_TEST && (!isOrderFillNormalErrorEvent || !isProductCardGetEndpoint || !isPromocodeEndpoint)) {
    const bugsnagError = config?.url ?? e;
    const bugsnagOptions = {
      metaData: {
        statusText: e.response?.statusText,
        status: e.response?.status,
        responseData: e.response?.data,
        errorString: JSON.stringify(e),
      },
      groupingHash: bugsnagError,
      data: config?.data,
      headers: config?.headers,
      request: {
        url: `${config?.baseURL}/${config?.url}`,
        method: config?.method,
        data: config?.method === 'get' ? config?.params : config?.data,
      },
    };

    if (typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { serverBugsnag } = require('lib/bugsnag');

      serverBugsnag.notify(bugsnagError, {
        ...bugsnagOptions,
        user: { id: config?.headers?.['X-User-Id'], token: config?.headers?.['X-User-Token'] },
      });
    } else {
      const bugsnagClient = require('lib/bugsnag').default; // именно такой импорт, потому что иначе падают тесты
      bugsnagClient.notify(bugsnagError, bugsnagOptions);
    }
  }
  return e;
};

export const sendCustomBugsnagReport = (e: any, payload?: any) => {
  if (!NODE_ENV_IS_TEST) {
    const bugsnagClient = require('lib/bugsnag').default; // именно такой импорт, потому что иначе падают тесты
    bugsnagClient.notify(e, {
      metaData: {
        ...(payload && payload),
      },
    });
  }
  return e;
};

const setNotificationIfNotEmpty = (dispatch: (action: any) => void) => (message: string | null) => {
  if (!isNil(message)) {
    dispatch(setNotificationAction(message));
  }
};

export const sendErrorNotification = (e: any, dispatch: (action: any) => void) =>
  pipe(getErrorMessage, setNotificationIfNotEmpty(dispatch))(e);
