import styled, { keyframes } from 'styled-components';
import { TransitionGroup } from 'react-transition-group';

export enum EnumTransitionNames {
  FADE = 'fade',
  FADE_RIGHT = 'fade_right',
  FADE_BOTTOM = 'fade_bottom',
}

export const fadeOutOpacity = keyframes`
  from { opacity: 1; } to { opacity: 0; }
`;

export const fadeInOpacity = keyframes`
  from { opacity: 0; } to { opacity: 1; }
`;

const animations = {
  [EnumTransitionNames.FADE]: {
    in: fadeInOpacity,
    out: fadeOutOpacity,
  },
  [EnumTransitionNames.FADE_RIGHT]: {
    in: keyframes`
      from { transform: translateX(1rem); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    `,
    out: keyframes`
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(1rem); opacity: 0; }
    `,
  },
  [EnumTransitionNames.FADE_BOTTOM]: {
    in: keyframes`
      from { transform: translateY(1rem); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    `,
    out: keyframes`
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(1rem); opacity: 0; }
    `,
  },
};

export const TransformTransitionGroup = styled(TransitionGroup)<{ $transitionName: EnumTransitionNames }>`
  ${({ $transitionName }) => `.${$transitionName}-enter`} {
    opacity: 0;
  }

  ${({ $transitionName }) => `.${$transitionName}-enter-done`} {
    animation: ${({ $transitionName }) => animations[$transitionName].in} 0.3s cubic-bezier(0.4, 0.1, 0.1, 0.9) forwards;
    will-change: transform;
  }

  ${({ $transitionName }) => `.${$transitionName}-exit`} {
    opacity: 1;
    ${({ $transitionName }) =>
      $transitionName === EnumTransitionNames.FADE_RIGHT &&
      `
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    `}
  }

  ${({ $transitionName }) => `.${$transitionName}-exit-active`} {
    animation: ${({ $transitionName }) => animations[$transitionName].out} 0.3s cubic-bezier(0.4, 0.1, 0.1, 0.9)
      forwards;
    will-change: transform;
  }
`;
