import { Dispatch, ThunkAction } from '../../reduxStore/types';
import { IListProductsResponse, IProduct } from 'Modules/Products/types';
import { fetchFavorites } from './http';

export enum EnumFavoritesType {
  START_GET_FAVORITES_PRODUCTS = 'START_GET_FAVORITES_PRODUCTS',
  FINISH_GET_FAVORITES_PRODUCTS = 'FINISH_GET_FAVORITES_PRODUCTS',
  ADD_FAVORITES_PRODUCTS = 'ADD_FAVORITES_PRODUCTS',
}

interface IStartGettingFavoriteProducts {
  type: EnumFavoritesType.START_GET_FAVORITES_PRODUCTS;
}

interface IFinishGettingFavoriteProducts {
  type: EnumFavoritesType.FINISH_GET_FAVORITES_PRODUCTS;
  payload: { list: IProduct[]; total: number };
}

interface IAddFavoriteProducts {
  type: EnumFavoritesType.ADD_FAVORITES_PRODUCTS;
  payload: IProduct[];
}

export type FavoritesActionsType =
  | IAddFavoriteProducts
  | IFinishGettingFavoriteProducts
  | IStartGettingFavoriteProducts;

export const startGettingFavoriteProducts = () => ({
  type: EnumFavoritesType.START_GET_FAVORITES_PRODUCTS,
});

const finishGettingFavoriteProducts = (favoritesProducts: IListProductsResponse): IFinishGettingFavoriteProducts => ({
  type: EnumFavoritesType.FINISH_GET_FAVORITES_PRODUCTS,
  payload: favoritesProducts,
});

export const getCurrentFavoriteProductsAction = (shopId: number, params?: object): ThunkAction => async (
  dispatch,
  _,
  fetcher
) => {
  const products = await fetchFavorites(shopId, params, fetcher);

  dispatch(finishGettingFavoriteProducts(products));

  return products;
};

const addFavoriteProducts = (payload: IProduct[]) => ({
  type: EnumFavoritesType.ADD_FAVORITES_PRODUCTS,
  payload,
});

export const updateCurrentFavoriteProducts = (shopId: number, params?: object) => async (dispatch: Dispatch) => {
  const favorites = await fetchFavorites(shopId, params);

  dispatch(addFavoriteProducts(favorites.list));
  return favorites;
};
