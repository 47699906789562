import styled, { css } from 'styled-components';
import { mediaSmUp } from '../../theme/helpers/css';
import CloseIcon from 'Components/Icon/CloseIcon';
import { hideAfter } from '../../theme/helpers';
import { Breakpoints } from '../../theme';
import Flex from 'Components/Grid/Flex';

interface IModal {
  isBackdropOpened: boolean;
}

export const Modal = styled.div<IModal>`
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  z-index: 999;
  overflow-x: hidden;

  ${mediaSmUp`
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    overflow-y: auto;
  `}

  ${({ isBackdropOpened }) =>
    isBackdropOpened &&
    css`
      opacity: 1;
      visibility: visible;

      ${mediaSmUp`
        background-color: rgba(0,0,0, 0.6);
      `}
    `}
`;

interface IModalContainer {
  isWindowOpened: boolean;
}

export const ModalContainer = styled(Flex).attrs({
  middle: true,
})<IModalContainer>`
  z-index: 9;
  position: relative;
  opacity: 0;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  height: 100%;

  ${({ isWindowOpened }) =>
    isWindowOpened &&
    `
      opacity: 1;
      visibility: visible;
    `}
`;

const ModalClose = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
`;

export const ModalCloseMobile = hideAfter(Breakpoints.sm)(ModalClose);
