import { IDeliveryDate, IShopServiceInfo, IShopWithLogo, IShopWithLogoExtended } from 'Modules/Shops/types';
import { IPlace } from 'Modules/Places/types';
import { IItems, IProduct } from 'Modules/Products/types';
import { ICategory } from 'Modules/Categories/types';
import { ISuccessLastOrder } from 'Modules/Cart/types';
import { PaymentTypes } from 'Modules/Checkout/types';
import { IPaymentCard } from 'Modules/PaymentCards/types';

export enum OrderStatus {
  CREATED = 'created',
  FILLED = 'filled',
  PACKING = 'packing',
  PACKED = 'packed',
  SHIPPED = 'shipped',
  DELIVERING = 'delivering',
  DECLINED = 'declined',
  DECLINED_BY_USER = 'declined_by_user',
  RETURNED = 'returned',
  PAID = 'paid',
}

export enum PaymentStatus {
  WAITING = 'waiting',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export enum OrderEventType {
  DELETED = 'deleted',
  CHANGED = 'changed',
  ACCEPTED = 'accepted',
}

export interface IEvent {
  title: string;
  name: OrderStatus;
  description: string;
  date: string;
  type: OrderEventType;
  product?: IProduct;
  similar_product?: IProduct;
}

export interface IEmployee {
  id: number;
  name: string;
  phone: string;
  photo_url: string;
  cloud_tips_url: string | null;
  gender: string;
  rating: number;
  status?: string;
  evaluation?: IEmployeeRate;
}
export interface IHistoryOrder {
  id: number;
  delivery_point: null;
  status: OrderStatus;
  delivery_date: IDeliveryDate;
  status_date: string;
  total_price: number;
  shop: IShopWithLogo;
  place: IPlace;
  short_address: string;
  weight: number;
  comment: string;
  payment_type: PaymentTypes;
  payment_card?: IPaymentCard;
  picker: IEmployee;
  courier: IEmployee;
  need_evaluation: boolean;
  evaluated: boolean;
  shop_id: number;
  zone_id: number;
  count_products: number;
  count_items: number;
  miss_products?: IProduct;
  invoice?: string;
  receipt?: string;
  change_from?: number;
}

export interface IOrdersListResponse {
  list: IHistoryOrder[];
  total: number;
}

export interface ICurrentOrder extends Omit<IHistoryOrder, 'shop'> {
  count_products: any;
  shop: IShopWithLogoExtended;
  products?: IProduct[];
  service_info: IShopServiceInfo[];
  recipient_phone: string;
}

export interface ICurrentOrderResponse {
  order: ICurrentOrder;
}
export interface ICurrentOrderPaymentStatusResponse {
  message: string;
  status: PaymentStatus;
}
export interface ICurrentOrderProductsResponse {
  list: IProduct[];
}

export interface ICurrentOrderItemsResponse {
  list: IProduct[];
  missing: IProduct[];
  replaced: IProduct[];
  returned: IProduct[];
}

export interface ICurrentOrderChangeResponse {
  products: IProduct[];
  order: ICurrentOrder;
}

export interface ICurrentOrderEventsResponse {
  categories: ICategory[];
  events: IEvent[];
}

export interface IOrdersHistoryState {
  currentOrder: ICurrentOrder | null;
  isFetchingOrder: boolean;
  products: IProduct[];
  items: IItems | null;
  lastSuccessOrder?: ISuccessLastOrder;
}

export enum OperationTypes {
  INC = 'inc',
  DEC = 'dec',
  SET = 'set',
}

export interface IActiveOrdersIdsResponse {
  list: number[];
}

export interface IEmployeeRate {
  employee_id: number;
  points: number;
  position: string;
  comment?: string;
}

export enum OrderHistoryTypes {
  START_GETTING_ORDER_INFO = 'START_GETTING_ORDER_INFO',
  FINISH_GETTING_ORDER_INFO = 'FINISH_GETTING_ORDER_INFO',
  FINISH_GETTING_ORDER_PRODUCTS = 'FINISH_GETTING_ORDER_PRODUCTS',
  FINISH_GETTING_ORDER_ITEMS = 'FINISH_GETTING_ORDER_ITEMS',
  SET_LAST_SUCCESS_ORDER = 'SET_LAST_SUCCESS_ORDER',
  SET_ORDER_HISTORY_PAYMENT_TYPE = 'SET_ORDER_HISTORY_PAYMENT_TYPE',
  SET_ORDER_HISTORY_PAYMENT_CARD = 'SET_ORDER_HISTORY_PAYMENT_CARD',
  SET_COMMENT_TO_PRODUCT = 'SET_COMMENT_TO_PRODUCT',
  CLEAR_ORDER_INFO = 'CLEAR_ORDER_INFO',
}

interface IStartGettingOrderInfo {
  type: OrderHistoryTypes.START_GETTING_ORDER_INFO;
}

interface IClearOrderInfo {
  type: OrderHistoryTypes.CLEAR_ORDER_INFO;
}

interface IFinishGettingOrderInfo {
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_INFO;
  payload: ICurrentOrder;
}

interface ISetLastSuccessOrder {
  type: OrderHistoryTypes.SET_LAST_SUCCESS_ORDER;
  payload: ISuccessLastOrder;
}

interface IFinishGettingOrderProducts {
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_PRODUCTS;
  payload: IProduct[];
}

interface IFinishGettingOrderItems {
  type: OrderHistoryTypes.FINISH_GETTING_ORDER_ITEMS;
  payload: ICurrentOrderItemsResponse;
}

interface ISetOrderPaymentType {
  type: OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_TYPE;
  payload: PaymentTypes;
}

interface ISetOrderPaymentCard {
  type: OrderHistoryTypes.SET_ORDER_HISTORY_PAYMENT_CARD;
  payload: IPaymentCard;
}

interface ISetCommentToProduct {
  type: OrderHistoryTypes.SET_COMMENT_TO_PRODUCT;
  payload: {
    product_id: number;
    comment: string;
  };
}

export type IOrderHistoryAction =
  | IStartGettingOrderInfo
  | IFinishGettingOrderProducts
  | IFinishGettingOrderInfo
  | ISetLastSuccessOrder
  | IFinishGettingOrderItems
  | ISetOrderPaymentType
  | ISetOrderPaymentCard
  | ISetCommentToProduct
  | IClearOrderInfo;

export enum ActiveOrderStates {
  active = 'active', // [filled, packing, packed, delivering]
  processed = 'processed', // all statuses not including created
}

export interface IOrdersMainInfo {
  id: number;
  delivery_date: string;
  shop_logo: string;
  status: OrderStatus;
  branding?: string;
}

export interface IOrdersMainInfoResponse {
  list: IOrdersMainInfo[];
}
