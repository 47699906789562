import { isBrowser } from 'utils/helpers';

interface IUseDeskClient {
  init(phone: string, name: string): void;
}

const UseDeskClient: IUseDeskClient = {
  init(phone, name) {
    if (isBrowser) {
      window.usedeskMessenger?.userIdentify({ phone: `+7${phone}`, name });
    }
  },
};

export default UseDeskClient;
