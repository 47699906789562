import cartReducer, { activeOrdersReducer } from 'Modules/Cart/reducer';
import { categoriesReducer, favoritesCategoriesReducer } from 'Modules/Categories/reducer';
import currentAddressReducer, { citiesReducer, currentCityReducer } from 'Modules/SearchAddress/reducer';

import authReducer from 'Modules/AAA/reducer';
import checkoutReducer from 'Modules/Checkout/reducer';
import { combineReducers } from 'redux';
import currentCardReducer from 'Modules/PaymentCards/reducer';
import currentShopReducer from 'Modules/Shops/reducer';
import favorites from 'Modules/Favorites/reducer';
import modalReducer from 'Modules/Modals/reducer';
import notificationsReducer from 'Modules/Notifications/reducer';
import ordersHistory from 'Modules/OrdersHistory/reducer';
import { productsSetsReducer } from 'Modules/Products/reducer';
import rateLastOrder from 'Modules/RateLastOrder/reducer';
import searchResults from 'Modules/SearchResults/reducer';
import staticNotifications from 'Modules/StaticNotifications/reducer';
import entityReducer from 'Modules/Entity/reducer';
import userSetsReducer from 'Modules/UserSets/reducer';

export default combineReducers({
  auth: authReducer,
  currentAddress: currentAddressReducer,
  currentShop: currentShopReducer,
  currentCity: currentCityReducer,
  cities: citiesReducer,
  modals: modalReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  categories: categoriesReducer,
  favoritesCategories: favoritesCategoriesReducer,
  favorites,
  productsSets: productsSetsReducer,
  notifications: notificationsReducer,
  staticNotifications,
  cards: currentCardReducer,
  ordersHistory,
  searchResults,
  rateLastOrder,
  activeOrders: activeOrdersReducer,
  entity: entityReducer,
  userSets: userSetsReducer,
});
