import styled from 'styled-components';
import Box from '../../Grid/Box';
import { colors } from 'constants/colors';
import { Transitions } from 'theme';
import Text from '../../Typography/Text';

//UPCOMING_ORDERS
export const OrderWrapper = styled(Box).attrs({ px: 4, py: 4 })`
  background-color: ${colors.paleGreyThree};
  transition: ${Transitions.bg};
  border-bottom: 1px solid ${colors.white};

  &:hover {
    background-color: ${colors.paleGrey};
  }
`;

export const ShopImageWrapper = styled(Box)<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  width: 100%;
  height: 28px;
  margin-left: -5px;
`;

export const OrderDate = styled(Text)`
  line-height: 17px;
`;
