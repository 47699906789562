import Http from 'lib/http';
import {
  IHistoryOrder,
  IOrdersListResponse,
  ICurrentOrderResponse,
  ICurrentOrderProductsResponse,
  ICurrentOrderChangeResponse,
  IActiveOrdersIdsResponse,
  IEmployeeRate,
  ICurrentOrderItemsResponse,
  IOrdersMainInfoResponse,
  ActiveOrderStates,
  ICurrentOrderPaymentStatusResponse,
  ICurrentOrderEventsResponse,
} from './types';
import { directSortStates } from 'constants/enums';
import { PaymentTypes } from 'Modules/Checkout/types';

interface IAddParams {
  shop_group?: string;
}

const additionalParams: IAddParams = {};

export const getOrdersList = (
  offset: number,
  limit = 40,
  shop_id?: number,
  fetcher = Http
): Promise<IOrdersListResponse> => fetcher.get(`/orders`, { shop_id, limit, offset, ...additionalParams });

export const getOrderInfo = (orderId: number, fetcher = Http): Promise<ICurrentOrderResponse> =>
  fetcher.get(`/orders/${orderId}`);

export const getOrderPaymentStatus = (orderId: number): Promise<ICurrentOrderPaymentStatusResponse> =>
  Http.get(`/orders/${orderId}/payment/status`);

export const getOrderProducts = (orderId: number): Promise<ICurrentOrderProductsResponse> =>
  Http.get(`/orders/${orderId}/products`);

export const getOrderItems = (orderId: number): Promise<ICurrentOrderItemsResponse> =>
  Http.get(`/orders/${orderId}/items`);

export const getOrderEvents = (orderId: number): Promise<ICurrentOrderEventsResponse> =>
  Http.get(`/orders/${orderId}/events`);

export const cancelOrder = (orderId: number) => Http.post(`/orders/${orderId}/decline`);

export const repeatOrder = (orderId: number): Promise<{ order: IHistoryOrder }> =>
  Http.post(`/orders/${orderId}/repeat`);

export const addCommentToOrder = (orderId: number, comment: string): Promise<ICurrentOrderResponse> =>
  Http.patch(`/orders/${orderId}/comment`, { comment });

export const updatePaymentType = ({
  orderId,
  payment_type,
  bank_card_id,
  change_from,
}: {
  orderId: number;
  payment_type: PaymentTypes;
  bank_card_id?: number;
  change_from?: number;
}): Promise<ICurrentOrderResponse> =>
  Http.post(`/orders/${orderId}/update_payment_type`, { payment_type, bank_card_id, change_from });

export const changeProductsInOrder = (
  orderId: number,
  product_id: number,
  operation: string,
  amount?: number,
  weight?: number
): Promise<ICurrentOrderChangeResponse> =>
  Http.post(`/orders/${orderId}/update_items`, {
    products: [{ product_id, operation, amount, weight }],
  });

export const evaluateEmployees = (orderId: number, rates: IEmployeeRate[]) =>
  Http.post(`/orders/${orderId}/evaluation`, {
    evaluations: rates,
  });

export const changeDeliveryTime = (
  orderId: number,
  deliver_at: string,
  early: boolean
): Promise<ICurrentOrderResponse> => Http.patch(`/orders/${orderId}/update_delivery`, { deliver_at, early });

export const getActiveOrdersIds = (): Promise<IActiveOrdersIdsResponse> => Http.get('/orders/active_order_ids');

export const getOrdersMainInfo = (
  statuses: ActiveOrderStates,
  sort: directSortStates,
  limit = 40,
  offset = 0
): Promise<IOrdersMainInfoResponse> => Http.get(`/orders/user_orders`, { limit, offset, statuses, sort });
