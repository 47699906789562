import React, { useCallback, useRef, useState } from 'react';
import EntityRegImage from 'assets/images/auth/entityReg.png';
import { EnumButtonSize, EnumSize, EnumTheme, InputFormat } from 'constants/enums';
import Title from 'Components/Typography/Title';
import Flex from 'Components/Grid/Flex';
import styled from 'styled-components';
import { FontSize } from 'theme';
import { AuthModalBody, AuthModalFooter, AuthModalStyled } from './styled';
import { mediaSmDown } from 'theme/helpers/css';

import ThemeButton from 'Components/ThemedButton';
import Box from 'Components/Grid/Box';
import Text from 'Components/Typography/Text';
import { useDispatch } from 'react-redux';
import ExternalLink from 'Components/ExternalLink';
import { URL } from 'constants/urlMaps';
import ColoredLinkWrapper from 'Components/Link/ColoredLinkWrapper';

import Input from 'Components/Input';
import {
  ENTER_PHONE_TAB_INPUT_TEST_ID,
  ENTER_TAX_ID_TAB_INPUT_TEST_ID,
  ENTER_TAX_ID_TAB_RESET_BTN_TEST_ID,
  ENTER_TAX_ID_TAB_SUBMIT_BTN_TEST_ID,
  ENTER_TAX_ID_TAB_WRAPPER_TEST_ID,
} from './constants';
import { isValid } from 'Components/Input/utils';
import { useDebounce } from 'utils/hooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { EnumYaCounters, ymReachGoal } from 'lib/yandexMetrics';
import { EMPTY_STRING } from 'constants/constants';
import EntityActions from 'Modules/Entity/actions';
import { colors } from 'constants/colors';

const LinkContent = styled(ColoredLinkWrapper)`
  margin-left: 3px;
  color: ${colors.primaryAction};
`;

const AuthModalHeader = styled(Flex)`
  ${mediaSmDown`
    margin-bottom: 24px;
  `}
`;

const EntityRegImg = styled.img`
  height: 60px;
  margin-bottom: 24px;
`;

const ResetBtn = styled(ThemeButton)`
  border-radius: 12px;
`;

const EntityRegText = styled(Title).attrs({ level: '2' })`
  text-align: center;
`;

const FooterAgreementText = styled(Text)`
  letter-spacing: -0.3px;
`;

const AuthModalFooterStyled = styled(AuthModalFooter)`
  ${mediaSmDown`
    margin-top: 32px;
  `}
`;

const INITIAL_TAX_ID = '';
const INITIAL_PHONE = '';
const INITIAL_TAX_ID_ERROR_MSG = '';
const INITIAL_PHONE_ERROR_MSG = '';

interface IEnterTaxID {
  onSuccess: () => void;
}

const EntityAuthFirstStep: React.FC<IEnterTaxID> = ({ onSuccess }) => {
  const { search } = useLocation();
  const { shop_group } = qs.parse(search.slice(1));

  const dispatch = useDispatch();

  const [taxID, setTaxID] = useState(INITIAL_TAX_ID);
  const [errorMsgTaxID, setErrorMsgTaxID] = useState(INITIAL_TAX_ID_ERROR_MSG);
  const [errorMsgPhone, setErrorMsgPhone] = useState(INITIAL_PHONE_ERROR_MSG);
  const [newPhone, setPhone] = useState(EMPTY_STRING);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTaxIDInput = (taxID: string) => {
    setTaxID(taxID);
    setErrorMsgTaxID(INITIAL_TAX_ID_ERROR_MSG);
  };

  const handleChangePhone = (newPhone: string) => {
    setPhone(newPhone);
    setErrorMsgPhone(INITIAL_PHONE_ERROR_MSG);
  };

  const submit = async () => {
    try {
      await dispatch(EntityActions.createEntity({ inn: taxID, phone: newPhone }));
      onSuccess();
      ymReachGoal(shop_group === 'auchan' ? EnumYaCounters.business_form_auchan_inn : EnumYaCounters.business_form_inn);
    } catch (e) {
      if (e.response?.data?.errors?.inn) {
        setErrorMsgTaxID(e.response.data.errors.inn[0]);
      }
      if (e.response?.data?.errors?.phone) {
        setErrorMsgPhone(e.response.data.errors.phone[0]);
      }
    }
  };

  const handleReset = () => {
    setTaxID(INITIAL_TAX_ID);
    setPhone(INITIAL_PHONE);
    setErrorMsgTaxID(INITIAL_TAX_ID_ERROR_MSG);
    setErrorMsgPhone(INITIAL_PHONE_ERROR_MSG);
    inputRef?.current?.focus();
  };

  const handleValidPhone = useCallback(() => isValid(newPhone, InputFormat.tel), [newPhone]);
  const handleValidTaxID = useCallback(() => isValid(taxID, InputFormat.inn), [taxID]);
  const debouncedIsValidTaxID = useDebounce(handleValidTaxID().result, 300);

  const hasErrorMsg = errorMsgTaxID || errorMsgPhone;

  return (
    <AuthModalStyled data-testid={ENTER_TAX_ID_TAB_WRAPPER_TEST_ID}>
      <div>
        <AuthModalHeader center middle column mb={12}>
          <EntityRegImg src={EntityRegImage} alt="Регистрация" />
          <EntityRegText>Регистрация</EntityRegText>
        </AuthModalHeader>
        <AuthModalBody>
          <Box width={'100%'}>
            <Input
              data-testid={ENTER_TAX_ID_TAB_INPUT_TEST_ID}
              ref={inputRef}
              errorMsg={errorMsgTaxID}
              format={InputFormat.inn}
              defaultValue={taxID}
              checkIsValid={handleValidTaxID}
              sizeName={EnumSize.lg}
              placeholder="Введите ИНН"
              onChangeInput={handleTaxIDInput}
              testId={ENTER_TAX_ID_TAB_INPUT_TEST_ID}
              required
              width="100%"
              withClear={false}
            />
          </Box>

          <Box mt={10} width={'100%'}>
            <Input
              onChangeInput={handleChangePhone}
              placeholder="Моб. телефон для связи"
              sizeName={EnumSize.lg}
              errorMsg={errorMsgPhone}
              checkIsValid={handleValidPhone}
              defaultValue={newPhone}
              format={InputFormat.tel}
              type={InputFormat.tel}
              testId={ENTER_PHONE_TAB_INPUT_TEST_ID}
              required
              width="100%"
              withClear={false}
            />
          </Box>
        </AuthModalBody>
      </div>
      <AuthModalFooterStyled>
        {hasErrorMsg ? (
          <ResetBtn
            data-testid={ENTER_TAX_ID_TAB_RESET_BTN_TEST_ID}
            fluid
            use={EnumTheme.roundDarkGray}
            size={EnumButtonSize.large}
            onClick={handleReset}
          >
            Сбросить и ввести заново
          </ResetBtn>
        ) : (
          <ThemeButton
            data-testid={ENTER_TAX_ID_TAB_SUBMIT_BTN_TEST_ID}
            fluid
            size={EnumButtonSize.large}
            disabled={!debouncedIsValidTaxID || !handleValidPhone().result}
            onClick={submit}
            use={EnumTheme.roundRed}
          >
            Продолжить
          </ThemeButton>
        )}

        <Box mt={5} pxXs={12}>
          <FooterAgreementText size={FontSize.xs}>
            Регистрируясь, вы принимаете условия
            <LinkContent>
              <ExternalLink href={URL.entity_offer} newTab>
                Оферты
              </ExternalLink>
            </LinkContent>
          </FooterAgreementText>
        </Box>
      </AuthModalFooterStyled>
    </AuthModalStyled>
  );
};

export default EntityAuthFirstStep;
