import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface IRedirectWithStatus {
  from?: string;
  to: string;
  status: number;
  exact?: boolean;
}

export const RedirectWithStatus: React.FC<IRedirectWithStatus> = ({ from, to, status, exact }) => {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.statusCode = status;
        return <Redirect exact={exact} from={from} to={to} />;
      }}
    />
  );
};

interface IStatus {
  code: number;
  children: React.ReactChild;
}

export const Status: React.FC<IStatus> = ({ code, children }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext && !staticContext.statusCode) staticContext.statusCode = code;
        return children;
      }}
    />
  );
};

export const NotFoundExample = () => {
  return (
    <Status code={404}>
      <div>
        <h1>Sorry, can’t find that.</h1>
      </div>
    </Status>
  );
};
