import { Action, AnyAction } from 'redux';
import { Dispatch, ThunkAction } from 'reduxStore/types';
import AAAHttp from './service';
import { AuthTokenService } from '@igooods/axios-helpers';
import { getCookieDomain, LAST_SUCCESS_ORDER } from 'constants/cookie';
import {
  IAuthResponse,
  IFullProfileResponse,
  IProfileResponse,
  IUpdateProfileData,
  IUser,
  IUpdateProfileDataAvatar,
} from './types';
import { storeStorage } from '../../utils/handleStoreUpdate';
import { ENTITY_NEED_RESIGN } from 'Containers/Profile/EntityInfo/Form';
import Cookie from 'js-cookie';
import { USER_HAS_SHOP } from 'constants/cookie';
import { ITest } from 'Modules/AAA/types';

export enum EnumAAAActionTypes {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER',
  DROP_USERS_ACTIVE_COUNT_ORDERS = 'DROP_USERS_ACTIVE_COUNT_ORDERS',
  SET_USERS_ACTIVE_COUNT_ORDERS = 'SET_USERS_ACTIVE_COUNT_ORDERS',
  SET_USER_TESTS = 'SET_USER_TESTS',
  SET_USER_TEST = 'SET_USER_TEST',
  REMOVE_USER_TEST = 'REMOVE_USER_TEST',
}

export function setUserTests(tests: ITest[]): AnyAction {
  return {
    type: EnumAAAActionTypes.SET_USER_TESTS,
    tests,
  };
}

export function setUserTest(testName: string, test: ITest): AnyAction {
  return {
    type: EnumAAAActionTypes.SET_USER_TEST,
    test,
    testName,
  };
}

export function removeUserTest(testName: string): AnyAction {
  return {
    type: EnumAAAActionTypes.REMOVE_USER_TEST,
    testName,
  };
}

export function setCurrentUser(user: IUser): AnyAction {
  return {
    type: EnumAAAActionTypes.SET_CURRENT_USER,
    user,
  };
}

export function dropUserActiveCountOrder(): Action {
  return {
    type: EnumAAAActionTypes.DROP_USERS_ACTIVE_COUNT_ORDERS,
  };
}

export function setUserActiveCountOrder(count: number): AnyAction {
  return {
    type: EnumAAAActionTypes.SET_USERS_ACTIVE_COUNT_ORDERS,
    count,
  };
}

export default {
  logout: (device_uid?: string): ThunkAction => async (): Promise<{}> => {
    const data = await AAAHttp.logout(device_uid);
    window.localStorage.removeItem(LAST_SUCCESS_ORDER);
    if (window.localStorage.getItem(ENTITY_NEED_RESIGN)) {
      window.localStorage.removeItem(ENTITY_NEED_RESIGN);
    }
    storeStorage.remove();
    AuthTokenService.remove();
    Cookie.remove(USER_HAS_SHOP);
    return data;
  },

  validateCode: (code: string): ThunkAction => async (dispatch: Dispatch): Promise<IAuthResponse> => {
    const data = await AAAHttp.validateCode(code);
    await dispatch(setCurrentUser(data.user));
    AuthTokenService.set(
      {
        token: data.token,
        id: data.user.id,
      },
      getCookieDomain().domain
    );

    return data;
  },

  getFullProfile: (): ThunkAction => async (dispatch: Dispatch): Promise<IFullProfileResponse> => {
    const data = await AAAHttp.getFullProfile();
    dispatch(setCurrentUser(data.user));
    return data;
  },

  updateProfile: (postData: IUpdateProfileData | IUpdateProfileDataAvatar): ThunkAction => async (
    dispatch: Dispatch
  ): Promise<IProfileResponse> => {
    return AAAHttp.updateProfile(postData).then(data => {
      dispatch(setCurrentUser(data.user));
      return data;
    });
  },

  login: (postData: IUpdateProfileData | IUpdateProfileDataAvatar): ThunkAction => async (
    dispatch: Dispatch
  ): Promise<IProfileResponse> => {
    return AAAHttp.login(postData).then(data => {
      dispatch(setCurrentUser(data.user));
      return data;
    });
  },
};
