/* eslint-disable react/display-name */
import React, { ComponentType, ChangeEvent } from 'react';
import { maskPhoneToValue } from './utils';
import { useFormikContext } from 'formik';

export const withPhoneMask = (Component: ComponentType<any>) => ({ name, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const handleMaskedChange = (e: ChangeEvent<any>) => {
    setFieldValue(name, maskPhoneToValue(e.target.value));
  };

  return (
    <Component
      {...props}
      name={name}
      onChange={handleMaskedChange}
      value={props.value && `+7${maskPhoneToValue(props.value)}`}
    />
  );
};

export const withNumberValidation = (Component: ComponentType<any>) => ({ name, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const handleMaskedChange = (e: ChangeEvent<any>) => {
    setFieldValue(name, e.target.value.replace(/\D/g, ''));
  };
  return (
    <Component
      {...props}
      name={name}
      onChange={handleMaskedChange}
      value={props?.value?.toString().replace(/\D/g, '')}
    />
  );
};

export const withDateValidation = (Component: ComponentType<any>) => ({ name, ...props }: any) => {
  const { setFieldValue } = useFormikContext();

  const handleMaskedChange = (e: ChangeEvent<any>) => {
    setFieldValue(name, e.target.value.replace(/[^0-9.]/g, ''));
  };
  return (
    <Component
      {...props}
      name={name}
      onChange={handleMaskedChange}
      value={props?.value?.toString().replace(/[^0-9.]/g, '')}
    />
  );
};

export const withSpaceTrim = (Component: ComponentType<any>) => ({ name, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const handleChange = (e: ChangeEvent<any>) => {
    setFieldValue(name, e.target.value.replace(/\s/g, ''));
  };
  return (
    <Component {...props} name={name} onChange={handleChange} value={props?.value?.toString().replace(/\s/g, '')} />
  );
};

export const dateOfBirthWithDots = (date: string | null) => {
  if (date) {
    return date.split('-').reverse().join('.');
  }
};
