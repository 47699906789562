import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import Logout from 'Containers/Profile/logout';
import Nav, { InternalNavLink, AboutUsNavLink } from 'Containers/Profile/Sidebar/Nav';
import { useDataApi } from 'utils/hooks';
import { Transitions } from 'theme/index';
import { useHistory } from 'react-router-dom';
import { shadows } from 'constants/shadows';
import UpcomingOrders from './UpcomingOrders';
import { ActiveOrderStates, IOrdersMainInfoResponse } from 'Modules/OrdersHistory/types';
import { getOrdersMainInfo } from 'Modules/OrdersHistory/http';
import { directSortStates } from 'constants/enums';
import useEffectWithPolling from 'utils/hooks/useEffectWithPolling';
import { UPCOMING_ORDERS_FETCH_INTERVAL } from './UpcomingOrders';
import useModalState from 'utils/hooks/useModalState';
import { useProfileRoutesList } from 'Containers/Profile/hooks';
import Dropdown from 'Components/Dropdown';

export const PROFILE_DROPDOWN_CONTAINER_TEST_ID = 'PROFILE_DROPDOWN_CONTAINER_TEST_ID';
export const PROFILE_DROPDOWN_MENU_TEST_ID = 'PROFILE_DROPDOWN_MENU_TEST_ID';

const ContainerWrapper = styled.div`
  position: absolute;
  padding-top: 8px;
  right: 0;
  top: 100%;
`;

const DropdownContent = styled.div`
  border-radius: 4px;
  min-width: 250px;
  max-width: 260px;
  box-shadow: ${shadows.boxShadow};
  background-color: ${colors.white};
  overflow: hidden;
`;

const DropdownNav = styled(Nav)`
  align-items: stretch;
  padding: 0;

  &:first-child {
    margin-top: 12px;
  }

  ${InternalNavLink},
  ${AboutUsNavLink} {
    margin: 0;
    padding: 8px 20px;
    display: flex;
    transition: ${Transitions.bg};

    &:first-child {
      padding-top: 12px;
    }

    &:hover {
      background-color: ${colors.paleGrey};
    }
  }

  ${AboutUsNavLink} {
    padding-bottom: 12px;
  }
`;

const DropdownFooter = styled.div`
  border-top: 1px solid ${colors.silver};
  padding: 4px 20px 4px;
`;

const ProfileDropdown: React.FC = ({ children }) => {
  const { isVisible: isShowDropdown, showModal: showDropdown, hideModal: hideDropdown } = useModalState();
  const history = useHistory();
  const currentPathRef = useRef(history.location.pathname);
  const [{ data: upcomingOrders }, fetch] = useDataApi<IOrdersMainInfoResponse>({ list: [] });
  const { filteredRoutes: routes } = useProfileRoutesList();

  const handleDropdownClick = useCallback(() => {
    if (currentPathRef.current !== history.location.pathname) {
      currentPathRef.current = history.location.pathname;
      hideDropdown();
    }
  }, [hideDropdown, history.location.pathname]);

  const fetchUpcomingOrders = useCallback(() => {
    fetch(getOrdersMainInfo(ActiveOrderStates.active, directSortStates.asc, 2));
  }, [fetch]);

  useEffect(() => fetchUpcomingOrders(), [fetch, fetchUpcomingOrders]);

  useEffectWithPolling(fetchUpcomingOrders, [fetch, fetchUpcomingOrders], {
    interval: UPCOMING_ORDERS_FETCH_INTERVAL,
  });

  return (
    <>
      <Dropdown
        testId={PROFILE_DROPDOWN_CONTAINER_TEST_ID}
        testIdContent={PROFILE_DROPDOWN_MENU_TEST_ID}
        toggle={children}
        isOpen={isShowDropdown}
        onMouseOut={hideDropdown}
        onMouseOver={showDropdown}
      >
        <ContainerWrapper>
          <DropdownContent onClick={handleDropdownClick}>
            {upcomingOrders.list && <UpcomingOrders orders={upcomingOrders.list} />}
            <DropdownNav routes={routes} />
            <DropdownFooter>
              <Logout>Выход</Logout>
            </DropdownFooter>
          </DropdownContent>
        </ContainerWrapper>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
