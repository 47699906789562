import styled from 'styled-components';
import { colors } from 'constants/colors';
import { shadows } from 'constants/shadows';
import { inEDGE, mediaMobile, onSafari, mediaMdDown } from 'theme/helpers/css';

export const HEADER_HEIGHT = 82;
export const HEADER_HEIGHT_TABLET = 72;
export const HEADER_HEIGHT_MOBILE = 72;

const HeaderContainer = styled.header<{
  isItProfile?: boolean;
}>`
  display: flex;
  align-items: center;
  z-index: 201;
  top: 0;
  box-shadow: ${shadows.stickyHeader};
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: ${colors.white};
  height: ${HEADER_HEIGHT}px;
  ${({ isItProfile }) => (isItProfile && `position: fixed ; width: 100%`) || `position: sticky`};

  ${mediaMdDown`
    height: ${HEADER_HEIGHT_TABLET}px;
  `}

  ${mediaMobile`
    height: ${HEADER_HEIGHT_MOBILE}px;
    position: fixed;
    width: 100%;
  `};

  ${onSafari`
    left: 0;
    position: fixed;
    right: 0;
  `}

  ${inEDGE`
    position: fixed;
    width: calc(100% - 17px);
  `}
`;

export default HeaderContainer;

export const MainWithStickyHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${mediaMobile`
    margin-top: ${HEADER_HEIGHT_MOBILE}px;
  `};

  ${inEDGE`
    margin-top: ${HEADER_HEIGHT}px;
  `}
`;
