import React from 'react';
import Icon, { IIcon } from './Icon';

const SocialTGIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2558 15.1861C12.4704 15.3381 12.7471 15.3761 12.9938 15.2828C13.2404 15.1888 13.4218 14.9781 13.4764 14.7228C14.0558 12.0001 15.4611 5.10877 15.9884 2.6321C16.0284 2.44543 15.9618 2.25143 15.8151 2.12677C15.6684 2.0021 15.4651 1.9661 15.2838 2.03343C12.4884 3.0681 3.87978 6.2981 0.36111 7.6001C0.137777 7.68277 -0.00755638 7.89743 -0.000223045 8.13277C0.00777696 8.36877 0.166444 8.57343 0.39511 8.6421C1.97311 9.1141 4.04444 9.77077 4.04444 9.77077C4.04444 9.77077 5.01244 12.6941 5.51711 14.1808C5.58044 14.3674 5.72644 14.5141 5.91911 14.5648C6.11111 14.6148 6.31644 14.5621 6.45978 14.4268C7.27044 13.6614 8.52378 12.4781 8.52378 12.4781C8.52378 12.4781 10.9051 14.2241 12.2558 15.1861ZM4.91578 9.40143L6.03511 13.0934L6.28378 10.7554C6.28378 10.7554 10.6084 6.85477 13.0738 4.63143C13.1458 4.5661 13.1558 4.45677 13.0958 4.3801C13.0364 4.30343 12.9271 4.28543 12.8451 4.33743C9.98778 6.1621 4.91578 9.40143 4.91578 9.40143Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default SocialTGIcon;
