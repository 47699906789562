import { HEIGHT_ROW, MOSCOW_ID, SPB_ID } from './constants';
import { ICity } from 'Modules/SearchAddress/types';
// import { getHostNameParts } from 'Containers/LandingPages/utils';
import { NODE_ENV_IS_DEVELOPMENT } from '../../../../config/config';

export const getHeightForListCities = (col: number) => ({ length }: { length: number }) => {
  return Math.ceil(length / col) * HEIGHT_ROW;
};

export const isMainCity = (city: ICity) => {
  return city.id === MOSCOW_ID || city.id === SPB_ID;
};

// 'Санкт-Петербург и Ленинградская область' to 'Санкт-Петербург'
export const removeProvince = (name: string) => name.replace(/ и.*/, '');

export const updateProvince = (name: string, id?: number) => {
  const removedProvince = name.replace(/ и.*/, '');

  switch (id) {
    case MOSCOW_ID:
      return `${removedProvince} и МО`;
    case SPB_ID:
      return `${removedProvince} и ЛО`;
    default:
      return removedProvince;
  }
};

export const getSortedCities = (cities: ICity[]): ICity[] => {
  const otherCities: ICity[] = [];
  const mainCities: ICity[] = [];
  const sortCompareFn = (a: ICity, b: ICity) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

  cities.forEach(city => {
    if (city) {
      const { name, id } = city;
      const newCity = { ...city, name: updateProvince(name, id) };
      if (isMainCity(city)) {
        mainCities.push(newCity);
      } else {
        otherCities.push(newCity);
      }
    }
  });

  return [...mainCities.sort(sortCompareFn), ...otherCities.sort(sortCompareFn)];
};

export const updCitySubdomain = (currentSubdomain?: string, newSubdomain?: string) => {
  // const hostnameParts = getHostNameParts(window?.location.href);
  // const path = hostnameParts?.groups?.path;
  // const params = hostnameParts?.groups?.params;
  // const pathParams = `${path ? `/${path}` : ''}${params ?? ''}`;
  // const subdomain = `${newSubdomain ? `${newSubdomain}.` : ''}`;

  if (currentSubdomain !== newSubdomain) {
    if (NODE_ENV_IS_DEVELOPMENT) {
      if (newSubdomain) {
        console.log(`new subdomain: ${newSubdomain}`);
      }
    } else {
      return;
      //window.location.href = `https://${subdomain}${hostnameParts?.groups?.hostname}${pathParams}`;
    }
  }
};
