import React from 'react';
import Icon, { IIcon } from './Icon';

const NewSocialINSTIcon: React.FC<IIcon> = props => (
  <Icon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.032.992C10.403.339 9.508 0 8.492 0H3.508C1.403 0 0 1.403 0 3.508v4.96c0 1.04.339 1.935 1.016 2.588.653.63 1.524.944 2.516.944h4.936c1.04 0 1.911-.339 2.54-.944.653-.629.992-1.524.992-2.564V3.508c0-1.016-.339-1.887-.968-2.516zM9.5 3.4a.9.9 0 100-1.8.9.9 0 000 1.8zM6 3C4.36 3 3 4.336 3 6s1.336 3 3 3 3-1.36 3-3-1.36-3-3-3zm0 4.922A1.93 1.93 0 014.078 6 1.93 1.93 0 016 4.078 1.93 1.93 0 017.922 6 1.93 1.93 0 016 7.922z"
    />
  </Icon>
);

export default NewSocialINSTIcon;
