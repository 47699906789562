import { createStore, applyMiddleware, compose, StoreEnhancer, AnyAction, Middleware, MiddlewareAPI } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { IApplicationState } from '../types';
import { isBrowser } from 'utils/helpers';
import Http from 'lib/http';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer;
    __IG_REDUX_LOGGER__?: Array<{ action: AnyAction; state: IApplicationState }>;
    cp: any; // cloudpayents client
    AF: any; // appsflyer client
  }
}

const initState: { [key: string]: any } = {
  // ...storeStorage.get(),
  modals: {
    isOpened: false,
    modalType: '',
  },
};

const igLogger: Middleware<any, {}> = (store: MiddlewareAPI) => (next: any) => (action: AnyAction) => {
  const result = next(action);
  if (isBrowser) {
    window.__IG_REDUX_LOGGER__ = window.__IG_REDUX_LOGGER__ || [];
    const loggedData = {
      action,
      state: store.getState(),
    };
    window.__IG_REDUX_LOGGER__?.unshift(loggedData);
    if (window.__IG_REDUX_LOGGER__?.length ?? 0 > 30) window.__IG_REDUX_LOGGER__?.slice(0, 10);
  }
  return result;
};

function getEnhancers(): StoreEnhancer[] {
  const enhancers = [];
  if (isBrowser && process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
  return enhancers;
}

const enhancers: (api: typeof Http) => StoreEnhancer<any, {}> = api =>
  compose(applyMiddleware(thunk.withExtraArgument(api), igLogger), ...getEnhancers());

type ConfigureStore = (options?: { initialState?: { [key: string]: any }; fetcher?: typeof Http }) => any;

export const configureStore: ConfigureStore = (options = { initialState: initState, fetcher: Http }) => {
  const { initialState, fetcher = Http } = options;
  const store = createStore(reducers, initialState, enhancers(fetcher));

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;

      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore();
