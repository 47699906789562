import React from 'react';
import Icon, { IIcon } from './Icon';

const CloseIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="-1 -1 15 15" opacity=".7" {...props}>
    <path
      id="a"
      d="M7.881 7l5.935-5.93a.627.627 0 0 0-.887-.886L7.001 6.119 1.07.184a.627.627 0 0 0-.886.887L6.12 6.999.185 12.93a.625.625 0 0 0 0 .886.625.625 0 0 0 .887 0L7 7.88l5.928 5.935a.625.625 0 0 0 .887 0 .625.625 0 0 0 0-.887L7.881 7z"
    />
  </Icon>
);

export default CloseIcon;
