import React, { useCallback, useEffect } from 'react';
import Modal, { IModalProps } from './Modal';
import { useServer } from 'utils/hooks/useServer';

const ESC_KEY = 'Escape';
const KEYBOARD_ESC_EVENT_TYPE = 'keyup';

export interface IClosableModal extends Omit<IModalProps, 'container'> {
  closeModal: () => void;
  show: boolean;
}

const ClosableModal: React.FC<IClosableModal> = ({ onClose, onOpen, children, closeModal, show, ...other }) => {
  const isServer = useServer();

  const escFunc = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === ESC_KEY) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    show && document.addEventListener(KEYBOARD_ESC_EVENT_TYPE, escFunc);
    return () => {
      document.removeEventListener(KEYBOARD_ESC_EVENT_TYPE, escFunc);
    };
  }, [escFunc, show]);

  return !isServer ? (
    <Modal
      show={show}
      onClick={closeModal}
      onOpen={onOpen}
      onClose={onClose}
      container={document.getElementById('portal-root') as HTMLElement}
      {...other}
    >
      {children}
    </Modal>
  ) : null;
};

export default ClosableModal;
