import { css, FlattenSimpleInterpolation } from 'styled-components';
import { Transitions } from 'theme';
import { mediaSmDown, mediaMdDown } from 'theme/helpers/css';
import { getHslValuesFromRgb, hex2rgba } from './helpers';

interface IAnyProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type style = string | FlattenSimpleInterpolation;

export const addStyleIfPropTruly = (propName: string, style: style) => (props: IAnyProp) => {
  if (props[propName]) {
    return css`
      ${style}
    `;
  }
};

export const addXsStyleIfPropTruly = (propName: string, style: style) => (props: IAnyProp) => {
  if (props[propName]) {
    return mediaSmDown`
      ${style}
    `;
  }
};

export const addMdStyleIfPropTruly = (propName: string, style: style) => (props: IAnyProp) => {
  if (props[propName]) {
    return mediaMdDown`
      ${style}
    `;
  }
};

export const highlightBgOnHover = (color: string) => {
  const [h, s, l, a] = getHslValuesFromRgb(color.includes('#') ? hex2rgba(color) : color);
  const highlightColor = `hsla(${h}, ${s - 8}%, ${l - 4}%, ${a})`;

  return `
    background-color: ${color};
    transition: ${Transitions.bg};

    &:hover,
    &:focus,
    &:active {
      background-color: ${highlightColor};
    }
  `;
};
