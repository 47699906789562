import React from 'react';
import { Box } from '@igooods/ui-kit';
import { colors } from 'constants/colors';
import { Text } from 'Components/Typography';
import { SubLinksContainer } from './styled';
import { subLinks } from '../consts';
import FaqLink from '../FaqLink';

const SubLinks: React.FC = () => {
  return (
    <SubLinksContainer>
      {subLinks.map(({ title, url }) => (
        <Box key={url} py={1.25}>
          <FaqLink href={url}>
            <Text color={colors.warmGrey}>{title}</Text>
          </FaqLink>
        </Box>
      ))}
    </SubLinksContainer>
  );
};

export default SubLinks;
