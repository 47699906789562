import {
  useEffect,
  useCallback,
  useLayoutEffect,
  useState,
  useReducer,
  MutableRefObject,
  SetStateAction,
  Dispatch,
  useRef,
  RefObject,
} from 'react';
import { EnumFetch } from 'constants/enums';
import { AnyAction } from 'redux';
import debounce from 'lodash.debounce';
import { isBrowser } from './helpers';
import { CATALOG_FOOTER_ID, FOOTER_HEIGHT } from 'Components/Footer/Footer';
import { SUPPORT_BUTTON_CLASSNAME } from 'constants/constants';

export interface IUseDataApiState<T> {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  data: T;
}

const initialDataApiHookState: IUseDataApiState<null> = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  data: null,
};

const dataFetchReducer = (state: IUseDataApiState<any>, action: AnyAction) => {
  switch (action.type) {
    case EnumFetch.init:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        isError: false,
      };
    case EnumFetch.success:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case EnumFetch.fail:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isError: true,
      };
    default:
      return initialDataApiHookState;
  }
};

export function useDataApi<T>(
  initialData: T | null,
  initialPromise?: () => Promise<T> | undefined
): [IUseDataApiState<T>, Dispatch<SetStateAction<Promise<any>>>] {
  const [promise, setPromise] = useState(initialPromise);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isSuccess: false,
    isLoading: true,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: EnumFetch.init });

      try {
        const result = await promise;

        if (!didCancel) {
          // @note for chrome dev tools when use offline mode
          if (result instanceof Error) {
            dispatch({ type: EnumFetch.fail });
          } else {
            dispatch({ type: EnumFetch.success, payload: result });
          }
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: EnumFetch.fail });
        }
      }
    };

    if (promise) (async () => fetchData())();

    return () => {
      didCancel = true;
    };
  }, [promise]);

  return [state, setPromise as Dispatch<SetStateAction<Promise<T>>>];
}

export const useOnClickOutside = (ref: MutableRefObject<any>, handler: () => void, withHideOnClickOutside = true) => {
  useEffect(() => {
    if (!withHideOnClickOutside) {
      return;
    }
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      // onClickOutside triggers when scrollbars got clicked - its wrong
      // that hack resolves the issue(for now, im not sure is it perfect)
      // Similar issue https://github.com/airbnb/react-outside-click-handler/issues/19
      const notGlobalNode = target.nodeName !== 'HTML';

      if (ref.current && !ref.current.contains(target) && notGlobalNode) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('contextmenu', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('contextmenu', handleClickOutside);
    };
  }, [ref, handler, withHideOnClickOutside]);
};

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useWait<T>(value: T, delay: number) {
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    setIsWait(true);

    const handler = setTimeout(() => {
      setIsWait(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return isWait;
}

export function usePromise<T>(
  initialData: T,
  initialPromise?: () => Promise<T> | undefined
): [IUseDataApiState<T>, Dispatch<SetStateAction<Promise<any>>>] {
  const [promise, setPromise] = useState(initialPromise);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isSuccess: false,
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: EnumFetch.init });

      try {
        const result = await promise;
        if (!didCancel) {
          dispatch({ type: EnumFetch.success, payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: EnumFetch.fail });
        }
      }
    };

    if (promise) (async () => fetchData())();

    return () => {
      didCancel = true;
    };
  }, [promise]);

  return [state, setPromise as Dispatch<SetStateAction<Promise<T>>>];
}

export const useScroll = (
  handleScroll: () => void,
  scrollableElement: HTMLElement | null,
  DEBOUNCE_TIME: number,
  extraDeps: any[] = []
) => {
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, DEBOUNCE_TIME);

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', debouncedHandleScroll);

      return () => {
        return scrollableElement.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
  }, [DEBOUNCE_TIME, handleScroll, scrollableElement, ...extraDeps]);
};

export const useWindowResize = (handleResize: () => void, deps: any[]) => {
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, ...deps]);
};

export const useScrollTop = (scrollableElement: HTMLElement | null, extraDeps: any[] = []) => {
  useEffect(() => {
    if (scrollableElement && scrollableElement.scrollTop) {
      scrollableElement.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [scrollableElement, ...extraDeps]);
};

export const useStickySupportButton = (scrollableElement: HTMLElement | null, btnContainer: HTMLElement | null) => {
  const footerBtnClassName = SUPPORT_BUTTON_CLASSNAME;
  const minBottomGap = 20;
  const btnPosition = useRef<number>(0);

  const handleScroll = useCallback(() => {
    const chatBtn = document?.querySelector(footerBtnClassName) as HTMLElement;

    if (chatBtn && scrollableElement && btnContainer) {
      const viewportHeight = scrollableElement.clientHeight; // высота вьюпорта
      const containerTopPos = btnContainer.getBoundingClientRect().top; //верхняя граница контейнера для кнопки относительно вехней границы вьюпорта
      const containerRightPos = btnContainer.getBoundingClientRect().right;
      const chatBtnHeight = chatBtn.clientHeight;

      const containerBottomPos = viewportHeight - containerTopPos; //верхняя граница контейнера для кнопки относительно нижней границы вьюпорта
      const chatBtnBottomPos = containerBottomPos - chatBtnHeight;

      if (chatBtnBottomPos >= 0) {
        const newPosition = chatBtnBottomPos - minBottomGap;
        chatBtn.style.transform = `translateY(-${newPosition}px)`;
        chatBtn.style.left = `${containerRightPos + 24}px`;
        btnPosition.current = newPosition;
      } else if (btnPosition.current !== 0) {
        chatBtn.style.transform = `translateY(0)`;
        chatBtn.style.left = '';
        btnPosition.current = 0;
      }
    }
  }, [btnContainer, footerBtnClassName, scrollableElement]);

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);

      return () => {
        return scrollableElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, scrollableElement]);

  useWindowResize(handleScroll, [scrollableElement]);
};

// Inverts support btn color scheme on reaching footer
// Toggle scheme manually by passing a boolean argument
export const useSupportBtnWithInvertStyles = (manualToogle?: boolean) => {
  const [isBottom, setIsBottom] = useState(false);
  const shouldUpdateOnScroll = typeof manualToogle === 'undefined';
  const scrollableElement = isBrowser && shouldUpdateOnScroll ? window.root : null;
  const handleScroll = useCallback(() => {
    if (!shouldUpdateOnScroll) return;

    if (scrollableElement) {
      setIsBottom(
        scrollableElement.scrollHeight - scrollableElement.scrollTop <=
          scrollableElement.clientHeight + FOOTER_HEIGHT / 2
      ); // половинка высоты футера
    }
  }, [scrollableElement, shouldUpdateOnScroll]);

  useScroll(handleScroll, scrollableElement, 0);

  useEffect(() => {
    const footerBtnId = 'uw-main-button';
    const footerCloseBtnId = 'uw-main-button-close';
    const chatBtn = document?.getElementById(footerBtnId) || document?.getElementById(footerCloseBtnId);

    const addInvertStyles = () => {
      if ((shouldUpdateOnScroll && isBottom) || manualToogle === true) {
        chatBtn?.classList.add('invert');
      } else {
        chatBtn?.classList.remove('invert');
      }
    };

    addInvertStyles();

    chatBtn?.addEventListener('click', addInvertStyles);
    return () => {
      chatBtn?.removeEventListener('click', addInvertStyles);
      chatBtn?.classList.remove('invert');
    };
  }, [isBottom, manualToogle, shouldUpdateOnScroll]);
};

export const usePlaceSupportBtnInFooter = () => {
  const btn = isBrowser && (document.getElementsByClassName(SUPPORT_BUTTON_CLASSNAME)[0] as HTMLElement);
  const catalogFooter = isBrowser && document.getElementById(CATALOG_FOOTER_ID);
  const scrollableElement = isBrowser ? window.root : null;

  const handleScroll = useCallback(() => {
    if (catalogFooter && btn) {
      const footerRect = catalogFooter.getBoundingClientRect();

      btn.style.bottom = 'unset';
      btn.style.top = `${footerRect.top + (footerRect.height - btn.offsetHeight) / 2}px`;
    }
  }, [btn, catalogFooter]);

  useEffect(() => {
    return () => {
      if (btn) {
        btn.style.bottom = '';
        btn.style.top = '';
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (btn && catalogFooter && scrollableElement) {
      handleScroll();
    }
  }, [btn, catalogFooter, scrollableElement, handleScroll]);

  useWindowResize(() => {
    handleScroll();
  }, [catalogFooter, btn, scrollableElement]);
  useScroll(handleScroll, scrollableElement, 0);
  useSupportBtnWithInvertStyles(true);
};

export function useDebounceCallback<T extends any[]>(callback: (...args: T) => void, wait: number) {
  const argsRef = useRef<T>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => cleanup, []);

  return function debouncedCallback(...args: T) {
    argsRef.current = args;
    cleanup();

    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current);
      }
    }, wait);

    return () => {
      cleanup();
    };
  };
}

export const useScript = (url: string) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = false;
    document.body.appendChild(script);

    script.onload = () => setIsScriptLoaded(true);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return [isScriptLoaded];
};

export const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

type TUseScrollToElement = () => [RefObject<HTMLDivElement>, () => void];

export const useScrollToElement: TUseScrollToElement = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  const scrollToBlock = () => {
    const elem = elementRef.current;

    elem?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  return [elementRef, scrollToBlock];
};
