import Http from 'lib/http';
import { ISearchResults } from './types';
import { defaultProductListParams } from 'Modules/Products/http';

export const getFiltersUrl = (shopId: number) => `shops/${shopId}/products/filters`;

export const fetchSearch = (shop_id: number, params?: object, fetcher = Http): Promise<ISearchResults> =>
  fetcher.get(`shops/${shop_id}/products/search`, { limit: 30, ...defaultProductListParams, ...params });

export const fetchProductsCount = (shop_id: number, categoryId: number, params?: object): Promise<{ total: number }> =>
  Http.get(`shops/${shop_id}/products_count`, { category_id: categoryId, ...params });

export const fetchFilters = (shop_id: number, params?: object, fetcher = Http): Promise<{ total: number }> =>
  fetcher.get(getFiltersUrl(shop_id), params);
