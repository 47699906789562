import React, { FC } from 'react';
import LogoWithSlogan from './LogoWithSlogan';
import LinkWrapper from 'Components/Link/LinkWrapper';
import { URL } from 'constants/urlMaps';
import Text from 'Components/Typography/Text';
import ProfileButton from 'Components/Header/ProfileDropdown/ProfileButton';
import { EnumButtonSize } from 'constants/enums';
import { useUpdCity } from 'utils/hooks/useUpdCity';
import {
  StyledThemeButton,
  StickySection,
  ContactsButton,
  CatalogButton,
  Cities,
  Container,
  ExternalNavLink,
  BurgerBtn,
  Nav,
  LogoLink,
  Auth,
} from './styles';

export const LANDING_HEADER_SECTION_TEST_ID = 'LANDING_HEADER_SECTION_TEST_ID';
export const LANDING_NAV_LINKS_TEST_ID = 'LANDING_NAV_LINKS_TEST_ID';
export const LANDING_AUTH_BTN_TEST_ID = 'LANDING_AUTH_BTN_TEST_ID';

const Header: FC = () => {
  const onCityChange = useUpdCity();

  return (
    <StickySection data-testid={LANDING_HEADER_SECTION_TEST_ID}>
      <Container>
        <LogoLink to={URL.root}>
          <LogoWithSlogan />
        </LogoLink>
        <Nav data-testid={LANDING_NAV_LINKS_TEST_ID}>
          <LinkWrapper to={URL.select_address}>
            <Text fontWeight="500">Список магазинов</Text>
          </LinkWrapper>
          <ExternalNavLink href={URL.how_it_works}>
            <Text fontWeight="500">Как это работает</Text>
          </ExternalNavLink>
        </Nav>
        <Cities onCityChange={onCityChange} checkCity />
        <Auth data-testid={LANDING_AUTH_BTN_TEST_ID}>
          <ProfileButton />
        </Auth>
        <ContactsButton />
        <CatalogButton to={URL.select_address}>
          <StyledThemeButton size={EnumButtonSize.normal}>Заказать доставку</StyledThemeButton>
        </CatalogButton>
        <BurgerBtn />
      </Container>
    </StickySection>
  );
};

export default Header;
