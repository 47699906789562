import React from 'react';
import styled, { css } from 'styled-components';
import { OrderStatus } from 'Modules/OrdersHistory/types';
import canceled from './img/canceled.svg';
import { colors } from 'constants/colors';
import { FontSize } from 'theme';
import Text from 'Components/Typography/Text';

export const STATES = {
  [OrderStatus.CREATED]: {
    label: 'Создан',
    color: colors.greyish,
    bigColor: colors.whiteSmoke,
  },
  [OrderStatus.FILLED]: {
    label: 'Ждет сборку',
    color: colors.greyish,
    bigColor: colors.whiteSmoke,
  },
  [OrderStatus.PACKING]: {
    label: 'Собираем',
    color: colors.macaroniAndCheese,
    bigColor: colors.aliceBlue,
  },
  [OrderStatus.PACKED]: {
    label: 'Ждет доставку',
    color: colors.macaroniAndCheese,
    bigColor: colors.aliceBlue,
  },
  [OrderStatus.SHIPPED]: {
    label: 'Курьер забрал заказ',
    color: colors.skyBlue,
    bigColor: colors.aliceBlue,
  },
  [OrderStatus.DELIVERING]: {
    label: 'В\u00A0пути',
    color: colors.skyBlue,
    bigColor: colors.aliceBlue,
  },
  [OrderStatus.DECLINED]: {
    label: 'Отменён',
    color: colors.reddishPink,
    bigColor: colors.mistyRoseLight,
  },
  [OrderStatus.DECLINED_BY_USER]: {
    label: 'Отменён вами',
    color: colors.reddishPink,
    bigColor: colors.mistyRoseLight,
  },
  [OrderStatus.RETURNED]: {
    label: 'Частичный возврат',
    color: colors.paleOliveGreen,
    bigColor: colors.mintHaze,
  },
  [OrderStatus.PAID]: {
    label: 'Доставлен',
    color: colors.paleOliveGreen,
    bigColor: colors.mintHaze,
  },
};

const getBackgroundColorFromStatus = ({ status, big, active }: IOrderStatusLabel) =>
  `background-color: ${big && !active ? STATES[status].bigColor : STATES[status].color};
    transition: background 0.2s ease-out;
  `;

const getStylesFromSize = ({ big }: IOrderStatusLabel) => {
  if (big) {
    return css`
      border-radius: 16px;
      padding: 4px 12px;
    `;
  }
  return `
    border-radius: 8px;
    padding: 0 6px;
    text-transform: lowercase;
    color: ${colors.white};
  `;
};

const getColorFromActive = ({ active }: IOrderStatusLabel) => {
  if (active) {
    return `color: ${colors.white}`;
  }
};

interface IOrderStatusLabel {
  status: OrderStatus;
  big?: boolean;
  className?: string;
  active?: boolean;
}

const OrderStatusLabelStyled = styled(Text).attrs<IOrderStatusLabel>(({ big }) => ({
  size: big ? FontSize.xs : FontSize.xxs,
  $nowrap: true,
}))<IOrderStatusLabel>`
  ${({ status, big }) => {
    const isOrderDeclined = status === OrderStatus.DECLINED || status === OrderStatus.DECLINED_BY_USER;

    if (isOrderDeclined && !big) {
      return css`
        &&&& {
          width: 16px;
          height: 16px;
          font-size: 0;
          background: transparent url(${canceled}) no-repeat;
        }
      `;
    }
    return css`
      box-sizing: border-box;
      ${getStylesFromSize}
      ${getColorFromActive}
      ${getBackgroundColorFromStatus}
    `;
  }}
`;

const OrderStatusLabel: React.FC<IOrderStatusLabel> = props => {
  const { status } = props;
  return <OrderStatusLabelStyled {...props}>{STATES[status].label}</OrderStatusLabelStyled>;
};

export default OrderStatusLabel;
