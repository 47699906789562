import { AnyAction } from 'redux';
import { IPaymentCard } from './types';
import { EnumPaymentCardsTypes } from './actions';

export interface ICardsState {
  list: IPaymentCard[];
  currentCard: Partial<IPaymentCard>;
}

const currentCardInitialState: ICardsState = {
  list: [],
  currentCard: {},
};

export default function currentCardReducer(state = currentCardInitialState, action: AnyAction) {
  switch (action.type) {
    case EnumPaymentCardsTypes.SET_CURRENT_CARD:
      return { ...state, currentCard: { ...action.card } };
    case EnumPaymentCardsTypes.SET_CURRENT_CARDS_LIST:
      return { ...state, list: [...action.cardsList] };
    default:
      return state;
  }
}
