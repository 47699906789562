import React from 'react';
import Icon, { IIcon } from './Icon';

const MenuIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 -1 16 15" {...props}>
    <path d="M0.639923 1.00799C0.639923 0.583836 0.983768 0.23999 1.40792 0.23999H14.5919C15.0161 0.23999 15.3599 0.583836 15.3599 1.00799C15.3599 1.43214 15.0161 1.77599 14.5919 1.77599H1.40792C0.983769 1.77599 0.639923 1.43214 0.639923 1.00799ZM0.639923 6.38405C0.639923 5.95989 0.983768 5.61605 1.40792 5.61605H14.5919C15.0161 5.61605 15.3599 5.95989 15.3599 6.38405C15.3599 6.8082 15.0161 7.15205 14.5919 7.15205H1.40792C0.983769 7.15205 0.639923 6.8082 0.639923 6.38405ZM1.40792 10.992C0.983768 10.992 0.639923 11.3358 0.639923 11.76C0.639923 12.1841 0.983769 12.528 1.40792 12.528H14.5919C15.0161 12.528 15.3599 12.1841 15.3599 11.76C15.3599 11.3358 15.0161 10.992 14.5919 10.992H1.40792Z" />
  </Icon>
);

export default MenuIcon;
