import { IApplicationState } from 'reduxStore/types';
import { IUser } from './types';
import { DEFAULT_USER_NAME } from 'constants/constants';

export const getCurrentUser = (state: IApplicationState) => state.auth.user as IUser;

export const getUserTests = (state: IApplicationState) => state.auth.tests;

export const getUserTestParticipation = (testName: string) => (state: IApplicationState) => {
  const test = getUserTests(state).find(({ name }) => name === testName);
  return test && test.participation;
};

export const getAuthState = (state: IApplicationState) => state.auth;
export const getUserIsLoaded = (state: IApplicationState) => getAuthState(state).isLoaded;

export const checkIsExistPhoneAndNameUser = (state: IApplicationState) =>
  (!!getCurrentUser(state).phone || !!getCurrentUser(state).unconfirmed_phone) && !!getCurrentUser(state).name;

export const checkIsTempUser = (state: IApplicationState) => {
  const user = getCurrentUser(state);
  return user.is_temp || !user.hasOwnProperty('is_temp');
};

export const checkIsExistActiveOrders = (state: IApplicationState) => !!getCurrentUser(state).active_orders_count;

export const getUserNameIsSet = (state: IApplicationState) => {
  const user = getCurrentUser(state);
  return user.name && user.name !== DEFAULT_USER_NAME;
};

export const checkAsEntity = (state: IApplicationState) => {
  const user = getCurrentUser(state);
  return user.as_entity || !user.hasOwnProperty('as_entity');
};
