import { DEPLOY_ENV_PRODUCTION } from '../../config/config';

export const getCookieDomain = (hostname?: string) => {
  if (process.env.NODE_ENV === 'production' && DEPLOY_ENV_PRODUCTION) {
    return { domain: '.yest.komus.ru' };
  } else if (hostname) {
    return { domain: hostname };
  }
  // если мы используем на сервере с development и не передаем параметр hostname,
  // вернет пустую строку, так как на сервере нет window
  return { domain: window?.location?.hostname || '' };
};

export const CITY_COOKIE = 'sa_current_city_details_cross_domain';

export const LAST_SUCCESS_ORDER = 'lastSuccessOrder';

export const SESSION_START = 'ig_session_start';

export const USER_HAS_SHOP = 'ig_user_has_shop';

export const SHOPS_LIST_PAYMENT_BANNER_SHOWN = 'shops_list_payment_banner_shown';
export const REF_COOKIE = 'ref';
export const REFERRER_COOKIE = 'http_referrer';

export const REJECTED_CITY_CHECK = 'rejected_city_check';

export const HIDE_MOBILE_APP = 'hide-mobile-app-page';

export const ADMITAD_COOKIE = 'admitad';

export const CATALOG_SESSION_START = 'catalog_session_start';

export const FIRST_TIME_VISIT = 'ig_first_time_visit';
