import { EnumAFEvents } from 'constants/enums';
import { IProduct } from 'Modules/Products/types';
import { ISomeOrder } from './types';
import analyticsManager from '../tagManager/manager';

export interface IAddedProducts {
  [id: string]: boolean;
}

let addedProductsInCart: IAddedProducts = {};

export const resetAddedProductsAnalytics = () => {
  addedProductsInCart = {};
};

export default function addProductInOrderAnalitic(product: IProduct, order: ISomeOrder) {
  const key = `${order.id}_${product.id}`;
  if (!addedProductsInCart[key]) {
    analyticsManager.af(EnumAFEvents.AF_ADD_TO_CART, {
      af_price: product.price,
    });
    addedProductsInCart[key] = true;
  }
}
