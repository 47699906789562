import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FullPageLoading from '../Loading/FullPageLoading';
import qs from 'qs';
import { getOrderInfo, getOrderItems, getOrderPaymentStatus } from 'Modules/OrdersHistory/http';
import { URL } from 'constants/urlMaps';
import { PaymentStatus } from 'Modules/OrdersHistory/types';
import { useDispatch, useSelector } from 'react-redux';
import { setLastSuccessOrder } from '../../Modules/OrdersHistory/actions';
import { sendCustomBugsnagReport } from '../../utils/errors';
import { getCartState } from '../../Modules/Cart/selectors';
import { getCurrentAddress } from '../../Modules/SearchAddress/selectors';
import { getCurrentUser } from '../../Modules/AAA/selectors';
import checkoutPaymentSuccessAnalytic from 'utils/analitic/checkoutPaymentSuccessAnalytic';

const OnlineSuccessPayment: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { orderId } = qs.parse(search.slice(1));
  const dispatch = useDispatch();
  const cart = useSelector(getCartState);
  const address = useSelector(getCurrentAddress);
  const user = useSelector(getCurrentUser);

  useEffect(() => {
    (async () => {
      if (orderId) {
        // fast hack to retry
        try {
          let retries = 1;
          const { order } = await getOrderInfo(+orderId);
          dispatch(setLastSuccessOrder({ id: order.id, delivery_date: order.delivery_date }));
          const checkIfOrderIsPayed = async () => {
            const { status: paymentStatus, message } = await getOrderPaymentStatus(orderId);
            if (paymentStatus === PaymentStatus.ACCEPTED) {
              const orderItems = await getOrderItems(order.id);
              checkoutPaymentSuccessAnalytic({ ...order, products: orderItems.list });
              history.push(URL.checkout_finish);
            } else if (paymentStatus === PaymentStatus.DECLINED) {
              history.push(`${URL.checkout_finish}?orderId=${orderId}&status=500&message=${message}`);
              return;
            } else {
              if (retries === 9) {
                sendCustomBugsnagReport('OrderOnlineFillError', { orderId: orderId });
                history.push(`${URL.checkout_finish}?orderId=${orderId}&status=500&message=${message}`);
                return;
              }
              retries++;
              await new Promise(r => setTimeout(r, 1000));
              await checkIfOrderIsPayed();
            }
          };
          await checkIfOrderIsPayed();
        } catch (e) {
          history.push(URL.select_address);
        }
      }
    })();
  }, [address.city, cart, dispatch, history, orderId, user.email]);

  return <FullPageLoading />;
};

export default OnlineSuccessPayment;
