import { ICheckout } from 'Modules/Checkout/types';

export enum CheckoutActionTypes {
  SET_CHECKOUT = 'SET_CHECKOUT',
}

export interface ICheckoutAction {
  type: string;
  payload: Partial<ICheckout>;
}

export function setCheckoutAction(payload: Partial<ICheckout>): ICheckoutAction {
  return {
    type: CheckoutActionTypes.SET_CHECKOUT,
    payload,
  };
}
