import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const LogoOval: React.FC<IIcon> = ({ color = colors.darkBlueGreen, ...props }) => (
  <Icon width="6" height="6" viewBox="0 0 6 6" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.516095 5.4839C-0.412721 4.55507 -0.0536266 2.69002 1.31818 1.3182C2.69006 -0.0536997 4.55509 -0.412643 5.48391 0.516029C6.41273 1.44486 6.05363 3.30991 4.68175 4.68181C3.30995 6.05363 1.44491 6.41273 0.516095 5.4839Z"
      fill={color}
    />
  </Icon>
);

export default LogoOval;
