import Http from 'lib/http';
import qs from 'qs';
import useSWR, { SWRConfiguration } from 'swr';

const fetcher = (apiVersion?: string) => (url: string, params: string) =>
  Http.get<any>(url, { ...qs.parse(params) }, { apiVersion });

interface IRequestParams {
  [x: string]: string | number | boolean;
}

interface IRequestConfig extends SWRConfiguration {
  apiVersion?: string;
}

export const useRequest = <T>(key: string | [string, IRequestParams] | null, config?: IRequestConfig) => {
  const [url, params] = Array.isArray(key) ? [key[0], key[1]] : [key, null];
  return useSWR<T>(params ? [url, qs.stringify(params)] : url, {
    revalidateOnFocus: false,
    ...(config?.apiVersion && { fetcher: fetcher(config?.apiVersion) }),
    ...config,
  });
};

export const SWRGlobalOptions = { fetcher: fetcher() };
