import React, { useContext } from 'react';

export interface IUserAgent {
  [key: string]: boolean;
}

export const userAgentContextInitialState = {
  isAndroid: false,
  isIOS: false,
  isIE: false,
  isMobile: false,
};

export const UserAgentContext = React.createContext<IUserAgent>(userAgentContextInitialState);

interface IUserAgentProvider {
  value: IUserAgent;
  children: React.ReactNode;
}

export const UserAgentProvider: React.FC<IUserAgentProvider> = ({ value, children }) => {
  return <UserAgentContext.Provider value={value}>{children}</UserAgentContext.Provider>;
};

export const useUserAgentContext = (): IUserAgent => useContext(UserAgentContext);
