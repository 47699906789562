import React from 'react';
import { GlobalStyle } from 'globalStyles';
import LogoIcon from 'Components/Header/Logo/LogoIcon';
import Box from 'Components/Grid/Box';
import Title from 'Components/Typography/Title';
import mascot from 'assets/images/igooods-mascot.png';
import chrome from 'assets/images/browsers/chrome.png';
import opera from 'assets/images/browsers/opera.png';
import firefox from 'assets/images/browsers/firefox.png';
import { useUserAgentContext } from 'utils/userAgent/context';
import { Content, Message, StyledHeader, Browsers, BrowsersLink, BrowserIcon, MascotImage, Container } from './styled';

const browsers = [
  { name: 'Chrome', link: 'https://www.google.com/chrome/', image: chrome },
  { name: 'Opera', link: 'https://www.opera.com/', image: opera },
  { name: 'Firefox', link: 'https://www.mozilla.org/', image: firefox },
];

export const IEPage: React.FC = () => (
  <Container>
    <StyledHeader>
      <LogoIcon mr={2} />
    </StyledHeader>
    <Content>
      <Box mb={5}>
        <MascotImage src={mascot} />
      </Box>

      <Box mb={5}>
        <Title>Ваш браузер устарел</Title>
      </Box>
      <Box mb={15}>
        <Message>
          Мы&nbsp;отказались от&nbsp;поддержки Internet Explorer, так как это может быть причиной медленной работы и
          непредвиденных ошибок. Для комфортного использования установите последнюю версию одного из&nbsp;браузеров:
        </Message>
      </Box>
      <Browsers>
        {browsers.map(browser => (
          <BrowsersLink key={browser.name} href={browser.link}>
            <Box mb={3}>
              <BrowserIcon src={browser.image} />
            </Box>
            {browser.name}
          </BrowsersLink>
        ))}
      </Browsers>
    </Content>
  </Container>
);

const IECheck: React.FC<{ children: React.ReactChild | React.ReactNode }> = ({ children }) => {
  const { isIE } = useUserAgentContext();

  return isIE ? (
    <>
      <GlobalStyle />
      <IEPage />
    </>
  ) : (
    <>{children}</>
  );
};

export default IECheck;
