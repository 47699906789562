import { EnumProductType, IProduct } from 'Modules/Products/types';
import {
  IAdditionalParamsGTag,
  IAnalyticsParams,
  IFormattedProductCriteo,
  IFormattedProductGTag,
  IViewedProduct,
} from './types';
import { EnumFromName } from 'constants/enums';
import { normalizePrice, truncateObjValues } from 'utils/helpers';
import { calcPercentDiscount } from 'utils/helpers';
import { truncateString } from 'utils/helpers';

const getPriceGTag = ({ type, price, price_per_kg }: IProduct) =>
  type === EnumProductType.weight ? price_per_kg / 1000 : price;

const getPriceCriteo = ({ type, price, price_per_kg, item_weight }: IProduct) =>
  type === EnumProductType.weight ? normalizePrice((price_per_kg * item_weight) / 1000) : price;

export function formattedProductForGTag(
  product: IProduct,
  additionalParams: IAdditionalParamsGTag = {}
): IFormattedProductGTag {
  const { type, weight, amount, name, model_id, old_price, price, price_per_kg } = product;
  const currentPrice = type === EnumProductType.piece ? price : price_per_kg;

  return {
    item_id: truncateString(model_id, 25),
    item_name: truncateString(name, 25),
    item_type: truncateString(type, 25),
    ...(product.root_category && { item_category: truncateString(product.root_category, 25) }),
    ...(product.category && { item_category2: truncateString(product.category, 25) }),
    // TODO Jira: https://igooods.atlassian.net/browse/IE-1679
    // item_brand: '',
    currency: 'RUB',
    price: truncateString(getPriceGTag(product), 25),
    quantity: (type === EnumProductType.weight ? weight : amount) ?? 0,
    ...(old_price && { discount: calcPercentDiscount(old_price, currentPrice) }),
    ...(additionalParams && additionalParams),
  };
}

export function formattedProductForCriteo(product: IProduct): IFormattedProductCriteo {
  return {
    id: String(product.model_id),
    quantity: product.amount,
    price: String(getPriceCriteo(product)),
  };
}

export const getItemListId = (from?: EnumFromName, fromId?: number) =>
  (from === EnumFromName.SALES || from === EnumFromName.SET) && fromId ? String(fromId) : '';

export const formattedViewedProductsLists = (products: IViewedProduct[]) => {
  const promoProducts: IViewedProduct[] = [];
  const defaultProducts: IViewedProduct[] = [];

  const productsModelIds = products.map(data => {
    data.params.promotion_id ? promoProducts.push(data) : defaultProducts.push(data);

    return String(data.product.model_id);
  });

  return { promoProducts, defaultProducts, productsModelIds };
};

export const getAdditionalParamsForGTag = (productData: IViewedProduct) => {
  const {
    params: { position, from, fromId },
  } = productData;
  const item_list_id = getItemListId(from, fromId);

  return {
    index: position,
    item_list_name: from,
    ...(item_list_id && { item_list_id }),
  };
};

export const getPromotionParamsForGTag = (analyticsParams: IAnalyticsParams) => {
  return {
    ...(analyticsParams.promotionId &&
      truncateObjValues({
        promotion_id: String(analyticsParams.promotionId),
        ...(analyticsParams.promotionName && { promotion_name: analyticsParams.promotionName }),
        ...(analyticsParams.promotionType && { location_id: analyticsParams.promotionType }),
      })),
  };
};
