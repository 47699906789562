import React, { FC } from 'react';
import Text from 'Components/Typography/Text';
import Flex from 'Components/Grid/Flex';
import { FontSize, FontWeight } from 'theme';
import ExternalLink from 'Components/ExternalLink';
import { useSelector } from 'react-redux';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import SocialsList from 'Components/Footer/SocialsList';
import { colors } from 'constants/colors';
import { isKomus } from '../../../../config/config';
import { Time, StyledFlex, StyledLink, StyledText } from './styled';

const Contacts: FC<{ className?: string }> = ({ className }) => {
  const currentCity = useSelector(getCurrentCity);

  return (
    <StyledFlex className={className}>
      {currentCity?.phone && (
        <ExternalLink href={`tel:8 (495) 729-54-71`}>
          <Text size={FontSize.m} fontWeight={FontWeight.semiBold} $nowrap>
            {`8 (495) 729-54-71`}
          </Text>
        </ExternalLink>
      )}
      <StyledText size={FontSize.m} $nowrap>
        <StyledLink href={`mailto:${'yest@komus.net'}`}>{'yest@komus.net'}</StyledLink>
      </StyledText>
      <Time size={FontSize.s}>Время работы с 8:00 до 18:00, суббота и воскресенье выходные дни</Time>
      {!isKomus && (
        <Flex mt={6} mtXs={4}>
          <SocialsList bgColor={colors.light200} />
        </Flex>
      )}
    </StyledFlex>
  );
};

export default Contacts;
