import React from 'react';
import LinkWrapper from 'Components/Link/LinkWrapper';
import { URL } from 'constants/urlMaps';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'Modules/AAA/selectors';
import { useHistory } from 'react-router-dom';
import { colors } from 'constants/colors';
import { getEntity } from 'Modules/Entity/selectors';
import { EnumEntityStatus } from 'constants/enums';
import UserIcon from 'Components/Icon/UserIcon';
import Flex from 'Components/Grid/Flex';
import { getCurrentShopId } from '../../Modules/Shops/selectors';
import { getCurrentAddressName } from '../../Modules/SearchAddress/selectors';
import { ProfileButton, PersonAvatar, UnconfirmedIndicator } from './styles';

export const USER_MENU_BTN_TEST_ID = 'USER_MENU_BTN_TEST_ID';
export const USER_AVATAR_TEST_ID = 'USER_AVATAR_TEST_ID';
export const USER_ICON_TEST_ID = 'USER_ICON_TEST_ID';
export const UNCONFIRMED_INDICATOR_TEST_ID = 'UNCONFIRMED_INDICATOR_TEST_ID';

const UserButton: React.FC = () => {
  const { avatar_url, as_entity } = useSelector(getCurrentUser);
  const history = useHistory();
  const { state } = useSelector(getEntity);
  const currentShopId = useSelector(getCurrentShopId);
  const addressName = useSelector(getCurrentAddressName);
  const redirectURL = currentShopId && addressName ? URL.profile_orders : URL.select_address;

  const isEntityUnconfirmed = as_entity && state !== EnumEntityStatus.confirmed;

  const ButtonContent = () => {
    return (
      <Flex middle center column pb={avatar_url ? 0 : 1}>
        {isEntityUnconfirmed && <UnconfirmedIndicator data-testid={UNCONFIRMED_INDICATOR_TEST_ID} />}
        {avatar_url ? (
          <PersonAvatar data-testid={USER_AVATAR_TEST_ID} image={avatar_url} />
        ) : (
          <UserIcon color={colors.blackRussian} width="22" height="22" data-testid={USER_ICON_TEST_ID} />
        )}
      </Flex>
    );
  };

  return (
    <>
      {history.location.pathname !== URL.profile_orders ? (
        <LinkWrapper data-testid={USER_MENU_BTN_TEST_ID} to={redirectURL}>
          <ProfileButton>
            <ButtonContent />
          </ProfileButton>
        </LinkWrapper>
      ) : (
        <ProfileButton>
          <ButtonContent />
        </ProfileButton>
      )}
    </>
  );
};

export default UserButton;
